import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import en from "../src/assets/content/en.json";
import Customerdashboard from "../src/assets/content/Customerdashboard.json";
import customerhome from "./assets/content/customer/home.json";
import customerasktheexpert from "./assets/content/customer/asktheexperts.json";
import customerblogs from "./assets/content/customer/blogs.json";
import customerinterest from "./assets/content/customer/interest.json";
import customeraccountsetting from "./assets/content/customer/accountsettings.json";
import customerawardscertificates from "./assets/content/customer/awardscertificates.json";
import customerdetails from "./assets/content/customer/customerdetails.json";
import customerlogo from "./assets/content/customer/logo.json";
import customersidebar from "./assets/content/customer/sidebar.json";
import customerproducts from "./assets/content/customer/custproducts.json";
import customerqnaanswer from "./assets/content/customer/customerqnaanswer.json";
import customerconfirmasktheexpert from "./assets/content/customer/confirmasktheexpert.json";
import customerfinalasktheexpertscreen from "./assets/content/customer/finalasktheexpertscreen.json";
import customeradds from "./assets/content/customer/custadds.json";
import customerqna from "./assets/content/customer/customerqna.json";
import customerviewspecialInfo from "./assets/content/customer/viewspecialInfo.json";
import customersocialmedia from "./assets/content/customer/socialmedia.json";
import customerotherdetails from "./assets/content/customer/otherdetails.json";
import customerregisteredaddress from "./assets/content/customer/registeredaddress.json";
import customeroccupation from "./assets/content/customer/occupation.json";
import instituteDashboard from "../src/assets/content/institute/dashboard.json";
import instituteAccount from "../src/assets/content/institute/myaccount.json";
import instituteExpertrs from "../src/assets/content/institute/myexperts.json";
import institutePayments from "../src/assets/content/institute/mypayments.json";
import instituteWorkspace from "../src/assets/content/institute/myworkspace.json";
import instituteAds from "../src/assets/content/institute/myads.json";
import instituteproduct from "../src/assets/content/institute/product.json";
import instituteoffer from "../src/assets/content/institute/myoffer.json";



// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: { ...en, Customerdashboard, instituteDashboard, instituteAccount, instituteExpertrs, institutePayments, instituteWorkspace, instituteAds, customerhome, customerasktheexpert, customerblogs, customerinterest, customeraccountsetting,customerawardscertificates,
      customerdetails,customerlogo,customeroccupation,customerotherdetails,customerregisteredaddress,customersocialmedia,customerviewspecialInfo,customeradds,customerconfirmasktheexpert,customerfinalasktheexpertscreen,customerqna,customerqnaanswer,customerproducts,customersidebar, instituteproduct,instituteoffer
     }
     
  },
  // fr: {
  //   translation: {
  //     "Welcome to React": "Bienvenue à React et react-i18next"
  //   }
  // }
};

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: document.querySelector('html').lng,
    fallbackLng: "en",
    //saveMissing: true, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    //  backend:{
    //       //loadPath: './assets/content/{{lang}}.json'
    //      loadPath: 'assets/locales/{{lng}}/translation.json'
    //  }
  });

export default i18n;