import React from 'react'
import { useTranslation } from "react-i18next";
import logIN from "../../../assets/images/login.png";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

const LoginJsx = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
    isFirstTime,
    onFirstLoginClick
  } = props;

  return (
    <>
      <section className="loginscreeninstitute loginmainds">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              {/* <a  className="logincs">
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>Customer Login</h3>
                    <p>Login here with Customer’s Credentials</p>
                  </figcaption>
                </div>
              </a> */}

              

              <a href="/login" className="logincs active">
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>Institution Login</h3>
                    <p>Login here with Institution’s Credentials</p>
                  </figcaption>
                </div>
              </a>

              <Link to ="/expertLogin" className="logincs ">
                <div className="loginscreenimage">
                  <figure><img src={logIN}  alt={' '}/></figure>
                  <figcaption>
                    <h3>Expert Login</h3>
                    <p>Login here with Expert’s Credentials</p>
                  </figcaption>
                </div>
              </Link>

              <a href="/customerLogin" className="logincs ">
                <div className="loginscreenimage">
                  <figure><img src={logIN} alt={' '} /></figure>
                  <figcaption>
                    <h3>Customer Login</h3>
                    <p>Login here with Customer’s Credentials</p>
                  </figcaption>
                </div>
              </a>
            </div>
            <div className="col-lg-7 col-sm-7">
              <div className="institutelogintext martop60 paddingbot60">
                <h2 className="h2_style text-center font-weight-600">{t("LOGIN.TITLE")}</h2>
                <h6 className="h6_style text-center">{t("LOGIN.SUB_TITLE")}</h6>
                <p className="smalltext martop30">{t("LOGIN.DESCRIPTION")}</p>

                <div className="mb-4 input-container form-group">
                          <input
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            required
                            type={'text'}
                            placeholder=" "
                            className={
                             formError['email']
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={'email'}
                            name={'email'}
                            maxLength={50}

                          />
                          <div className="cut"></div>
                          <label className="placeholder">{t(`Email`)} </label>

                          <span className="text-danger">
                            {
                              formError['email'] &&
                              (formError['email'] === "req"
                                ? t('Email is required!')
                                : t('Enter a valid email address.'))}
                          </span>

                        </div>

                        {
                          isFirstTime && <button className='mt-4 ctatwobutton' onClick={onFirstLoginClick}>Login</button>
                        }

                        {!isFirstTime && <div className="mb-4 input-container form-group">
                          <input
                            onChange={onInputChangeHandler}
                            onBlur={onTouchHandler}
                            required
                            type={'password'}
                            placeholder=" "
                            className={
                              formError['password']
                                ? "input form-control  is-invalid"
                                : "input form-control"
                            }
                            id={'password'}
                            name={'password'}
                            maxLength={16}

                          />
                          <div className="cut"></div>
                          <label className="placeholder">{t(`password`)} </label>

                          <span className="text-danger">
                            {
                              formError['password'] &&
                              (formError['password'] === "req"
                                ? t('Password is required!')
                                : t('Enter a valid Password.'))}
                          </span>

                        </div>
}
                { !isFirstTime &&  <> <div id="forgetpass" className="form-text" onClick={(e)=> {history.push('/forgotPassword'); localStorage.setItem('requestType','forgot-password'); localStorage.setItem('flowName','login');localStorage.setItem('flowType','institute')}}>{t("LOGIN.FORGOT")}</div>
                  <button type="submit" className={
                    formSubmitButtonDisable
                      ? "btn-secondary btn mt-4 ctatwobutton"
                      : "mt-4 ctatwobutton"
                  }
                  onClick={formSubmitHandler}
                    disabled={formSubmitButtonDisable}>{isLoading ? t("COMMON.PROCESSING") : t("LOGIN.LOGINBUTTON")}</button></>}
                  <div className="form-text mt-3">{t("LOGIN.IS_ACCOUNT")} </div>
                  {/* <Link className={'mt-4 ctaonebutton'} to={getRoute('institutional','login')}>Register Now</Link> */}
                  {/* <button type="button" className="mt-4 ctaonebutton" onClick={(e)=> history.push('/institutionalRegistration')} >{t("LOGIN.REGISTER_BUTTON")}</button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </>

  );
};

export default LoginJsx;
