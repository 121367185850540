import React from 'react'
import Select from 'react-select';
import InputMask from 'react-input-mask';

const RegisteredAddressJsx = (props) => {
  const {
    onInputChangeHandler, provinceList, handleChange, countryList,
    editAddress, changeAddressState, onAddressButtonClick,
    onTouchHandler, formError
  } = props;
  const isRequired = true
  const information = props?.wholeData
  const totalInformation = props.responseData
  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'
  return (
    <>
      {!editAddress ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Registered Address</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeAddressState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
          </div>
        </div>


        <div className="form-fieldinner">
          <label>Street Address *  </label>
          <div className="fieldans">{totalInformation?.address}</div>
        </div>
        <div className="disgrid grid-4">
          <div className="form-fieldinner">
            <label>Provience *</label>
            <div className="fieldans">{totalInformation?.state?.name} </div>
          </div>
          <div className="form-fieldinner">
            <label>City *</label>
            <div className="fieldans">{totalInformation?.city}</div>
          </div>
          <div className="form-fieldinner">
            <label>Postal Code *  </label>
            <div className="fieldans">{totalInformation?.postalCode}</div>
          </div>
          <div className="form-fieldinner">
            <label>Country *  </label>
            <div className="fieldans">{totalInformation?.country?.name} </div>
          </div>
        </div>
      </div> : null}
      {editAddress ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Registered Address</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeAddressState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
          </div>
        </div>

        <div className="form-fieldinner">
          <div className="input-container form-group">
            <input type="text" className={
              isRequired && formError["street"]
                ? "input form-control  is-invalid"
                : "input form-control"
            } placeholder=" " name="street" maxLength={100} value={information.street} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
            <label className="placeholder">Street Address * </label>
            <span className="text-danger">
              {isRequired &&
                formError["street"] &&
                (formError["street"] === "req"
                  ? "street is required!"
                  : "Please enter a proper street!")}
            </span>
          </div>
        </div>
        <div className="disgrid grid-4">
          <div className="form-fieldinner">
            <div className="input-container form-group">
              {/* <input type="text" className={
                isRequired && formError["firstName"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="province" value={information.province} onChange={onInputChangeHandler} />
              <label className="placeholder">Provience *</label> */}
              <Select className={`selectbox`}
                name="province"
                closeMenuOnSelect={true}
                placeholder={" "}
                options={provinceList}
                onChange={(e) => handleChange(e, 'province')}
                value={provinceList.filter(ite => ite.value === information.province)}
              />
              <div className="placeholder">province</div>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <input type="text" className={
                isRequired && formError["city"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="city" maxLength={50} value={information.city} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              <label className="placeholder">City *</label>
              <span className="text-danger">
                {isRequired &&
                  formError["city"] &&
                  (formError["city"] === "req"
                    ? "city is required!"
                    : "Please enter a proper city!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              {/* <input type="text" className={
                isRequired && formError["firstName"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" " name="postalCode" value={information.postalCode} onChange={onInputChangeHandler} />
              <label className="placeholder">Postal Code *</label> */}
              <InputMask mask={getMuskPattern('postalCode')} name="postalCode" value={information.postalCode} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps} type="tel" className={
                  isRequired && formError["postalCode"]
                    ? "input form-control  is-invalid"
                    : "input form-control"
                } placeholder=" " disableUnderline />}
              </InputMask>
              <div className="cut"></div>
              <label className="placeholder">Postal Code *</label>
              <span className="text-danger">
                {isRequired &&
                  formError["postalCode"] &&
                  (formError["postalCode"] === "req"
                    ? "postalCode is required!"
                    : "Please enter a proper postalCode!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <Select className={`selectbox`}
                closeMenuOnSelect={true}
                placeholder={" "}
                name="country"
                options={countryList}
                onChange={(e) => handleChange(e, 'country')}
                value={countryList.filter(ite => ite.value === information.country)}
              />
              <div className="placeholder">Country *</div>
            </div>
          </div>


        </div>

        <button type="button" className="ctaonebutton widthhalf" onClick={() => onAddressButtonClick()}>Save</button>
      </div> : null}
    </>
  );
};

export default RegisteredAddressJsx;
