import React, { useEffect } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";
import email from "../../../../assets/Instituteppvimages/email.svg";
import Sidebar from "../../institute/sidebar/sidebar.js";
import InputMask from 'react-input-mask';
import Registered from "../myExpert/myExpert.js"
import { NEW_URL } from '../../../../constants/applicationConstants'; 
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import "../myExpert/myExpert.model.css"

import logout from "../../../../assets/images/logout.svg";
import myexpertbg from "../../../../assets/Instituteppvimages/myexpertbg.svg";
import { PreNexPagination } from '../../../../components/common/pre_next_pagination/pre_next_pagination';

const MyExpertJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();


  const {
    categoryData, formData,
    onInputChange,
    formError,
    onTouchHandler,
    onSubmitButtonClick,
    expertData,
    activeTab,
    onTabClick,
    onFirstLoginClick
  } = props;

  const loginData = JSON.parse(sessionStorage.getItem('loginData'));


  const getInstitutionName = () => {
    let tempArr = [];

    expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())




  }
  useEffect(() => {
    getInstitutionName()
  });

  const planType = sessionStorage.getItem("planName")
  const addAnotherExpert = () => {
    let token = sessionStorage.getItem("token")
    localStorage.setItem("instituteExpertToken", token)
    localStorage.setItem("instituteExpert", true)
    localStorage.setItem("adInsExpert", true)

    history.push("/expertRegistration");
  }
  return (
    <>
      <>

        {  activeTab === 'partial' ?
          <section className="entiredashboard maincustomerdash main">
            <div className="container">
              <div className="row dashboard-decoration">
                <div className="col-lg-3 col-sm-3">
                  <Sidebar />
                </div>
                <div className="col-lg-9 col-sm-9 dashboard-data">
                  <div  >
                    <div  >
                      <div className="row" >

                        <div className="col-lg-7  col-sm-7">

                          <p className="dahsboardTitle">{t("instituteExpertrs.HEADING")}</p>
                          <p className="dahsboardSubTitle"> <Trans i18nKey="instituteExpertrs.SUBHEADING" ></Trans> </p>
                        </div>
                        <div class="col-lg-5  col-sm-5"  >
                          <div class="logoutbutton">  <img class="logoutImg" src={logout} alt={''} />
                            <p className="dashBoardLogout ">{t("instituteDashboard.LOGOUT")} </p>
                          </div>
                        </div>
                      </div>
                      <div className="dashboardrightpannerinnerright">

                        <div id="logout" className="modal-window">
                          <div>
                            <a title="Close" className="modal-close">{t("instituteExpertrs.CLOSE")}</a>
                            <div className="modal-header">

                            </div><div className="modal-body mb-4">
                              <div className="modal-body-inner">
                                <h2 className="h2_style text-center">{t("instituteExpertrs.LOGOUTTITLE")}</h2>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="ctaonebutton"> {t("instituteExpertrs.SAVE")}</button>
                              <button type="button" className="ctatwobutton"> {t("instituteExpertrs.SAVE")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row add-expert">
                      <div className="col-6 add-expert-data">
                        <h3 className="add-expert-title">{t("instituteExpertrs.ADDEXPERT")}</h3>
                        <p className="add-expert-subtitle">{t("instituteExpertrs.ADDEXPERTTITLE")}</p>
                        <p className="add-expert-limit">{t("instituteExpertrs.ADDEXPERTLIMIT")} {loginData?.totalExpertsLimit} </p>
                        <div className="addexpertright">
                          {planType && planType === "Premium" ? <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>
                            :
                            <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>

                          }
                        </div>
                      </div>
                      <div className="col-6 add-expert-img">
                        <img src={myexpertbg} alt={''} />
                      </div>

                    </div>

                    <div className="myexpert-nav row">
                      <p className="partially-registered col-6" onClick={() => onTabClick('acntStng')}>{t("instituteExpertrs.REGEXPERTS")}</p>
                      <p className="registered-experts col-6">{t("instituteExpertrs.PARREGEXPERTS")}</p>
                    </div>
                    <div className="expert-section grid">
                      {props?.expertData?.length > 0 ? (
                        props.expertData.map(data => (
                          <div className="expert-div" key={data.slugUrl}>
                            <div className="expert-profile">
                              <img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`} alt={' '} />
                            </div>
                            <div className="plan-name">{data.planName || t("instituteExpertrs.NOTAVAILABLE")}</div>
                            <div className="free-button">Free</div>
                            <div>
                              <h3 className="expert-name">{`${data?.firstName} ${data?.lastName}`}</h3>
                              <div className="expert-category" >{data?.expertSpecializations[0]?.parentInstitutionCategory}</div>
                              <ul>
                                <li className="expert-location"><span><img src={map} alt={''} /></span> {data?.expertSpecializations[0]?.city || "NA"}</li>
                                <li className="expert-call"><span><img src={call} alt={''} /></span> {data.phoneNumber && formatCanadianPhoneNumber(data.phoneNumber)}</li>
                                <li className="expert-email"><span><img src={email} alt={''} /></span> {data.email}</li>
                              </ul>
                            </div>
                            <button className="view-profile-button" onClick={() => onFirstLoginClick(data)}>{t("instituteExpertrs.RESUME")}</button> 
                          </div>
                        ))
                      ) : (
                        <h3>{t("instituteExpertrs.NOEXPERTMSG")}</h3>
                      )}
                    </div>
                    {/* {props?.expertData?.length > 6 && <div className="pagination-center"><PreNexPagination
                    limit={6}
                    totalCount={props?.expertData?.length}
                    onPaginationButtonClick={onPaginationButtonClick}/> </div>
                      } */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          : <Registered />}

      </>
    </>

  );
};

export default MyExpertJsx;
