import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import OffersJsx from './offers.jsx'


export default class Offers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,

            keyword: null,
            selectedCategoryId: null,
            subCategories: [],
            categories: [],
            checkedCats: [],
            checkedSubCats: [],
            oldest: false,

            allOffersData: null,
            overallOffers: null,
            topOffers: null,
            isProcessing: false,

            formSubmitButtonDisable: true,
            isLoading: false,
            selectAll: false,
            expertData: [],
            page: 1,
        }
    }


    componentDidMount = () => {
        this.getTopOffers();

        this.getcategorySubcategory();
        setTimeout(() => {
            this.selectAllIds()

        }, 1000);
        this.props.match.params.keyword && this.fetchSearchData(this.props.match.params.keyword);


    }
    onPaginationButtonClick = (page) => {
        window.scrollTo(1200,1200);
        this.setState({ page: page })
    }

    getcategorySubcategory = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

    hitOldest = (action) => {

        this.setState({ oldest: action, isProcessing: true })
        setTimeout(() => {
            this.getAllFeauturedOffersData()
            this.selectAllIds()
        }, 500);
    }
    getAllFeauturedOffersData = () => {
        const selectedType = this.props.match.params.type;
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/search-offers?sortBy=${this.state.oldest ? "expired" : "latest"}`).then(res => {
            this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
        })

    }

    selectAllHit = (e) => {
        const { checked } = e.target
        if (!checked) {
            this.setState({ selectAll: false })
        } else {
            this.setState({ selectAll: true })
        }
        if (checked) {
            this.setState({ allOffersData: this.state.overallOffers, keyword: "" })
            this.selectAllIds()
        }
    }

    searchOfferByKeyword = (e) => {
        const data = e.target.value
        this.setState({ keyword: data })
    }

    hitSearch = () => {
        this.props.history.replace('/')
        this.props.history.push(`/offers/${this.state.keyword}`)
        this.fetchSearchData(this.state.keyword)
    }

    fetchSearchData = (data) => {

        axios.get(`${baseURL}/search-content?keyword=${data}`).then(res => {
            this.setState({ allOffersData: res.data.data.offers, overallOffers: res.data.data.offers, isProcessing: false })
        })
    }


    getTopOffers = () => {
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/topOffers`).then(res => {
            this.setState({ topOffers: res.data.data, isProcessing: false })
        })
    }


    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    // filter all selected id
    selectAllIds = () => {

        const institutionCategoryIds = this.state.categoryData?.map(category => category.institutionCategoryId);
        this.setState({ categories: institutionCategoryIds })

        // Extract sub-institution category IDs as well
        this.state.categoryData?.forEach(category => {
            if (category?.subInstitutionCategories) {
                const subIds = category?.subInstitutionCategories?.map(subCategory => subCategory.institutionCategoryId);
                // institutionCategoryIds.push(...subIds);
                this.setState(prevState => ({
                    subCategories: [...prevState.subCategories, ...subIds] // Spread existing items and add new items
                }));
                // this.setState({ subCategories: ...subIds })

            }
        });

        this.setState({ institutionCategoryIds });
    }


    filterCatData = () => {
        const catDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.subCategories.includes(offer.institutionSubCategoryId)
        );
        this.setState({ allOffersData: catDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }


    }
    filterSubData = () => {
        const subCatDataFiltered = this.state.overallOffers.filter(offer =>
            this.state.subCategories.includes(offer.institutionSubCategoryId)

        );
        this.setState({ allOffersData: subCatDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }

    }


    selectCategory = (data, e) => {
        sessionStorage.setItem("changing",true)
        const { checked } = e.target
        this.setState({ selectAll: false })
        // 
        const selectedId = data.institutionCategoryId;
        this.setState({ selectedCategoryId: selectedId });

        const selectedCategory = this.state.categoryData.find(category => category.institutionCategoryId === selectedId);


        if (selectedCategory) {
            const insInsideCat = selectedCategory.subInstitutionCategories
            const institutionCategoryIds = insInsideCat.map(category => category.institutionCategoryId);


            if (checked) {
                this.setState((prevState) => ({
                    subCategories: [...prevState.subCategories, ...institutionCategoryIds],
                }));
                this.setState({ page: 1 })


            } else {

                this.setState((prevState) => ({
                    subCategories: prevState.subCategories.filter(
                        (id) => !institutionCategoryIds.includes(id) // Remove IDs present in newIds
                    )
                }));
                this.setState({ page: 1 })


            }
        } else {
            this.setState({ subCategories: [] });
        }
        setTimeout(() => {
            this.filterCatData()

        }, 500);


        this.setState((prevState) => {
            const { categories } = prevState;

            // Check if the id is already in the categories
            if (categories.includes(data.institutionCategoryId)) {
                // Remove the id
                return { categories: categories.filter(selectedId => selectedId !== data.institutionCategoryId) };
            } else {
                // Add the id
                return { categories: [...categories, data.institutionCategoryId] };
            }
        });
    }


    handleCheckboxChange = (id) => (event) => {
        if (event.target.checked) {
            // Add ID to the selected array if not already present
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.includes(id)
                    ? prevState.selectedIds
                    : [...prevState.selectedIds, id]
            }));
        } else {
            // Remove ID from the selected array when unchecked
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.filter((selectedId) => selectedId !== id)
            }));
        }
    };

    selectSubCategory = (data) => {
        sessionStorage.setItem("changing",true)

        this.setState((prevState) => {
            if (prevState.subCategories.includes(data.institutionCategoryId)) {
                // If the ID is already selected, remove it
                return { subCategories: prevState.subCategories.filter((prevId) => prevId !== data.institutionCategoryId) };
            } else {
                // If the ID is not selected, add it
                return { subCategories: [...prevState.subCategories, data.institutionCategoryId] };
            }
        });

        setTimeout(() => {
            this.filterSubData()

        }, 500);
    }



    render() {
        const { id } = this.props.match.params;

        return (
            <div>
                <OffersJsx
                    subCategories={this.state.subCategories}
                    categories={this.state.categories}
                    searchOfferByKeyword={this.searchOfferByKeyword}
                    hitSearch={this.hitSearch}
                    selectAllHit={this.selectAllHit}
                    keyword={this.state.keyword}
                    getAllFeauturedOffersData={this.getAllFeauturedOffersData}

                    topOffers={this.state.topOffers}

                    allOffersData={this.state.allOffersData}
                    oldest={this.state.oldest}
                    hitOldest={this.hitOldest}
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    page={this.state.page}
                    selectCategory={this.selectCategory}
                    selectSubCategory={this.selectSubCategory}
                    selectAll={this.state.selectAll}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}