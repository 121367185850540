import React, { useState } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import CustomerQnaAnswer from '../customerQnaAnswer/customerQnaAnswer';
import CustomerQna from '../customerQna/customerQna';
import SuggestedOffer from '../suggestedOffers/suggestedOffer';
import discount from "../../../../assets/images/discount.png";
import pending from "../../../../assets/images/pending.png";
import ans from "../../../../assets/images/ansicon.png";
import seacrchicon from "../../../../assets/images/seacrchicon.png";

import "../customerDashboard/customerDashboard.css";
import "../customerDashboardResponsive.css";
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../common/pagination/pagination.js';




const CustomerDashboardJsx = (props) => {
  const { t } = useTranslation();

  const {
    activeTab, 
    onTabClick,
    seTypeSearchValue,
    redirectSearchPage
  
  } = props;
 
  return (
    <>
      <section className="entiredashboard maincustomerdash customer-dashboard">
        <div className="container">

          <div className="row bg-color-customer">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel">

                <div className="dashboardlinkpop">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9 dashboardcolor p-0">
              <div className="dashboardrightpanel ">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft dashboard">
                    <h2 className="h2_style font-weight-600">{t("customerhome.HEADING")}</h2>
                    <h5 className="h5_style">{t("customerhome.PARAGRAPH")}</h5>
                    <h5 className="h5_style">{t("customerhome.SUBPARAGRAPH")} </h5>
                    <h4 className="h5_style dashboarditext">{t("customerhome.ITALICPARAGRAPH")}</h4>
                  </div>
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">{t("customerhome.LOGOUTBUTTON")}</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a title="Close" className="modal-close">{t("customerhome.CLOSE")}</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">{t("customerhome.LOGOUTALERT")}</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> {t("customerhome.SAVEBUTTON")}</button>
                          <button type="button" className="ctatwobutton"> {t("customerhome.SAVEBUTTON")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40 customermain">
                  <ul>
                    <li className={`${activeTab === 'answered' ? 'active' : ''}`} onClick={() => onTabClick('answered')}><img src={ans} alt=""/>{t("customerhome.ANSWEREDBUTTON")}</li>

                    <li className={`${activeTab === 'pending' ? 'active' : ''}`} onClick={() => onTabClick('pending')}><img src={pending} alt=""/> {t("customerhome.PENDINGBUTTON")}</li>
                    <li className={`${activeTab === 'suggestedOffer' ? 'active' : ''}`} onClick={() => onTabClick('suggestedOffer')}><img src={discount} alt="" /> {t("customerhome.LATESTOFFERSBUTTON")}</li>
                  </ul>
                </div>
                <div>
                  {activeTab === 'answered' ? <CustomerQnaAnswer /> : activeTab === 'pending' ? <CustomerQna /> : <SuggestedOffer />}
                  
                </div>

                {activeTab !== 'suggestedOffer' && <div className="searchincustomer">
                  <section className="searchpageherosection backwhite">

                    <div class="searchmaininnerhero heroleftpanel">
                      <h2 class="text-center"> {t("customerhome.SEARCHTITLE")}</h2>
                      <h4 className="h4_style text-center">{t("customerhome.SEARCHPARAGRAPH")}</h4>
                      <h4 className="h4_style text-center">{t("customerhome.SEARCHSUBPARAGRAPH")}</h4>
                      <h4 className="h4_style text-center">{t("customerhome.SEARCHSPANPARAGRAPH")}</h4>
                      <h2 className="h2_style text-center">{t("customerhome.SEARCHQUESTIONPARAGRAPH")} </h2>
                      <div className="myexpertformarea searcharea searchmainara ">
                        <div className="input-container form-group">
                          <select className="input form-control form-select" onChange={(e) => seTypeSearchValue(e.target.value)} placeholder=" " name="category">
                            <option value="/searchExpert">{t("customerhome.EXPERTOPTION")}</option>
                            <option value="/searchInstitute">{t("customerhome.EXPERTINSTITUTION")}</option>
                          </select>
                          {/* <div className="placeholder">{t("customerhome.SELECTBUTTON")}</div> */}
                        </div>
                        <div className="input-container form-group">
                          <button type="submit" onClick={redirectSearchPage} className="ctaonebutton searchiconbutton"><img src={seacrchicon} alt="" /></button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>}
              </div>
               
            </div>
          </div>
        </div>
       
      </section>
      <Footer />

    </>

  );
};

export default CustomerDashboardJsx;