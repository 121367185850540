import React from 'react';

import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";

export const AdsSection = (props) => {
    const { profileData,legalName } = props;
    var settings = {
        dots: true,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight:true,
        // initialSlide:2  
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerPadding: '0px',
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerPadding: '0px',
                slidesToScroll: 1
              }
            }
          ]  
      };
      var settingstwo = {
        dots: false,
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        adaptiveHeight:true,
        // initialSlide:2  
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '0px',
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                centerPadding: '0px',
                slidesToScroll: 1
              }
            }
          ]  
      };
    
    const { t } = useTranslation();

    return <section className="limitedoffer" id="ppvAdsSection">
        <div className="container">
            <div className="limitedofferinner ppvofferinnersection">
                <div className="heading">
                    <h2>{t("PPVINSTITUTE.MYADTITLE")}</h2>
                    <h5 className="mt-2">{t("PPVINSTITUTE.MYADSUBTITLE")}</h5>
                    <div className="offerslider martop40">
                    <Slider {...settingstwo}>
                {
                  profileData?.advertisements?.map(ad => {
                    return <div className="sliderContent">
                    
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${ad.imageUrl}`}
                            alt=" "
                          />
                         
                         {/* <div className="offerdspop">
                         <div className="ppvcat"><h3>Category</h3>{ad?.subInstitutionCategory?.name}</div>
                        <div className="ppvdate"><h3>Date</h3></div> 
                        </div> */}
                        <div className="descriptionppvpop">
                          <h5> {ad.description}</h5>
                         
                         <div className="ppvcat"><h3>Sub-Category</h3>{ad?.subInstitutionCategory?.name}</div>
                        {/* <div className="ppvdate"><h3>Date</h3></div> */}
                    
                        </div>
                    </div>
                  })
                }
              </Slider>
                        {/* <AwesomeSlider>
                            {
                                profileData?.advertisements?.map(ad => {
                                    return <>
                                        <img
                                            className="d-block w-100"
                                            src={`${uploadURL}/images${ad.imageUrl}`}
                                            alt=" "
                                        />
                                        <h3>{ad.description}</h3>
                                        <div  className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className="ppvawesomesliderproducttype"><p>{ad?.description}</p></div>
                                            <div className="ppvawesomesliderreleatedproduct">{ad?.subInstitutionCategory?.name}</div>
                                        </div>
                                    </>
                                })
                            }
                        </AwesomeSlider> */}
                    </div>
                    <Link
                        to={{
                            pathname: "/listOfAd",
                            state: { data: legalName, frompage: 'ppvAdsSection' ,pagePath:window.location.pathname }
                        }}
                        className="ctaonebutton widthhalf mt-100"
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link>
                    {/* <button type="button" className="ctaonebutton widthhalf mt-5" onClick={() => onViewAllButtonClick('Ads')}>View All</button> */}
                </div>
            </div>
        </div>
    </section>

}
