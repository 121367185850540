import React from 'react'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

import selectall from "../assets/offersimage/Select_all.svg";

import 'react-awesome-slider/dist/styles.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../constants/applicationConstants';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
export const FilterGlobal = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  // if (window.location.href.includes('dev.rimib.com') || window.location.href.includes('dev2.rimib.com') || window.location.href.includes('localhost')) {

  const {
    categoryData,
    selectAll,
    selectAllHit, 
    selectCategory, 
    selectSubCategory,
    subCategories, 
    categories
  } = props;
  // useEffect(() => {
  //   getAllFeauturedOffersData()
  // }, [pathname])
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <div className="filtersectionglobal">
      <div className={`selectallcheckbox ${selectAll && 'selected'}`}>
        <div className="form-checkselect">

          <input className="form-check-input"
            checked={selectAll}

            onChange={e => selectAllHit(e)}
            type="checkbox" value="" id="Checkall" name="selectAll" />
          <span className="maincheckboxicon"><img src={selectall}  alt=''/></span><label className="form-check-label mainchecklabel" for="Checkme4">{t("OFFERE.TEXT10")}</label>
        </div>
      </div>
      <div className="selectallinnercheckbox select-all">
        <ul>

          {categoryData?.map((item) => {
            return (
              <>
                <li className={`selectinner ${item.name}`} >
                  <div className="dropdown">

                    <div className="cstick">
                      <input className="form-check-input"
                        checked={categories?.includes(item.institutionCategoryId)}

                        type="checkbox" value="" id="Checkmerealestate" onChange={(e) => selectCategory(item, e)} />
                    </div>
                  <div 
                    className={pathname == "/globalMainSearchexpert" || pathname =="/globalMainSearchInstitutions" ? 'form-check' : 'form-check dropdown-toggle'}
                     type="button" id="dropdownMenuButtoninvest" data-bs-toggle="dropdown" aria-expanded="false">

                      <span className="maincheckboxicon">
                        <img src={`${NEW_URL}/${item.icon}`}  alt=''/>
                        </span><label className="form-check-label mainchecklabel" for="Checkme4">{item.name}</label>

                    </div>
                    {pathname == "/globalMainSearchexpert" || pathname =="/globalMainSearchInstitutions" ? 
                      null : <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtoninvest">
                      {item?.subInstitutionCategories?.map((subItem) => {
                        return (
                          <>
                            <li>
                              <a className="dropdown-item">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox"
                                    checked={subCategories?.includes(subItem.institutionCategoryId)}

                                    value="" id="Checkme1" onChange={() => selectSubCategory(subItem)} />
                                  <span className="subcatimage"><img src={`${NEW_URL}/${subItem.icon}`}  alt=''/></span> <label className="form-check-label" for="Checkme1">{subItem?.name}</label>
                                </div>
                              </a>
                            </li>
                          </>
                        )
                      })}


                    </ul> }
                  </div>
                </li>
              </>
            )
          })}
        </ul>
      </div>
    </div>


  );
};

export default FilterGlobal;