import moment from "moment";
import { NEW_URL } from "../../../constants/applicationConstants";
import { useTranslation } from 'react-i18next';

const CommonOffer = ({offer,classnameattr}) => {
  const { t } = useTranslation();
  return (
    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className={`offer-${classnameattr}-inner offer-inner`}>
                        <div className="list-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="categotyinpop">
                        <div className="categotyinpopfl d-flex">
                        <div className="pscat">{offer?.parentInstitutionCategory}</div>
                        <div className="pscat"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
<path d="M7.41854 10.6572V7.28769L9.10327 6.27686M13.4836 7.28769C13.4836 10.6373 10.7682 13.3527 7.41854 13.3527C4.06892 13.3527 1.35352 10.6373 1.35352 7.28769C1.35352 3.93808 4.06892 1.22267 7.41854 1.22267C10.7682 1.22267 13.4836 3.93808 13.4836 7.28769Z" stroke="black" stroke-width="1.2021" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span> {t("COMMON.EXPIRING")} : {moment(offer?.validTo).format('Do MMM YY')}</div>
                        </div>
                        <div className="list-name disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="list-des offerdes">{offer?.description}</div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" text-center applynowbuttoncomm textcolors"
                          >
                            {t("REALESTATE.TEXT29")}
                          </a>
                         </div>
                        </div>
                      </div>
                    </div>
  )
}

export default CommonOffer;