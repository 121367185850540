import React from 'react'

import { TagsInput } from "react-tag-input-component";

const AwardsJsx = (props) => {
  const {
  onContactButtonClick, editContact,onMetaTagChange,onMetaTagChange1,
    changeContactState
  } = props;
  const information = props?.wholeData
  const certificateList = []
  information.certification?.map(value => {
    certificateList.push(value.certificate)
  })
  const awardList = []
  information.awards?.map(value => {
    awardList.push(value.award)
  })

  return (
    <>
      {!editContact ? <div className="myaccountsection institutedetails awardsectionmyaccount martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Certification & Awards</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeContactState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
          </div>
        </div>


        <div className="form-fieldinner">
          <label>Awards </label>
          {
            information?.awards?.map(data => {
              return (
                <ul>
                  <li>{data}</li>
                </ul>

              )
            })
          }


        </div>
        <div className="form-fieldinner">
          <label>Certificates </label>
          {
            information?.certification?.map(data => {
              return (
                <ul>
                  <li>{data}</li>
                </ul>
              )
            })
          }
        </div>

      </div>
        : null}
      { editContact ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Certification & Awards</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeContactState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
          </div>
        </div>


        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="mb-4 input-container form-group specialtag">
              <div className="normal" id="tagInput"
                onFocus={() => {
                  let elem = document.getElementById("tagInput");
                  elem.classList.add("my-classfocus");
                }}
                onBlur={() => {
                  let elem = document.getElementById("tagInput");
                  elem.classList.remove("my-classfocus");
                }}

              >
                <TagsInput
                  value={information.awards}
                  onChange={onMetaTagChange}
                  label="xxxxxxxxx"
                  name="awards"
                  placeHolder=" "
                  classNames={{ tag: 'tag-clsxxxx', input: 'input form-control' }}
                />
              </div>
              <label className="placeholder">Awards<span className="plantooltip custooltip" >
                <div className="tooltipcontent"> Please ensure that you add specific and relevant meta tags. To enter multiple meta tags, hit Enter after adding each one. We advise against using too many tags or repeating them excessively. </div>
              </span></label>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="mb-4 input-container form-group specialtag">
              <div className="normal" id="tagInput"
                onFocus={() => {
                  let elem = document.getElementById("tagInput");
                  elem.classList.add("my-classfocus");
                }}
                onBlur={() => {
                  let elem = document.getElementById("tagInput");
                  elem.classList.remove("my-classfocus");
                }}

              >
                <TagsInput
                  value={information.certification}
                  onChange={onMetaTagChange1}
                  label="xxxxxxxxx"
                  name="certificates"
                  placeHolder=" "
                  classNames={{ tag: 'tag-clsxxxx', input: 'input form-control' }}
                />
              </div>
              <label className="placeholder">Certificates<span className="plantooltip custooltip" >
                <div className="tooltipcontent"> Please ensure that you add specific and relevant meta tags. To enter multiple meta tags, hit Enter after adding each one. We advise against using too many tags or repeating them excessively. </div>
              </span></label>
            </div>
          </div>
        </div>
        
        <button type="button" className="ctaonebutton widthhalf" onClick={() => onContactButtonClick()}>Save</button>
      </div> : null}

    </>
  );
};

export default AwardsJsx;
