import React from 'react'
import call from "../../../assets/images/call.svg";
import map from "../../../assets/images/map.svg";
import envelop from "../../../assets/images/envelop.svg";
import InputMask from 'react-input-mask';
import { NEW_URL } from '../../../constants/applicationConstants';
import { formatCanadianPhoneNumber } from '../../../shared/helper'

const MyExpertJsx = (props) => {
  const {
    categoryData, formData,
    onInputChange,
    formError,
    onTouchHandler,
    onSubmitButtonClick,
    onViewProfieClick
  } = props;

  const getCategory =  (abhi) =>{

    const data =  abhi?.expertSpecializations?.map(spec => '<span>'+spec.parentInstitutionCategory+'</span>');
    //return [...new Set(data)].toString()
    return data && data.join(', ');
    
  }

  // const addAnotherExpert = () => {
  //   let token = sessionStorage.getItem("token")
  //   localStorage.setItem("instituteExpertToken", token)
  //   localStorage.setItem("instituteExpert", true)
  //   // sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.tokenValidate));
  //   history.push("/expertRegistration");
  // }

  return (
    <>
      <section className="entiredashboard">
        <div className="container">

          <div className="col-lg-12 col-sm-12">
            <div className="myexpertformarea martop40">
              <div>
                <div className="input-container form-group">
                  <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                    {(inputProps) => <input {...inputProps}
                      type="text"
                      className={
                        formError['postalCode']
                          ? "input form-control  is-invalid"
                          : "input form-control"
                      }
                      placeholder=" "
                      disableUnderline />}
                  </InputMask>
                  <div className="placeholder">Postal Code</div>
                </div>

                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="category"
                    onClick={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {categoryData?.map((item) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Category</div>
                </div>

                <div className="input-container form-group"><button className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Submit</button></div>
              </div>
            </div>
            <p className="smalltext myexpert martop20">(Valid categories of experts - Banking, Insurance, Real Estate, Investment, Mortgage)</p>
            <div className="expertsection martop30">
              {props?.expertData?.length > 0 ?
                props.expertData?.map(data => {
                  return <div className="expertinner">
                    <div className="expertuser"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName} ${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`}  alt={' '}/></div>
                    <div className="experdetails">
                    <h3>{`${data?.firstName} ${data?.lastName}`}<span><div className="expcat"  dangerouslySetInnerHTML={{__html:getCategory(data)}}></div></span></h3>
                      <ul>
                        <li><span><img src={map} alt={' '} /></span> {data?.expertSpecializations[0]?.city || "NA"}</li>
                        <li><span><img src={call} alt={' '} /></span> {data.phoneNumber && formatCanadianPhoneNumber(data.phoneNumber)}</li>
                        <li><span><img src={envelop} alt={' '} /></span> {data.email}</li>
                      </ul>
                    </div>
                    <div className="viewprofile"><button className="ctaonebutton" onClick={() => onViewProfieClick(data?.slugUrl)}>View Profile</button></div>
                    <div className="expertpremium">{data.planName || "Not available"}</div>
                  </div>
                })
                : <span className="opacity0">No data available</span>}


            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyExpertJsx;
