import React from 'react'

import datecalender from "../../../../assets/images/datecalender.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import dropbox from "../../../../assets/images/dropbox.svg";
import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { formatInputValue } from '../../../../shared/helper';
import { TagsInput } from "react-tag-input-component";
import { NEW_URL } from '../../../../constants/applicationConstants';
import adsbg from '../../../../assets/Instituteppvimages/ads_bg.svg';
import uploadfile from '../../../../assets/Instituteppvimages/uploadfile.svg';
import { useTranslation } from "react-i18next";
import './myAds.model.css';

const MyAdsJsx = (props) => {
    const {
        blogFormData,
        formError,
        onDateChange,
        onInputChangeHandler,
        onRadioButtonClick,
        isExpires,
        showAddModal,
        onModalClose,
        onSubmitButtonclick,
        onTouchHandler,
        formSubmitButtonDisable,
        categoryData,
        subCategoryData,
        onMetaTagChange,
        onEditButtonClick,
        onDeleteButtonClick,
        adData,
        onScrollerChange,
        profileImagePreviewUrl,
        imgURL,
        editMode,
        onFileChange
    } = props;

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    let flowName = localStorage.getItem('flowName');

    const { t } = useTranslation();
    return (
        <>
            <section className="">
                <div className="container">
                    <div className="row ad-main">
                       
                                {flowName === 'expert' && <div>
                                    <h2>{t("instituteAds.TITLE")}</h2>
                                    {t("instituteAds.ADDISCRIPTION")}
                                </div>}
                                <div className="craete-ad-containt">
                                    <div>
                                        <div className="row">
                                            <p className="subtitle col-9"><strong>{t("instituteAds.SUBTITLE")}</strong></p>

                                            <button type="button" disabled={adData?.length === limitData?.totalAdsLimit} className="col-3 craete-ads-button" onClick={() => onModalClose(true)}>{t("MYADS.CREATEAD")}</button>


                                        </div>
                                        <p className="subtitle">{t("instituteAds.CREATEDADS")}<strong>{t("instituteAds.PUBLICPROFILE")}</strong></p>
                                        <div className="userguide">
                                            <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                                                <li className="dropdown-item">{t("instituteAds.HOWTOCREATE")}
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li className="dropdown-item">{t("instituteAds.UPLOADADIMAGE")}</li>
                                                        <li className="dropdown-item">{t("instituteAds.ENTERADCAPTION")}</li>
                                                        <li className="dropdown-item">{t("instituteAds.EXPIREDATE")}
                                                            <ol>
                                                                <li>{t("instituteAds.SELECTEXPIRE")}</li>
                                                                <li>{t("instituteAds.ENTERVALIDDATE")}</li>
                                                            </ol></li>
                                                        <li className="dropdown-item" >{t("instituteAds.NEVEREXPIRE")}</li>
                                                        <li className="dropdown-item" >{t("instituteAds.CLICKSUBMIT")}</li>
                                                        <li className="dropdown-item" >{t("instituteAds.DELETEIT")}</li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("instituteAds.EDITAD")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("instituteAds.CLICKEDIT")}</a></li>

                                                        <li><a className="dropdown-item"  >{t("instituteAds.POPUPINFO")}
                                                            <ol>
                                                                <li>{t("instituteAds.UPLOADNEWAD")} </li>
                                                                <li>{t("instituteAds.EDITADCAPTION")} </li>
                                                            </ol> </a></li>
                                                        <li><a className="dropdown-item"  >{t("instituteAds.CLICKSUBMITBUTTON")} </a></li>
                                                        <li><a className="dropdown-item"  >{t("instituteAds.DISCARDCHANGES")} </a></li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("instituteAds.DELETEEXISTINGAD")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("instituteAds.DELETEOPTION")} </a></li>


                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("instituteAds.ADDMOEAD")} </a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("instituteAds.ADLIMIT")}</a></li>

                                                    </ul>
                                                </li>
                                                <li><a className="dropdown-item" type="button">{t("instituteAds.ADSTATUS")}</a>
                                                    <ul className="dropdown-menu dropdown-submenu">
                                                        <li><a className="dropdown-item"  >{t("instituteAds.SWITCH")} </a></li>
                                                        <li><a className="dropdown-item"  >{t("instituteAds.ACTIVETOGGLE")}</a></li>
                                                        <li><a className="dropdown-item"  >{t("instituteAds.INACTIVETOGGLE")} </a></li>


                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>



                                </div>
                                {showAddModal && <div className=" " style={{ marginTop: '30px' }}>

                                    <div className="craete-ads">
                                        <div>
                                            <button type="button" className="close-ads" onClick={() => onModalClose(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                width="30.000000pt" height="30.000000pt" viewBox="0 0 80.000000 80.000000"
                                                preserveAspectRatio="xMidYMid meet">

                                                <g transform="translate(-14.000000,60.000000) scale(0.100000,-0.100000)"
                                                    fill="#ffffff" stroke="none">
                                                    <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                                                </g>
                                            </svg></button>
                                        </div>
                                        <div className=" ">
                                            <div className="adsbg">
                                                <p className="ads-title">{t("instituteAds.CREATEAD")}</p>
                                                <img className="adsimg" src={adsbg} alt={''} />
                                                <div className="outerlogo">
                                                </div>
                                                <div className="uploadexpert mb-4">
                                                    <div className="row border-div">
                                                        <div className="col-4 upload-image"> <img src={uploadfile} alt={''} /></div>
                                                        <div className="col-8 brows-part">
                                                            {!(editMode || profileImagePreviewUrl) ? <><p className="uplod-title"><b>{t("instituteAds.UPLOADLOGO")}</b></p>
                                                                <div className="divider">
                                                                    <span className="divider-text">Or</span>
                                                                </div>
                                                            </>
                                                                :
                                                                <div className="uploadlogo chnageupload">
                                                                    <div className="brows-part">
                                                                        <input type="file" id="upload_file" name="" onChange={onFileChange} />
                                                                        <label for="upload_file">{"Change Icon"}</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                profileImagePreviewUrl ? <img
                                                                    src={profileImagePreviewUrl}
                                                                    className="select-img"
                                                                    alt="edit profile"
                                                                /> : <div className="uploadlogo">
                                                                    <input type="file" id="upload_filelogo" name="" onChange={onFileChange} />
                                                                    <label for="upload_filelogo">

                                                                        {editMode ?
                                                                            <img
                                                                                src={`${NEW_URL}/${imgURL}`}
                                                                                height="170px"
                                                                                width="250px"
                                                                                alt="edit profile"
                                                                            />
                                                                            : <p>Browse</p>
                                                                        }

                                                                    </label>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>



                                        <div className="row input-form-div">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="textarea-caption">
                                                    <textarea
                                                        onBlur={onTouchHandler}
                                                        className={"textarea-caption"}
                                                        placeholder={t("instituteAds.ADDCAPTION")}
                                                        value={blogFormData.caption}
                                                        name="caption"
                                                        onChange={onInputChangeHandler}
                                                        maxLength={500}></textarea>
                                                    <span className=" ">
                                                        {formError['caption'] &&
                                                            (formError['caption'] === "req"
                                                                ? 'Caption is required'
                                                                : '')}
                                                    </span>
                                                </div>


                                            </div>

                                            <div className="col-lg-6 col-sm-6">


                                                <div className="input-caption">
                                                    <div className="input-caption" id="tagInput" titleLbl={'Product Meta Tag'}
                                                        onFocus={() => {
                                                            let elem = document.getElementById("tagInput");
                                                            elem.classList.add("my-classfocus");
                                                        }}
                                                        onBlur={() => {
                                                            let elem = document.getElementById("tagInput");
                                                            elem.classList.remove("my-classfocus");
                                                        }}

                                                    >

                                                        <TagsInput
                                                            value={blogFormData.metaTag}
                                                            onChange={onMetaTagChange}
                                                            label="xxxxxxxxx"
                                                            name="metaTags"
                                                            placeHolder=" "
                                                            classNames={{ tag: 'tag-clsxxxx', input: 'input-caption' }}
                                                        />
                                                    </div>
                                                    <label className="placeholder">{t("instituteAds.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip} alt={''} className="tooltipim" />
                                                        <div className="tooltipcontent">{t("instituteAds.METATAGTOOLTIP")}</div>
                                                    </span></label>

                                                </div>
                                                <div className="input-caption">
                                                    <input
                                                        onBlur={onTouchHandler}
                                                        className={'input form-control '}
                                                        placeholder=" "
                                                        value={blogFormData.link}
                                                        name="link"
                                                        onChange={onInputChangeHandler}
                                                        maxLength={100}
                                                    />
                                                    <label className="placeholder">{t("instituteAds.EXTERNALLINK")}</label>
                                                    <span className="text-danger">
                                                        {formError['link'] &&
                                                            (formError['link'] === "req"
                                                                ? ''
                                                                : 'Enter a valid link')}
                                                    </span>
                                                </div>

                                                {isExpires && <div className="disgrid grid-2">
                                                    <DatePicker3 className="input-caption" name="startDate"
                                                        onChange={(date) => { onDateChange('validFrom', date) }}
                                                        inputPlaceholder="dd/mm/yyyy"
                                                        shouldHighlightWeekends
                                                        wrapperClassName="mb-4 input-caption"
                                                        maximumDate={{
                                                            year: new Date().getFullYear(),
                                                            month: new Date().getMonth() + 1,
                                                            day: new Date().getDate()
                                                        }}
                                                        renderInput={({ ref }) => (
                                                            <>
                                                                <input
                                                                    ref={ref}
                                                                    placeholder="Valid From*"
                                                                    type="text"
                                                                    required
                                                                    className="input-caption"
                                                                    value={formatInputValue(blogFormData?.validFrom)}
                                                                />
                                                                <div className="cut"></div>

                                                                <span className="input-icon-add"><img src={datecalender} alt={''} /></span>
                                                            </>
                                                        )}
                                                        formatInputText={() => formatInputValue(blogFormData?.validFrom)}
                                                    />
                                                    <DatePicker3 className="input-caption" name="startDate"
                                                        onChange={(date) => { onDateChange('validTill', date) }}
                                                        inputPlaceholder="dd/mm/yyyy"
                                                        shouldHighlightWeekends
                                                        wrapperClassName="input-caption"
                                                        minimumDate={{
                                                            year: new Date().getFullYear(),
                                                            month: new Date().getMonth() + 1,
                                                            day: new Date().getDate() + 1
                                                        }}
                                                        renderInput={({ ref }) => (
                                                            <>
                                                                <input
                                                                    ref={ref}
                                                                    placeholder="Valid Till*"
                                                                    type="text"
                                                                    required
                                                                    className="input-caption"
                                                                    value={formatInputValue(blogFormData?.validTill)}
                                                                />
                                                                <div className="cut"></div>
                                                                <span className="input-icon-add"><img src={datecalender} alt={''} /></span>
                                                            </>
                                                        )}
                                                        formatInputText={() => formatInputValue(blogFormData?.validTill)}
                                                    />
                                                </div>
                                                }
                                                <div className="disgrid grid-2">
                                                    <div className="mb-4 input-container form-group">
                                                        <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                                            <option value="">{t("instituteAds.SELECTCATEGORY")}</option>
                                                            {categoryData?.map((item) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.category}>{item.name}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                        <label className="placeholder">{t("instituteAds.CATEGORY")}</label>
                                                    </div>
                                                    <div className="mb-4 input-container form-group">
                                                        <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                                            <option value="">{t("instituteAds.SELECTSUBCAT")}</option>
                                                            {subCategoryData?.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId === blogFormData.subCategory}>{item.name}</option>
                                                                    </>
                                                                )
                                                            })}
                                                        </select>
                                                        <label className="placeholder">{t("instituteAds.SUBCAT")}</label>
                                                    </div>
                                                </div>
                                                <div className="mb-4 input-container form-group radioform">
                                                    <label className="radiocontrol">
                                                        <input type="radio" name="radio" onClick={() => onRadioButtonClick(true)} checked={blogFormData.isExpires} />{t("instituteAds.EXPIRES")}</label>

                                                    <label className="radiocontrol">
                                                        <input type="radio" name="radio" onClick={() => onRadioButtonClick(false)} checked={!blogFormData.isExpires} />{t("instituteAds.NEVEREXPIRES")}</label>
                                                </div>
                                                <div className="mb-4 input-container form-group">
                                                    <button type="button" disabled={formSubmitButtonDisable} className="ctaonebutton" onClick={() => onSubmitButtonclick()}>{t("instituteAds.SUBMIT")}</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                }


                                <div  >
                                    <p className="adstitle">{t("instituteAds.TITLE")} </p>
                                    <p className="adsubtitle">{t("instituteAds.VIIBLEAD")} </p>
                                    <p className="adsdes">{t("instituteAds.BELOWAD")}  </p><br />
                                    <p className="adsdes1">{t("instituteAds.YOUCANUPLOAD")}  {limitData?.totalAdsLimit} {t("instituteAds.BELOWAD1")}  </p>
                                </div>

                                <div className="adsblog">
                                    <div className="row">
                                        {
                                            adData?.length > 0 ?
                                                adData?.map(data => {
                                                    return <div className="col-lg-4 col-md-6 col-sm-6">
                                                        <div className="myadposter">
                                                            <div className="myadposterimage">
                                                                <img src={`${NEW_URL}/${data.imageUrl}`} alt={''} />
                                                            </div>
                                                            <div className="myadposterbanner">
                                                                <label className="switch-active">
                                                                    <input type="checkbox" checked={data.isActive} onChange={() => onScrollerChange(data)} />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <div>
                                                                    <button type="button" onClick={() => onEditButtonClick(data)} className="edit-btn"><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white" />
                                                                    </svg></button>
                                                                    <button type="button" onClick={() => onDeleteButtonClick(data)} className="edit-btn"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round" />
                                                                        <path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white" />
                                                                        <path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round" />
                                                                    </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }) :
                                                <div className="dropdefault text-center mb-5">
                                                    <div className="dropdefaultimage">
                                                        <img src={dropbox} alt={''} />
                                                    </div>
                                                    <div className="dropdefaulttext">
                                                        <h5>{t("instituteAds.LIVEAD")} </h5>
                                                        <h5>{t("instituteAds.CREATENEWAD")}</h5>
                                                    </div>

                                                </div>
                                        }
                                    </div>
                                </div>
                             

                       
                    </div>
                </div>
            </section>


        </>

    );
};

export default MyAdsJsx;
