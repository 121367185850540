import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import "../investment/investment.css";
import "../investment/investmentresponsive.css";

import iconaskbtn from "../../../assets/sharedImages/iconaskbtn.svg";
import RealEtext from "../../../assets/investmentimages/RealEtext.png";
import toprealEttextline from "../../../assets/sharedImages/toprealEttextline.png";
import expertuiserborder from "../../../assets/investmentimages/expertuiserborder.png";
import dropbox from "../../../assets/images/dropbox.svg";
import Investmentbanner from "../../../assets/investmentimages/Investmentimage.svg";
import DetailedProductsJsx from '../../publicProfileView/ppvCommon/detailedProduct/detailedProduct.jsx';


const InvestmentJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    productData,
   
    expertData,
    instituteData,
    blogData,

    onExpertClick,
    onInstituteClick
  } = props;

  const { hash } = useLocation()


  useEffect(() => {
    AOS.init();
    if (hash) {
      document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      //window.scrollTo(0, 0);
    }

  }, [])
  var institutelogo = {
    infinite: false,
    rows: 2,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    adaptiveHeight: true,
    arrows: true,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                rows: 2,
                infinite: false,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                dots: true,

            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                dots: true,

            }
        },
        {
            breakpoint: 320,
            settings: {
                dots: true,

            }
        }
    ]


};
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var expslider = {
    dots: false,
    infinite: false,
    arrows:false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    //centerPadding: '20px',
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
 

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () =>{
    setProductDetails({})
  }

  const [visibleBlogs, setVisibleBlogs] = useState(4);
  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };
  // {t("PRINT.TITLE")}
  return (
    <>
    <section className="container-rimib investmentnewui">
      <section className="categorybanner">
     
        <div className="container makethecontainer">
        <div className="row">
        <div className="col-lg-6 col-sm-6">
            <div className="categorybannertext">
              <div className="bannerfont borderbottom ">
                <h2>{t("INVESTMENT.TEXT1")}</h2>
              
                <p>{t("INVESTMENT.TEXT2")}</p>
                <h6 className="h6_style ">{t("INVESTMENT.TEXT3")} {t("INVESTMENT.TEXTONE3")} {t("INVESTMENT.TEXTTWO3")} {t("INVESTMENT.TEXTTHREE3")}</h6>
                <p className="bannersubtext">{t("INVESTMENT.TEXT4")} {t("INVESTMENT.TEXTTWO4")} {t("INVESTMENT.TEXTTHREE4")} {t("INVESTMENT.TEXTFOUR4")}  </p>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="askbutton">
                    <p className="askres"> {t("INVESTMENT.TEXT25")}</p>
                    <img src={iconaskbtn} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
            <div className="col-lg-6 col-sm-6">
              <div className="bn-image">
                  <img src={Investmentbanner} alt=''/>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section className="categorysectionone">
        <div className="container">
          <div className="letest-inner">
            <h2>{t("INVESTMENT.TEXT5")}</h2>
            <h6>{t("INVESTMENT.TEXT6")}
              <br />{t("INVESTMENT.TEXT7")} {t("INVESTMENT.TEXTTWO7")}
</h6>
          </div>
          <div className="institumain displaynone">
            <div className="latestbutton margintoptop">
            <Link to={'/offers'} className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
            </div>
          </div>
          
          <div className="row  offersss ">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="offer-inner">
                        <div className="list-image">
                          <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                        </div>
                        <div className="categotyinpop">
                        <div className="categotyinpopfl d-flex">
                        <div className="pscat">{offer?.parentInstitutionCategory}</div>
                        <div className="pscat"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
<path d="M7.41854 10.6572V7.28769L9.10327 6.27686M13.4836 7.28769C13.4836 10.6373 10.7682 13.3527 7.41854 13.3527C4.06892 13.3527 1.35352 10.6373 1.35352 7.28769C1.35352 3.93808 4.06892 1.22267 7.41854 1.22267C10.7682 1.22267 13.4836 3.93808 13.4836 7.28769Z" stroke="black" stroke-width="1.2021" stroke-linecap="round" stroke-linejoin="round"/>
</svg></span> {t("COMMON.EXPIRING")} :  {moment(offer?.validTo).format('Do MMM YY')}</div>
                        </div>
                        <div className="list-name disfontsmall">
                          {offer?.offerName}
                        </div>
                        <div className="list-des offerdes">{offer?.description}</div>
                        <div className="posbuttontf d-flex">
                          <a
                            href={offer?.externalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className=" text-center applynowbuttoncomm"
                          >
                           {t("INVESTMENT.TEXT29")} 
                    </a>
                          
                        </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <a href="/offers" className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</a>
            </div>
          </div>
        </div>
      </section>
      <section className="categorysectiontwo">
        <div className="container">
          <div className="TopRealEstateProducts-inner">
            <h2 className="investthreesubtext">{t("INVESTMENT.TEXTONE8")}</h2>
            <h6>{t("INVESTMENT.TEXT9")}<br/>
            {t("INVESTMENT.TEXTONE9")}
             </h6>
          </div>
          <div className="row offersss marginnnn">
            {productData?.length > 0 ? (
              <Slider {...settings}>
                {productData.map((item,index) => {
                  return (
                    <div key={item.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="list-inner">
                        <div className="list-image">
                          <img src={`${NEW_URL}/${item?.imageUrl}`} alt="" />                        
                        </div>                      
                        <div className="coliin">
                        <div className="colinstititeinnername text-center">{item?.productName}</div>                       
                        <div className="posbuttontf d-flex">
                          
                        <button
                                    className="applynowbuttoncomm "
                                    onClick={() => {setProductDetails({ data: item, category: item?.parentInstitutionCategory, subCategory: item?.subInstitutionCategory?.name, frompage: 'investmentProduct', pagePath: '/products' })}}>
                            {t("INVESTMENT.TEXT30")}
                    </button>
                        </div>
                      </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
            <Link to={'/products'} className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
            </div>
          </div>
        </div>
      </section>
     
      <section className="categorysectionthree">
        <div className="container">
          <div className="row" >
          <div className="col-lg-7 col-12 p-0 ">
              <div className="institutelogosection">
                
                  {instituteData?.length > 0 &&
                  <Slider {...institutelogo}>
                  {
                    instituteData.map(institute => {
                      return<div className="psi">
                        <div
                          className="colinstititeinner"
                          onClick={() => onInstituteClick(institute)}
                        >
                          <div className="colinstititeinnerimage text-center">
                            <img
                              src={!institute?.imageUrl ? `https://ui-avatars.com/api/?name=${institute?.name}&rounded=false` : `${NEW_URL}/${institute?.imageUrl}`}
                              alt={institute?.name || "Institute Image"}
                            />
                          </div>
                          <div className="colinstititeinnername text-center institute-text">
                            {institute?.name}
                          </div>
                        </div>
                      </div>
                     })
                    }
                </Slider>
            }


  

              </div>
              <div className="showallbtn text-center ">
                <a
                  href="/globalMainSearchInstitutions"
                  className="showallourbesttoo "
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>

            </div>
            <div className="col-lg-5 col-12 p-0">
              <div className="toprealErighttext">
                <h2>{t("INVESTMENT.TEXT13")}<span><img className="toprealEtext" src={RealEtext} alt="" />{t("INVESTMENT.TEXT14")}</span> <span className="canada">{t("INVESTMENT.TEXT15")}</span></h2>
                <img className="toprealEborder" src={toprealEttextline} alt="" />
                <p><span className="topsubtext"> {t("INVESTMENT.TEXT16")} </span> {t("INVESTMENT.TEXTONE16")} <span className="topsubtext"> {t("INVESTMENT.TEXTTWO16")}</span>{t("INVESTMENT.TEXTTHREE16")}<br/></p>
                <span className="topsubtextfive">{t("INVESTMENT.TEXT17")}</span> 
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="categorysectionfour  bestdeals Experts">
        <div className="container-fluid">
          <h2 className="text-center expertheading">{t("INVESTMENT.TEXT18")}</h2>
          <h5 className="text-center  h5_style expertsub1">{t("INVESTMENT.TEXTONE18")}<br />
          </h5>
          <p className="expertsub2  text-center">{t("INVESTMENT.TEXTTWO18")}</p>
          
          <div className=" p bestdealsbox bestexperts">
            {expertData?.length > 0 ?
              <Slider {...expslider}>
                {expertData?.map(data => {
                  return (
                    <div className="mt-5">
                      <div className="Expertsitmes" onClick={() => onExpertClick(data?.slugUrl)}>
                        <div className="Expertstop"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data.firstName}+${data.lastName}&rounded=false` : `${NEW_URL}/${data?.imageUrl}`} alt={' '} />                      
                        </div>
                        <div className="Expertsbottom Expertstext text-center">
                          <div className="Expertstext">
                            <h5>{`${data.firstName} ${data.lastName}`}</h5>
                             <img src={expertuiserborder} alt=""/>
                        <p>{data?.description}</p>
                             </div>
                        <div className="Expertsbutton">
                    <div className="Expertsbuttoni">
                      <a href={`/expertPpv/${data?.slugUrl}#contact`} className="messagemebuttoncomm">{t("INVESTMENT.TEXT27")}</a>                      
                    </div>
                  </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
              :
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>
              
            }
            <div className="latestbutton  marginbottom">
              <a href="/globalMainSearchExpert" className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</a>
            </div>
          </div>
         
        </div>
      </section>
      <section className="categorysectionfive">
        <div className="container-fluid">
          <div className="realEquestion">
            <div className="realEquestiontext">
              <h4>{t("INVESTMENT.TEXT19")}</h4>
              <h2><span>{t("INVESTMENT.TEXTTWO19")} </span>{t("INVESTMENT.TEXTTHREE19")}<span>{t("INVESTMENT.TEXTFOUR19")} </span>{t("INVESTMENT.TEXTFIVE19")}</h2>
              <h3 className=""
                >{t("INVESTMENT.TEXT20")}</h3>
              <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                <div className="realEbuttonmain realEmargin askbuttoncomm">
                  <p className="realEbutton"> {t("INVESTMENT.TEXT25")}</p>
                  <img src={iconaskbtn} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="categorysectionsix blogdetailsexpertppv">
        <div className="container-fluid">
          <div className="realEbloginner">
            <h2 className="text-center"
              >{t("INVESTMENT.TEXT21")}</h2>
            <p className="text-center  realEblogtext">{t("INVESTMENT.TEXT22")}
           </p>
            <p> {t("INVESTMENT.TEXTONE23")}</p>
          </div>
          <div className="row  realEbloglog">
            {
              blogData?.body?.length > 0 ?
                blogData?.body?.slice(0, visibleBlogs).map(blog => {
                  return <div className="col-lg-6 col-md-6 col-sm-6 minheightrealestate">
                    <Link to={`/articleDetails/${blog.slug}`}>
                      <div className="bloginner  blogsmain text-center">
                        <div className="bloginnerimage "><img src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content}</div>
                      </div>
                    </Link>

                  </div>
                }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any Blogs yet. </h5>
                  </div>
                </div>
            }

          
<Link to={{pathname: `/articles`, search:'?category=Investment'}} className="showallourbesttoo">
              {t("COMMON.SHOWALL")}
                </Link>
          </div>
        </div>
      </section>
      <section>
      </section>
      </section>
      <Footer />

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }

      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory ={productDetails.subCategory} closFunction={closeFunction}/>
      }
    </>

  );
};

export default InvestmentJsx;