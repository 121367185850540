import React from 'react'
import '../../../../assets/stylesheet/desktop.css';
import '../../../../assets/stylesheet/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import leftarrow from "../../../../assets/images/left-arrow.svg";

import "react-modern-calendar-datepicker/lib/DatePicker.css";

const ViewSpecialInfoJSX = (props) => {
    const {
        specialInfoDetails,changeHideState,onDeleteButtonClick,
        editParticularSpecialization
    } = props;

    return (
        <>
            {specialInfoDetails?.length &&

                <section className="listofoffermainsection">
                    <>

                        <div className="container">
                            <div className="leftsightbackdetails listofofferleft padtop60">
                                <div className="back_btn"><img src={leftarrow} alt={' '} />
                                </div>
                                <div className="plaindetailsleft">
                                    <h1 className="h2_style font-weight-bold">Specialization Information</h1>
                                    <h5 className="h5_style">Our platform allows you to register as an expert in multiple specializations. </h5>
                                    <h5 className="h5_style">To add additional areas of expertise, simply click the "Add Another Specialization" button.</h5>
                                </div>
                            </div>
                            {specialInfoDetails.map((item, i) => (

                                <div className="specialinfoinner mt-5 mb-5">

                                    <div className="col-lg-12">

                                        <div className="form-field">
                                            <button type="button" className="myadbtn absoluteup" onClick={() => onDeleteButtonClick(item?.expertSpecializationId)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                                            <button type="button" className="myadbtn absolutedown"  onClick={() => editParticularSpecialization(item?.expertSpecializationId)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
                                            <div className="disgrid grid-2">
                                                <div className="form-fieldinner"><label>Name of Institution</label><div className="fieldans">{item?.institute?.name}</div></div>
                                                <div className="form-fieldinner"><label>Category of Specialization</label><div className="fieldans">{item?.parentInstitutionCategory}</div></div>
                                            </div>
                                            <div className="form-fieldinner mt-4"><label>Sub-Categories</label><div className="fieldans d-flex">

                                            {item?.expertSpecializationSubCategories?.map((item1, i) => (

                                                <span>{item1?.subInstitutionCategory
                                                    ?.name}</span>
                                                ))}
                                            </div></div>
                                            <hr></hr>
                                            <div className="disgrid grid-2">
                                                <div className="form-fieldinner"><label>Business Name</label><div className="fieldans">{item?.businessName}</div></div>
                                                <div className="form-fieldinner"><label>Business License No.</label><div className="fieldans">{item?.businessLicenseNumber}</div></div>
                                                <div className="form-fieldinner"><label>Business Phone No.</label><div className="fieldans">{item?.businessPhoneNumber}</div></div>
                                                <div className="form-fieldinner"><label>Business E-mail Address</label><div className="fieldans">{item?.businessEmail}</div></div>
                                                <div className="form-fieldinner"><label>Business Website</label><div className="fieldans">{item?.businessWebsite}</div></div>
                                                <div className="form-fieldinner"><label>Years of Experience</label><div className="fieldans">{item?.experienceInYears}</div></div>
                                            </div>
                                            <hr></hr>
                                            <div className="form-fieldinner"><label>Street Address</label><div className="fieldans">{item?.address}</div></div>
                                            <div className="disgrid grid-4">
                                                <div className="form-fieldinner"><label>City</label><div className="fieldans">{item?.city}</div></div>
                                                <div className="form-fieldinner"><label>Province </label><div className="fieldans">{item?.state?.name}</div></div>
                                                <div className="form-fieldinner"><label>Country</label><div className="fieldans">{item?.country?.name}</div></div>
                                                <div className="form-fieldinner"><label>Postal Code</label><div className="fieldans">{item?.postalCode}</div></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                            <button type="submit" className="ctaonebutton mt-5 mb-5" onClick={() => changeHideState(true)}>Add Another Specialization</button>


                        </div>
                    </>

                </section>
            }


        </>


    );
};

export default ViewSpecialInfoJSX;
