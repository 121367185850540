import React from 'react'
import { NEW_URL } from "../../../../constants/applicationConstants.js";
import "../accountSetting/accountSetting.model.css"

const LogoJsx = (props) => {
    const {
        imgURL, onFileChange, profileImagePreviewUrl, onNextButtonClick
    } = props;
    

    console.log("profileImagePreviewUrl => ",profileImagePreviewUrl);
    return (
        <>
            <div >
                <div >
                    <h3 className="subtitles">Your Logo</h3>
                    
                </div>
                <div className="profilelogo">
                    <div className="from-input">
                      
                        {
                            profileImagePreviewUrl ?
                                <img
                                    src={profileImagePreviewUrl}
                                    height="200px"
                                    width="200px"
                                    alt="edit profile"
                                /> :

                                <img
                                    src={`${NEW_URL}/${imgURL}`}
                                    height="200px"
                                    width="200px"
                                    alt="edit profile"
                                />

                        }

                        <div className="uploaddetails">
                            
                            <div className="button_outer">
                                <div className="btn_upload">
                                    <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                                        <label for="upload_file">Choose Logo</label>
                                        </div>
                                </div>
                            <button type="button" className="ctaonebutton" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Logo</button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoJsx;
