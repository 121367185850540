import React, { useEffect } from 'react'
import { Footer } from '../../../common/footer/footer';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../globalMainSearchArticles/globalMainSearchArticles.model.css';
import { Pagination } from '../../../common/pagination/pagination';

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const GlobalMainSearchArticlesJsx = (props) => {
  const {
    articleData, 
    onPaginationButtonClick, 
    hitSearch, 
    searchOfferByKeyword
  } = props;

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  useEffect(() => {
    AOS.init();
  }, [])

  const { t } = useTranslation();
  const history = useHistory();




  return (
    <>
      <section className="homecustom">
        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search" defaultValue={params.search} placeholder="" onChange={searchOfferByKeyword}
              />
              <button class="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("ARTICLES.SEARCHTITLE")}</h2>
            <h4>{t("ARTICLES.SEARCHDESH")}</h4>
          </div>
        </section>
        <section className="searchresultsection">
          <div className="expertsection martop30 mb-5">
            <div className="container articlemain">
              <div className="articlehading">
                <h1> {params.search === "" || params.search === null ? `${t("ARTICLES.EMPTYTITLE")}` : `“${params.search}” ${t("ARTICLES.TITLE")}`}</h1>
              </div>
              {
                articleData?.body?.length > 0 ?
                  articleData?.body?.map(article => {
                    return <div className="articlecard row" onClick={() => {
                      history.push({
                        pathname: `/articleDetails/${article.slug}`
                      });

                    }} >
                      <div className="col-3"><div className="articlecardimg"><img src={article.image} alt={' '} /></div>
                      </div>
                      <div className="col-9">
                        <p className="articletitle">{article.title}</p>
                        <p className="articlesubtitle">{article.short_content}</p>
                      </div>
                    </div>
                  }) :
                  <div className="dropdefault text-center">
                    <h5>You don't have any Article yet. </h5>
                  </div>
              }
            </div>
            {articleData?.body?.length > 0 && <Pagination
              limit={10}
              totalCount={articleData?.total_post}
              onPaginationButtonClick={onPaginationButtonClick}
              resetPagi={true}
            />}
          </div>
        </section>
        <Footer />
      </section>
    </>
  );
};

export default GlobalMainSearchArticlesJsx;