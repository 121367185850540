import React from 'react'
import Sidebar from "../sidebar/sidebar.js";
import AccountSetting from "../accountSetting/accountSetting.js";
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js";
import ChangePassword from "../../../common/changePassword/changePassword.js";
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';
import "../myAccount/myAccount.css";
import accountsettingss from  "../../../../assets/images/accountsettingss.png";
import changepass from  "../../../../assets/images/changepass.png";
import customerdashboardContent from "../../../../assets/content/Customerdashboard.json";
import { useTranslation } from 'react-i18next';


const MyAccountsJsx = (props) => {
  const { t } = useTranslation();

  const {
    activeTab,
    onTabClick
  } = props;

  return (
    <>
      <section className="entiredashboard maincustomerdash main-myaccount-dashboard ">
        <div className="container">
          <div className="row bg-color-customer">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel">

                <div className="dashboardlinkpop">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9 p-0">
              <div className="dashboardrightpanel martop40">
                <section classNames="addexpertformsec">
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">{t("customeraccountsetting.HEADING")}</h2>
                      <h5 className="h5_style">{t("customeraccountsetting.PARAGRAPH")} </h5>
                    </div>

                  </div>

                  <div className="myexpertnav myaccountnav martop40 customermain account-setting-block">
                    <ul>

                      <li className={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}> <img src={accountsettingss} alt=""/>{t("customeraccountsetting.ACCOUNTSETTINGBUTTON")}</li>
                      {/* {data.isPrimaryUser && <li className={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}>Manage Users</li>} */}
                      {/* <li className={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}>My Plans</li> */}
                      <li className={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}><img src={changepass} alt=""/>{t("customeraccountsetting.CHANGEPASSWORDBUTTON")}</li>
                    </ul>
                  </div>
                  {
                    activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="accountchangepass"><ChangePassword /></div> : <MyPlansJsxclass />
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyAccountsJsx;
