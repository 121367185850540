import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { baseURL,uploadURL } from '../../../../constants/applicationConstants.js';
import { allRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './adminAskTheExpert.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                category: '',
                subCategory: '',
                stripeCode: '',
                isFeatured: '',
                price: '',
                adsLimit: '',
                offerLimit: '',
                productLimit: '',
                expertLimit: '',
                secondaryStripeCode: '',
                active: '',
                description:'',
                secondaryPrice: ''
            },
            formError: {},
            formSubmitButtonDisable: false,
            isLoading: false,
            expertData: [],
            meta: {},
            page: 1,
            imageUrl: "",
            image: null,
            profileImagePreviewUrl: null,

        }
    }
    validFileName = (name) => name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png');

    onFileChange = (e) => {
        let file, img;
        if (e.target.files[0]?.size) {
          if (e.target.files[0]?.size < 512000) {
            if (e.target.files[0] && this.validFileName(e.target.files[0].name)) {
              // if ((file = e.target.files[0])) {
              //     img = new Image();
              //     img.onload = function () {
              //         if (this.height > 800) {
              //             toast.error("big height")
              //         }
              //         else{
              //             toast.error("small height")
              //             img.src = this.state._URL.createObjectURL(file);
              //             this.setState({ fileInfo: e.target.files[0] })
              //             this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
              //             this.setState({ imageName: e.target.files[0].name },()=> this.isFromSubmissionBlock())
              //         }
              //     };
              //     img.onerror = function () {
              //         toast.error("not a valid file: " + file.type);
              //     };
              // }
              this.setState({ fileInfo: e.target.files[0] }, () => this.isFromSubmissionBlock())
              this.setState({ profileImagePreviewUrl: URL.createObjectURL(e.target.files[0]) })
              this.setState({ imageName: e.target.files[0].name })
            }
            else
              toast.error('.jpeg/.jpg/.png format required')
          }
          else
            toast.error('File should be less than 500 kb.')
        }
      }
      onNextButtonClick = () => {
        this.setState({
          isLoading: true
        })
        let formData1 = new FormData();
    
        if (this.state.fileInfo) {
          formData1.append('image', this.state.fileInfo)
          formData1.append('imageCategory', "category")
    
          axios.post(`${uploadURL}/upload-file`, formData1).then(resp => {
            if (resp.status === 200) {
              this.setState({ image: resp.data.filename })
              toast.success("Image uploaded successfully");
              this.setState({
                isLoading: false
              })
            //   setTimeout(() => {
            //     this.onNextButtonClick1()
            //   }, 2000)
            }
          }).catch(() => {
            this.setState({
              isLoading: false
            })
          })
        }
        else this.onNextButtonClick1();
      };

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }

    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)
        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return allRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    isFromSubmissionBlock = () => {
        let { name, category, subCategory } = this.state.formData;

        if (name && category ) {
            if (
                this.inputRegexValidation('name', name)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }

        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };
    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.onSubmitButtonClick())
    }

    onSubmitButtonClick = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
        this.setState({ isLoading: true });
        const { name,
            isActive,
            isFeatured,
            category,
            externalUrl,
            description
            } = this.state.formData;
            let image = this.state.image;

        const payload = {
            

                "institutionCategoryId": category, 
                
                "isActive": isActive, 
                
                "isFeatured": isFeatured, 
                
                "title": name, 
                
                "externalUrl": externalUrl, 
                
                "imageUrl": image, 
                
                "description":description ,

        }
        axios.post(`${baseURL}/admin/sponsorAds`, payload, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ isLoading: false })
            toast.success("Plans added succuessfully!")
            setTimeout(() => {
                window.location.reload()

            }, 2000);
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }

    onViewProfieClick = (url) => {
        this.props.history.push(`/expertPpv/${url}`)
    }

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx
                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertData={this.state.expertData}
                    meta={this.state.meta}
                    onViewProfieClick={this.onViewProfieClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    onFileChange={this.onFileChange}
                    imgURL={this.state.imageUrl}
                    onNextButtonClick={this.onNextButtonClick}
                    profileImagePreviewUrl={this.state.profileImagePreviewUrl}

                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />            </div>
        )
    }
}
