import React from 'react'
import { Component } from 'react';
import axios from 'axios';
import { baseURL } from '../../../constants/applicationConstants.js';
import BlogsJsx from './blogs.jsx';
import { BLOG_URL_TOKEN,BLOG_URL } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';


export default class Blogs extends Component{
    constructor(props) {
        super(props);
        this.state = {
          categoryData: null,
            isLoading: false,
            categoryName: "",
            subCategoryData: null,
            categoryId: null,
            subCategoryId: null,
            subCategoryName: null,


          blogData: null,
          isProcessing: false,
          topOffers:null,
          page: 1,
          blogType: 'featured,main_news,glossory,trending',
          quoteData: {
            type: null,
            name: null,
            email: null,
            phone: null,
            postalCode: null
          },
          formError: {},
          expertData: null,
          productData: null,
          offerData: null
        }
      }
      getType = {
        Featured: 'main_news',
        Trending: "trending",
        Latest: "featured",
        Glossary: "glossory"
      }

      componentDidMount = () => {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
            




        this.setState({ isLoading: true,categoryName:params.get('category')  || ""})
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false }, () => {
                const data = JSON.parse(sessionStorage.getItem('askTheExpert'));
                if (data) {
                    const { categoryId,categoryName,subCategoryId, subCategoryName, questionAsked, formData } = data;
                    this.setState({ subCategoryName: subCategoryName, questionAsked: questionAsked, formData: formData, categoryId: categoryId, categoryName: categoryName })
                    this.setState({
                        subCategoryData: this.state.categoryData.filter(catDT => catDT.name === categoryName)[0]?.subInstitutionCategories
                    }, () => this.isFromSubmissionBlock())
                    this.setState({ subCategoryId: subCategoryId });
                    axios.get(`${baseURL}/askExpertQuestions?expertCategoryId=${categoryId}&expertSubCategoryId=${subCategoryId}`).then(res => {
                        this.setState({ expertQuestions: res.data.data });
                    })
                }
            })
            
        }).catch(err=>{
            this.setState({ isLoading: false })
          })

          setTimeout(() => {
            this.getBlogData();

          }, 500);

    }

    onCategoryClick = (catId, catName) => {
      this.props.history.replace('/')
      this.props.history.push(`/articles?category=${catName}`)
        this.setState({ categoryId: catId, categoryName: catName });
        setTimeout(() => {
          this.getBlogData()

        }, 400);
        // this.setState({
        //     subCategoryData: this.state.categoryData.filter(catDT => catDT.name === catName)[0]?.subInstitutionCategories
        // }, () => this.isFromSubmissionBlock())
        
      }
      getQueryParams() {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        return {
            paramName: params.get('search'), // Change 'paramName' to your actual query parameter name
            catName: params.get('category') ?? null, // Change 'paramName' to your actual query parameter name
        }; 

    }



      

      getBlogData = () => {
        var data = new FormData();
        data.append('limit', '36');
        data.append('device_token', '20.204.66.244');
          data.append('cat_slug', this.state.categoryName);
        data.append('paged', 1)
        // data.append('type', this.getType[this.state.blogType])
        data.append('type', 'featured,main_news,glossory,trending')

        this.setState({ isProcessing: true })
    
        axios.post(`${BLOG_URL}`, data,
          {
            headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
          }).then(res => {
            this.setState({ blogData: res.data, isProcessing: false });
          }).catch(err=>{
            this.setState({isProcessing: false})
          })
      }

    render(){
        return(
            <div>
                <BlogsJsx
                categoryData={this.state.categoryData}
                onCategoryClick={this.onCategoryClick}
                categoryName={this.state.categoryName}

                blogData={this.state.blogData}/>
                                {this.state.isProcessing && <Loader />}

            </div>
        )
    }
}