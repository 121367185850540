import React, { useEffect, useState } from 'react'
import { Footer } from '../../../common/footer/footer';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import dropbox from "../../../../assets/images/dropbox.svg";



import Sidebar from "../sidebar/sidebar.js";
import "../customerBlogs/customerBlogs.css";

// import "../customerBlogs/customerBlogs.css";
import userimg1 from "../../../../assets/images/user-img.png";
import readmorearrow from "../../../../assets/images/BLACKARROW.svg";
import { useTranslation } from 'react-i18next';

import { NEW_URL } from '../../../../constants/applicationConstants';
import { Col, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const CustomerBlogJsx = (props) => {
    const { t } = useTranslation();

    const {
        categoryData,
        blogData,
        onBlogTypeClick
    } = props;

    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        const formattedTitle = title.toLowerCase().replace(/\s+/g, '-');
        onBlogTypeClick(formattedTitle);
    }


    const [activeKey, setActiveKey] = useState();
    useEffect(() => {
        if (categoryData?.data?.length > 0) {
            setActiveKey(categoryData.data[4].name);
        }
    }, [categoryData]);

    const formatSlug = (categoryName) => {
        return categoryName.toLowerCase().replace(/\s+/g, '-');
    };
    return (
        <>
            <section className="entiredashboard blogs maincustomerdash blogs-main">
                <div className="container">
                    <div className="row bg-color-customer">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel">

                                <div className="dashboardlinkpop">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9 blogsinner">
                            <div className="blogs-category">
                                <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                                    <Row>
                                        <Col sm={12} className="main-round-wrapper">
                                            <Nav variant="pills" className="main-round-box">
                                                {categoryData?.data?.length > 0 ? (
                                                    categoryData.data.map((tab, index) => (
                                                        <Nav.Item key={tab.id} className="round-box">
                                                            <Nav.Link
                                                                onClick={() => {
                                                                    onSelectClick(tab.name, tab.icon);
                                                                    // setCurrentCategory(tab.name); // Set current category
                                                                }}
                                                                className="blog-cat-roundbox"
                                                                eventKey={tab.name}
                                                            >
                                                                <img src={`${NEW_URL}/${tab?.icon}`} alt="" />
                                                                {tab.name}
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))
                                                ) : (
                                                    <Nav.Item>
                                                        <Nav.Link disabled>{t("customerblogs.NOCATEGORIES")}</Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </Nav>
                                        </Col>
                                    </Row>

                                    <Tab.Content>
                                        {categoryData?.data?.map((tab) => (
                                            <Tab.Pane eventKey={tab.name} key={tab.id}>
                                                <div className="row acc-home main-blog-section">
                                                    {blogData?.body?.length > 0 ? (
                                                        blogData.body.map(blog => {
                                                            return <div className={`col-lg-4 col-md-6 col-sm-6 p-0 blogblogmain ${blog.cats[0].cat_name?.replace(/\s+/g, '')}`} key={blog.id}>
                                                               
                                                                <Link to={`/articleDetails/${blog.slug}`}  className="realestatebuttonblogs">
                                                                    <div className={`bloginner mainblog-rimib blog-design-${tab.name.toLowerCase().replace(' ', '-')}`}>
                                                                        <div className="bloginnerimage">
                                                                            <img src={blog?.image} alt="" />
                                                                        </div>
                                                                        <div className="blog-design">
                                                                            <div className="bolg_user">
                                                                                <div className="blog_user_img">
                                                                                    <div className="blog_user_main_img">
                                                                                        <img src={userimg1} alt="" />
                                                                                    </div>
                                                                                    <div className="blog_user_name">
                                                                                        <p>James Potter</p>
                                                                                        <p className="blog_user_date">{blog?.date_publish}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <a className="readmore-button">
                                                                                    {t("customerblogs.READMOREBUTTON")}
                                                                                    <img src={readmorearrow} alt="" />
                                                                                </a>
                                                                            </div>
                                                                            <div className="bloginnertext blog-text">
                                                                                {blog?.title}
                                                                            </div>
                                                                            <div className="bloginnertag blog-button mt-3">
                                                                                {blog?.cats.map(cat => cat.name).join(', ')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                                
                                                            </div>
                                                        })
                                                    ) : (
                                                        <div className="dropdefault text-center">
                                                            <div className="dropdefaultimage">
                                                                <img src={dropbox} alt="" />
                                                            </div>
                                                            <div className="dropdefaulttext">
                                                                <h5>{t("customerblogs.YOUDONTHAVETEXT")}</h5>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Tab.Container>


                            </div>
                        </div>
                    </div>
                </div>

                {
                    openModal &&
                    <div className="modalspecial blog-modal">
                        <div>
                            <div className="mybtn">
                                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                        fill="#ffffff" stroke="none">
                                        <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                                    </g>
                                </svg></button>
                            </div>
                            <div className="modal-header text-center">
                                <h3>{blog?.title}</h3>
                            </div>
                            <div className="modal-body text-center">
                                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                                </div>
                                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                            </div>
                        </div>
                    </div>

                }


            </section>
            <Footer />

        </>

    );
};

export default CustomerBlogJsx;