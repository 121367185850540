import React, { useState } from 'react';

import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import IconClosed from "../../../assets/images/Icon-Set-Filled.png";
export const AdsSection = (props) => {
  const { profileData, legalName } = props;
  const [showModal, SetShowModal] = useState(false);
  const [modalData, setModalData] = useState({})

  const showModalDive = (data, action) => {

    setModalData(data)
    SetShowModal(action)
  }
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    margin: 10,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  const { t } = useTranslation();

  return <><section className="limitedoffer" id="ppvAdsSection">
    <div className="container">
      <div className="expppvofferinnersection">
        <div className="heading">
          <h2>{t("ADSSECTION.TEXT1")}</h2>
          <h5 className="h5_style">{t("ADSSECTION.TEXT2")} </h5>
          <h5 className="h5_style">{t("ADSSECTION.TEXT3")}</h5>
          <h5 className="h5_style"><b>{t("ADSSECTION.TEXT4")}</b></h5>
          <div className="offerslider martop40">
            <Slider {...settingstwo}>
              {
                profileData?.advertisements?.map(ad => {
                  return <div className="sliderContent">

                    <img
                      className="d-block w-100"
                      src={`${NEW_URL}/${ad.imageUrl}`}
                      alt=" "
                    />

                    {/* <div className="offerdspop">
                         <div className="ppvcat"><h3>Category</h3>{ad?.subInstitutionCategory?.name}</div>
                        <div className="ppvdate"><h3>Date</h3></div> 
                        </div> */}
                    <div className="descriptionppvpop">

                      <h3>Debt Consolidatation</h3> <h5> {ad.description}<button className="readmore" onClick={() => showModalDive(ad, true)}>Read More....</button>
                      </h5>
                      {/* <div className="disgrid grid-3 offerdspop">
                         <div className="ppvcat"><h3>Sub-Category</h3>{ad?.subInstitutionCategory?.name}</div>
                     
                        </div> */}
                      <div>
                      {ad.externalLink && <button onClick={()=> {window.scrollTo(0,0); window.open(ad.externalLink,'_blank')}} className="knowmorebutton">Know More</button>}
                        
                      </div>
                    </div>
                  </div>
                })
              }
            </Slider>
            {/* <AwesomeSlider>
                            {
                                profileData?.advertisements?.map(ad => {
                                    return <>
                                        <img
                                            className="d-block w-100"
                                            src={`${uploadURL}/images${ad.imageUrl}`}
                                            alt=" "
                                        />
                                        <h3>{ad.description}</h3>
                                        <div  className="ppvawesomesliderdescription" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className="ppvawesomesliderproducttype"><p>{ad?.description}</p></div>
                                            <div className="ppvawesomesliderreleatedproduct">{ad?.subInstitutionCategory?.name}</div>
                                        </div>
                                    </>
                                })
                            }
                        </AwesomeSlider> */}
          </div>
          <div className="showall  text-center">
            <Link to={{
              pathname: "/listOfAd",
              state: { data: legalName, frompage: 'ppvAdsSection', pagePath: window.location.pathname }
            }} className="showallbtn mt-4 mb-3">{t("ADSSECTION.VIEWALLBTN")}</Link>
          </div>
          {/* <Link
            to={{
              pathname: "/listOfAd",
              state: { data: legalName, frompage: 'ppvAdsSection', pagePath: window.location.pathname }
            }}
            className="ctaonebutton widthhalf mt-100"
            onClick={() => window.scrollTo(0, 0)}>
            View All
                    </Link> */}
          {/* <button type="button" className="ctaonebutton widthhalf mt-5" onClick={() => onViewAllButtonClick('Ads')}>View All</button> */}
        </div>
      </div>
    </div>
  </section>
    {showModal && <div className="modalspecial popupad">
      <div>
        <div className="expertadmodal">
          <button type="button close-button" style={{ border: "none", background: 'none', position: "absolute", top: "1%", right: "0%" }} onClick={() => { showModalDive(null, false) }}> <img src={IconClosed}  alt=''/></button>

      <div className="expmodalimage"><img src={`${NEW_URL}/${modalData.imageUrl}`}  alt=''/></div>
      <h3 className="ds">Description</h3><div className="expmodaltext">{modalData?.description} </div>
      {modalData.externalLink && <button onClick={()=> {window.scrollTo(0,0); window.open(modalData.externalLink,'_blank')}} className="knowpop">Know More</button>}
      
    </div></div></div>}
  </>

}
