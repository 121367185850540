import React from 'react';

import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
export const OfferSection = (props) => {
  const { t } = useTranslation();
  const { profileData, legalName } = props;


  var settings = {
    dots: true,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // initialSlide:2  
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerPadding: '0px',
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            slidesToScroll: 1
          }
        }
      ]  
  };
  var settingstwo = {
    dots: false,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // initialSlide:2  
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '0px',
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: '0px',
            slidesToScroll: 1
          }
        }
      ]  
  };

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return <section className="limitedtimeoffer" id="ppvOfferSection">
    <div className="container">
      <div className="ppvofferinnersection">
       
          <h2>{t("PPVINSTITUTE.OFFERTITLE")}</h2>
          <h5>{t("PPVINSTITUTE.OFFERSUBTITLE")} </h5>
          <div className="offerslider">
          <Slider {...settingstwo}>
                {
                  profileData?.instituteOffers?.map(offer => {
                    return <div className="sliderContent">
                     
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                         
                         
                        <div className="descriptionppvpop">
                          <h5> {offer.offerName}</h5>
                     
                         <div className="ppvcat"><h3>Category:<span> {offer?.subInstitutionCategory?.name}</span></h3></div>
                      <a href={(offer?.externalUrl)} target="_blank" className="desApplynow">Apply Now</a>
                        </div>
                         <div className="expdate"><h3>Expires On: <span>{getProperData(offer?.validTo)}</span></h3></div>
                    </div>
                  })
                }
              </Slider>

           
          </div>
                    <Link
                        to={{
                            pathname: "/listOfOffer",
                            state: { data: legalName, frompage: 'ppvOfferSection' ,pagePath:window.location.pathname }
                        }}
                        className="showallbtn "
                        onClick={()=> window.scrollTo(0,0)}>
                        View All
                    </Link>
       
      </div>
    </div>
  </section>
}
