import React from 'react'
import { useTranslation } from "react-i18next";
import ResetPassJson from "./passwordCreation.json";
import leftarrow from "../../../assets/images/left-arrow.svg";
import "../passwordCreation/passwordCreation.css";
const PassCreationJsx = (props) => {
  const { t } = useTranslation();

  let {
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
  } = props;

  return (
    <>
      <section className="loginscreeninstitute registrationinstitute passw ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5 ">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => props.onBackClick()}><img src={leftarrow}  alt={''}/></div>
                <div className="detailsleft">
                  <h1>Password Creation</h1>

                  <div className="passtext martop60">
                    <h4 className="italic h4_style">Far from the bots. Even farther from the hackers. </h4>
                    <h5 className="h4_style p-text">Please create a strong password having:</h5>
                    <ul className="password-condition">
                      <li>Characters between 8 and 16</li>
                      <li>At least 1 number</li>
                      <li>At least 1 special character e.g.,! @ # ?</li>
                      <li>At least 1 uppercase character</li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext passwordauthorization martop60 paddingbot60">
                <h2 className="h2_style text-center">Enter a strong <span>password</span> below </h2>
                <h6 className="h5_style text-center mb-5">Do you know in 81% of the cyber attacks, the culprit is a weak password?
Please create a strong password following the rules mentioned on the left.</h6>


                {ResetPassJson.map((item, i) => (
                  <>
                    {/*
                     <div className="input-container form-group mb-4">
                      <input type="password" className="input form-control" placeholder=" " />
                      <div className="cut"></div>
                      <label className="placeholder">Enter Repassword </label>
                    </div>
                     */}

                    <div className="input-container form-group  mb-4">
                      <input
                        type={item.type}
                        className={
                          item.isRequired && formError[item.name]
                            ? "input form-control  is-invalid"
                            : "input form-control"
                        }
                        id={item.name}
                        name={item.name}
                        placeholder=" "
                        defaultValue={formData[item.name]}
                        onChange={onInputChangeHandler}
                        onBlur={onTouchHandler}
                      />
                      <div className="cut"></div>
                      <label className="placeholder">{t(`${item.label}`)}</label>
                      <span className="h6 text-danger">
                        {item.isRequired &&
                          formError[item.name] &&
                          (formError[item.name] === "req"
                            ? t(item.error)
                            : (formError[item.name] === "regx") ? t(item.type_error) : "Password did not match")}
                      </span>
                    </div>

                  </>
                ))}
                {/* <div className="input-container form-group mb-4 mt-4">
                  <input type="password" className="input form-control" placeholder=" " />
                  <div className="cut"></div>
                  <label className="placeholder">Enter Password </label>
                </div>
                <div className="input-container form-group mb-4">
                  <input type="password" className="input form-control" placeholder=" " />
                  <div className="cut"></div>
                  <label className="placeholder">Enter Repassword </label>
                </div> */}
                <button type="submit" 
                className={(formSubmitButtonDisable) ?  "btn-secondary btn mt-4 ctatwobutton"
                      : "mt-4 ctaonebutton"}
                disabled={formSubmitButtonDisable}
                onClick={formSubmitHandler}>CONTINUE</button>

              </div>

            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default PassCreationJsx;
