import React from 'react'
import { useEffect, useState } from 'react';

import { Pagination } from '../../../common/pagination/pagination';
import moment from 'moment';


import { Footer } from '../../../common/footer/footer';
import {  NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    
    onTouchHandler,
    formSubmitButtonDisable,
    onSubmitButtonClick,
    onPaginationButtonClick,
    expertData,
    
    handleChange,
    deleteOffer, editCategory,meta, onFileChange,imgURL, profileImagePreviewUrl, onNextButtonClick,editOn
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  
  const [page, setPage] = useState(1);



  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Submit Category</h2>
                {
                  profileImagePreviewUrl ?
                    <img
                      src={profileImagePreviewUrl}
                      height="170px"
                      width="250px"
                      alt="edit profile"
                    /> :
                    editOn ?
                      <img
                        src={`${NEW_URL}/${imgURL}`}
                        height="170px"
                        width="250px"
                        alt="edit profile"
                      />
                      : "Your uploaded picture appears here"

                }
                <div className="uploaddetails">
                  {/* <input type="file" onChange={onFileChange}></input> */}
                  <div className="button_outer">
                    <div className="btn_upload">
                      <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                      <label for="upload_file">Choose Picture *</label>
                    </div>
                  </div>
                  <button type="button" className="ctaonebutton" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Picture</button>
                  {/* <button type="button" className="ctaonebutton" onClick={() => changeLogoState(true)}>Change Logo</button> */}
                </div>
                <div className="myexpertformarea searcharea seachadmincustom martop40">
                  <div className="input-container form-group">

                    <input
                      className="input form-control"
                      placeholder=" "
                      name="name"
                      onChange={onInputChange}
                      onBlur={onTouchHandler}
                      value={formData.name}
                    />
                    <div className="placeholder">Name *</div>

                  </div>
                  <div className="input-container form-group">
                    <select
                      className="input form-control form-select"
                      placeholder=" "
                      name="category"
                      onChange={onInputChange}
                      onBlur={onTouchHandler}
                    >
                      <option value="">All</option>
                      {categoryData?.map((item) => {
                        return (
                          <>
                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>
                    <div className="placeholder">Category</div>
                  </div>
                  <div className="input-container form-group">
                    <select
                      className="input form-control form-select"
                      placeholder=" "
                      name="subCategory"
                      onChange={onInputChange}
                      onBlur={onTouchHandler}
                    >
                      <option value="">All</option>
                      {subCategoryData?.map((item, i) => {
                        return (
                          <>
                            <option value={item.institutionSegmentId} selected={item.institutionSegmentId === formData.subCategory}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>
                    <div className="placeholder">Segment *</div>
                  </div>

                  <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
                </div>
              </div>
{!editOn && 
              <div className="expertsection martop30 mb-5">
                <div className="searchdetails searchdetalsen">
                  <table className="searchdetailsdb">
                    <tr>
                      <th>ID</th>
                      <th>Icon</th>
                      <th>Name</th>
                      <th>Created ad</th>
                      <th>isFeaturedOfferSubCategory</th>
                      <th>isFeaturedProductSubCategory</th>
                      <th>Action</th>
                    </tr>

                    {expertData?.map((item, i) => (
                      <>
                        <tr>
                          <td>{item.institutionCategoryId}</td>
                          <td>                      <img src={`${NEW_URL}/${item?.icon}`} alt={''} />
                          </td>
                          <td>{item.name}</td>
                          <td>{moment(item.updatedAt).format('DD-MM-YYYY')}</td>
                          <td><input name="offer" type="checkbox" checked={item?.isFeaturedOfferSubCategory}
                            onChange={e => handleChange(e, item.institutionCategoryId)}
                          />
                          </td>
                          <td><input name="product" type="checkbox" checked={item?.isFeaturedProductSubCategory}
                            onChange={e => handleChange(e, item.institutionCategoryId)}

                          /></td>

                          <td>{moment(item.updatedAt).format('MM-DD-YYYY')}</td>
                          <td>
                            <button className="btn btn-success" onClick={() => editCategory(item?.institutionCategoryId)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => deleteOffer(item?.institutionCategoryId)} >delete</button></td>

                        </tr>
                      </>
                    ))}

                  </table>
                </div>
              </div>
}

 {expertData?.length > 0 && !editOn && <Pagination
            limit={10}
            totalCount={meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />}
            </div>

          </div>

        </div>
        
      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;