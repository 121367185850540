import React from 'react' 
import { Component } from 'react';  
import ArticleDeatilsJsx from './articleDeatils.jsx';

import axios from 'axios';
import { BLOG_URL, BLOG_URL_TOKEN } from '../../../constants/applicationConstants.js'; 
import { Loader } from '../../../components/common/loader/loader.jsx';

export default class ArticleDeatils extends Component {
     
    constructor(props) {
        super(props);
        this.state = {
            detialsData: null,
            isLoading:false
        }
    }


    onDataDetails = ( ) => {
        console.log("===>>>> 222222")
        console.log(this.props.match.params.id)
        this.setState({ isLoading: true })
        
        var data = new FormData(); 
        data.append('device_token', '20.204.66.244'); 
        data.append('slug',`${this.props.match.params.id}`)
        
        
        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                console.log("resresres =>  ",res.data.body);
                this.setState({ detialsData: res.data, isLoading: false }); 
            }).catch(err => {
                this.setState({ isLoading: false })
            })
    }


    componentDidMount = () => {
        console.log("===>>>> 11111")
        this.onDataDetails();
    }


    render() {
        return (
            <div>
                <ArticleDeatilsJsx
                 detialsData={this.state.detialsData}  
                 isLoading={this.isLoading}/>

                    {this.state.isLoading && <Loader />}
            </div>
        )
    }
}