import React from 'react'
import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { getProperWebsite } from '../../../../shared/helper';
import { NEW_URL } from '../../../../constants/applicationConstants';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {

    productData
  } = props;

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="alldashboard cust-product">

        <div className="dashboardrightpanel  ">

          <div className="suggestedoffercustomer">
            <section className="limitedoffer ">
              {
                productData
                  ?.RealEstate &&
                <div className="limitedofferinner">
                  <div className="heading">
                    
                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE0")}</h5>
                  </div>
                  <div className="offerslider  ">
                    <AwesomeSlider>
                      {
                        productData
                          .RealEstate?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerproducts.DESCRIPTION")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerproducts.CATEGORY")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">

                                      {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              }
              {
                productData
                  ?.Investment &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>

                    </div>
                    <div className="offerslider  ">

                      <AwesomeSlider>
                        {
                          productData
                            ?.Investment?.map(offer => {
                              return <div>
                                 <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`} >{offer?.parentInstitutionCategory}</button></div>
                                <img
                                  className="d-block w-100"
                                  src={`${NEW_URL}/${offer.imageUrl}`}
                                  alt=" "
                                />
                                <h3>{offer.description}</h3>
                                <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner">
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerproducts.DESCRIPTION")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerproducts.CATEGORY")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">

                                      {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className=" knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}
                                    </div>
                                  </div>
                                </div>

                              </div>
                                
                              </div>
                            })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                productData
                  ?.Mortgage &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE2")} </h5>
                    </div>
                    <div className="offerslider  ">

                      <AwesomeSlider>
                        {
                          productData
                            ?.Mortgage?.map(offer => {
                              return <div>
                                 <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                                <img
                                  className="d-block w-100"
                                  src={`${NEW_URL}/${offer.imageUrl}`}
                                  alt=" "
                                />
                                <h3>{offer.description}</h3>
                                <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerproducts.DESCRIPTION")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerproducts.CATEGORY")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">

                                      {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}
                                    </div>
                                  </div>
                                </div>

                              </div>
                                
                              </div>
                            })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                productData
                  ?.Insurance &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE3")}</h5>
                    </div>
                    <div className="offerslider  ">

                      <AwesomeSlider>
                        {
                          productData
                            ?.Insurance?.map(offer => {
                              return <div>
                                 <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                                <img
                                  className="d-block w-100"
                                  src={`${NEW_URL}/${offer.imageUrl}`}
                                  alt=" "
                                />
                                <h3>{offer.description}</h3>
                                <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerproducts.DESCRIPTION")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerproducts.CATEGORY")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">

                                      {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className=" knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}
                                    </div>
                                  </div>
                                </div>

                              </div>
                                
                              </div>
                            })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                productData
                  ?.Banking &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE4")} </h5>

                    </div>
                    <div className="offerslider  ">

                      <AwesomeSlider>
                        {
                          productData
                            ?.Banking?.map(offer => {
                              return <div>
                                 <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                                <img
                                  className="d-block w-100"
                                  src={`${NEW_URL}/${offer.imageUrl}`}
                                  alt=" "
                                />
                                <h3>{offer.description}</h3>
                                <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerproducts.DESCRIPTION")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerproducts.CATEGORY")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">

                                      {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className=" knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}
                                    </div>
                                  </div>
                                </div>

                              </div>
                               
                              </div>
                            })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
            </section>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;