import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { baseURL } from '../../../../../constants/applicationConstants.js';
import { Loader } from '../../../../common/loader/loader.jsx';
import ConfirmAskTheExpertJsx from './confirmAskTheExpert.jsx'

export default class ConfirmCustomerAskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            subCategory: null,
            question: null,
            formData: null,
            isLoading: false,
            categoryId: null,
            subCategoryId: null,
            customerID: null
        }
    }

    componentDidMount = () => {
        const data = JSON.parse(sessionStorage.getItem('askTheExpert'));
        console.log("data===>>>",data);
        const { categoryName, subCategoryName, questionAsked, formData, categoryId, subCategoryId, customerID } = data;
        this.setState({ category: categoryName, subCategory: subCategoryName, question: questionAsked, formData: formData, subCategoryId: subCategoryId, categoryId: categoryId, customerID: customerID });
    }

    onEditButtonClick = () => {
        this.props.history.push('/customerAskTheExpert');
    }

    onConfirmAndSubmitButtonClick = () => {
        let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

        this.setState({ isLoading: true });
        const payload = {
            // "expertCategoryId": this.state.categoryId,
            // "expertSubCategoryId": this.state.subCategoryId,
            // "phoneNUmber": this.state.formData.phoneNumber,
            // "fullName": this.state.formData.name,
            // "postalCode": this.state.formData.postalCode,
            // "email": this.state.formData.email,
            // "question": this.state.question

            "categoryId": this.state.categoryId,
            "subCategoryId": this.state.subCategoryId,
            "question": this.state.question
        }
        axios.post(`${baseURL}/customer/${this.state.customerID}/askExpertQuestionResponse`, payload, {
            headers: { 'x-access-token': token }
          }).then(res => {
            this.props.history.push('/confirmAndSubmitCustomerAskTheExpert');
            this.setState({ isLoading: false })
            sessionStorage.removeItem("askTheExpert")
        }).catch(err => {
            this.setState({ isLoading: false })
            toast.error(err?.response.data.error.message);
        })
    }

    render() {
        return (
            <div>
                <ConfirmAskTheExpertJsx
                    category={this.state.category}
                    subCategory={this.state.subCategory}
                    question={this.state.question}
                    onEditButtonClick={this.onEditButtonClick}
                    onConfirmAndSubmitButtonClick={this.onConfirmAndSubmitButtonClick}
                />
                {this.state.isLoading && <Loader />}
                <ToastContainer />
            </div>
        )
    }
}