import React from 'react'
import { useTranslation } from "react-i18next";
import '../../../../assets/stylesheet/desktop.css';
import '../../../../assets/stylesheet/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import leftarrow from "../../../../assets/images/left-arrow.svg";
import datecalender from "../../../../assets/images/datecalender.svg"
import alertCircle from "../../../../assets/images/planpricingtooltip.svg";

import DatePicker3 from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Select, { components } from 'react-select';
import { formatInputValue } from '../../../../shared/helper';
import InputMask from 'react-input-mask';
import DropdownList from "./dropdownList.jsx";

const SpecialModalJSX = (props) => {
    const { t } = useTranslation();
    const {
        field,
        formData,
        onInputChangeHandler,
        onTypeSearch,
        onTouchHandler,
        categorySection,
        subCategorySection,
        formSubmitButtonDisable,
        formError,
        onDateChange,
        handleChange,
        onNextButtonClick,
        fillBusinessPhone,
        fillBusinessEmail,
        getSocialImage,
        keyword,
        onInstituteClick,
        showModal,
        changeHideState,
        disableEdit
    } = props;


    // const abhi = [
    //     {
    //         "institutionCategoryId": 11,
    //         "name": "Long term non redeemable GIC"
    //     },
    //     {
    //         "institutionCategoryId": 12,
    //         "name": "Long term redeemable GIC"
    //     },
    //     {
    //         "institutionCategoryId": 13,
    //         "name": "Escalating GIC"
    //     },
    //     {
    //         "institutionCategoryId": 14,
    //         "name": "Mutual Funds"
    //     },
    //     {
    //         "institutionCategoryId": 16,
    //         "name": "Direct Trading Account"
    //     },
    //     {
    //         "institutionCategoryId": 17,
    //         "name": "TFSA(Tax Free Saving Account)"
    //     },
    //     {
    //         "institutionCategoryId": 18,
    //         "name": "RRSP (Registered Retirement Savings Plan)"
    //     },
    //     {
    //         "institutionCategoryId": 19,
    //         "name": "RESV(Registered Education Savings Plan)"
    //     }
    // ]

    const edit = sessionStorage.getItem("editSpecialization") && JSON.parse(sessionStorage.getItem("editSpecialization"))


    const newArrayOfObj = subCategorySection?.map(({
        name: label, institutionCategoryId: value,
        ...rest
    }) => ({
        label, value,
        ...rest
    }));


    const closeModal = () => {
        sessionStorage.removeItem("editSpecialization");
    }
    // const masterID = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    // const insExpert = localStorage.getItem("adInsExpert")

    const getMuskPattern = (name) =>
        name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'

    const flowType = localStorage.getItem('flowName');
    const verificationStatus = localStorage.getItem('verificationStatus');

    return (
        <>
            {showModal &&
                <div className="modalspecial">
                    <div>
                        <div className="right-institute">

                            <div className="">
                                <div className="mybtn">
                                    <button type="button" className="myadbtn" onClick={() => { changeHideState(false); closeModal() }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                                </div>
                                <div className="modal-header text-center">
                                    <div className="leftsightbackdetails listofofferleft d-flex mt-3">
                                        <div className="back_btn" onClick={() => { changeHideState(false); closeModal() }}><img src={leftarrow} alt={' '} />
                                        </div>

                                        <div className="plaindetailsleft">
                                            <h1 className="h2_style font-weight-bold">Add your Specialization</h1>
                                            <h5 className="h5_style">Demonstrate your professional skills & become recognized! </h5>
                                            <h5 className="h5_style">As expert in a specific field, you assist individuals searching for help in that subject</h5>
                                            <h5 className="h5_style">Please enter the information below. All the fields marked with * are mandatory
                         </h5>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="h4_style text-center mt-5">Specialization Detail</h4>
                                <hr></hr>

                                {field?.map((item, i) => (
                                    <>
                                        {item.fieldType === "autoselect" &&
                                            <>

                                                <div className="input-container form-group mb-4">
                                                    <input
                                                        type="text"
                                                        required=""
                                                        name={item.name}
                                                        className={
                                                            item.isRequired && formError[item.name]
                                                                ? "input form-control  is-invalid"
                                                                : "input form-control"
                                                        }
                                                        onChange={onTypeSearch}
                                                        value={formData[item.name]}
                                                        disabled={disableEdit && flowType != 'admin' && verificationStatus != 'unVerified'}
                                                    />
                                                    <label className="placeholder">{t(`${item.placeholder}`)}</label>
                                                    <div className="cut"></div>
                                                    <span className="text-danger">
                                                        {item.isRequired &&
                                                            formError[item.name] &&
                                                            (formError[item.name] === "req"
                                                                ? t(item.error)
                                                                : t(item.type_error))}
                                                    </span>
                                                </div>

                                                <DropdownList
                                                    keyword={keyword}
                                                    onInstituteClick={onInstituteClick}
                                                />
                                                <p className="small">Can't find your institution? <a href="mailto:support@rimib.com">Contact our marketing team</a></p>
                                            </>
                                        }
                                        {item.fieldType === "input" &&
                                            <div className="position-relative">

                                                <div className="mb-4 input-container form-group">
                                                    {item.name === 'postalCode' ?
                                                        <InputMask mask={getMuskPattern(item.name)} value={formData[item.name]} name={item.name} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                            {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                                                        </InputMask> :
                                                        <input
                                                            onChange={onInputChangeHandler}
                                                            onBlur={onTouchHandler}
                                                            required
                                                            type={item.type}
                                                            className={
                                                                item.isRequired && formError[item.name]
                                                                    ? "input form-control  is-invalid"
                                                                    : "input form-control"
                                                            }
                                                            id={item.name}
                                                            name={item.name}
                                                            placeholder={" "}
                                                            maxLength={item.maxLength}
                                                            value={formData[item.name]}
                                                        />}
                                                    <div className="cut"></div>
                                                    <label className="placeholder">{t(`${item.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > {<img src={alertCircle} className="tooltipim" alt={' '} />}<div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>
                                                    {item.name === "institution" &&
                                                        <div className="form-text">Can't find your institution? Contact our marketing team</div>
                                                    }
                                                </div>
                                                <span className="text-danger">
                                                    {item.isRequired &&
                                                        formError[item.name] &&
                                                        (formError[item.name] === "req"
                                                            ? t(item.error)
                                                            : t(item.type_error))}
                                                </span>
                                            </div>}
                                        {item.showAutoFill &&

                                            <div className="disgrid grid-2 mt-3 mb-3">
                                                <div className="input-container form-group form-check d-flex">
                                                    <input className="form-check-input" onClick={() => fillBusinessPhone()} type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        Business Phone same as Primary Phone? Check to pre-fill.
                   </label>
                                                </div>
                                                <div className="input-container form-group form-check d-flex">
                                                    <input className="form-check-input" onClick={() => fillBusinessEmail()} type="checkbox" value="" id="flexCheckDefault1" />
                                                    <label className="form-check-label" for="flexCheckDefault1">
                                                        Business Email same as Expert Login Email? Check to pre-fill.
                   </label>
                                                </div>
                                            </div>
                                        }




                                        {item.fieldType === "select" &&
                                            <div className={`mb-4 input-container selectboxmain form-group ${formData[item.name] && `selecttransition`}`}>
                                                <>
                                                    {
                                                        item.name === "catagorySpecialization" &&
                                                        <div className="mb-4 input-container form-group">
                                                            <select className="input form-control form-select" onChange={onInputChangeHandler} name="catagorySpecialization" disabled={edit && edit}
                                                            >
                                                                <option value="">Select</option>
                                                                {categorySection?.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId == formData['catagorySpecialization']}>{item?.institutionCategory?.name}</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <label className="placeholder">{t(`${item.placeholder}`)}
                                                                {item.showTooltip && <span className="plantooltip custooltip" title="" >
                                                                    <img src={alertCircle} className="tooltipim" alt={' '} />
                                                                    <div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>
                                                        </div>
                                                    }
                                                    {
                                                        item.name === "subCategory" &&
                                                        <div className="mb-4 input-container form-group">
                                                            <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                                                <option value="">Select</option>
                                                                {subCategorySection?.map((item) => {
                                                                    return (
                                                                        <>
                                                                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId == formData['subCategory']} >{item.name}</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <label className="placeholder">{t(`${item.placeholder}`)}
                                                                {item.showTooltip && <span className="plantooltip custooltip" title="" >
                                                                    <img src={alertCircle} className="tooltipim" alt={' '} />
                                                                    <div className="tooltipcontent">{item.tooltipTitle}</div></span>}</label>
                                                        </div>
                                                    }


                                                </>
                                            </div>
                                        }
                                        {
                                            item.fieldType === "multiple" &&
                                            <div className={`disgrid grid-${item.fields.length}`}>
                                                {item.fields.map((items) => {
                                                    return <>
                                                        {
                                                            items.fieldType === "input" &&
                                                            <div>

                                                                <div className="mb-4 input-container form-group">
                                                                    {
                                                                        items.name === 'phone' || items.name === 'institutionalContact' || items.name === 'customerContact' || items.name === 'businessPhone' ?
                                                                            <InputMask mask={getMuskPattern('phone')} value={formData[items.name]} name={items.name} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                                                {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                                                                            </InputMask> :
                                                                            <input
                                                                                onChange={onInputChangeHandler}
                                                                                onBlur={onTouchHandler}
                                                                                required
                                                                                type={items.type}
                                                                                className={
                                                                                    items.isRequired && formError[items.name]
                                                                                        ? "input form-control  is-invalid"
                                                                                        : "input form-control"
                                                                                }
                                                                                id={items.name}
                                                                                name={items.name}
                                                                                placeholder={" "}
                                                                                maxLength={items.maxLength}
                                                                                value={formData[items.name]}
                                                                            />}
                                                                    <div className="cut"></div>
                                                                    <label className="placeholder">{t(`${items.placeholder}`)} {items.showTooltip && <span className="plantooltip custooltip righttool" title="" > <img src={alertCircle} className="tooltipim" alt={' '} /><div className="tooltipcontent">{items.tooltipTitle}</div></span>} </label>
                                                                    <span className="text-danger">
                                                                        {items.isRequired &&
                                                                            formError[items.name] &&
                                                                            (formError[items.name] === "req"
                                                                                ? t(items.error)
                                                                                : t(items.type_error))}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }{
                                                            items.fieldType === "select" &&
                                                            <div className={`mb-4 input-container selectboxmain form-group ${formData[items.name] && `selecttransition`}`}>
                                                                <>
                                                                    <Select className={`selectbox`}
                                                                        closeMenuOnSelect={true}
                                                                        placeholder={" "}
                                                                        options={items?.options}
                                                                        onChange={(e) => handleChange(e, items)}
                                                                        value={items?.options.filter(ite => ite.value === formData[items.name])}

                                                                        components={{
                                                                            Input: (props) => {
                                                                                const { value: inputval } = props

                                                                                return <>
                                                                                    <div className={`placeholder ${inputval !== '' && 'active'}`}>{t(`${items.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > <img src={alertCircle} className="tooltipim" alt={' '} /><div className="tooltipcontent">{item.tooltipTitle}</div></span>}</div>

                                                                                    <components.Input {...props} className={"mb-4 input-container form-group"} >
                                                                                        {props.children}
                                                                                    </components.Input>
                                                                                </>
                                                                            },
                                                                        }}
                                                                    />
                                                                    {/* <div className="placeholder">{t(`${items.placeholder}`)} {item.showTooltip && <span className="plantooltip custooltip" title="" > <img src={alertCircle} className="tooltipim"  alt={' '} /><div className="tooltipcontent">{item.tooltipTitle}</div></span>}</div> */}
                                                                </>
                                                            </div>
                                                        }
                                                        {items.fieldType === "date" &&
                                                            <>
                                                                <DatePicker3 className="form-control" name="startDate"
                                                                    onChange={(date) => { onDateChange(date) }}
                                                                    inputPlaceholder="dd/mm/yyyy"
                                                                    shouldHighlightWeekends
                                                                    maximumDate={{
                                                                        year: new Date().getFullYear(),
                                                                        month: new Date().getMonth() + 1,
                                                                        day: new Date().getDate()
                                                                    }}
                                                                    wrapperClassName="input-container form-group"
                                                                    renderInput={({ ref }) => (
                                                                        <>
                                                                            <input
                                                                                ref={ref}
                                                                                placeholder=" "
                                                                                type="text"
                                                                                required
                                                                                className="input form-control"
                                                                                value={formatInputValue(formData.dateOfIncorporation)}
                                                                            />
                                                                            <div className="cut"></div>
                                                                            <label className="placeholder">{t(`${items.placeholder}`)}</label>
                                                                            <span className="dateim"><img src={datecalender} alt={' '} /></span>
                                                                        </>
                                                                    )}
                                                                    formatInputText={() => formatInputValue(formData.dateOfIncorporation)}
                                                                />
                                                            </>
                                                        }
                                                    </>
                                                })}
                                            </div>
                                        }
                                        {
                                            item.fieldType === "text" &&
                                            <div className="primarycontact martop40">
                                                <h4 className="h4_style text-center">{t(`${item.contentHeading}`)}</h4>
                                                <h5 className="h5_style text-center">{t(`${item.SUBHEADING1}`)}</h5>
                                                <h5 className="h5_style text-center">{t(`${item.SUBHEADING2}`)}</h5>

                                                <hr></hr>
                                            </div>
                                        }
                                        {item.fieldType === "social" &&
                                            <div className="disgrid grid-2">
                                                {
                                                    item.types.map(items =>
                                                        <div className="input-container form-group social-control">
                                                            <input
                                                                type="text"
                                                                required=""
                                                                name={items.name}
                                                                className={
                                                                    items.isRequired && formError[items.name]
                                                                        ? "input form-control  is-invalid"
                                                                        : "input form-control"
                                                                }
                                                                placeholder=" "
                                                                onChange={onInputChangeHandler}
                                                                value={formData[items.name]}
                                                            />
                                                            <label className="placeholder">{t("EXPERT_SPECIAL.LINK")}</label>
                                                            <div className="cut"></div>
                                                            <img src={getSocialImage(items)} className="formscicon" alt={' '} />
                                                            <span className="text-danger">
                                                                {items.isRequired &&
                                                                    formError[items.name] &&
                                                                    (formError[items.name] === "req"
                                                                        ? t(items.error)
                                                                        : t(items.type_error))}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }

                                        {/* <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                            <option value="">Select</option>
                                            {subCategorySection?.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId == formData['subCategory']} >{item.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select> */}

                                        {item.fieldType === "multiSelect" &&
                                            <>

                                                <Select className="mb-4 form-container form-group multiselect"
                                                    classNamePrefix="multicustomselect"
                                                    closeMenuOnSelect={false}
                                                    isMulti
                                                    options={newArrayOfObj}
                                                    onChange={(e) => handleChange(e, 'subCategory')}

                                                    value={formData['subCategory']}
                                                    components={{
                                                        Input: (props) => {
                                                            const { value } = props

                                                            return <>
                                                                <label className={`multiplaceholer ${(formData['subCategory']?.length > 0 || value !== '') ? 'active' : ''}`}>{t(`${item.placeholder}`)}
                                                                    {item.showTooltip && <span className="plantooltip custooltip" title="" > {<img src={alertCircle} className="tooltipim" alt={' '} />}<div className="tooltipcontent">{item.tooltipTitle}</div></span>}
                                                                </label>

                                                                <components.Input {...props} className={"mb-4 input-container form-group"} >
                                                                    {props.children}
                                                                </components.Input>
                                                            </>
                                                        },
                                                        Option: (props) => {
                                                            const { isSelected } = props

                                                            return (
                                                                <div>
                                                                    <components.Option {...props}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={isSelected}
                                                                            onChange={() => null}
                                                                        />
                                                                        {props.children}
                                                                    </components.Option>
                                                                </div>
                                                            );
                                                        }
                                                    }}

                                                />
                                            </>
                                        }
                                    </>
                                ))}

                                <button className={formSubmitButtonDisable ? 'btn-secondary btn mt-4 ctatwobutton' : 'mt-4 ctaonebutton'} disabled={formSubmitButtonDisable} onClick={() => onNextButtonClick()}>{t("EXPERT_SPECIAL.NEXT")}</button>
                                {/* <button className='mt-4 ctaonebutton' onClick={() => onNextButtonClick()}>{t("EXPERT_SPECIAL.NEXT")}</button> */}
                            </div>
                        </div></div></div>
            }

        </>


    );
};

export default SpecialModalJSX;
