import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import signupleftgraphics from "../../../../assets/images/signupleftgraphics.svg";
import fileuploadpopup from "../../../../assets/images/fileuploadpopup.svg";
import facebook from "../../../../assets/images/facebook.svg";
import twitter from "../../../../assets/images/twitter.svg";
import instagram from "../../../../assets/images/insta.svg";
import linkedin from "../../../../assets/images/linkedin.svg";
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import datecalender from "../../../../assets/images/datecalender.svg"

const ExpertOnboardingsJsx = () => {

  return (
    <>
      <section className="loginscreeninstitute registrationinstitute">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn"><img src={leftarrow} alt={' '} /></div>
                <div className="detailsleft">
                  <h1>Expert Registration Form</h1>
                  <a href="#logochangeModal">
                    <div className="uploadinslogo">Your uploaded profile picture appears here  </div>
                  </a>
                  <div id="logochangeModal" className="modal-window">
                    <div><a  title="Close" className="modal-close">Close</a>
                      <div className="modal-header">
                        <h2 className="h2_style">Logo change</h2>
                      </div>
                      <div className="modal-body mb-4">
                        <div className="modal-body-inner">
                          <div className="button_outerlogo">
                            <div className="btn_uploadlogo">
                              <input type="file" id="upload_filelogo" name="" />
                              <label for="upload_filelogo"><img src={fileuploadpopup} alt={' '}  />
                              </label>
                            </div>
                          </div>
                          <h5 className="text-center h5_style">Upload your Logo Image</h5>
                          <p className="text-center">Quickly upload your Ad image by clicking on “+”</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <main className="main_full"><div className="uploadproduct mb-4">
                    <div className="button_outer">
                      <div className="btn_upload">
                        <input type="file" id="upload_file" name="" accept="image/png, image/gif, image/jpeg" />
                        <label for="upload_file">Upload Now</label>
                      </div>
                    </div>
                  </div>
                    <div className="error_msg"> JPEG/JPG/PNG files allowed.</div>
                    <div className="error_msg"> Maximum file is 2 MB</div>
                    {/* <div className="error_msg"> Dimensions : 800 x 800 px</div> */}
                  </main>
                </div>
              </div>
              <div className="instituteleft-illustration martop60 text-center">
                <img src={signupleftgraphics}  alt={' '} />
              </div>

            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext martop60 paddingbot60">
                <h2 className="h2_style text-center">Let us know you better! </h2>
                <h6 className="h6_style text-center">Please enter the following information</h6>
                <p className="text-center smalltext">(Fields marked with * are mandatory)</p>
                <h4 className="h4_style text-center mt-5">Personal Details</h4>
                <h5 className="h5_style text-center">We forge long-term associations. Let’s begin this journey from your name.
Please enter the latest information below.</h5>
                <hr></hr>
                <div className="disgrid grid-2">
                  <div className="input-container form-group">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">First Name * </div>
                  </div>
                  <div className="input-container form-group">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">Last Name *</div>
                  </div>
                  <div className="input-container form-group">
                    <input type="email" placeholder=" " className="input form-control" />
                    <div className="placeholder">Expert Login Email * <span className="plantooltip custooltip" title="" > <img src={planpricingtooltip} className="tooltipim"  alt={' '} />
                      <div className="tooltipcontent">This Email address will be your Login ID. Also, we will use it for sending all our communications. </div></span></div>
                  </div>
                  <div className="input-container form-group">
                    <input type="email" placeholder=" " className="input form-control" />
                    <div className="placeholder">Alternate Email  <span className="plantooltip custooltip righttool" title="" > <img src={planpricingtooltip} className="tooltipim"  alt={' '} />
                      <div className="tooltipcontent">We don’t prefer communication gaps. In case we are not able to communicate through your primary “Email Address”, we will use this alternate.</div></span></div>
                  </div>
                  <div className=" input-container form-group">
                    <input type="tel" placeholder=" " className="input form-control" />
                    <div className="placeholder">Primary Phone No. * <span className="plantooltip custooltip" title="" > <img src={planpricingtooltip} className="tooltipim"  alt={' '} />
                      <div className="tooltipcontent">Please enter the main phone number where you can be reached. However, our preferred mode of communication is sending emails. We just want to make sure we can reach you. </div></span></div>
                  </div>
                  <div className="input-container form-group">
                    <input type="tel" placeholder=" " className="input form-control" />
                    <div className="placeholder">Secondary Phone No. </div>
                  </div>
                  <div className="input-container form-group">
                    <input type="tel" placeholder=" " className="input form-control" />
                    <div className="placeholder">Date of Birth </div>
                    <span className="dateim"><img src={datecalender} alt={' '}  /></span>
                  </div>
                  <div className="input-container selectboxmain form-group ">
                    <select className="input form-control">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                    <div className="placeholder">Gender</div>
                  </div>
                </div>
                <h4 className="h4_style text-center mt-5">Social Media</h4>
                <h5 className="h5_style text-center">Can we enhance your digital presence? Please enter the valid links of your active social media accounts. All added links appear on public profile page.</h5>
                <hr></hr>

                <div className="disgrid grid-2">
                  <div className="input-container form-group social-control">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">Link</div>
                    <img src={facebook} className="formscicon" alt={' '}  />
                  </div>
                  <div className="input-container form-group social-control">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">Link</div>
                    <img src={instagram} className="formscicon" alt={' '}  />
                  </div>
                  <div className="input-container form-group social-control">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">Link</div>
                    <img src={twitter} className="formscicon"  alt={' '} />
                  </div>
                  <div className="input-container form-group social-control">
                    <input type="text" placeholder=" " className="input form-control" />
                    <div className="placeholder">Link</div>
                    <img src={linkedin} className="formscicon"  alt={' '}  />
                  </div>
                </div>
                <h4 className="h4_style text-center mt-5">Certifications & Awards</h4>
                <h5 className="h5_style text-center">Differentiate from other experts by showcasing your outstanding achievements
                All added certifications & awards appear on public profile page
You can add up to three certifications and awards, separated by commas.</h5>
                <hr></hr>
                <div className="input-container form-group mb-4">
                  <input type="text" placeholder=" " className="input form-control" />
                  <div className="placeholder">Certifications <span className="plantooltip custooltip" title="" > <img src={planpricingtooltip} className="tooltipim"  alt={' '} />
                    <div className="tooltipcontent">It’s time to impress your clients. Please enter certifications related to your area of expertise. It’s preferred if you first enter the name of your certification and then the year in which you received it. You can add multiple certifications, separated by commas. For e.g. XYZ 2011, ABC 2012, QRT 2015 </div></span></div>
                </div>
                <div className="input-container form-group mb-4">
                  <input type="text" placeholder=" " className="input form-control" />
                  <div className="placeholder">Awards <span className="plantooltip custooltip" title="" > <img src={planpricingtooltip} className="tooltipim" alt={' '}  />
                    <div className="tooltipcontent">Please enter awards related to your area of expertise. It’s preferred if you first enter the name of your award and then the year in which you won it. You can add multiple awards, separated by commas. For e.g. XYZ 2011, ABC 2012, QRT 2015 </div></span></div>
                </div>
                <button type="button" className="ctaonebutton">Next</button>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default ExpertOnboardingsJsx;
