import React, { Component } from "react";
import ChangePasswordPage from "./changePassword.jsx"
import axios from 'axios';
import {
  baseURL
} from "../../../constants/applicationConstants.js";
import swal from 'sweetalert'
import { encrypt, passwordRegex } from '../../../shared/helper'
import { Loader } from "../loader/loader.jsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class ChangePass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        currentpassword: "",
        newpassword: "",
        verifypassword: "",
      },
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
    };
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },

          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };

  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "currentpassword") {
      // return passwordRegex.test(value);
      return true;
    } else if (name === "newpassword") {
      return passwordRegex.test(value);
    } else if (name === "verifypassword") {
      return passwordRegex.test(value);
    }
  };

  isFromSubmissionBlock = () => {
    let { currentpassword, newpassword, verifypassword } = this.state.formData;
    if ((newpassword && verifypassword)) {
      if (
        !this.inputRegexValidation("currentpassword", currentpassword) ||
        !this.inputRegexValidation("newpassword", newpassword) ||
        !this.inputRegexValidation("verifypassword", verifypassword)

      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        if (newpassword === verifypassword) {
          this.setState({
            formSubmitButtonDisable: false,
            formError: {
              ...this.state.formError,
              verifypassword: "",
            }
          });
        } else {
          this.setState({
            formSubmitButtonDisable: true,
            formError: {
              ...this.state.formError,
              verifypassword: "notMatch",
            }
          });
        }
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {


    e.preventDefault();
    const { currentpassword, newpassword, verifypassword } = this.state.formData;
    const token = JSON.parse(sessionStorage.getItem('token'));
    let flowType = localStorage.getItem("flowType")
    const instLoginType = sessionStorage.getItem('instLoginType') ?? '';

    const id = JSON.parse(sessionStorage.getItem("masterID"));
    const expertId = JSON.parse(sessionStorage.getItem("expertId"));
    const loginData = JSON.parse(sessionStorage.getItem('loginData'));
    const customerId = JSON.parse(sessionStorage.getItem("customerId"));
    const instUserId = sessionStorage.getItem('instUserId') ?? '';

    let data = {
      "password": encrypt(currentpassword),
      "newPassword": encrypt(newpassword),
      "confirmPassword": encrypt(verifypassword),
      "instituteId": id,
      "category": instLoginType === 'institute_user' ? "institute_user" : "institute"
    }
    if (instLoginType === 'institute_user'
    ) {
      delete data.instituteId;
      data.instituteUserId = instUserId;
    }
    let dataExpert = {
      "password": encrypt(currentpassword),
      "newPassword": encrypt(newpassword),
      "confirmPassword": encrypt(verifypassword),
      "expertId": expertId,
      "category": "expert"
    }
    let dataCustomer = {
      "password": encrypt(currentpassword),
      "newPassword": encrypt(newpassword),
      "confirmPassword": encrypt(verifypassword),
      "customerId": customerId,
      "category": "customer"
    }
    this.setState({
      isLoading: true
    })

    axios.put(`${baseURL}/changePassword`, flowType === "expert" ? dataExpert : flowType === "customer" ? dataCustomer : data, {
      headers: {
        'x-access-token': token
      }
    }).then(resp => {
      if (resp.data.success) {
        this.setState({
          isLoading: false
        })
        toast.success("Password updated.");
        swal({
          title: "success",
          text: "Password changed successfully. Your other active login sessions remain open and have not been automatically logged out.",
          icon: "success",
          successMode: true,
        }).then(res => {
          if (res) {
            window.location.reload()
          }
        });

      } else {
        toast.error("something went wrong");

        this.setState({
          isLoading: false
        })
      }
    }).catch(err => {
      toast.error(err.response.data.error.message)
      this.setState({
        isLoading: false
      })
    })
  };



  render() {

    return (
      <>
        <ChangePasswordPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
        />
        {this.state.isLoading && <Loader />}

      </>
    );
  }
}

export default ChangePass;


