
import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { emailRegex, nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerAskTheExpertJsx from './customerAskTheExpert.jsx'

export default class AskTheExpert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            isLoading: false,
            categoryName: null,
            subCategoryData: null,
            categoryId: null,
            subCategoryId: null,
            subCategoryName: null,
            expertQuestions: [],
            questionAsked: null,
            formData: {
                name: '',
                email: '',
                postalCode: '',
                phoneNumber: ''
            },
            formError: {},
            formSubmitButtonDisable: true
        }
    }

    componentDidMount = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false }, () => {
                const data = JSON.parse(sessionStorage.getItem('askTheExpert'));
                if (data) {
                    const { categoryId, categoryName, subCategoryId, subCategoryName, questionAsked, formData } = data;
                    this.setState({ subCategoryName: subCategoryName, questionAsked: questionAsked, formData: formData, categoryId: categoryId, categoryName: categoryName })
                    this.setState({
                        subCategoryData: this.state.categoryData.filter(catDT => catDT.name === categoryName)[0]?.subInstitutionCategories
                    }, () => this.isFromSubmissionBlock())
                    this.setState({ subCategoryId: subCategoryId });
                    axios.get(`${baseURL}/askExpertQuestions?expertCategoryId=${categoryId}&expertSubCategoryId=${subCategoryId}`).then(res => {
                        this.setState({ expertQuestions: res.data.data });
                    })
                }
            })
        }).catch(err => {
            this.setState({ isLoading: false })
        })
    }

    onCategoryClick = (catId, catName) => {
        this.setState({ categoryId: catId, categoryName: catName });
        this.setState({
            subCategoryData: this.state.categoryData.filter(catDT => catDT.name === catName)[0]?.subInstitutionCategories
        }, () => this.isFromSubmissionBlock())

        this.setState({
            subCategoryId: null,
            subCategoryName: null,
            expertQuestions: [],
            questionAsked: null,
            formData: {
                name: '',
                email: '',
                postalCode: '',
                phoneNumber: ''
            }
        })
        setTimeout(function () {
            // document.getElementById("askts") && document.getElementById("askts").scrollIntoView();
            var element = document.getElementById('askts');
            var headerOffset = 200;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }, 500)

    }

    onclickFocus = (toDiv) => {
        setTimeout(function () {
            // document.getElementById("askts") && document.getElementById("askts").scrollIntoView();
            var element = document.getElementById(toDiv);
            var headerOffset = 200;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }, 200)

    }

    onSubcategoryClick = (subCatId, subCatName) => {
        this.setState({ subCategoryId: subCatId, subCategoryName: subCatName });
        axios.get(`${baseURL}/askExpertQuestions?expertCategoryId=${this.state.categoryId}&expertSubCategoryId=${subCatId}`).then(res => {
            this.setState({ expertQuestions: res.data.data });
        })
    }
    
    
  
    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        else if (name === "email")
            return emailRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);
        else if (name === "phone")
            return !this.getProperPhoneNumber(value).includes('_');

        return true;
    };

    getProperPhoneNumber = (value) => {

        if (!isNaN(value)) {
            return value
        }

        const number = value.split(' ');
        return number[0].replace('(', '').replace(')', '') + number[1] + number[2]
    }

    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }

    isFromSubmissionBlock = () => {
        let { name, email, postalCode } = this.state.formData;
        let { questionAsked } = this.state;

        if (name && email && postalCode && questionAsked) {
            if (
                this.inputRegexValidation('name', name) &&
                this.inputRegexValidation('email', email) &&
                this.inputRegexValidation('postalCode', postalCode)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    onTextAreaChange = (e) => {
        this.setState({ questionAsked: e.target.value }, () => this.isFromSubmissionBlock())
    }

    onQuestionClick = (question) => {
        this.setState({ questionAsked: question.question }, () => this.isFromSubmissionBlock());
    }

    onSubmitButtonClick = () => {
        const customerId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
        const payload = {
            categoryName: this.state.categoryName,
            subCategoryName: this.state.subCategoryName,
            questionAsked: this.state.questionAsked,
            formData: this.state.formData,
            categoryId: this.state.categoryId,
            subCategoryId: this.state.subCategoryId,
            customerID: customerId
        }

        sessionStorage.setItem('askTheExpert', JSON.stringify(payload));
        this.props.history.push('/confirmCustomerAskTheExpert');
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <CustomerAskTheExpertJsx categoryData={this.state.categoryData}
                    onCategoryClick={this.onCategoryClick}
                    categoryName={this.state.categoryName}
                    subCategoryData={this.state.subCategoryData}
                    onSubcategoryClick={this.onSubcategoryClick}
                    subCategoryName={this.state.subCategoryName}
                    formData={this.state.formData}
                    questionAsked={this.state.questionAsked}
                    onInputChange={this.onInputChange}
                    onTextAreaChange={this.onTextAreaChange}
                    formError={this.state.formError}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onQuestionClick={this.onQuestionClick}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    expertQuestions={this.state.expertQuestions}
                    onclickFocus={this.onclickFocus}
                />
                {this.state.isLoading && <Loader />}
            </div>
        )
    }
}
