import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { formatCanadianPhoneNumber } from '../../../shared/helper';
import { NEW_URL } from '../../../constants/applicationConstants';

export const ExpertSpecialization = (props) => {
    const { t } = useTranslation();
    const { profileData } = props;
    const [showData, setShowData] = useState({})
    const [activeIndex, setActiveIndex] = useState(null);

    const onAnsweredQuestionClick = (data, index) => {
        setShowData(data)
        setActiveIndex(index);


    }

    useEffect(() => {
        onAnsweredQuestionClick(profileData?.expertSpecializations[0])
        setActiveIndex(0);

    }, [profileData?.expertSpecializations])


    return (
        <section className="specialisationexpertppv">
            <div className="container">
                <div className="heading">
                    <h2>{t("EXPERTSPECIALIZATION.TEXT1")}</h2>
                    <h5 className="mt-2 text-center">{t("EXPERTSPECIALIZATION.TEXT2")} </h5>
                    <h5 className="mt-2 text-center"><strong>{t("EXPERTSPECIALIZATION.TEXT3")}</strong></h5>
                    <h5 className="text-center">{t("EXPERTSPECIALIZATION.TEXT4")}</h5>
                </div>
            </div>


            
                <div className="container">
                    <div className="myexpertnavnewdesign">
                        <ul>
                            {
                                profileData?.expertSpecializations?.map((expertSpec, index) => {
                                    return (
                                        <>
                                            <li className={`${activeIndex === index ? 'active' : ''} ${expertSpec?.parentInstitutionCategory}`}
                                                onClick={() => onAnsweredQuestionClick(expertSpec, index)}><span>{expertSpec?.parentInstitutionCategory}</span></li>

                                        </>
                                    )
                                })
                            }
                        </ul>
                        {
                                profileData?.expertSpecializations?.map((expertSpec, index) => {
                                    return (
                                        <>
                        <div className={`latestdeailsexpertppvspecialize ${activeIndex === index ? 'active' : ''} ${expertSpec?.parentInstitutionCategory}`}>
                            <div className="container">

                                <div className="bg-white-new-exp">
                                    <h3>{t("EXPERTSPECIALIZATION.TEXT5")}</h3>
                                    <div className="disgrid grid-2">
                                        <div className="detailsbank">

                                            <h4>{t("EXPERTSPECIALIZATION.TEXT6")}</h4>
                                            <h5>{showData?.institute?.name}</h5>

                                        </div>
                                        <div className="detailsbank">
                                            <h4>{t("EXPERTSPECIALIZATION.TEXT7")}</h4>
                                            <h5>{showData?.parentInstitutionCategory}</h5>
                                        </div>
                                        <div className="detailsbank">
                                            {showData?.institute?.institutionSegmentId === 3 &&
                                                <div className="lincenceWrapExprtIns">
                                                    <h4>{t("EXPERTSPECIALIZATION.TEXT8")}</h4>
                                                    {showData?.institute?.instituteLicenses.map((e, key) => (
                                                        <h5 key={key}>{e?.licenseNumber || 'N/A'}</h5>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="detailsbank bg-white-new-exp subcategoryposition">
                                        <h3>{t("EXPERTSPECIALIZATION.TEXT9")}</h3>
                                       
                                            <div className="categoryonesetwidthexpert">
                                                <div className="categoryonesetwidthinner ">
                                                    {showData?.expertSpecializationSubCategories?.map((item1, i) => (

                                                        <span>
                                                            <div className="iconone">
                                                                <img src={`${NEW_URL}/${item1?.subInstitutionCategory?.icon}`}   alt=''/>
                                                            </div>
                                                          
                                                            {item1?.subInstitutionCategory
                                                            ?.name}</span>
                                                    ))}
                                                </div>
                                            </div>
                                       
                                    </div>
                                   
                                    <div className="bg-white-new-exp">
                                        <h3>{t("EXPERTSPECIALIZATION.TEXT10")}</h3>
                                        <div className="disgrid grid-2">
                                            <div className="detailsbank">

                                                <h4>{t("EXPERTSPECIALIZATION.TEXT11")}</h4>
                                                <h5>{showData?.businessName}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT12")}</h4>
                                                <h5>{showData?.businessLicenseNumber}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT13")}</h4>
                                                <h5>{showData?.businessPhoneNumber && formatCanadianPhoneNumber(showData?.businessPhoneNumber)}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT14")}</h4>
                                                <h5>{showData?.businessEmail}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT15")}</h4>
                                                <h5><Link to={showData?.businessWebsite}  target="_blank"><a href={showData?.businessWebsite}>{showData?.businessWebsite}</a></Link></h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT16")}</h4>
                                                <h5>{showData?.experienceInYears}</h5>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="detailsbank bg-white-new-exp no-after">
                                        <h3>{t("EXPERTSPECIALIZATION.TEXT17")}</h3>
                                        <div className="disgrid grid-2">
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT18")}</h4>
                                                <h5>{showData?.city}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT19")} </h4>
                                                <h5>{showData?.state?.name}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT20")}</h4>
                                                <h5>{showData?.country?.name}</h5>
                                            </div>
                                            <div className="detailsbank">
                                                <h4>{t("EXPERTSPECIALIZATION.TEXT21")}</h4>
                                                <h5>{showData?.postalCode}</h5>
                                            </div>
                                            <div className="detailsbank">
                                        <h4>{t("EXPERTSPECIALIZATION.TEXT22")}</h4>
                                        <h5>{showData?.address}</h5>
                                        </div>
                                        </div>
                                       

                                    </div>

                                </div>

                            </div>
                        </div>
                        </>
                                    )
                                })
                            }
                    </div>




                </div>

        </section>

    )
}