import React from 'react'
import { useEffect, useState } from 'react';
import { Footer } from '../../../common/footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    
    onTouchHandler,
    formSubmitButtonDisable,
    onSubmitButtonClick
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
            <div className="dashboardrightpanel martop40">
            <h2 className="h2_style">Edit Question</h2>

          </div>
          <div className="dashboardrightpanel martop40">

            <div className="input-container form-group">
              <input
                type="text"
                className="form-control input"
                placeholder=" "
                name="name"
                onChange={onInputChange}
                onBlur={onTouchHandler}
                value={formData.name}
              />
              <div className="placeholder">Question</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="category"
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {categoryData?.map((item) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Category</div>
            </div>
            <div className="input-container form-group">
              <select
                className="input form-control form-select"
                placeholder=" "
                name="subCategory"
                value={formData.subCategory}
                onChange={onInputChange}
                onBlur={onTouchHandler}
              >
                <option value="">All</option>
                {subCategoryData?.map((item, i) => {
                  return (
                    <>
                      <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                    </>
                  )
                })}
              </select>
              <div className="placeholder">Sub Category</div>
            </div>

            <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
          </div>
        </div>
            </div>

          </div>
          
      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;