import React, { useEffect, useState } from 'react';
import minus from "../../../assets/images/minus.svg";
import plus from "../../../assets/images/plus.svg";
import { Link } from "react-router-dom";
import { NEW_URL } from '../../../constants/applicationConstants';
import { useTranslation } from "react-i18next";

export const ProductSection = (props) => {
    const { t } = useTranslation();
    const { profileData, legalName } = props;

    const [productData, setProductData] = useState(null);
    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        if (profileData) {
            setProductData(profileData?.instituteProducts?.categories);
            setActiveTab(profileData?.instituteProducts?.categories[0]?.parentInstitutionCategory);
        }
    }, [profileData])

    const onSubcategoryClick = (value, action) => {
        let tempArray = [...productData]
        tempArray.map((tempDT) => {
            tempDT.subInstitutionCategories?.map((subDT, index) => {
                if (subDT.name === value)
                    tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], isOpen: !action }
            })

        })
        setProductData(tempArray)
    }

    const onTabClick = (tab) => {
        setActiveTab(tab)
    }

    return <section className="product-offer service-offer" id="ppvProductSection">
        <div className="container">
            <div className="heading">
                <h2>{t("PPVINSTITUTE.PRODUCTTITLE")}</h2>
                <h3>{t("PPVINSTITUTE.PRODUCTSUBTITLE")}</h3>
                <h4>{t("PPVINSTITUTE.PRODUCTSUBSUBTITLE")}</h4>
            </div>
            <div className="myexpertnav martop60">
                <ul>
                    {
                        productData?.map(product => {
                            return <li className={`${activeTab === product.parentInstitutionCategory ? 'active' : ''}`} onClick={() => onTabClick(product.parentInstitutionCategory)}>{product.parentInstitutionCategory}</li>
                        })
                    }
                </ul>
                {
                    productData?.map(product => {
                        return <>
                            <div className="product-offer-inner">
                                <ul className="serviceoffer-inner-accordian mt-4">
                                    {
                                        activeTab === product.parentInstitutionCategory && product?.subInstitutionCategories?.map(subCat => {
                                            return <><li><h3 className="h3_style text-center" onClick={() => onSubcategoryClick(subCat.name, subCat.isOpen)}>{subCat.name}</h3>
                                                {subCat.isOpen ? <img src={minus} alt={' '} className="minus" onClick={() => onSubcategoryClick(subCat.name, true)} /> :
                                                    <img src={plus} className="plus" alt={' '} onClick={() => onSubcategoryClick(subCat.name, false)} />
                                                }
                                                <div className="">
                                                    {
                                                        subCat.isOpen &&
                                                        <div className="row mt-4">
                                                            {
                                                                subCat.products?.map(product => {
                                                                    return <div className="col-lg-4 col-sm-4">
                                                                        <div className="text-center ppvim"><img src={`${NEW_URL}/${product.imageUrl}`} alt={'image'} /></div>
                                                                        <div className="text-center ppvimp">{product.productName}</div>
                                                                        <div className="text-center ppvbtn">
                                                                            {product.externalLink && <button className="productppnbtn" onClick={() => window.open(product.externalLink, '_blank')}>Apply Now</button>}
                                                                            <Link
                                                                                to={{
                                                                                    pathname: "/detailedProduct",
                                                                                    state: { data: product, category: activeTab, subCategory: subCat.name, frompage: 'ppvProductSection' ,pagePath:window.location.pathname }
                                                                                }}
                                                                                className="productppnbtn"
                                                                                onClick={() => window.scrollTo(0, 0)}>
                                                                                View Details
                                                                            </Link>
                                                                            {/* <button className="productppnbtn">View Details</button> */}
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                            <div className="clearfix">
                                                                <Link
                                                                    to={{
                                                                        pathname: "/listOfProduct",
                                                                        state: { data: product.parentInstitutionCategoryId, legalName: legalName, category: product.parentInstitutionCategory, subCategory: subCat.name, frompage: 'ppvProductSection' ,pagePath:window.location.pathname }
                                                                    }}
                                                                    className="ctaonebutton widthhalf  mt-5"
                                                                    onClick={() => window.scrollTo(0, 0)}>
                                                                    View All
                                                             </Link>
                                                            </div>
                                                        </div>

                                                    }

                                                </div>
                                            </li>

                                            </>
                                        })
                                    }
                                </ul>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    </section>

}