import React, { useEffect, useState } from 'react'
import dashboard from "../../../../assets/images/dashboard.svg";
import myexpert from "../../../../assets/images/myexpert.svg";
import myworkspace from "../../../../assets/images/myworkspace.svg";
import myblog from "../../../../assets/images/myblog.svg";
import myaccount from "../../../../assets/images/myaccount.svg";
import mypayment from "../../../../assets/images/mypayment.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import User_Manual from "../../../../assets/images/User_Manual.svg"
import { Link } from "react-router-dom";
import { NEW_URL, baseURL } from '../../../../constants/applicationConstants'; 
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import axios from 'axios'
import '../sidebar/sidebar.model.css';

const SideBarsJsx = (props) => {
  const history = useHistory();
  const [state, setState] = useState()

  const {
    response
  } = props;

  useEffect(() => {
    callDetails()
  }, []);

  const callDetails = () => {
    let token = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
    let instituteId = sessionStorage.getItem("masterID") && JSON.parse(sessionStorage.getItem("masterID"))
    const instUserId = sessionStorage.getItem('instUserId') ?? '';

    let url = `${baseURL}/institute/${instituteId}/details`
    let secondary_url = `${baseURL}/detailsInstituteUser/${instUserId}`

    const adminLoginData = sessionStorage.getItem('adminLoginData');
 

    axios.get(instUserId? secondary_url : url, {
      headers: { 'x-access-token': token }
    }).then(res => {

      setState(res.data.data)
      if (!adminLoginData && !res.data?.data?.isPermanentPasswordSet) {
        history.push("/saveNewPassword");
        // window.location.reload()
      }

    }).catch(err => {
    })
  }
  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  
  return (
    <>
    
    <div className="dashboardleftpanel">
                <div className="dashboardlinkpop"> 
     <div className="dashboard-container maincustomerdashboard main">
  <div className="dashboardleftpanel">
    
    <div className="text-column">
      <div className="responsename">
        <div className="dashboardlogo">
          <img
            src={!response?.imageUrl
              ? `https://ui-avatars.com/api/?name=${response?.firstName}+${response?.lastName}&rounded=true`
              : `${NEW_URL}/${response?.imageUrl}`}
            alt={''} 
          />
        </div>
        {`${response?.name}`}
      </div>

      <div className="dashboardlink dashboardtext mydash">
        <ul className="dashboardinner">
          <li >
            <Link to={'/dashBoard'}>
            <span className="menusideimage"><img className="leftborder" src={dashboard} alt={''} /></span>  <p className={` ${window.location.href?.toLowerCase().includes('dashboard') ? 'activetext' : ""}`}>Dashboard</p> 
            </Link>
          </li>
          <li >
            <Link to={'/myExpert'}>
                <span className="menusideimage"><img className="leftborder" src={myexpert} alt={''} /></span><p className={`${window.location.href?.toLowerCase().includes('myexpert') ? 'activetext' : ''}`}>My Experts</p>
            </Link>
          </li>
          <li >
            <Link to={'/myWorkspace'}>
               <span className="menusideimage"><img className="leftborder" src={myworkspace} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myworkspace') ? 'activetext' : ''}`}>My Workspace</p>
            </Link>
          </li>
          <li >
            <Link to={'/myBlog'}>
               <span className="menusideimage"><img className="leftborder" src={myblog} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myblog') ? 'activetext' : ''}`}>My Blogs</p>
            </Link>
          </li>
          <li >
            <Link to={'/myAccounts'}>
               <span className="menusideimage"><img className="leftborder" src={myaccount} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('myaccounts') ? 'activetext' : ''}`}>My Account</p>
            </Link>
          </li>
          <li >
            <Link to={'/myPayment'}>
               <span className="menusideimage"><img className="leftborder" src={myaccount} alt={''} /></span> <p className={`${window.location.href?.toLowerCase().includes('mypayment') ? 'activetext' : ''}`}>My Payment</p>
            </Link>
          </li>
          <li onClick={logout}>
            <a>
              <span className="menusideimage"><img className="leftborder" src={mypayment} alt={''} /></span><p>Logout</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

</div>
</div>
    </>

  );


   
};

export default SideBarsJsx;