import React from 'react'
import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { onOfferClick } from '../../../../shared/helper';
import { NEW_URL } from '../../../../constants/applicationConstants';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {

    offerData
  } = props;



  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="alldashboard cust-offers">

        <div className="dashboardrightpanel">

          <div className="suggestedoffercustomer">
            <section className="limitedoffer ">
              {
                offerData?.RealEstate &&
                <div className="limitedofferinner">
                  <div className="heading">

                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE0")}</h5>
                  </div>
                  <div className="offerslider ">
                    <AwesomeSlider>
                      {
                        offerData.RealEstate?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                              <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`} >{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner" >
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customerinterest.PRODUCTNAMEANDDES")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customerinterest.RELATEDPRODUCTS")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              }
              {
                offerData?.Investment &&
                <section className="limitedoffer">
                  <div className="limitedofferinner">
                    <div className="heading">

                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>
                    </div>
                    <div className="offerslider">

                      <AwesomeSlider>
                        {
                          offerData?.Investment?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                                <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner">
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerinterest.PRODUCTNAMEANDDES")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerinterest.RELATEDPRODUCTS")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">
                                      <button onClick={() => onOfferClick(offer)} className="knowmore_button  ctaonebutton widthhalf" >{t("customerinterest.KNOWMOREBUTTON")}</button>

                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                offerData?.Mortgage &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">

                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE2")} </h5>
                    </div>
                    <div className="offerslider">

                      <AwesomeSlider>
                        {
                          offerData?.Mortgage?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                                <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner">
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerinterest.PRODUCTNAMEANDDES")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerinterest.RELATEDPRODUCTS")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">
                                      <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>

                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                offerData?.Insurance &&
                <section className="limitedoffer">
                  <div className="limitedofferinner">
                    <div className="heading">

                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE3")}</h5>
                    </div>
                    <div className="offerslider">

                      <AwesomeSlider>
                        {
                          offerData?.Insurance?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                                <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerinterest.PRODUCTNAMEANDDES")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerinterest.RELATEDPRODUCTS")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">
                                      <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf" >{t("customerinterest.KNOWMOREBUTTON")}</button>

                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                offerData?.Banking &&
                <section className="limitedoffer">
                  <div className="limitedofferinner">
                    <div className="heading">

                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE4")} </h5>
                    </div>
                    <div className="offerslider">

                      <AwesomeSlider>
                        {
                          offerData?.Banking?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                                <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="ppvawesomesliderproducttype-inner" >
                                  <div className="ppvawesomesliderproducttyp-name">
                                    <h4>{t("customerinterest.PRODUCTNAMEANDDES")}</h4>
                                    {/* <p>{offer?.offerName}</p> */}
                                    <p>{offer?.description}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-product">
                                    <div className="ppvawesomesliderproducttype-product-text">
                                      <h4>{t("customerinterest.RELATEDPRODUCTS")}</h4>
                                      <p >{offer?.subInstitutionCategory?.name}</p>
                                    </div>
                                    <div className="ppvawesomesliderproducttype-button">
                                      <button onClick={() => onOfferClick(offer)} className="ctaonebutton widthhalf" style={{ backgroundColor: offer.parentInstitutionCategory.toLowerCase() === 'mortgage' ? '#2F596F' : offer.parentInstitutionCategory.toLowerCase() === 'investment' ? '#86357A' : offer.parentInstitutionCategory.toLowerCase() === 'banking' ? '#2281B8' : offer.parentInstitutionCategory.toLowerCase() === 'real estate' ? '#00831D' : "#FF4C00" }}>{t("customerinterest.KNOWMOREBUTTON")}</button>

                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
            </section>
            
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;