import React from 'react'
import LogoSection from "./logo.jsx"
import InstituteDetails from "./instituteDetails.jsx"
import SocialMedia from "./socialMedia.jsx"
import AwardsJsx from './awardsCertificates.jsx'
import ViewSpecialInfo from './viewSpecialInfo.jsx'
import AddSpecialInfo from '../specialInformation/specialInformation.js'

const AccountSettingJsx = (props) => {
  const { onInputChangeHandler, onSubmitButtonClick, provinceList, onMetaTagChange, onMetaTagChange1,
    segmentList, categoryList, handleChange, countryList, sizeList, onDateChange,
    editInstitite, editLogo, changeLogoState,
    changeHideState, onInstituteButtonClick, onContactButtonClick,
    editContact, changeContactState,onDeleteButtonClick,
    editSocial, changeSocialState, formError,
    onTouchHandler, formSubmitButtonDisable, imgURL,
    onFileChange, imageName, profileImagePreviewUrl,
    onNextButtonClick, onOthersButtonClick,
    changeSpecialState, editSpecial, getSpecialization,callDetails,
    onCatgeoryClick,subCategorySection

  } = props;
  const wholeData = props.formData
  const responseData = props.responseData



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="">
                <section classNames="addexpertformsec">

                  <LogoSection
                    wholeData={wholeData}
                    responseData={responseData}
                    onSubmitButtonClick={onSubmitButtonClick}
                    handleChange={handleChange}
                    editLogo={editLogo}
                    changeLogoState={changeLogoState}
                    imgURL={imgURL}
                    onNextButtonClick={onNextButtonClick}
                    onFileChange={onFileChange}
                    imageName={imageName}
                    profileImagePreviewUrl={profileImagePreviewUrl}
                  />
                  <InstituteDetails
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    handleChange={handleChange}
                    sizeList={sizeList}
                    onDateChange={onDateChange}
                    segmentList={segmentList}
                    categoryList={categoryList}
                    editInstitite={editInstitite}
                    changeHideState={changeHideState}
                    onInstituteButtonClick={onInstituteButtonClick}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                    formSubmitButtonDisable={formSubmitButtonDisable}

                  />
                  <SocialMedia
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    onSubmitButtonClick={onSubmitButtonClick}
                    handleChange={handleChange}
                    editSocial={editSocial}
                    changeSocialState={changeSocialState}
                    formError={formError}
                  />
                  <AwardsJsx
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    onContactButtonClick={onContactButtonClick}
                    handleChange={handleChange}
                    editContact={editContact}
                    changeContactState={changeContactState}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                    onMetaTagChange={onMetaTagChange}
                    onMetaTagChange1={onMetaTagChange1}
                  />
                  <ViewSpecialInfo
                    wholeData={wholeData}
                    onCatgeoryClick={onCatgeoryClick}
                    onDeleteButtonClick={onDeleteButtonClick}
                    specialInfoDetails={responseData?.expertSpecializations}
                    getSpecialization={getSpecialization}
                    changeSpecialState={changeSpecialState}
                    editSpecial={editSpecial}
                    formError={formError}
                    provinceList={provinceList}
                    countryList={countryList}
                    handleChange={handleChange}
                    onInputChangeHandler={onInputChangeHandler}
                    onOthersButtonClick={onOthersButtonClick}
                    subCategorySection={subCategorySection}
                  //  onDeleteButtonClick={onDeleteButtonClick}
                  //  editParticularSpecialization={editParticularSpecialization}

                  />
                  {/* <PrimaryContact
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    onContactButtonClick={onContactButtonClick}
                    handleChange={handleChange}
                    editContact={editContact}
                    changeContactState={changeContactState}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                  />
                  <RegisteredAddress
                    wholeData={wholeData}
                    provinceList={provinceList}
                    countryList={countryList}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    onAddressButtonClick={onAddressButtonClick}
                    handleChange={handleChange}
                    changeAddressState={changeAddressState}
                    editAddress={editAddress}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                  />
                  <OtherDetailsJsx
                    wholeData={wholeData}
                    onInputChangeHandler={onInputChangeHandler}
                    responseData={responseData}
                    changeOtherState={changeOtherState}
                    onOthersButtonClick={onOthersButtonClick}
                    handleChange={handleChange}
                    editOther={editOther}
                    changeContactState={changeContactState}
                    formError={formError}
                    onTouchHandler={onTouchHandler}
                  /> */}
                  <AddSpecialInfo
                  callDetails={callDetails}
                  />

                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>


  );
};

export default AccountSettingJsx;
