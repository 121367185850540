import React, { Component } from "react";
import DetailedProductsJsx from "./detailedProduct.jsx"

export class DetailedProductsJsxclass extends Component {

  constructor(props){
    super(props);
    this.state={
      data:null,
      category:null,
      subCategory:null
    }
  }

  componentDidMount = () =>{
    const {data,category,subCategory} = this.props?.details;
    data && this.setState({data:data,category:category,subCategory:subCategory});
  }

  closeFunction = () => {
    this.props.closeFunction()
  }

    render() {
      return (
        <div>
          <DetailedProductsJsx data={this.state.data} category={this.state.category} subCategory ={this.state.subCategory} closFunction={this.closeFunction}/>
        </div>
      );
    }
  }

  export default DetailedProductsJsxclass;