import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { baseURL,BLOG_URL,BLOG_URL_TOKEN } from '../../../constants/applicationConstants.js';
import { Loader } from '../../common/loader/loader.jsx';
import BankingJsx from './banking.jsx'

export default class Banking extends Component{
    constructor(props){
        super(props);
        this.state={
            offerData:null,
            fullOfferData:null,
            productData:null,
            fullProductData:null,
            adData:null,
            allAdData:null,
            instituteData:null,
            expertData:null,
             categoryData:null,
            subCategoryData: null,
            blogData:null,
            isProcessing:false,
            page:1,
            instituteID:4,
            userData: {
              productType: '0',
              productName: '',
              typeOfProduct:null,
              adType:'6'
            },
            formError: {},
            formSubmitButtonDisable: false
        }
    }

    componentDidMount = () =>{
        this.getOfferData();
        this.getProductData();
        this.getAdData();
        this.getExpertData();
        this.getBlogData();
        this.getInstituteData();
                    this.getCategories();

    }
    getCategories = () => {
        this.setState({ isProcessing: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
          headers: { 'x-access-token': token }
        }).then(res => {
          
          this.setState({ categoryData: res.data.data, isLoading: false })
          const subData = this.state.categoryData.filter(itm => itm.name === "Banking")
          this.setState({ subCategoryData: subData[0].subInstitutionCategories })

          
        }).catch(err => {
          this.setState({ isProcessing: false })
        })
      }

    getInstituteData = () => {
      this.setState({ isProcessing: true })
      axios.get(`${baseURL}/featured-institutes?institutionCategoryId=${this.state.instituteID}`).then(res => {
        this.setState({ instituteData: res.data.data, isProcessing: false })
      })
    }

    getOfferData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredOffers?institutionCategoryId=${this.state.instituteID}&institutionSubCategoryId=${this.state.userData?.productType}&offerName=${this.state.userData?.productName}`).then(res=>{
          this.setState({fullOfferData:res.data.data, offerData:res.data.data,isProcessing: false})
        })
      }

      getProductData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredProducts?institutionCategoryId=${this.state.instituteID}`).then(res=>{
          this.setState({fullProductData:res.data.data, productData:res.data.data,isProcessing: false})
        })
      }

      getAdData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredAds?institutionCategoryId=${this.state.instituteID}&institutionSubCategoryId=${this.state.userData?.adType}`).then(res=>{
          this.setState({allAdData:res.data.data, adData:res.data.data,isProcessing: false})
        })
      }

      getExpertData = () =>{
        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/featuredExperts?institutionCategoryId=${this.state.instituteID}`).then(res=>{
          this.setState({expertData:res.data.data,isProcessing: false})
        })
      }

      getBlogData = () => {
          var data = new FormData();
          data.append('limit', '9');
          data.append('device_token', '20.204.66.244');
          data.append('paged', this.state.page)
          data.append('cat_slug', 'banking')
          this.setState({ isProcessing: true })
  
          axios.post(`${BLOG_URL}`, data, 
              {
                  headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
              }).then(res => {
                  this.setState({ blogData: res.data });
              }).catch(err=>{
                this.setState({isProcessing: false})
              })
      }

      onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
    
        if (value) {
          if (!this.inputRegexValidation(name, value)) {
            this.setState(
              {
                userData: {
                  ...this.state.userData,
                  [name]: value,
                },
                formError: {
                  ...this.state.formError,
                  [name]: "regx",
                },
              },
              () => this.isFromSubmissionBlock()
            );
          } else {
            this.setState(
              {
                userData: {
                  ...this.state.userData,
                  [name]: value,
                },
                formError: {
                  ...this.state.formError,
                  [name]: "",
                },
              },
              () => this.isFromSubmissionBlock()
            );
          }
        } else {
          this.setState(
            {
              userData: {
                ...this.state.userData,
                [name]: value,
              },
              formError: {
                ...this.state.formError,
                [name]: "",
              },
            },
            () => this.isFromSubmissionBlock()
          );
        }
      };
    
      isFromSubmissionBlock = () => {
        let { name, email, phone, phoneNumber, message } = this.state.userData;
    
        if (name && email && phone && phoneNumber && message) {
          if (
            this.inputRegexValidation('name', name) &&
            this.inputRegexValidation('email', email) &&
            this.inputRegexValidation('phone', phone) &&
            phoneNumber && message
          ) {
            this.setState({
              formSubmitButtonDisable: false
            });
          }
          else {
            this.setState({
              formSubmitButtonDisable: true
            });
          }
        } else {
          this.setState({
            formSubmitButtonDisable: true
          });
        }
      };
    
      onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
          this.setState({
            formError: {
              ...this.state.formError,
              [name]: "req",
            },
          });
        } else {
          if (!this.inputRegexValidation(name, value)) {
            this.setState({
              formError: {
                ...this.state.formError,
                [name]: "regx",
              },
            });
          }
        }
      };
    
      inputRegexValidation = () => {
        return true;
      };
    
      onOfferSearchButtonClick = () =>{
        // this.getOfferData();
        const catID = this.state.instituteID
        const subCatID = this.state.userData?.productType
        const offerName = this.state.userData?.productName?.toLowerCase();
        if(subCatID == '0'){
          const data = this.state.fullOfferData.filter(offer=> offer.offerName.toLowerCase().includes(offerName));
          this.setState({offerData:data});
        }else{
          const data = this.state.fullOfferData.filter(offer=> offer.institutionCategoryId == catID && offer.institutionSubCategoryId == subCatID && offer.offerName.toLowerCase().includes(offerName));
          this.setState({offerData:data});
        }
      }
    
      onAdSearchButtonClick = () =>{
        const subCatID = this.state.userData?.adType;
        if(subCatID == 'all'){
          this.setState({adData:this.state.allAdData})
        }else{
        const data = this.state.allAdData.filter(ad=> ad.institutionSubCategoryId == subCatID )
        this.setState({adData:data});
        }
      }

      onTypeOfProductSearchButtonClick = () =>{
        const productName = this.state.userData?.typeOfProduct?.toLowerCase();
        const data = this.state.fullProductData.filter(offer=> offer.productName.toLowerCase().includes(productName));
        this.setState({productData:data});
      }

      onExpertClick = (data) =>{
        this.props.history.push(`/expertPpv/${data}`)
      }
    
      onInstituteClick = (data) =>{
         this.props.history.push(`/institute/${data?.formattedLegalName}`)
        //this.props.history.push(`/institute/icici-canada`)
      }

    render(){
        return(
            <div>
                <BankingJsx 
                userData={this.state.userData}
                offerData={this.state.offerData}
                                           subCategoryData={this.state.subCategoryData}

                productData={this.state.productData}
                adData={this.state.adData}
                expertData={this.state.expertData}
                instituteData={this.state.instituteData}
                blogData={this.state.blogData}
                onTouchHandler={this.onTouchHandler}
                onInputChangeHandler={this.onInputChangeHandler}
                formError={this.state.formError}
                onOfferSearchButtonClick={this.onOfferSearchButtonClick}
                onAdSearchButtonClick={this.onAdSearchButtonClick}
                onTypeOfProductSearchButtonClick={this.onTypeOfProductSearchButtonClick}
                onExpertClick={this.onExpertClick}
                    onInstituteClick={this.onInstituteClick}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}