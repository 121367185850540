import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import Sidebar from "../sidebar/sidebar.js"; 
import AccountSetting from "../accountSetting/accountSetting.js"
import ManageUser from "../../dashboardCommon/manageUser/manageUser.js"
import ChangePassword from "../../../common/changePassword/changePassword.js"
import MyPlansJsxclass from '../../dashboardCommon/myPlane/myPlane';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import '../myAccount/myAccount.model.css';
import { useTranslation } from 'react-i18next';
import logout from "../../../../assets/images/logout.svg";
import help from "../../../../assets/Instituteppvimages/help.svg";
import accountSetting from "../../../../assets/Instituteppvimages/account setting.svg";
import changePassword from "../../../../assets/Instituteppvimages/change password.svg";
import manageUser from "../../../../assets/Instituteppvimages/manage user.svg";
import myPlans from "../../../../assets/Instituteppvimages/my plans.svg";


const MyAccountsJsx = (props) => {


  const {
    activeTab,
    onTabClick
  } = props;


  const data = JSON.parse(sessionStorage.getItem("auth"))
  const { t } = useTranslation();

  // {t("PRINT.TITLE")}
  return (
    <>
       <section className="entiredashboard maincustomerdash main">
        <div className="container">
          <div className="row dashboard-decoration">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9 dashboard-data">
              <div className="row" >

                <div className="col-9">

                  <p className="title">{t("instituteAccount.HEADING")}</p>
                  <p className="subTitle">{t("instituteAccount.SUBHEADING")}</p>
                  
                </div>
                <div class="col-1"  >
                  <div class="helpbutton">
                    <img class="helpImg" src={help} alt={''} />
                    <p className="logout ">{t("instituteAccount.HELP")} </p>
                  </div>
                </div>
                <div class="col-2"  >
                  <div class="logoutbutton loginrightmargin">  <img class="logoutImg" src={logout} alt={''} />
                    <p className="logout ">{t("instituteDashboard.LOGOUT")} </p>
                  </div>
                </div>
              </div>
              <div className=" ">
                <section classNames=" ">
                 

                  <div className="myaccountnav">
                    <ul>
                     
                       <li class={`${activeTab === 'acntStng' ? 'active' : ''}`} onClick={() => onTabClick('acntStng')}><img class={`${activeTab === 'acntStng' ? 'active' : ''}`} src={accountSetting} alt={''} />Account Settings</li>
                      {data.isPrimaryUser && <li class={`${activeTab === 'mngUser' ? 'active' : ''}`} onClick={() => onTabClick('mngUser')}><img class={`${activeTab === 'mngUser' ? 'active' : ''}`} src={manageUser} alt={''} />Manage Users</li>}
                  <li class={`${activeTab === 'myPlans' ? 'active' : ''}`} onClick={() => onTabClick('myPlans')}><img class={`${activeTab === 'myPlans' ? 'active' : ''}`} src={myPlans} alt={''} />My Plan</li>
                  <li class={`${activeTab === 'newPass' ? 'active' : ''}`} onClick={() => onTabClick('newPass')}><img class={`${activeTab === 'newPass' ? 'active' : ''}`} src={changePassword} alt={''} />Change Password</li>

                    </ul>
                  </div>
                  {
                    activeTab === 'acntStng' ? <AccountSetting /> : activeTab === 'mngUser' ? <ManageUser /> : activeTab === 'newPass' ? <div className="accountchangepass"><ChangePassword /></div> : <MyPlansJsxclass />
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyAccountsJsx;
