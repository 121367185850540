import React, { useState } from 'react'
import InputMask from 'react-input-mask';
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';
import certificationicon from "../../../assets/expertppvimage/certicicationicon.svg";
import insuranceicon11 from "../../../assets/homeimages/insuranceiconooff.png";
import insuranceicon1 from "../../../assets/homeimages/insuranceicononn.png";
import insuranceicon22 from "../../../assets/homeimages/insuranceiconofff2.png";
import insuranceicon2 from "../../../assets/homeimages/insuranceicon2.png";
import awardicon from "../../../assets/expertppvimage/awards_icon.svg";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./expertppv.css";
import "./expertppvresponsive.css";
import { NEW_URL } from '../../../constants/applicationConstants';

// const GetInTouchSection = lazy(() => import('../institute/getInTouchSection'));
// const ExpertBlogSection = lazy(() => import('./expertBlogSection'));
// const ExpertSpecialization = lazy(() => import('./expertSpecialization'));
// const AdsSection = lazy(() => import('../institute/adsSection'));
// const ExpertDetails = lazy(() => import('./expertDetail'));



// {my code updated}

import { ExpertDetails } from './expertDetail';
import { AdsSection } from './adsSection';
import { ExpertSpecialization } from './expertSpecialization';
import { ExpertBlogSection } from './expertBlogSection';
import { GetInTouchSection } from './getInTouchSection';
import { useTranslation } from 'react-i18next';


const ExpertPpvJsx = (props) => {
  const { t } = useTranslation();
  const { profileData,
    slugUrl,
    blogData,
    userData,
    formError,
    quoteData,
    onButtonClick,
    onInputChangeHandler,
    onTouchHandler,
    onSendMessageClick,
    formSubmitButtonDisable
  } = props;

  
  const [activeButton, setActiveButton] = useState('insurance');
    

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };
  const [name, setName] = useState('');
  const handleChange = (event) => {
    const { value } = event.target;
    const isValid = /^[a-zA-Z\s]*$/.test(value);
    if (isValid) {
      setName(value);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  

  
  return (
    <>
      <section className="entireexpertppv">
        <section className="expertpublicviewprofiledetails">
          <ExpertDetails profileData={profileData} />
        </section>

        {profileData?.advertisements?.length > 0 && <AdsSection profileData={profileData} legalName={slugUrl} />}

        {<ExpertSpecialization profileData={profileData} />}
        {blogData?.body?.length > 0 && <ExpertBlogSection blogData={blogData} />}
        <section className="aboutexpertppv mt-5">
          <div className="container">

            <div className="heading"><h2>{t("EXPERTPPV.TEXT1")}</h2></div>
            <div className="row">
              <div className="col-lg-7">
                <div className="product-offer-inner">
                  <h3>{`${profileData?.firstName} ${profileData?.lastName}`}</h3>
                  <p>{profileData?.description}</p>
                </div>

              </div>
            </div>
            <div className="dp"><div className="triangle">

            </div> <img src={!profileData?.imageUrl ? `https://ui-avatars.com/api/?name=${profileData?.firstName}+${profileData?.lastName}&rounded=true` : `${NEW_URL}/${profileData.imageUrl}`} alt={' '} /></div>

          </div>
        </section>
        

        <section className="quotes expertppv-quotes"  >
          <div className="container ">
            <div className="quotes-inner">
              <div className="quotes-main">
                <h2>{t("EXPERTPPV.TEXT2")}</h2>
              </div>
              <div className="row" >
                <div className="col-lg-6 col-12">
                  <div className="quotes-main-main" style={{ position: "relative" }}>
                    <div className="quotes-text mortgagequot">
                      <h2> {activeButton === 'mortgage'
                        ? 'Find Your Perfect Quote!'
                        : 'Your Perfect Quote is Here!'}</h2>

                      <p> {activeButton === 'mortgage'
                        ? 'Explore the best mortgage or insurance quotes designed specifically for you.'
                        : 'Get the best mortgage or insurance quotes specifically designed for you.'}</p>
                      <p className="weoffer"> {activeButton === 'mortgage'
                        ? 'We offer various options to provide you with quotes that fit your specific needs.'
                        : ''}</p>
                      <div className="mainexplorenowbutton" >
                        <a className="explorebtn mt-4 mb-4">{activeButton === 'mortgage' ? 'Explore Now!'
                          : 'Start the process now!'}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="quotes-main-right">
                    <div className="quotes-bg-color">
                      <div className="quotes-text-right ">
                        <div className="quotes-on-btn">
                          <div className="text-center ">
                            <a
                              className={`quotesinsurancebtn ${activeButton === 'insurance' ? 'active' : ''}`}
                              onClick={() => handleButtonClick('insurance')}
                              style={activeButton === 'insurance' ? { backgroundColor: '#E34400' } : {}}
                            >
                              <img src={activeButton === 'insurance' ? insuranceicon1 : insuranceicon11} style={{ marginRight: '10px' }} alt="Insurance Icon" /> {t("EXPERTPPV.TEXT3")}
                                                        </a>
                          </div>
                        </div>
                        <div className="quotes-on-btn">
                          <div className="text-center">
                            <a
                              className={`quotesmortgagebtn ${activeButton === 'mortgage' ? 'active' : ''}`}
                              onClick={() => handleButtonClick('mortgage')}
                              style={activeButton === 'mortgage' ? { backgroundColor: '#2F596F' } : {}}
                            >
                              <img
                                src={activeButton === 'mortgage' ? insuranceicon2 : insuranceicon22}
                                style={{ marginRight: '10px' }}
                                alt="Mortgage Icon"
                              /> {t("EXPERTPPV.TEXT4")} </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {activeButton === 'insurance' && (
                      <div className="insuranceonclickform">

                        <div className="row quotes-form">
                          <div className="col-12 mb-4 inputextsize">
                            <input type="text" className="form-control" placeholder=" " maxlength="30" name='name' placeholder="Your full Name" onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            <span className="text-danger">
                              {formError['name'] &&
                                (formError['name'] === "req"
                                  ? ''
                                  : 'Enter a valid name')}
                            </span>
                          </div>
                          <div className="col-12 mb-4 inputextsize">
                            <input type="text" className="form-control" placeholder="Your Email" maxlength="30" name='email' onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            <span className="text-danger">
                              {formError['email'] &&
                                (formError['email'] === "req"
                                  ? ''
                                  : 'Enter a valid Email')}
                            </span>
                          </div>

                          <div className="col-6 mb-4 inputextsize">
                            <InputMask mask={'(999) 999 9999'} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                              {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Your phone number" disableUnderline />}
                            </InputMask>

                            <span className="text-danger">
                              {formError['phone'] &&
                                (formError['phone'] === "req"
                                  ? ''
                                  : 'Enter a valid Phone Number')}
                            </span>
                          </div>
                          <div className="col-6 mb-4 inputextsize">
                            <InputMask mask={'a9a 9a9'}  name="postalCode" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                              {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Postal Code" disableUnderline />}
                            </InputMask>

                            <span className="text-danger">
                              {formError['postalCode'] &&
                                (formError['postalCode'] === "req"
                                  ? ''
                                  : 'Enter a valid Postal Code')}
                            </span>
                          </div>

                          <div className="col-12"><button type="button" className="showmebtn" onClick={() => onButtonClick()}>{t("EXPERTPPV.TEXT5")}</button></div>

                        </div>

                      </div>
                    )}
                    {activeButton === 'mortgage' && (
                      <div className="morgageonclickform">
                        <div className="row quotes-form">
                          <div className="mb-4 col-12 inputextsize mortgageshadow">
                            <input type="text" className="form-control" placeholder=" " maxlength="30" name='name' placeholder="Your full Name"  onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            <span className="text-danger">
                              {formError['name'] &&
                                (formError['name'] === "req"
                                  ? ''
                                  : 'Enter a valid name')}
                            </span>
                          </div>
                          <div className="mb-4 col-12 inputextsize mortgageshadow">
                            <input type="text" className="form-control" placeholder="Your Email" maxlength="30" name='email'  onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                            <span className="text-danger">
                              {formError['email'] &&
                                (formError['email'] === "req"
                                  ? ''
                                  : 'Enter a valid Email')}
                            </span>
                          </div>

                          <div className="mb-4 col-6 inputextsize mortgageshadow">
                            <InputMask mask={'(999) 999 9999'} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                              {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Your phone number" disableUnderline />}
                            </InputMask>

                            <span className="text-danger">
                              {formError['phone'] &&
                                (formError['phone'] === "req"
                                  ? ''
                                  : 'Enter a valid Phone Number')}
                            </span>
                          </div>
                          <div className="mb-4 col-6 inputextsize mortgageshadow">
                            <InputMask mask={'a9a 9a9'} name="postalCode" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                              {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Postal Code" disableUnderline />}
                            </InputMask>

                            <span className="text-danger">
                              {formError['postalCode'] &&
                                (formError['postalCode'] === "req"
                                  ? ''
                                  : 'Enter a valid Postal Code')}
                            </span>
                          </div>

                          <div className="col-12"> <button type="button" className="showmebtn mortgagebuttoncolor" onClick={() => onButtonClick()}>{t("EXPERTPPV.TEXT5")}</button></div>

                        </div>

                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        
        <section className="awards">
          <div className="container">
            <div className="publicpersonaldetails">
              <div className="logemail">
                <heading>{t("EXPERTPPV.TEXT6")}</heading>
                <ul className="cetificatesection">


                  {
                    profileData?.certificates.length > 0 ? profileData?.certificates.map(certificate => {
                      return <li><div><span><img src={certificationicon} alt={' '} /></span><div className="cerdet">{certificate.certificate}</div></div></li>
                    })
                      :
                      <li><div><span><img src={certificationicon} alt={' '} /></span><div className="cerdet">{t("EXPERTPPV.TEXT7")}</div></div></li>
                  }


                </ul>

              </div>
            </div>
            <div className="publicpersonaldetails mt-5">
              <heading>{t("EXPERTPPV.TEXT8")}</heading>
              <div className="logemail">

                <ul className="awardssection">

                  {
                    profileData?.awards.length > 0 ? profileData?.awards.map(award => {
                      return <li><div><span><img src={awardicon} alt={' '} /></span><div className="cerdet">{award.award}</div></div></li>
                    })
                      :
                      <li><div><span><img src={awardicon} alt={' '} /></span><div className="cerdet">{`N/A`}</div></div></li>
                  }


                </ul>
              </div>
            </div>
          </div>
        </section>


        <GetInTouchSection
          profileData={profileData}
          userData={userData}
          formError={formError}
          onInputChangeHandler={onInputChangeHandler}
          onTouchHandler={onTouchHandler}
          onSendMessageClick={onSendMessageClick}
          formSubmitButtonDisable={formSubmitButtonDisable}
        />


      </section>
      <Footer />
    </>

  );
};

export default ExpertPpvJsx;