import React from 'react'
import InputMask from 'react-input-mask';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'

const PrimaryContactJsx = (props) => {
  const {
    onInputChangeHandler, onContactButtonClick, editContact,
    changeContactState, formError, onTouchHandler
  } = props;
  const isRequired = true
  const totalInformation = props.responseData
  const information = props?.wholeData
  const getMuskPattern = (name) =>
    name === 'phone' ? '(999) 999 9999' : 'a9a 9a9'
  return (
    <>
      {!editContact ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Primary Contact Person</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeContactState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
          </div>
        </div>

        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>First Name*   </label>
            <div className="fieldans">{totalInformation?.primaryInstituteUser?.firstName}</div>
          </div>
          <div className="form-fieldinner">
            <label>Last Name*  </label>
            <div className="fieldans">{totalInformation?.primaryInstituteUser?.lastName}</div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <label>Email*</label>
            <div className="fieldans">{totalInformation?.primaryInstituteUser?.email}</div>
          </div>
          <div className="form-fieldinner">
            <label>Phone Number*</label>
            <div className="fieldans">{formatCanadianPhoneNumber(totalInformation?.primaryInstituteUser?.contactNumber)}</div>
          </div>
        </div>
      </div>
        : null}
      { editContact ? <div className="myaccountsection institutedetails martop60">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Primary Contact Person</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeContactState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
          </div>
        </div>


        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <input type="text" className={
                isRequired && formError["firstName"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              }
              maxLength={30}
               placeholder=" " name="firstName" value={information.firstName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              <label className="placeholder">First Name *</label>
              <span className="text-danger">
                {isRequired &&
                  formError["firstName"] &&
                  (formError["firstName"] === "req"
                    ? "firstName is required!"
                    : "Please enter a proper firstName!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <input type="text" className={
                isRequired && formError["lastName"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              }
              maxLength={30}
              placeholder=" " name="lastName" value={information.lastName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              <label className="placeholder">Last Name *</label>
              <span className="text-danger">
                {isRequired &&
                  formError["lastName"] &&
                  (formError["lastName"] === "req"
                    ? "lastName is required!"
                    : "Please enter a proper lastName!")}
              </span>
            </div>
          </div>
        </div>
        <div className="disgrid grid-2">
          <div className="form-fieldinner">
            <div className="input-container form-group">
              <input type="email" name="email" className={
                isRequired && formError["email"]
                  ? "input form-control  is-invalid"
                  : "input form-control"
              } placeholder=" "
              maxLength={60} value={information.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
              <label className="placeholder">Email * </label>
              <span className="text-danger">
                {isRequired &&
                  formError["email"] &&
                  (formError["email"] === "req"
                    ? "email is required!"
                    : "Please enter a proper email!")}
              </span>
            </div>
          </div>
          <div className="form-fieldinner">
            <div className="input-container form-group">
              {/* <input type="tel" className="input form-control" placeholder=" " name="phone" value={information.phone} onChange={onInputChangeHandler} />
              <label className="placeholder">Phone Number * </label> */}
              <InputMask mask={getMuskPattern('phone')} name="phone" value={information.phone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps} type="tel" className={
                  isRequired && formError["phone"]
                    ? "input form-control  is-invalid"
                    : "input form-control"
                } placeholder=" " disableUnderline />}
              </InputMask>
              <div className="cut"></div>
              <label className="placeholder">Phone Number * </label>
              <span className="text-danger">
                {isRequired &&
                  formError["phone"] &&
                  (formError["phone"] === "req"
                    ? "phone is required!"
                    : "Please enter a proper phone!")}
              </span>
            </div>
          </div>

        </div>
        <button type="button" className="ctaonebutton widthhalf" onClick={() => onContactButtonClick()}>Save</button>
      </div> : null}

    </>
  );
};

export default PrimaryContactJsx;
