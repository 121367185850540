import { NEW_URL } from '../../../../constants/applicationConstants';
import dropbox from "../../../../assets/images/dropbox.svg";
import { Pagination } from '../../../common/pagination/pagination';
import moment from 'moment';

import "../customerQnaAnswer/customerQnaAnswer.css";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const CustomerQnaAnswerJsx = (props) => {
  const {t} =useTranslation()
  const {
    data, onPaginationButtonClick, meta,allOffersData,page
  } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5
  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  ); 

  // const [background, setBackground] = useState('#fff');
  // const colors = ['#B8FFB8', '#90be6d', '#f94144', '#f8961e', '#43aa8b', '#577590'];

  // useEffect(() => {

  //   const hasRealEstate = data.some(list => list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'mortgage');

  //   if (hasRealEstate) {
  //     // setBackground(`url(${mortgagebg})`,); 
  //   } else {
  //     // const randomColor = colors[Math.floor(Math.random() * colors.length)];
  //     // setBackgroundColor(randomColor);
  //   }
  // }, [data, colors]); 

  return (
    <>
      <section className="alldashboard allcustans">
        <div className="dashboardrightpanelinner">
          <div className="questioninner mt-4">
            {data.length ? (
              data.map(list => {
                return <div
                  className={`questioninnerin mb-3 ${list.askExpertQuestionUserResponse.parentInstitutionCategory}`}
                >
                  <div className="d-flex questioninnerinbottom">
                    <div className="expertquestiondetails">
                      <div className="expertuser">
                        <img
                          src={!list?.expert?.imageUrl
                            ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true`
                            : `${NEW_URL}/${list?.expert?.imageUrl}`}
                          alt={`${list?.expert?.firstName} ${list?.expert?.lastName}`}
                        />
                      </div>
                      <div className="expName">
                        <span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                        <div className="questiondate">
                          {moment(list?.answeredAt).format('MM-DD-YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="expertquestiondetailscategory">
                      <span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span>
                    </div>
                  </div>
                  <h5 className="h5_style questiontext">
                    <span>{t("customerqnaanswer.QUESTION")}</span> <b>{list.askExpertQuestionUserResponse.question}</b>
                  </h5>
                  <h5 className="h5_style questiontext">
                    <span>{t("customerqnaanswer.ANSWER")}</span> <b>{list.answer}</b>
                  </h5>
                </div>
              })
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="Default" />
                </div>
                <div className="dropdefaulttext">
                  <h5>{t("customerqnaanswer.DEFAULTTEXT")}</h5>
                </div>
              </div>
            )}
            {data.length > itemsPerPage &&
            <section className="newpagination"> <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allOffersData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>
            }
            {/* {data.length > 0 && (
              <Pagination
                limit={10}
                totalCount={meta?.totalCount || 0}
                onPaginationButtonClick={onPaginationButtonClick}
              />
            )} */}
          </div>

        </div>
      </section>
    </>

  );
};

export default CustomerQnaAnswerJsx;