import React from 'react'
import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { NEW_URL } from '../../../../constants/applicationConstants';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {

    addData
  } = props;

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="alldashboard cust-adds">

        <div className="dashboardrightpanel ">

          <div className="suggestedoffercustomer">
            <section className="limitedoffer ">
              {
                addData?.RealEstate &&
                <div className="limitedofferinner">
                  <div className="heading">
                    
                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE0")}</h5>

                  </div>
                  <div className="offerslider ">
                    <AwesomeSlider>
                      {
                        addData.RealEstate?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`}>{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <h3>{offer.description}</h3>
                            <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customeradds.HEADING")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customeradds.RELATEDPRODUCT")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              }
              {
                addData?.Investment &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>
                    </div>
                    <div className="offerslider ">

                      <AwesomeSlider>
                        {
                          addData?.Investment?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customeradds.HEADING")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customeradds.RELATEDPRODUCT")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button  ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}

                                  </div>
                                </div>
                              </div>

                            </div>
                              
                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                addData?.Mortgage &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE2")} </h5>
                    </div>
                    <div className="offerslider ">

                      <AwesomeSlider>
                        {
                          addData?.Mortgage?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customeradds.HEADING")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customeradds.RELATEDPRODUCT")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}

                                  </div>
                                </div>
                              </div>

                            </div>
                              
                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                addData?.Insurance &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE3")}</h5>
                    </div>
                    <div className="offerslider ">

                      <AwesomeSlider>
                        {
                          addData?.Insurance?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customeradds.HEADING")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customeradds.RELATEDPRODUCT")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}

                                  </div>
                                </div>
                              </div>

                            </div>
                              
                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
              {
                addData?.Banking &&
                <section className="limitedoffer ">
                  <div className="limitedofferinner">
                    <div className="heading">
                      
                      <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE4")} </h5>
                    </div>
                    <div className="offerslider ">

                      <AwesomeSlider>
                        {
                          addData?.Banking?.map(offer => {
                            return <div>
                              <div className="ppvawesomeslider-button">
                    <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`}>{offer?.parentInstitutionCategory}</button></div>
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${offer.imageUrl}`}
                                alt=" "
                              />
                              <h3>{offer.description}</h3>
                              <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("customeradds.HEADING")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("customeradds.RELATEDPRODUCT")}</h4>
                                    <p >{offer?.subInstitutionCategory?.name}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    {offer?.externalLink && <button href={offer?.externalLink} target="_blank" className="knowmore_button ctaonebutton widthhalf">{t("customerinterest.KNOWMOREBUTTON")}</button>}

                                  </div>
                                </div>
                              </div>

                            </div>
                              
                            </div>
                          })
                        }
                      </AwesomeSlider>
                    </div>
                  </div>
                </section>
              }
            </section>
          </div>
        </div>
      </section>
    </>

  );
};

export default SuggestedOfferJsx;