import React, { useEffect,useState } from 'react'
import email from "../../../../assets/search/email.svg";
import Phone from "../../../../assets/search/phone.svg";
import location from "../../../../assets/search/location.svg";
import heroimage from "../../../../assets/search/search_institution.svg";
import ascending from "../../../../assets/productimage/ascending-bars.svg";
import descending from "../../../../assets/productimage/descending-bars.svg";
import locationpin from "../../../../assets/search/location-pin.svg";
import name from "../../../../assets/search/user.svg";
import { useTranslation } from 'react-i18next';
import { Footer } from '../../../common/footer/footer';
import InputMask from 'react-input-mask';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../globalMainSearchInstitutions/globalMainSearchInstitutions.css";
import Filter from '../../../../shared/filter.jsx';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Pagination } from '../../../common/pagination/uiPagination.js';

// import "../globalMainSearchInstitutions/globalMainSearchInstitutionsresponsive.css";
const GlobalMainSearchInstitutionsJsx = (props) => {
  const {
    categoryData, 
    onSubmitButtonClick,
    formData,
    onInputChange,
    formError,
    onTouchHandler,
    allOffersData,
    selectAll,
    selectAllHit,
    hitOldest,
    oldest,
    getAllFeauturedOffersData, 
    selectCategory,
    onPaginationButtonClick, 
    page,
    selectSubCategory, 
    subCategories, 
    categories
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])


  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="institutesearchmain">
        <section className="institutesearchmainherosection">
          <img src={heroimage} alt=''/>
          <div className="container">
            <div className="herotext">
              <h2>{t("SEARCHINSTITUTEPAGE.TEXT1")} <span>{t("SEARCHINSTITUTEPAGE.TEXT2")} </span></h2>
              <h3><b>{t("SEARCHINSTITUTEPAGE.TEXT3")}  </b></h3>
              <h3>{t("SEARCHINSTITUTEPAGE.TEXT4")} <span>{t("SEARCHINSTITUTEPAGE.TEXT5")}</span></h3>
              <h5><b>{t("SEARCHINSTITUTEPAGE.TEXT6")}</b> {t("SEARCHINSTITUTEPAGE.TEXT7")}  <b>{t("SEARCHINSTITUTEPAGE.TEXT8")}</b> {t("SEARCHINSTITUTEPAGE.TEXT9")} </h5>
            </div></div> </section>
        <div className="container">
          <div className="forminsti d-flex">
          <div className="input-container ts">
              <InputMask mask={'a9a 9a9'} value={formData.postalCode} name="postalCode" onChange={onInputChange} onBlur={onTouchHandler}>
                {(inputProps) => <input {...inputProps}
                  type="text"
                  className={
                    formError['postalCode']
                      ? "input form-control  is-invalid"
                      : "input form-control"
                  }
                  placeholder=" "
                  disableUnderline />}
              </InputMask>
              <div className="placeholder">Enter Postal Code</div>
              <span><img src={locationpin} alt=''/></span>

            </div>
            <div className="input-container ts">
              <input
                type="text"
                className="form-control input"
                placeholder=" "
                name="name"
                onChange={onInputChange}
                onBlur={onTouchHandler}
                value={formData.name}
              />
              <div className="placeholder">Enter Name</div>
              <span><img src={name} alt=''/> </span>

            </div>
            {/* <div className="ts">
              <input type="text" className="form-control" placeholder="Enter Postal Code" />
              <span><img src={locationpin} alt=''/></span>
            </div>
            <div className="ts">
              <input type="text" className="form-control bc-name" placeholder="Enter Name" />
              <span><img src={name} alt=''/> </span></div> */}
            <div className="showallourbesttwo  text-center mb-3 mb-5"  onClick={() => onSubmitButtonClick()}>
              <a className="showallourbesttoo mt-5 mb-4">{t("COMMON.SEARCH")}</a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="instisearchheading">
            <h2>Find the top institutions quickly.</h2>
            <h3>Use filters and categories to find exactly what fits your needs!</h3>
          </div>


          <Filter
            categoryData={categoryData}
            selectAll={selectAll}
            selectAllHit={selectAllHit}
            getAllFeauturedOffersData={getAllFeauturedOffersData}
            selectCategory={selectCategory}
            selectSubCategory={selectSubCategory}
            subCategories={subCategories}
            categories={categories}
          />
          <section className="sortingdiv">
            <div className="container">
              <span className="shortby">{t("PRODUCTS.TEXT17")}</span>
              <ul>
              <li className= {!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}><img src={ascending} className="descending" alt=''/> {t("PRODUCTS.TEXT18")}</a></li>
              <li className= {oldest ? "active" : ""}><a onClick={() => hitOldest(true)}><img src={descending} className="descending" alt=''/> {t("PRODUCTS.TEXT19")}</a></li>
            </ul>
            </div>
          </section>
          <section className="institutesearchmainlistingsection">
            <div className="container">
              {
                currentItems?.map(offer => {
                  return (
                    <div className={`institutesearchmainlistingsectionps Elite ${offer.instituteCategories[0]?.name}`}>
                      <div className="instilogoouter">
                        <div className="instilogo">
                          <Link to={`/institute/${offer?.legalName}`} target="_blank">

                            <div className="instilogoimage"><img src={!offer?.imageUrl
                              ? `https://ui-avatars.com/api/?name=${offer?.name}+${offer?.name}&rounded=false`
                              : `${NEW_URL}/${offer?.imageUrl}`} alt=''
                            /></div>
                          </Link>
                        </div>
                        <div className="plainde">{offer.planName}</div>
                      </div>
                      <div className="instisegricateouter d-flex">
                        <div className="instisegricateone">
                          <h4>{offer?.name}</h4>
                          <div className="condetails">
                            <ul>
                              <li><span><img src={location} alt=''/></span> {offer?.city}</li>
                              <li><span><img src={Phone} alt=''/></span><a href="tel:416262-5457">{offer?.contactNumber}</a> </li>
                              <li><span><img src={email} alt=''/></span> <a href="mailto:samar@indexrealtybrokerage.com">{offer.email}</a></li>
                            </ul>
                          </div>
                          <div className="basicdetails">
                            <a href="" className="bdetail">Contact Us</a>
                            <a href="" className="bdetail">Offers</a>
                            <a href="" className="bdetail">Products</a>
                          </div>
                        </div>
                        <div className="instisegricatetwo">
                          <ul>
                            {
                              offer.instituteCategories?.map(cat => {
                                return (
                                  <li className={cat.name}>{cat.name}</li>

                                )
                              })
                            }
                            {/* <li className="Real Estate">Real Estate</li>
                            <li className="Insurance">Insurance</li>

                            <li className="Investment">Investment</li>
                            <li className="Mortgage">Mortgage</li>

                            <li className="Banking">Banking</li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                })
              }


            </div>
            <section className="newpagination"> <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allOffersData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>
          </section>
          <Footer />
        </div>
      </section>
    </>

  );
};

export default GlobalMainSearchInstitutionsJsx;