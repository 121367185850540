import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import question from "../../../../assets/images/question.svg"
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";
import { format } from 'date-fns';
import { decrypt } from '../../../../shared/helper';
import { Link } from 'react-router-dom';
import leftarrow from "../../../../assets/images/left-arrow.svg";
import "../myPayment/myPayment.model.css"
import { useTranslation } from "react-i18next";
import logout from "../../../../assets/images/logout.svg";
import help from "../../../../assets/Instituteppvimages/help.svg";
import download from "../../../../assets/Instituteppvimages/download.svg";

const MyPaymentsJsx = (props) => {
  const { t } = useTranslation();
  const {
    paymentData,
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
    isShowCard,
    onReplaceCardClick,
    isCardSummary,
    isPaymentSummary,
    onCompletePayment, invoiceDownload, backToPayment, onBackCardClick, backToAddCard
  } = props;

  const cardDetails = JSON.parse(sessionStorage.getItem("addCard"))

  console.log("paymentData => ", paymentData);
  return (
    <>
      <section className="entiredashboard maincustomerdash main">
        <div className="container">
          <div className="row dashboard-decoration">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9 dashboard-data">
              <div className="row title-section" >

                <div className="col-9">

                  <p className="maintitle">{t("institutePayments.TITLE")}</p>
                  <p className="mainsubTitle">{t("institutePayments.SUBTITLE")}</p>
                </div>
                <div class="col-1"  >
                  <div class="helpbutton">
                    <img class="helpImg" src={help} alt={''} />
                    <p className="logout ">{t("institutePayments.HELP")} </p>
                  </div>
                </div>
                <div class="col-2"  >
                  <div class="logoutbutton loginrightmargin">  <img class="logoutImg" src={logout} alt={''} />
                    <p className="logout ">{t("instituteDashboard.LOGOUT")} </p>
                  </div>
                </div>
              </div>

              <div className="card-details">

                {!isShowCard && !isCardSummary && !isPaymentSummary && <section > <div className="card-section">
                  <div className="row">
                    <div className="col-6">

                      <p className="your-card">{t("institutePayments.YOURCARD")}</p>

                      <p className="your-card-subtitle">{t("institutePayments.SAVECARDTITILE")}</p>
                      <div className="payment-card" >
                        <div className="row namewithlogo">
                          <div className="card-logo col-1"></div>
                          <div className="card-user-name col-10">Logo</div>
                        </div>
                        <div className="card-digit">XXXX XXXX XXXX {paymentData?.cardLast4Digits}</div>
                        <div className="row expiry">
                          <div className="card-date col-3">2024</div>
                          <div className="col-6 exp-date-section">
                            <div className="card-date-title">{t("institutePayments.EXPDATE")}</div>
                            <div className="card-date">{paymentData?.expiryMonth}/{paymentData?.expiryYear}</div>
                          </div>
                          <div className="card-simbol col-3"></div>

                        </div>
                        <p className="card-user-name">{paymentData?.name}</p>

                      </div>

                    </div>
                    <div className="col-6 replace-button-section">
                      <div className="replace-button" onClick={onReplaceCardClick}>
                        {t("institutePayments.REAPLACECARD")}
                      </div>
                    </div>
                  </div>

                  <div >
                    <p className="your-card">{t("institutePayments.SUBSRIPTIONTITLE")}</p>
                    <p className="your-card-subtitle">{t("institutePayments.SUBSRIPTIONSUBTITLE")}</p>
                    <p className="customer-since">{t("institutePayments.CUSTOMERSINCE")} {paymentData && format(new Date(paymentData?.customerJoiningDate), 'dd MMM yyyy')}</p>

                    <div className="plan-section">

                      <div className="row">
                        <div className="col-6 text-right plan-title"> <p>{t("institutePayments.CURRENTPLAN")} :</p></div>
                        <div className="col-6 plan-answer">  <p>{paymentData?.planName}</p></div>
                      </div>

                      <div className="row plan-time">
                        <div className="col-6">
                          <div className="plan-title"> <p> {t("institutePayments.SUBSRIPTIONSTART")}</p></div>
                          <div className="plan-answer">  <p>{paymentData && format(new Date(paymentData?.subscriptionStartDate), 'dd MMM yyyy')}</p></div>
                        </div>
                        <div className="col-6 text-right plan-date">
                          <div className="plan-title"> <p> {t("institutePayments.SUBSRIPTIONEND")}</p></div>
                          <div className="plan-answer">  <p>{paymentData && format(new Date(paymentData?.subscriptionEndDate), 'dd MMM yyyy')}</p></div>
                        </div>
                      </div>

                    </div>
                    {paymentData?.planName === 'Premium' &&
                      <div className="change-plan-section">
                        <Link
                          to={{
                            pathname: "/changeplan"
                          }}
                          className="change-plan-button">
                          {t("institutePayments.CHANGEPLAN")}
                        </Link>
                      </div>
                    }
                  </div>
                </div>

                  <div className="payment-history">
                    <p className="your-card">{t("institutePayments.PAYMENTTITLE")}</p>
                    <p className="your-card-subtitle">{t("institutePayments.PAYMENTSUBTITLE")}</p>
                    <ul className="payment-structure">
                      <li className="text-underline"><span className="period">Period</span> <span className="amount">Amount</span> <span className="status">Status</span> <span>Invoice</span></li>
                      {paymentData?.subscriptions.map((item) => {
                        return (
                          <>
                            <li className="payment-entry">
                              <span>{format(new Date(item.startDate), ' MMM-yyyy')} - {format(new Date(item.endDate), ' MMM-yyyy')}</span>
                              <span>${parseFloat((item?.amount ?? 0) / 100).toFixed(2)}</span>
                              <span>Paid</span>
                              <span><button type="button" className="download-button" onClick={() => invoiceDownload(item)}>Download<img src={download} alt={'icon'} /></button> </span>
                            </li>                                      </>
                        )
                      })}

                      {/* <li>
                        <span>May 2022 - May 2023</span>
                        <span>$123</span>
                        <span><button type="button" className="ctaonebutton">Paid</button></span>
                        <span><button type="button" className="ctaonebutton">Download Icon</button></span>
                      </li>
                      <li>
                        <span>May 2022 - May 2023</span>
                        <span>$123</span>
                        <span><button type="button" className="ctaonebutton">Paid</button></span>
                        <span><button type="button" className="ctaonebutton">Download Icon</button></span>
                      </li> */}
                    </ul>
                  </div>

                </section>
                }

                <section>

                  {
                    isShowCard ?

                      <div id="down" className="sectioncardadd mt-4">
                        <div className="back_btn" onClick={() => onBackCardClick()}><img src={leftarrow} alt={'icon'} /></div>

                        <h2 className="text-center font-we-400">Replace your Existing Card</h2>
                        <h4 className="text-center">Do you wish to add another credit card? Do it easily from below!
                            Please make sure you are correctly entering the card details.</h4>
                        <div className="detailscardenter">
                          <p>Please enter the following card information:</p>
                          <div className="mt-4 mb-4 input-container form-group">
                            <input
                              onChange={onInputChangeHandler}
                              onBlur={onTouchHandler}
                              required
                              type="text"
                              value={(formData) ? formData.name : null}
                              placeholder=" "
                              maxLength="50"
                              className={
                                formError["name"]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              id="name"
                              name="name"
                            />
                            <div className="placeholder">Name of the Cardholder</div>
                            <span className="text-danger">

                              {formError["name"] &&
                                (formError["name"] === "req"
                                  ? "Card member name is required!"
                                  : "Please enter a proper name!")}
                            </span>
                          </div>
                          <div className="mb-4 input-container form-group">
                            <input
                              onChange={onInputChangeHandler}
                              onBlur={onTouchHandler}
                              required
                              type="text"
                              maxLength="16"
                              value={(formData) ? formData.card : null}
                              placeholder=" "
                              className={
                                formError["card"]
                                  ? "input form-control  is-invalid"
                                  : "input form-control"
                              }
                              id="card"
                              name="card"
                            />
                            <div className="placeholder">Card Number</div>
                            <span className="text-danger">
                              {formError["card"] &&
                                (formError["card"] === "req"
                                  ? "Card Number is required!"
                                  : "Please enter a proper Card Number!")}
                            </span>
                          </div>
                          <div className="disgrid grid-2 cardvalid">

                            <div className="inlinecard">
                              <div className="input-container form-group">
                                <span className="validth">Valid Thru</span>
                                <input
                                  onChange={onInputChangeHandler}
                                  onBlur={onTouchHandler}
                                  value={(formData) ? formData.month : null}
                                  required
                                  type="text"
                                  placeholder=" "
                                  className={
                                    formError["month"]
                                      ? "input form-control  is-invalid"
                                      : "input form-control"
                                  }
                                  id="month"
                                  name="month"
                                  maxLength="2"
                                />
                                <div className="placeholder">Month</div>
                                <span className="text-danger">
                                  {formError["month"] &&
                                    (formError["month"] === "req"
                                      ? "Month is required!"
                                      : "Please enter a proper Month!")}
                                </span>
                              </div>
                              <div className="input-container form-group">
                                <input
                                  onChange={onInputChangeHandler}
                                  onBlur={onTouchHandler}
                                  required
                                  type="text"
                                  placeholder=" "
                                  value={(formData) ? formData.year : null}
                                  maxLength="4"
                                  className={
                                    formError["year"]
                                      ? "input form-control  is-invalid"
                                      : "input form-control"
                                  }
                                  id="year"
                                  name="year" />
                                <div className="placeholder">Year</div>
                                <span className="text-danger">
                                  {formError["year"] &&
                                    (formError["year"] === "req"
                                      ? "year  is required!"
                                      : "Please enter a proper year !")}
                                </span>
                              </div>
                            </div>
                            <div className="input-container form-group">
                              <input
                                onChange={onInputChangeHandler}
                                onBlur={onTouchHandler}
                                required
                                type="text"
                                placeholder=" "
                                maxLength="3"
                                value={(formData) ? formData.cvc : null}
                                className={
                                  formError["cvc"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                                }
                                id="cvc"
                                name="cvc" />
                              <div className="placeholder">CVC</div>
                              <span className="text-danger">
                                {formError["cvc"] &&
                                  (formError["cvc"] === "req"
                                    ? "cvc  is required!"
                                    : "Please enter a proper cvc !")}
                              </span>
                            </div>
                          </div>
                          <button disabled={formSubmitButtonDisable} onClick={formSubmitHandler} type="button" className={
                            formSubmitButtonDisable
                              ? "btn-secondary btn mt-4 ctatwobutton"
                              : "mt-4 ctaonebutton"
                          }> {isLoading ? t("COMMON.PROCESSING") : t("COMMON.ADDCARD")}</button>
                        </div>
                      </div>

                      : null
                  }
                </section>


                {isCardSummary && <section>
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">Make Payment</h2>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {cardDetails &&
                    <div className="sectioncardadd mt-4">
                      <div className="back_btn" onClick={() => backToAddCard()}><img src={leftarrow} alt={'icon'} /></div>

                      <h2 className="text-center font-we-400">Pay Easily with Your Added Credit Card</h2>
                      <h4 className="text-center">Just tap on the card below to make payment</h4>
                      <div className="paymentchange">
                        <div className="previewcardinner mt-4">
                          <div className="namecardholder">{cardDetails?.name}</div>
                          <div className="cardnumber"><div>{cardDetails && decrypt(cardDetails?.card).replace(/[^a-z0-9]+/gi, '').replace(/(.{4})/g, '$1 ')}</div><div className="cardde"></div></div>
                          <div className="carddate">{cardDetails?.month}/{cardDetails?.year}</div>
                        </div>
                        <button type="button" className="ctaonebutton mt-4" onClick={onCompletePayment}>COMPLETE PAYMENT</button>
                      </div>

                    </div>}
                </section>}
                {isPaymentSummary && <section>
                  <div className="dashboardrightpannerinner">
                    <div className="dashboardrightpannerinnerleft">
                      <h2 className="h2_style font-weight-600">Payment Summary</h2>

                    </div>
                    <div className="dashboardrightpannerinnerright">
                      <div className="signout">
                        <a href="#logout">
                          <img src={question} alt={''} /><span className="title">Help</span>
                        </a>
                      </div>
                      <div className="signout">
                        <a href="#logout">
                          <img src={Sign_out_squre} alt={''} /><span className="title">Logout</span>
                        </a>
                      </div>
                      <div id="logout" className="modal-window">
                        <div>
                          <a title="Close" className="modal-close">Close</a>
                          <div className="modal-header">

                          </div><div className="modal-body mb-4">
                            <div className="modal-body-inner">
                              <h2 className="h2_style text-center">Are you sure you want to
Logout ?</h2>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="ctaonebutton"> Save</button>
                            <button type="button" className="ctatwobutton"> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {<div className="sectioncardadd mt-4">
                    <div className="back_btn" onClick={() => backToPayment()}><img src={leftarrow} alt={'icon'} /></div>

                    <h2 className="text-center font-we-400 voila">Voila! Your transaction was successful!</h2>
                    <h4 className="text-center fw-lighter">Thank You for Choosing Us Again!</h4>
                    <h4 className="text-center fst-italic fw-lighter">Thank You for Choosing Us Again!</h4>
                    <div className="Paymentsummeryinner">
                      <div className="paymentsummery">
                        <li>STATUS:<span>SUCCESS</span></li>
                        <li>Transaction ID:<span>XXXXXXXXXXX</span></li>
                        <li>Date of Transaction:<span>DD/MM/YY</span></li>
                        <li>Amount Paid:<span>$1000</span></li>
                        <li> Paid via Card ending XXXX (last four digits) </li>
                      </div>
                      <button type="button" className="ctaonebutton mt-4" >Download Invoice</button>
                      <button type="button" className="ctaonebutton mt-4" onClick={() => backToPayment()}>Continue To Payment-Screen</button>
                    </div>

                  </div>}
                </section>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  );
};

export default MyPaymentsJsx;