import React from 'react'
import { Component } from 'react';
import GlobalMainSearchExpertJsx from './globalMainSearchExpert.jsx'
import axios from 'axios';
import { baseURL } from '../../../../constants/applicationConstants.js';
import { Loader } from '../../../common/loader/loader.jsx';
import { nameRegex, postalCodeRegex } from '../../../../shared/helper.js';
export default class GlobalMainSearchExpert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            subCategoryData: null,
            formData: {
                name: '',
                postalCode: '',
                category: null,
                subCategory: null,
                keyword: ''
            },
            oldest: false,

            selectedCategoryId: null,
            subCategories: [],
            categories: [],
            checkedCats: [],
            checkedSubCats: [],

            investmentOfferData: null,
            allOffersData: null,
            overallOffers: null,
            topOffers: null,
            mortgageOfferData: null,
            insuranceOfferData: null,
            bankingOfferData: null,
            realestateOfferData: null,
            isProcessing: false,
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            selectAll: false,
            expertData: [],
            page: 1,
        }
    }
    onPaginationButtonClick = (page) => {
        window.scrollTo(1200,1200);
        this.setState({ page: page })
    }


    componentDidMount = () => {
        this.getTopOffers();

        this.getcategorySubcategory();
        setTimeout(() => {
            this.selectAllIds()

        }, 1000);
        this.props.match.params.keyword && this.fetchSearchData(this.props.match.params.keyword);


    }

    getcategorySubcategory = () => {
        this.setState({ isLoading: true })
        let token = JSON.parse(sessionStorage.getItem('token'));
        axios.get(`${baseURL}/institutionCategoriesAndSubCategories`, {
            headers: { 'x-access-token': token }
        }).then(res => {
            this.setState({ categoryData: res.data.data, isLoading: false })
        })
    }
    getAllFeauturedOffersData = () => {
        const selectedType = this.props.match.params.type;
        this.setState({ isProcessing: true })
        // for offer button click
        if (selectedType === "all") {
            axios.get(`${baseURL}/search-offers`).then(res => {

                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
            })
        } else if (selectedType === "homeFeatured") {

            axios.get(`${baseURL}/homeFeaturedOffers`).then(res => {

                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
            })
        } else {
            axios.get(`${baseURL}/FeaturedOffers?institutionCategoryId=${selectedType}`).then(res => {

                this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
            })
        }

    }

    selectAllHit = (e) => {
        const { checked } = e.target
        if (!checked) {
            this.setState({ selectAll: false })
        } else {
            this.setState({ selectAll: true })

        }
        if (checked) {
            this.setState({ allOffersData: this.state.overallOffers })
            this.selectAllIds()


        }


    }

    searchOfferByKeyword = (e) => {
        const data = e.target.value
        this.setState({ keyword: data })
    }

    hitSearch = () => {
        this.props.history.replace('/')
        this.props.history.push(`/offers/${this.props.match.params.type}/${this.state.keyword}`)
        this.fetchSearchData(this.state.keyword)
    }

    fetchSearchData = (data) => {

        axios.get(`${baseURL}/search-content?keyword=${data}`).then(res => {
            this.setState({ allOffersData: res.data.data.offers, overallOffers: res.data.data.offers, isProcessing: false })
        })
    }

    hitOldest = (action) => {
        
        this.setState({ oldest : action,isProcessing: true})
        setTimeout(() => {
            this.getTopOffers()
            this.selectAllIds()
        }, 500);
    }


    getTopOffers = () => {
        const queryString = window.location.search; // Get the query string
        const urlParams = new URLSearchParams(queryString); // Create URLSearchParams object
        const searchValue = urlParams.get('search') || "";

        this.setState({ isProcessing: true })
        axios.get(`${baseURL}/expert/search-global-experts?search=${searchValue}&page=1&perPage=100&expire=${this.state.oldest ?  "oldest" : "latest"}`).then(res => {
            this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false,selectAll: true })
        })
    }


    onCatgoryClick = (value) => {
        const subData = this.state.categoryData.filter(itm => itm.institutionCategoryId == value)

        this.setState({ subCategoryData: subData[0]?.subInstitutionCategories })
    }
    isFromSubmissionBlock = () => {
        let { postalCode } = this.state.formData;

        if (postalCode) {
            if (
                this.inputRegexValidation('postalCode', postalCode)
            ) {
                this.setState({
                    formSubmitButtonDisable: false
                });
            }
            else {
                this.setState({
                    formSubmitButtonDisable: true
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true
            });
        }
    };
    onInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        if (name === 'category') {
            this.onCatgoryClick(value)
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        'subCategory': '',
                    }
                }
            )

        }


        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    }
    inputRegexValidation = (name, value) => {
        if (name === 'name')
            return nameRegex.test(value);
        if (name === 'postalCode')
            return postalCodeRegex.test(value);

        return true;
    };

    onSubmitButtonClick = () =>{
        this.setState({isLoading:true});
        const {name,postalCode} = this.state.formData;
        axios.get(`${baseURL}/searchExperts?page=${this.state.page}&perPage=999&name=${name}&postalCode=${postalCode}&expire=${this.state.oldest ?  "oldest" : "latest"}`).then(res=>{
            this.setState({ allOffersData: res.data.data, overallOffers: res.data.data, isProcessing: false, selectAll: true })
        }).catch(err=>{
            this.setState({isLoading:false});
            console.warn(err?.response.data.error.message);
        })
    }

    // filter all selected id
    selectAllIds = () => {
        const institutionCategoryIds = this.state.categoryData?.map(category => category.institutionCategoryId);
        this.setState({ categories: institutionCategoryIds })

        // Extract sub-institution category IDs as well
        this.state.categoryData?.forEach(category => {
            if (category?.subInstitutionCategories) {
                const subIds = category?.subInstitutionCategories?.map(subCategory => subCategory.institutionCategoryId);
                // institutionCategoryIds.push(...subIds);
                this.setState(prevState => ({
                    subCategories: [...prevState.subCategories, ...subIds] // Spread existing items and add new items
                }));
                // this.setState({ subCategories: ...subIds })

            }
        });

        this.setState({ institutionCategoryIds });
    }


    filterCatData = () => {

        const catDataFiltered = this.state.overallOffers.filter(offer => {
            return offer.expertSpecializations.some(specialization =>
                specialization.institutionCategoryId && this.state.categories.includes(specialization.institutionCategoryId)
            );
        }
        );
        this.setState({ allOffersData: catDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }


    }

    filterSubData = () => {

        const subCatDataFiltered = this.state.overallOffers.filter(offer => {
            // Check if any of the expertSpecializations have a matching institutionSubCategoryId
            return offer.expertSpecializations.some(specialization =>
                specialization.expertSpecializationSubCategories &&
                specialization.expertSpecializationSubCategories.some(subCat =>
                    this.state.subCategories.includes(subCat.institutionSubCategoryId)
                )
            );
        });


        this.setState({ allOffersData: subCatDataFiltered })
        if (this.state.subCategories.length === 0) {
            this.setState({ allOffersData: this.state.overallOffers, selectAll: true })
            this.selectAllIds()

        }

    }




    selectCategory = (data, e) => {
        sessionStorage.setItem("changing",true)

        const { checked } = e.target


        this.setState({ selectAll: false })
        // 
        const selectedId = data.institutionCategoryId;
        this.setState({ selectedCategoryId: selectedId });

        const selectedCategory = this.state.categoryData.find(category => category.institutionCategoryId === selectedId);


        if (selectedCategory) {
            const insInsideCat = selectedCategory.subInstitutionCategories
            const institutionCategoryIds = insInsideCat.map(category => category.institutionCategoryId);


            if (checked) {
                this.setState((prevState) => ({
                    subCategories: [...prevState.subCategories, ...institutionCategoryIds],
                }));
                this.setState({ page: 1 })

            } else {

                this.setState((prevState) => ({
                    subCategories: prevState.subCategories.filter(
                        (id) => !institutionCategoryIds.includes(id) // Remove IDs present in newIds
                    )
                }));
                this.setState({ page: 1 })

                // this.setState((prevState) => ({
                //     subCategories: prevState.subCategories.filter((selectedId) =>                   
                //       console.log("hhhh",institutionCategoryIds)
                //     // selectedId !== institutionCategoryIds
                //     )
                // }));
            }
        } else {
            this.setState({ subCategories: [] });
        }
        setTimeout(() => {
            this.filterCatData()

        }, 500);


        this.setState((prevState) => {
            const { categories } = prevState;

            // Check if the id is already in the categories
            if (categories.includes(data.institutionCategoryId)) {
                // Remove the id
                return { categories: categories.filter(selectedId => selectedId !== data.institutionCategoryId) };
            } else {
                // Add the id
                return { categories: [...categories, data.institutionCategoryId] };
            }
        });
    }


    handleCheckboxChange = (id) => (event) => {
        if (event.target.checked) {
            // Add ID to the selected array if not already present
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.includes(id)
                    ? prevState.selectedIds
                    : [...prevState.selectedIds, id]
            }));
        } else {
            // Remove ID from the selected array when unchecked
            this.setState((prevState) => ({
                selectedIds: prevState.selectedIds.filter((selectedId) => selectedId !== id)
            }));
        }
    };













    selectSubCategory = (data) => {
        sessionStorage.setItem("changing",true)

        this.setState((prevState) => {
            if (prevState.subCategories.includes(data.institutionCategoryId)) {
                // If the ID is already selected, remove it
                return { subCategories: prevState.subCategories.filter((prevId) => prevId !== data.institutionCategoryId) };
            } else {
                // If the ID is not selected, add it
                return { subCategories: [...prevState.subCategories, data.institutionCategoryId] };
            }
        });

        setTimeout(() => {
            this.filterSubData()

        }, 500);

        // console.warn("selected sub cat", data,this.state.checkedCats)

    }
    render() {
        return (
            <div>
                <GlobalMainSearchExpertJsx
                    subCategories={this.state.subCategories}
                    categories={this.state.categories}
                    searchOfferByKeyword={this.searchOfferByKeyword}
                    hitSearch={this.hitSearch}
                    selectAllHit={this.selectAllHit}
                    getAllFeauturedOffersData={this.getAllFeauturedOffersData}
                    investmentOfferData={this.state.investmentOfferData}
                    topOffers={this.state.topOffers}
                    mortgageOfferData={this.state.mortgageOfferData}
                    insuranceOfferData={this.state.insuranceOfferData}
                    bankingOfferData={this.state.bankingOfferData}
                    realestateOfferData={this.state.realestateOfferData}
                    allOffersData={this.state.allOffersData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    page={this.state.page}

                    categoryData={this.state.categoryData}
                    subCategoryData={this.state.subCategoryData}
                    formData={this.state.formData}
                    onInputChange={this.onInputChange}
                    formError={this.state.formError}
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    selectCategory={this.selectCategory}
                    selectSubCategory={this.selectSubCategory}
                    selectAll={this.state.selectAll}
                    oldest={this.state.oldest}
                    hitOldest={this.hitOldest}
                />
                {this.state.isProcessing && <Loader />}

            </div>
        )
    }
}