import React from 'react'
import planpricingtooltip from "../../../../assets/images/planpricingtooltip.svg";
import ProductList from "./productsList.jsx"
import { TagsInput } from "react-tag-input-component";
import { ToastContainer } from 'react-toastify';
import { NEW_URL } from '../../../../constants/applicationConstants';
import "../myProducts/products.model.css"
import "../../institute/institute.model.css"
import gridetooltip from "../../../../assets/Instituteppvimages/gride.svg"; 
import { useTranslation } from "react-i18next"; 

const ProductsJsx = (props) => {
    const {
        productFormData,
        categoryData,
        subCategoryData,
        productTypes,
        changeHideState,
        formError,
        imgURL,
        hideAdd,
        profileImagePreviewUrl,
        onFileChange,
        _handleUploadImage,
        onInputChangeHandler,
        getProducts,
        getParticularProducts,
        onTouchHandler,
        formSubmitButtonDisable,
        produtsList,
        onMetaTagChange,
        onTabClick,
        productCount
    } = props;
    const editMode = sessionStorage.getItem("editProductId")
    const limitData = JSON.parse(sessionStorage.getItem('loginData'));
    const {

    } = props;

    const { t } = useTranslation();
    const openEditBox = () => {
        window.scrollTo({
            top: 500,
            left: 100,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <section classNames=" ">
                                <div className="">
                                    <div className="create-product-banner row">
                                        <div className="col-7">
                                            <div className="banner-description">{t("instituteproduct.ADDPRODUCTPAGE")}
                                                 <div className="userguide d-block">
                                                    <div className="dropdown-toggle userdropdown-toggle banner-gride" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false"> Help Guide <img src={gridetooltip} alt={''} /> </div>
                                                    <ul className="dropdown-menu userdropdown-menu" aria-labelledby="dropdownMenu2">
                                                        <li className="dropdown-item">{t("instituteproduct..HOWTOCREATE")}
    <ul className="dropdown-menu dropdown-submenu">
                                                                <li className="dropdown-item">{t("instituteproduct.ADDPRODUCTPACLICKTOCREATEGE")}</li>
                                                                <li className="dropdown-item">{t("instituteproduct.UPLOADPRODUCTIMAGE")}</li>
                                                                <li className="dropdown-item">{t("instituteproduct.ENTERPRODUCTNAME")} </li>
                                                                <li className="dropdown-item">{t("instituteproduct.ENTERDESCRIPTION")}</li>
                                                                <li className="dropdown-item">{t("instituteproduct.INTREATRATEANDTAG")}</li>
                                                                <li className="dropdown-item">{t("instituteproduct.ADDLINKTHEPRODUCT")} </li>

                                                            </ul>
                                                        </li>
                                                        <li><a className="dropdown-item" type="button">{t("instituteproduct.EDITEXISTPRODUCT")}</a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.EDITOPTION")} </a></li>

                                                                <li><a className="dropdown-item"  >{t("instituteproduct.POPUPWINDOWSAPPIER")}
       </a></li>
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.CLICKSUBMITCHANGES")}</a></li>


                                                            </ul>
                                                        </li>
                                                        <li><a className="dropdown-item" type="button">{t("instituteproduct.HOWTODELETE")}</a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.CLICKDELETEOPTION")}</a></li>


                                                            </ul>
                                                        </li>
                                                        <li><a className="dropdown-item" type="button">{t("instituteproduct.HOWTOADDPRODUCTS")}</a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.UPLODMOREPRODUCT")}</a></li>

                                                            </ul>
                                                        </li>
                                                        <li><a className="dropdown-item" type="button">{t("instituteproduct.HOWTOSETSTATUS")} </a>
                                                            <ul className="dropdown-menu dropdown-submenu">
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.SWITHBELOWADS")}   </a></li>
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.RIGHTSIDEACTIVE")}  </a></li>
                                                                <li><a className="dropdown-item"  >{t("instituteproduct.RIGHTSIDEINACTIVE")} </a></li>


                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-5">

                                            <button type="button" disabled={productCount === limitData?.totalProductsLimit} className="craete-button" onClick={() => changeHideState(true)}>{t("instituteproduct.CREATENEWPRODUCT")}</button>
                                            {productCount === limitData?.totalProductsLimit && <p className="text-danger">{t("instituteproduct.MAXIMUMPRODUCT")}</p>}
                                        </div>
                                    </div>
                                    <section classNames=" ">
                                        <br />


                                        {hideAdd && <div className="expertformmain">

                                            <div className="expertinnerformmain" id="abhinaba">
                                                <div className="expertformmainheader">
                                                    <div className="expertformmainheaderleft">
                                                        <h3>Create New Product</h3>

                                                    </div>
                                                    <div className="expertformmainheaderright">
                                                        <button type="button" className="myadbtn" onClick={() => changeHideState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                            width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                                                            preserveAspectRatio="xMidYMid meet">

                                                            <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                                                                fill="#ffffff" stroke="none">
                                                                <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                                                            </g>
                                                        </svg></button>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-4">
                                                        <div className="uploadproduct mb-4">
                                                        
                                                            {
                                                                profileImagePreviewUrl ?
                                                                    <div className="uploadpd">
                                                                        <img
                                                                            src={profileImagePreviewUrl}
                                                                            height="170px"
                                                                            width="250px"
                                                                            alt="edit profile"
                                                                        /> </div> :
                                                                    editMode ?
                                                                        <div className="uploadpd">
                                                                            <img
                                                                                src={`${NEW_URL}/${imgURL}`}
                                                                                height="170px"
                                                                                width="250px"
                                                                                alt="edit profile"
                                                                            />
                                                                        </div>
                                                                        : <div className="uploadpd"></div>
                                                            }

                                                            <div className="button_outer">
                                                                <div className="btn_upload">
                                                                    <input type="file" id="upload_file" name=""
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={onFileChange}
                                                                    />
                                                                    <label for="upload_file" >{t("instituteproduct.UPLOADIMAGE")}</label>
                                                                </div>
                                                            </div>
                                                           </div>



                                                    </div>
                                                    <div className="col-lg-8 col-sm-8">
                                                        <div className="disgrid grid-3">
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="category">
                                                                    <option value="">{t("instituteproduct.SELECTCATEGORY")}</option>
                                                                    {categoryData?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.institutionCategoryId} selected={item.institutionCategoryId === productFormData.category}>{item.name}</option>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <label className="placeholder">{t("instituteproduct.CATEGORY")}</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="subCategory">
                                                                    <option value="">{t("instituteproduct.SELECTSUBCATEGORY")}</option>
                                                                    {subCategoryData?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.institutionCategoryId} selected={item.institutionCategoryId === productFormData.subCategory}>{item.name}</option>
                                                                            </>
                                                                        )
                                                                    })}
                                                                   
                                                                </select>
                                                                <label className="placeholder">{t("instituteproduct.SUBCATEGORY")}</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <select className="input form-control form-select" onChange={onInputChangeHandler} name="productTypeId">

                                                                    {productTypes?.length <= 0 ? <option value={null}>{t("instituteproduct.NOPRODUICTAVAILABLE")}</option> : <option value="">{t("instituteproduct.SELECTPRODUCT")}</option>}
                                                                    {productTypes?.map((item, i) => {
                                                                        return (
                                                                            <>
                                                                                {item?.name && <option value={item.productTypeId} selected={item.productTypeId == productFormData.productTypeId}>{item.name}</option>}
                                                                            </>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <label className="placeholder">{t("instituteproduct.PRODUCTTYPE")}</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="product"
                                                                value={productFormData.product}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                                maxLength={50}
                                                            />
                                                            <span className="text-danger">
                                                                {formError['product'] &&
                                                                    (formError['product'] === "req"
                                                                        ? 'product is required'
                                                                        : 'please enter valid product name')}
                                                            </span>
                                                            <label className="placeholder">{t("instituteproduct.PRODUCTNAME")}</label>

                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                name="description"
                                                                value={productFormData.description}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                                maxLength={250}
                                                            ></textarea>
                                                            <span className="text-danger">
                                                                {formError['description'] &&
                                                                    (formError['description'] === "req"
                                                                        ? 'description is required'
                                                                        : '')}
                                                            </span>
                                                            <label className="placeholder">{t("instituteproduct.DESCRIPTIONS")}</label>
                                                        </div>
                                                        <div className="disgrid grid-2">
                                                            <div className="mb-4 input-container form-group">
                                                                <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                    name="benefits"
                                                                    value={productFormData.benefits}
                                                                    onChange={onInputChangeHandler}
                                                                    onBlur={onTouchHandler}
                                                                    maxLength={250}
                                                                ></textarea>
                                                                <span className="text-danger">
                                                                    {formError['benefits'] &&
                                                                        (formError['benefits'] === "req"
                                                                            ? 'benefits is required'
                                                                            : '')}
                                                                </span>
                                                                <label className="placeholder">{t("instituteproduct.BENIFIT")}</label>
                                                            </div>
                                                            <div className="mb-4 input-container form-group">
                                                                <textarea className="input form-control descriptiontextarea" placeholder=" "
                                                                    name="features"
                                                                    value={productFormData.features}
                                                                    onChange={onInputChangeHandler}
                                                                    onBlur={onTouchHandler}
                                                                    maxLength={250}
                                                                ></textarea>
                                                                <span className="text-danger">
                                                                    {formError['features'] &&
                                                                        (formError['features'] === "req"
                                                                            ? 'features is required'
                                                                            : '')}
                                                                </span>
                                                                <label className="placeholder">{t("instituteproduct.FEATURE")}</label>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="interestRate"
                                                                type="number"
                                                                value={productFormData.interestRate}
                                                                onChange={onInputChangeHandler}
                                                            />
                                                            <label className="placeholder">{t("instituteproduct.INTRESTRATE")} </label>
                                                        </div>


                                                        <div className="mb-4 input-container form-group specialtag">
                                                            <div className="normal" id="tagInput"
                                                                onFocus={() => {
                                                                    let elem = document.getElementById("tagInput");
                                                                    elem.classList.add("my-classfocus");
                                                                }}
                                                                onBlur={() => {
                                                                    let elem = document.getElementById("tagInput");
                                                                    elem.classList.remove("my-classfocus");
                                                                }}

                                                            >
                                                                <TagsInput
                                                                    value={productFormData.metaTags}
                                                                    onChange={onMetaTagChange}
                                                                    label="xxxxxxxxx"
                                                                    name="metaTags"
                                                                    placeHolder=" "
                                                                    classNames={{ tag: 'tag-clsxxxx', input: 'input form-control' }}
                                                                />
                                                            </div>
                                                            <label className="placeholder">{t("instituteproduct.METATAG")}<span className="plantooltip custooltip" ><img src={planpricingtooltip} className="tooltipim" />
                                                                <div className="tooltipcontent"> {t("instituteproduct.METATAGDESCRIPTION")} </div>
                                                            </span></label>
                                                        </div>
                                                        {/*  */}
                                                        <div className="mb-4 input-container form-group">
                                                            <input className="input form-control" placeholder=" "
                                                                name="externalLink"
                                                                value={productFormData.externalLink}
                                                                onChange={onInputChangeHandler}
                                                                onBlur={onTouchHandler}
                                                            />
                                                            <label className="placeholder">{t("instituteproduct.ENTERNALLINK")}</label>
                                                            <span className="text-danger">
                                                                {formError['externalLink'] &&
                                                                    (formError['externalLink'] === "req"
                                                                        ? ''
                                                                        : t("instituteproduct.ENTERENTERNALLINK"))}
                                                            </span>
                                                        </div>

                                                        <div className="mb-4 input-container form-group">
                                                            <button type="button" disabled={!editMode && formSubmitButtonDisable} className="ctaonebutton" onClick={() => _handleUploadImage()} >{!editMode ? t("instituteproduct.SUBMIT") : t("instituteproduct.UPDATE")}</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </section>
                                    <ProductList
                                        openEditBox={openEditBox}
                                        getProducts={getProducts}
                                        getParticularProducts={getParticularProducts}
                                        produtsList={produtsList}
                                        onTabClick={onTabClick}
                                    />
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />

        </>

    );
};

export default ProductsJsx;
