import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Sidebar from "../sidebar/sidebar.js";
import MyAds from '../../dashboardCommon/myAds/myAds.js';
import MyOffer from '../../dashboardCommon/myOffer/myOffer.js';
import MyProduct from '../../dashboardCommon/myProducts/products.js'
import MyServices from "../../dashboardCommon/myServices/myServices.js"
import "../myWorkspace/myWorkspace.model.css"
import { Trans, useTranslation } from 'react-i18next';
import logout from "../../../../assets/images/logout.svg";
import offer from "../../../../assets/Instituteppvimages/myoffer.svg";
import service from "../../../../assets/Instituteppvimages/service.svg";
import product from "../../../../assets/Instituteppvimages/myproduct.svg";
import ads from "../../../../assets/Instituteppvimages/myads.svg";


const MyWorkspaceJsx = (props) => {
  const {
    activeTab,
    onTabClick,

  } = props;

  const { t } = useTranslation();

  return (
    <>
      <section className="entiredashboard maincustomerdash main">
        <div className="container">
          <div className="row dashboard-decoration">
            <div className="col-lg-3 col-sm-3">
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9 myworkspace-data">
              <div className="row col-12 myworkspace-padding" >

                <div className="col-9">

                  <p className="title">{t("instituteWorkspace.TITLE")}</p>
                  <p className="subTitle">{t("instituteWorkspace.SUBTITLE")}</p>
                  <p className="subTitlesimple" ><Trans i18nKey="instituteWorkspace.USETHIS" ></Trans></p>
                </div>
                <div class="col-3"  >
                  <div class="logoutbutton">  <img class="logoutImg" src={logout} alt={''} />
                    <p className="logout ">{t("instituteDashboard.LOGOUT")} </p>
                  </div>
                </div>
              </div>


              <div class="myworkspacenav myworkspace-padding">
                <ul>
                  <li class={`${activeTab === 'myAds' ? 'active' : ''}`} onClick={() => onTabClick('myAds')}><img class={`${activeTab === 'myAds' ? 'active' : ''}`} src={ads} alt={''} /> My Ads</li>
                  <li class={`${activeTab === 'myProduct' ? 'active' : ''}`} onClick={() => onTabClick('myProduct')}><img class={`${activeTab === 'myProduct' ? 'active' : ''}`} src={product} alt={''} /> My Products</li>
                  <li class={`${activeTab === 'myOffer' ? 'active' : ''}`} onClick={() => onTabClick('myOffer')}><img class={`${activeTab === 'myOffer' ? 'active' : ''}`} src={offer} alt={''} /> My Offers</li>
                  <li class={`${activeTab === 'myService' ? 'active' : ''}`} onClick={() => onTabClick('myService')}><img class={`${activeTab === 'myService' ? 'active' : ''}`} src={service} alt={''} /> My Services</li>

                </ul>
              </div>




              {
                activeTab === 'myAds' ? <MyAds /> : activeTab === 'myOffer' ? <MyOffer /> : activeTab === 'myService' ? <MyServices /> : <MyProduct />
              }


            </div>

          </div>

        </div>

      </section>

    </>

  );
};

export default MyWorkspaceJsx;
