import React from 'react'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../common/footer/footer';
import Articles_banner_image from "../../../assets/blogimages/Articles_banner_image.svg";
import iconaskbtn from "../../../assets/sharedImages/iconaskbtn.svg";
import left_arrow from "../../../assets/blogimages/Black_arrow.svg"
import realestateicon from "../../../assets/askTheExpertImages/realestateicon.svg";
import insurenceicon from "../../../assets/askTheExpertImages/insurenceicon.svg";
import mortgageicon from "../../../assets/askTheExpertImages/mortgageicon.svg";
import bankingicon from "../../../assets/askTheExpertImages/bankingicon.svg";
import investmenticon from "../../../assets/askTheExpertImages/investmenticon.svg";
import realestateicontwo from "../../../assets/askTheExpertImages/realestateicontwo.svg";
import insurenceicontwo from "../../../assets/askTheExpertImages/insurenceicontwo.svg";
import mortgageicontwo from "../../../assets/askTheExpertImages/mortgageicontwo.svg";
import bankingicontwo from "../../../assets/askTheExpertImages/bankingicontwo.svg";
import investmenticontwo from "../../../assets/askTheExpertImages/investmenticontwo.svg";
import { NEW_URL } from '../../../constants/applicationConstants';
import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom';
import "./blogs.css";

export const getIconOne = (label) => {
    if (label === 'Real Estate')
        return realestateicon
    else if (label === 'Insurance')
        return insurenceicon
    else if (label === 'Mortgage')
        return mortgageicon
    else if (label === 'Investment')
        return investmenticon
    else if (label === 'Banking')
        return bankingicon

    else return ''
}


const BlogsJsx = (props) => {
    const { blogData } = props;
    const { t } = useTranslation();
    const { hash } = useLocation()
    useEffect(() => {
        AOS.init();
        if (hash) {
            document.getElementById(hash.replace('#', '')).scrollIntoView()
        } else {
            //window.scrollTo(0, 0);
        }

    }, [])

    const {        
        hitSearch,
        searchOfferByKeyword   ,
        categoryData,
    onCategoryClick, 
    categoryName    
    } = props;

    const getIconTwo = (label) => {
        if (label === 'Real Estate')
            return realestateicontwo
        else if (label === 'Mortgage')
            return mortgageicontwo
        else if (label === 'Insurance')
            return insurenceicontwo
        else if (label === 'Banking')
            return bankingicontwo
        else if (label === 'Investment')
            return investmenticontwo
        else return ''
    }
    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});
    var trendingsection = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        arrow: true,
        adaptiveHeight: true,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,

                }
            }
        ]


    };

    return (
        <>
            <section className="container-rimib newblogdesign">
                <section className="blogcustommainpage">
                    <section className="herosectionoffer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="blogbannerimage">
                                        <img src={Articles_banner_image} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="blogtext">
                                        <div className="bannerheading">{t("ARTICLECHILD.TOPTRENDING")}</div>
                                        <div className="bannergredientheading">{t("ARTICLECHILD.ARTICLES")}</div>
                                        <div className="bannertext">{t("ARTICLECHILD.BANNERSUBTEXT")}</div>
                                        <div className="bannertext">{t("ARTICLECHILD.READCAPTION")}</div>
                                        <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                                            <div className="askbutton">
                                                <p className="askres">{t("BANKING.TEXT22")}</p>
                                                <img src={iconaskbtn} alt="" />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="offersearch">
                        <div className="container">
                            <div className="customsearchblock">
                                <input type="search" placeholder="" onChange={searchOfferByKeyword}
                                />
                                <button className="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
                            </div>

                        </div>
                    </section>

                    <div className="container">


                        <section className="catblog">
                            <div className="catstart clearfix">
                                <div className={`categoryonesetfirsttest`}>
                                    <div className="choose-main" >
                                    {/* <div class="choose categoryonesettwo">
                                        <div class="iconone "><img src={realestateicon} alt="" /></div>
                                        <div class="icontwo"><img src={realestateicontwo} alt="" /></div>
                                    </div>
                                    <div class="choose categoryonesettwo">
                                        <div class="iconone "><img src={insurenceicon} alt="" /></div>
                                        <div class="icontwo"><img src={insurenceicontwo} alt="" /></div>
                                    </div>
                                    <div class="choose categoryonesettwo">
                                        <div class="iconone "><img src={mortgageicon} alt="" /></div>
                                        <div class="icontwo"><img src={mortgageicontwo} alt="" /></div>
                                    </div>
                                    <div class="choose categoryonesettwo">
                                        <div class="iconone "><img src={bankingicon} alt="" /></div>
                                        <div class="icontwo"><img src={bankingicontwo} alt="" /></div>
                                    </div>
                                    <div class="choose categoryonesettwo">
                                        <div class="iconone "><img src={investmenticon} alt="" /></div>
                                        <div class="icontwo"><img src={investmenticontwo} alt="" /></div>
                                    </div> */}
 {
                        categoryData?.map(catDT => {
                          return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                            <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                            <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                            {/* <h5>{catDT.name}</h5> */}
                          </div>
                        })
                      }


                                        {/* {
                                            categoryData?.map(catDT => {
                                                return <div className={`choose categoryonesettwo ${categoryName === catDT?.name ? 'active' : ''}`} onClick={() => onCategoryClick(catDT?.institutionCategoryId, catDT?.name)}>
                                                    <div className={`iconone ${categoryName === catDT?.name ? 'active' : ''}`} ><img src={getIconOne(catDT.name)} alt={''} /></div>
                                                    <div className="icontwo"><img src={getIconTwo(catDT.name)} alt={''} /></div>
                                              
                                                </div>
                                            })
                                        } */}
                                    </div>

                                    <div className="realblogcatwise">
                                        <h4 className="text-center text-heading">{t("ARTICLECHILD.TITLE")}</h4>
                                        <p className="text-center  text-subheading">{t("ARTICLECHILD.SUBTITLE")}</p>
                                        <div className="realblogcatwise-inner">
                                            {
                                                blogData?.body?.glossory?.length > 0 ?
                                                    blogData?.body?.glossory?.map((blog, index) => {
                                                            return (<div className={`card  ${blog.cats[0]?.name}`}>


                                                                <div className="card-img-top"><img src={blog?.image} alt={''} />
                                                                    <div className="blog-upperstrip">
                                                                        <div className="d-flex upperstrip">
                                                                            <div className="d-flex basicuserdetails">
                                                                                <div className="userimage">
                                                                                    <img src={!blog?.author_image

                                                                                        ? `https://ui-avatars.com/api/?name=${blog?.author}&rounded=true`
                                                                                        : `${NEW_URL}/${blog?.imageUrl}`} alt=''/>
                                                                                </div>
                                                                                <div className="usertext">
                                                                                    <div className="name">{blog?.author}</div>
                                                                                    <div className="date">{blog?.date_publish}</div>
                                                                                </div>
                                                                            </div>
                                                                            <Link to={`/articleDetails/${blog.slug}`} className="blogreadmore">Read More <img src={left_arrow} alt=''/></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-blog-text">
                                                                    <div className="bloginnertag">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                                                                    <div className="bloginnertext">{blog?.title}</div>

                                                                </div>



                                                            </div>)
                                                        
                                                    }) :
                                                    <div className="dropdefault text-center">
                                                        <div className="dropdefaultimage">

                                                        </div>
                                                        <div className="dropdefaulttext">
                                                            <h5>You don't have any Blogs yet. </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        <div className="text-center">
                                            <button href="#" className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                        </div> </div>
                                </div>
                            </div>
                        </section>
                        <section className="featuredblog">
                            <h4 className="text-center  text-heading">{t("ARTICLECHILD.FEATURED")}</h4>
                            <p className="text-center  text-subheading">{t("ARTICLECHILD.FEATUREDSUBTITLE")}</p>
                       
                            <div className="innerfeaturedblog">
                                <Slider {...trendingsection}>
                                    {
                                        blogData?.body?.featured?.map((blog, index) => {
                                            if (index > 0 && index < 4) {
                                                return (
                                                    <div className={`smblog  ${blog.cats[0]?.name}`}>
                                                        <div className="smbloginner">
                                                            <div className="row">
                                                                <div className="col-md-5 col-sm-12">

                                                                    <div className="blog-image"><img src={blog?.image} alt={''} />
                                                                        <button className="bloginnertag">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-7 col-sm-12 ">
                                                                    {
                                                                        <Link to={`/articleDetails/${blog.slug}`}>

                                                                            <div className="bloginnertext">{blog?.title}</div>
                                                                            <div className="d-flex basicuserdetails">
                                                                                <div className="userimage">
                                                                                    <img src={!blog?.author_image

                                                                                        ? `https://ui-avatars.com/api/?name=${blog?.author}&rounded=true`
                                                                                        : `${NEW_URL}/${blog?.imageUrl}`} alt=''/>
                                                                                </div>
                                                                                <div className="usertext">
                                                                                    <div className="name">{blog?.author}</div>
                                                                                    <div className="date">{blog?.date_publish}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="bloginnertextfe">{blog?.short_content}</div>
                                                                            <Link to="{`/articleDetails/${blog.slug}`}" className="blogreadmore">Read More <img src={left_arrow} alt=''/></Link>
                                                                        </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }</Slider>
                            </div>
                            <div className="text-center"> <button href="#" className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                            </div> </section>


                        <section className="latestblog">
                            <div className="row">
                                <h4 className="text-center  text-heading">{t("ARTICLECHILD.LATEST")}</h4>
                                <p className="text-center  text-subheading">{t("ARTICLECHILD.LATESTSUBTITLE")}</p>

                                <div className="row" style={{ padding: "10px 10px 10px 20px !important;" }}>
                                    {
                                        blogData?.body?.main_news?.map((blog, index) => {
                                            if (index > 10 && index < 16) {
                                                return (
                                                    <div className="col smblog">
                                                        <div className={`basicstructure ${blog.cats[0]?.name}`}>
                                                            {
                                                                <Link to={`/articleDetails/${blog.slug}`}>
                                                                    <div className="blogimage"><img src={blog?.image} alt={''} />
                                                                        <button className="bloginnertag mt-3">{blog?.cats.map((cat) => cat.name).join(',')}</button>
                                                                    </div>
                                                                    <div className="bloginnertext mt-2">{blog?.title}</div>
                                                                    <div className="d-flex basicuserdetails">
                                                                        <div className="userimage">
                                                                            <img src={!blog?.author_image

                                                                                ? `https://ui-avatars.com/api/?name=${blog?.author}&rounded=true`
                                                                                : `${NEW_URL}/${blog?.imageUrl}`} alt=''/>
                                                                        </div>
                                                                        <div className="usertext">
                                                                            <div className="name">{blog?.author}</div>
                                                                            <div className="date">{blog?.date_publish}</div>
                                                                        </div>
                                                                    </div>
                                                                    <Link to="{`/articleDetails/${blog.slug}`}" className="blogreadmore">Read More <img src={left_arrow} alt=''/></Link>
                                                                    {/* <div className="bloginnerdate">{blog?.views} views  .  </div> */}
                                                                </Link>
                                                            }
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                </div>
                                <div className="text-center"> <button href="#" className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                </div>
                            </div>
                        </section>

                        <section className="trendingsection">

                            <h4 className="text-center  text-heading">{t("ARTICLECHILD.TRENDING")}</h4>
                            <p className="text-center  text-subheading">
                            {t("ARTICLECHILD.TRENDINGSUBTITLE")} <br />
                            {t("ARTICLECHILD.TRENDINGSUBTITLEONE")}
            </p>
                            <div className="trendingsectioninner">
                                <Slider {...trendingsection}>
                                    {
                                        blogData?.body?.trending?.map((blog, index) => {
                                            if (index > 31 && index < 36) {
                                                return (
                                                    <div className={`sliderblog  ${blog.cats[0]?.name}`}>
                                                        <div className="smblog">

                                                            <div className="trending-blogimage">
                                                                <img src={blog?.image} alt={''} />
                                                                <div className="blog-upperstrip">
                                                                    <div className="d-flex upperstrip">
                                                                        <div className="d-flex basicuserdetails">
                                                                            <div className="userimage">
                                                                                <img src={!blog?.author_image

                                                                                    ? `https://ui-avatars.com/api/?name=${blog?.author}&rounded=true`
                                                                                    : `${NEW_URL}/${blog?.imageUrl}`} alt=''/>
                                                                            </div>
                                                                            <div className="usertext">
                                                                                <div className="name">{blog?.author}</div>
                                                                                <div className="date">{blog?.date_publish}</div>
                                                                            </div>
                                                                        </div>
                                                                        <Link to={`/articleDetails/${blog.slug}`} className="blogreadmore">Read More <img src={left_arrow} alt=''/></Link>
                                                                    </div>
                                                                </div>

                                                                <div className="bloginnertext"> <button className="bloginnertag">{blog?.cats.map((cat) => cat.name).join(',')}</button>{blog?.title}</div>
                                                            </div>


                                                            {/* <div className="bloginnerdate">{blog?.views} views  .  {blog?.date_publish}</div>    */}

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })

                                    }</Slider>
                                <div className="text-center">  <button href="#" className="showallourbesttoo blogbtn-show" style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</button>
                                </div>  </div>
                        </section>

                    </div>




                </section>
            </section>
            <Footer />
        </>
    )
};

export default BlogsJsx;