import { NEW_URL } from '../../../../constants/applicationConstants'; 
import dropbox from "../../../../assets/images/dropbox.svg";

import moment from 'moment';
import { Pagination } from '../../../common/pagination/pagination';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';


const CustomerQnaJsx = (props) => {
  const {t} = useTranslation()
  const {
    data,onPaginationButtonClick,meta,allOffersData,page
  } = props;

  // const [backgroundColor, setBackgroundColor] = useState('#fff');
  // const colors = ['#B8FFB8'];

  // useEffect(() => {
  //   const hasRealEstate = data.some(list => list.askExpertQuestionUserResponse.parentInstitutionCategory.toLowerCase() === 'investment');
  //   if (hasRealEstate) {
  //     setBackgroundColor('#FFD0F8');
  //   } else {
  //     const randomColor = colors[Math.floor(Math.random() * colors.length)];
  //     setBackgroundColor(randomColor);
  //   }
  // }, [data, colors]); 
 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5
  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  ); 
  return (
    <>
      <section className="alldashboard">

        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-sm-12 mrg ">

              {
                data.length ? data?.map(list => {
                  return (
                  <div className={`questioninnerin mb-3 backdeepblue${list.askExpertQuestionUserResponse.parentInstitutionCategory}`}>
                    <div className="d-flex questioninnerinbottom">
                      <div className="expertquestiondetails">
                      <div className="expertuser"><img src={!list?.expert?.imageUrl ? `https://ui-avatars.com/api/?name=${list?.expert?.firstName}+${list?.expert?.lastName}&rounded=true` : `${NEW_URL}/${list?.expert?.imageUrl}`} alt={''} /></div>
                      <div className="expName"><span>{`${list?.expert?.firstName} ${list?.expert?.lastName}`}</span>
                      <div className="questiondate">{moment(list?.askExpertQuestionUserResponse?.approvedAt).format('MM-DD-YYYY hh:mm A')}</div></div>

                      </div>
                      <div className="expertquestiondetailscategory"><span>{list.askExpertQuestionUserResponse.parentInstitutionCategory}</span></div>
                    </div>
                     <h5 className="h5_style questionstext"><span>{t("customerqna.QUESTION")}</span> <b>{list.askExpertQuestionUserResponse.question}</b></h5>

                    <h5 className="h5_style questionstext"><span>{t("customerqna.ANSWER")}</span><b>{list.answer}</b></h5>

                  </div>
                  )
                } ) :

                  <div className="dropdefault text-center">
                    <div className="dropdefaultimage">
                      <img src={dropbox} alt={''} />
                    </div>
                    <div className="dropdefaulttext">
                      <h5>{t("customerqna.DEFAULTTEXT")} </h5>
                    </div>
                  </div>
              }
              {data.length > itemsPerPage &&
            <section className="newpagination"> <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allOffersData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

            /></section>
            }
              {/* {data.length > 0 && <Pagination
                      limit={10}
                      totalCount={meta?.totalCount}
                      onPaginationButtonClick={onPaginationButtonClick}
                    />} */}
            </div>
          </div>
        </div>

      </section>
    </>

  );
};

export default CustomerQnaJsx;
