import React, { useEffect, useState } from 'react';
import './pre_next_pagination.css';

import back from "../../../../src/assets/Instituteppvimages/backArrow.svg";
import next from "../../../../src/assets/Instituteppvimages/nextArrow.svg";


  
                
export const PreNexPagination = (props) =>{
    const {limit,totalCount,onPaginationButtonClick,resetPagi=false} = props;
    const [activePage,setActive] = useState(1);

    const onNextButtonClick = () =>{
        setActive(activePage + 1)
        onPaginationButtonClick(activePage+1)
    }

    const onPreviousButtonClick = () =>{
        setActive(activePage - 1)
        onPaginationButtonClick(activePage-1)
    }

    useEffect(()=>{
        setActive(1);
    },[totalCount])

    
    useEffect(()=>{
        resetPagi && setActive(1);
    },[resetPagi])
    // activePage >= (Math.ceil(totalCount/limit))
    return(
        <div className='pagination-section'>
            {
            activePage === 1?<img src={back} alt={''}   className="opacity-25"/>:  <img src={back} alt={''}   onClick={()=>onPreviousButtonClick()}/> }
            { activePage >= (Math.ceil(totalCount/limit))?<img src={next} alt={''} className="opacity-25" /> : <img src={next} alt={''} onClick={()=>onNextButtonClick()} /> }
            </div>
         
    )
}