import React from 'react'
import { useEffect, useState } from 'react';

import { Pagination } from '../../../common/pagination/pagination';


import { Footer } from '../../../common/footer/footer';
import { NEW_URL } from '../../../../constants/applicationConstants.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import moment from 'moment';
import Sidebar from "../sidebar/sidebar.js";


export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData, editOn,
    onInputChange,    
    onTouchHandler,
    onDeleteButtonClick,
    onSubmitButtonClick,
    onPaginationButtonClick,
    onEditButtonClick,
    formSubmitButtonDisable,
    expertData,    
    profileImagePreviewUrl, onFileChange, onNextButtonClick, imgURL,meta
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  
  const [searchLoading, setSearchLoading] = useState(false);  
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };
  const typeData = [
    {
      "label": "Yes",
      "value": "true"
    },
    {
      "label": "No",
      "value": "false"
    }
  ]

  const planDataSet = [
    {
      "label": "Yes",
      "value": "true"
    },
    {
      "label": "No",
      "value": "false"
    }
  ]



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">

              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h3>Sponsors Adds List</h3>

                {/* <div className="myexpertformarea searcharea searchareaquestion searchareaquestionadmin martop40">
                  <div className="input-container form-group">
                    <select
                      className="input form-control form-select"
                      placeholder=" "
                      name="category"
                      onChange={onInputChange}
                      onBlur={onTouchHandler}
                    >
                      <option value="">All</option>
                      {categoryData?.map((item) => {
                        return (
                          <>
                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>
                    <div className="placeholder">Category</div>
                  </div>
                  <div className="input-container form-group">
                    <select
                      className="input form-control form-select"
                      placeholder=" "
                      name="subCategory"
                      value={formData.subCategory}
                      onChange={onInputChange}
                      onBlur={onTouchHandler}
                    >
                      <option value="">All</option>
                      {subCategoryData?.map((item, i) => {
                        return (
                          <>
                            <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                          </>
                        )
                      })}
                    </select>
                    <div className="placeholder">Sub Category</div>
                  </div>

                  <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
                </div> */}
              </div>
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Submit Category</h2>
                {
                  profileImagePreviewUrl ?
                    <img
                      src={profileImagePreviewUrl}
                      height="170px"
                      width="250px"
                      alt="edit profile"
                    /> :
                    editOn ?
                      <img
                        src={`${NEW_URL}/${imgURL}`}
                        height="170px"
                        width="250px"
                        alt="edit profile"
                      />
                      : "Your uploaded picture appears here"

                }

                <div className="uploaddetails">
                  {/* <input type="file" onChange={onFileChange}></input> */}
                  <div className="button_outer">
                    <div className="btn_upload">
                      <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                      <label for="upload_file">Choose Picture *</label>
                    </div>
                  </div>
                  <button type="button" className="ctaonebutton" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Picture</button>
                  {/* <button type="button" className="ctaonebutton" onClick={() => changeLogoState(true)}>Change Logo</button> */}
                </div>
              </div>
              <div className="dashboardrightpanel martop40">
                <div className="row">
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="name"
                        maxLength={20}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.name}
                      />
                      <div className="placeholder">Title *</div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="active"
                        value={formData.planFor}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                                            <option value="">All</option>

                        {typeData?.map((item, i) => {
                          return (
                            <>
                              <option value={item.value} selected={item.value === formData.active}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Active</div>
                    </div>
                  </div> */}
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="category"
                        value={formData.category}

                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                        <option value="">All</option>
                        {categoryData?.map((item) => {
                          return (
                            <>
                              <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Category *</div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="isFeatured"
                        value={formData.isFeatured}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                        <option value="">Select</option>

                        {planDataSet?.map((item) => {
                          <option></option>
                          return (
                            <>
                              <option value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Is Featured *</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="description"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        maxLength={200}

                        value={formData.description}
                      />
                      <div className="placeholder">Description *</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="externalUrl"
                        
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.externalUrl}
                      />
                      <div className="placeholder">External Url *</div>
                    </div>
                  </div>

                  <div className="input-container form-group"><button type="submit" className="ctaonebutton" disabled={formSubmitButtonDisable} onClick={() => onSubmitButtonClick()}>Submit</button></div>
                </div>
              </div>
              {!editOn &&
                <div className="expertsection martop30 mb-5">


                  {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  <div className="searchdetails searchdetalsen">
                    <table className="searchdetailsdb">
                      <tr>
                        <th>Title</th>
                        {/* <th>Is Active</th> */}
                        <th>Is Featured</th>
                        <th>Created At </th>
                        <th>Action</th>
                      </tr>
                      {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                      {
                        expertData.length > 0 ?
                          expertData?.map(data => {
                            return <tr>


                              <td>{data?.title
                              }</td>
                              {/* <td>                                                                    <input type="checkbox" checked={data.isActive} />
                              </td> */}
                              <td>    
                               {/* <input type="checkbox" checked={data.isFeatured} /> */}
                               {data.isFeatured ? "Active" : "In-Active"}
                              </td>
                              <td>{moment(data?.createdAt).format('DD-MM-YYYY')}</td>
                              <td>  <button className="btn btn-success" onClick={() => onEditButtonClick(data?.sponsorAdId)}>Edit</button>
                                <button className="btn btn-danger" onClick={() => onDeleteButtonClick(data?.sponsorAdId)} >delete</button></td>
                            </tr>
                          }) :
                          <div className="opacity0">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                      }
                    </table>
                  </div>
                  {/* {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  {
                    expertData.length > 0 ?
                      expertData?.map(data => {
                        return <div className="expertinner">
                          <div className="expertuser">

                            <p>Question : {data?.question}</p>
                           Created At : {moment(data?.createdAt).format('DD-MM-YYYY')}
                          </div>
                          <button onClick={() => onEditButtonClick(data?.askExpertQuestionId)}>Edit</button>
                          <button onClick={() => onDeleteButtonClick(data?.askExpertQuestionId)} >delete</button>


                        </div>
                      }) :
                      <div className="opacity1">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  } */}


                  {expertData?.length > 0 && <Pagination
                    limit={10}
                    totalCount={meta?.totalCount}
                    onPaginationButtonClick={onPaginationButtonClick}
                  />}
                </div>
              }
            </div>
          </div>

        </div>



      </section><Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;