import React from 'react'
import axios from 'axios';
import { Component } from 'react';
import { BLOG_URL, BLOG_URL_TOKEN } from '../../../../constants/applicationConstants.js';
import GlobalMainSearchArticlesJsx from './globalMainSearchArticles.jsx'
import { Loader } from '../../../common/loader/loader.jsx';


export default class GlobalMainSearchArticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articleData: null,
            isProcessing: false, 
            page: 1,
            input:"",
        }
    }
    

    getQueryParams() {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        return {
            paramName: params.get('search'), // Change 'paramName' to your actual query parameter name
            catName: params.get('category') ?? null, // Change 'paramName' to your actual query parameter name
        }; 
    }

    hitSearch  = () => {
        this.state.page = 1;
        const { paramName } = this.getQueryParams();  
        if(this.state.input==""){
            this.state.input = paramName;
        } 
            
            this.props.history.replace({ pathname: "searchArticles", search:`search=${this.state.input}`}); 
        
         
        this.onSubmitButtonClick(); 
    }

    onSubmitButtonClick = ( ) => {
        const { paramName , catName } = this.getQueryParams();
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('paged', this.state.page)
        data.append('search',  this.state.input)
        if(catName)
        {
            data.append('cat_slug',  catName)
        }
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ articleData: res.data, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false });
            })
    }

    componentDidMount = () => {
        this.onSubmitButtonClick();
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.onSubmitButtonClick());
    }

    
    searchOfferByKeyword = (e) => { 
        this.state.input =  e.target.value; 
    }

    render() {
        return (
            <div>
                <GlobalMainSearchArticlesJsx
                    articleData={this.state.articleData} 
                    onSubmitButtonClick={this.onSubmitButtonClick}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    searchOfferByKeyword={this.searchOfferByKeyword}
                    hitSearch={this.hitSearch}
                    input={this.input}
                />

                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}