import React, { useState, useRef } from 'react'
import InputMask from 'react-input-mask';
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SECRET_KEY } from "../../../constants/applicationConstants";

export const GetInTouchSection = (props) => {
    const {
        profileData,
        onInputChangeHandler,
        onTouchHandler,
        userData,
        formError,
        onSendMessageClick,
        formSubmitButtonDisable
    } = props;
    const [recapchaVerified, setRecaptchaVerified] = useState(false);
    const onChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        }
    }

    let flowName = window.location.href.includes('expertPpv')
    const recaptchaRef = useRef();

    return <section className="expertformppi">
        <div className="container">
            <h2>GET IN TOUCH</h2>
            <div className="expertformppiinner">
                <div className="expertformppiinnerdis">
                    <div className="mb-4 input-container form-group">
                        <input type="text" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='name' value={userData.name} className="input form-control" placeholder=" " maxLength={60} />
                        <label className="placeholder">Name*</label>
                        <span className="text-danger">
                            {formError['name'] &&
                                (formError['name'] === "req"
                                    ? ''
                                    : 'Enter a valid Name')}
                        </span>
                    </div>
                    <div className="input-container form-group">
                        <InputMask mask={'(999) 999 9999'} value={userData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                            {(inputProps) => <input {...inputProps} type="tel" className="input form-control" placeholder=" " disableUnderline />}
                        </InputMask>
                        <div className="placeholder">Phone Number*</div>
                        <span className="text-danger">
                            {formError['phone'] &&
                                (formError['phone'] === "req"
                                    ? ''
                                    : 'Enter a valid Phone')}
                        </span>
                    </div>

                </div>
                <div className="expertformppiinnerdis">
                    <div className="mb-4 input-container form-group">
                        <input type="text" name='email' onChange={onInputChangeHandler} onBlur={onTouchHandler} value={userData.email} className="input form-control" placeholder=" " maxLength={60} />
                        <div className="placeholder">Email Address*</div>
                        <span className="text-danger">
                            {formError['email'] &&
                                (formError['email'] === "req"
                                    ? ''
                                    : 'Enter a valid Email')}
                        </span>
                    </div>
                    <div className="input-container form-group">
                        <select className="input form-control form-select" onChange={onInputChangeHandler} name='phoneNumber' placeholder=" ">
                            <option value=''>Select</option>
                            {
                                flowName ?
                                    profileData?.expertSpecializations?.map(cat => {
                                        return <option value={cat?.instituteCategoryId}>{cat?.parentInstitutionCategory}</option>
                                    })
                                    :
                                    profileData?.instituteCategories?.map(cat => {
                                        return <option value={cat.instituteCategoryId}>{cat.institutionCategory?.name}</option>
                                    })
                            }
                        </select>
                        <div className="placeholder">Category*</div>
                    </div>
                </div>
                <div className="expertformppiinnerdis">
                    <div className="input-container form-group">
                        <textarea className="input form-control textareaform" onChange={onInputChangeHandler} onBlur={onTouchHandler} name='message' value={userData.message} placeholder=" " maxLength={250}></textarea>
                        <div className="placeholder">Message*</div>
                        <span className="text-danger">
                            {formError['message'] &&
                                (formError['message'] === "req"
                                    ? ''
                                    : 'Enter a valid Message')}
                        </span>
                    </div>
                    <div className="recaptcha_css">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={CAPTCHA_SECRET_KEY}
                            onChange={onChange}
                            size='compact'
                        />
                    </div>
                </div>
                <div className="expertformppiinnerdis">
                    <button type="submit" disabled={formSubmitButtonDisable || !recapchaVerified } className="ctatwobutton" onClick={() => onSendMessageClick()}>Send Message</button>

                </div>
            </div>
        </div>
    </section>

}