import React from 'react';
import Sidebar from "../../sidebar/sidebar";
import { Footer } from '../../../../common/footer/footer';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export const FinalAsktheExpertScreenJSX = (props) => {
const {t} = useTranslation()
    const { onHomebuttonClick } = props;
    return (
        <>
            <section className="entiredashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlinkpop martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9">
                            <div style={{ marginBottom: '50px' }}>

                                <div className="confy">
                                    <div className="confirmtion">
                                        <h2 className="text-center h2_style">{t("customerfinalasktheexpertscreen.QUESTION")}</h2>
                                        <h5 className="text-center h5_style fst-italic">{t("customerfinalasktheexpertscreen.CONSCIOUSFINANCE")} </h5>
                                        <h5 className="text-center h5_style fst-italic">{t("customerfinalasktheexpertscreen.YOURQUESTION")}</h5>
                                        <h5 className="text-center h5_style fst-italic">{t("customerfinalasktheexpertscreen.EXPECTTAILORED")}</h5>
                                        <div className="confirmbutton mt-4 d-flex"><Link to={'/customerDashboard'} className="ctatwobutton">{t("customerfinalasktheexpertscreen.HOMEBUTTON")}</Link>
                                            <Link to={'/customerAskTheExpert'} className="ctaonebutton">{t("customerfinalasktheexpertscreen.ASKANOTHERQUERYBUTTON")}</Link></div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}