import React from 'react'
import '../../../../assets/stylesheet/desktop.css';
import '../../../../assets/stylesheet/responsive.css';
import 'bootstrap/dist/css/bootstrap.css';
import leftarrow from "../../../../assets/images/left-arrow.svg";

import Subtract from "../../../../assets/images/Subtract.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

const ViewSpecialInfoJSX = (props) => {
    const {
        specialInfoDetails,changeHideState,onDeleteButtonClick,
        editParticularSpecialization
    } = props;

    return (
        <>
            {specialInfoDetails?.length &&

                <section className="listofoffermainsection">
                    <>

                        <div className="container">
                            <div className="leftsightbackdetails listofofferleft padtop60">
                                <div className="back_btn"><img src={leftarrow} alt={''} />
                                </div>
                                <div className="plaindetailsleft">
                                    <h1 className="h2_style font-weight-bold">Specialization Information</h1>
                                    <h5 className="h5_style">Our platform allows you to register as an expert in multiple specializations. </h5>
                                    <h5 className="h5_style">To add additional areas of expertise, simply click the "Add Another Specialization" button.</h5>
                                </div>
                            </div>
                            {specialInfoDetails.map((item, i) => (

                                <div className="specialinfoinner mt-5 mb-5">

                                    <div className="col-lg-12">

                                        <div className="form-field">
                                            <button type="button" className="myadbtn absoluteup" onClick={() => onDeleteButtonClick(item?.expertSpecializationId)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="9.99935" cy="5.83325" rx="5.83333" ry="2.5" stroke="white" stroke-linecap="round"/>
<path d="M5.83268 14.9999L4.16602 5.83325L6.66602 8.33325H13.3327L15.8327 5.83325L14.166 14.9999L11.666 16.6666H8.33268L5.83268 14.9999Z" fill="white"/>
<path d="M4.16602 5.83325L5.82953 14.9826C5.83158 14.9939 5.83703 15.0043 5.84516 15.0124V15.0124C8.13946 17.3067 11.8592 17.3067 14.1535 15.0124V15.0124C14.1617 15.0043 14.1671 14.9939 14.1692 14.9826L15.8327 5.83325" stroke="white" stroke-linecap="round"/>
</svg>
</button>
                                            <button type="button" className="myadbtn absolutedown"  onClick={() => editParticularSpecialization(item?.expertSpecializationId)}><img src={Subtract} alt={''} /></button>
                                            <div className="disgrid grid-2">
                                                <div className="form-fieldinner"><label>Name of Institution</label><div className="fieldans">{item?.institute?.name}</div></div>
                                                <div className="form-fieldinner"><label>Category of Specialization</label><div className="fieldans">{item?.parentInstitutionCategory}</div></div>
                                            </div>
                                            <div className="form-fieldinner mt-4"><label>Sub-Categories</label><div className="fieldans d-flex">
                                                <span>{item?.subInstitutionCategory?.name}</span>
                                                
                                            </div></div>
                                            <hr></hr>
                                            <div className="disgrid grid-2">
                                                <div className="form-fieldinner"><label>Business Name</label><div className="fieldans">{item?.businessName}</div></div>
                                                <div className="form-fieldinner"><label>Business License No.</label><div className="fieldans">{item?.businessLicenseNumber}</div></div>
                                                <div className="form-fieldinner"><label>Business Phone No.</label><div className="fieldans">{item?.businessPhoneNumber}</div></div>
                                                <div className="form-fieldinner"><label>Business E-mail Address</label><div className="fieldans">{item?.businessEmail}</div></div>
                                                <div className="form-fieldinner"><label>Business Website</label><div className="fieldans">{item?.businessWebsite}</div></div>
                                                <div className="form-fieldinner"><label>Years of Experience</label><div className="fieldans">{item?.experienceInYears}</div></div>
                                            </div>
                                            <hr></hr>
                                            <div className="form-fieldinner"><label>Street Address</label><div className="fieldans">{item?.address}</div></div>
                                            <div className="disgrid grid-4">
                                                <div className="form-fieldinner"><label>City</label><div className="fieldans">{item?.city}</div></div>
                                                <div className="form-fieldinner"><label>Province </label><div className="fieldans">{item?.state?.name}</div></div>
                                                <div className="form-fieldinner"><label>Country</label><div className="fieldans">{item?.country?.name}</div></div>
                                                <div className="form-fieldinner"><label>Postal Code</label><div className="fieldans">{item?.postalCode}</div></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                            <button type="submit" className="ctaonebutton mt-5 mb-5" onClick={() => changeHideState(true)}>Add Another Specialization</button>


                        </div>
                    </>

                </section>
            }


        </>


    );
};

export default ViewSpecialInfoJSX;
