import React, { Component } from "react";
import NewPasswordPage from "./otpLogin.jsx"
import axios from 'axios';
import { baseURL } from "../../../constants/applicationConstants.js";
import { Loader } from "../loader/loader.jsx";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class OtpLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {

                newpassword: "",
            },
            formError: {},
            formSubmitButtonDisable: true,
            isLoading: false,
            timerStart: true,            
        };
    }


    onTimerComplete = () => {
        this.setState({ timerStart: false })
    }





    onResendOTPClick = () => {
        const expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
        let flowType = localStorage.getItem("flowType")
        let caseName = localStorage.getItem("requestType")

        let data = sessionStorage.getItem("resendOtpData")
        let payload = data && JSON.parse(data)

        this.setState({ isLoading: true });
        axios.post(`${baseURL}/${flowType === "expert" ? "expert" : flowType === "institute" ? "institute" : "customer"}/login`, payload).then((res) => {

            toast.success("Login OTP sent to you Email successfully");
            sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));

            this.setState({ isLoading: false });

        }).catch((res) => {
            toast.error(res?.response.data.error.message);
            this.setState({ isLoading: false });
        })
    }
    onInputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value) {
            if (!this.inputRegexValidation(name, value)) {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "regx",
                        },

                    },
                    () => this.isFromSubmissionBlock()
                );
            } else {
                this.setState(
                    {
                        formData: {
                            ...this.state.formData,
                            [name]: value,
                        },
                        formError: {
                            ...this.state.formError,
                            [name]: "",
                        },
                    },
                    () => this.isFromSubmissionBlock()
                );
            }
        } else {
            this.setState(
                {
                    formData: {
                        ...this.state.formData,
                        [name]: value,
                    },
                    formError: {
                        ...this.state.formError,
                        [name]: "",
                    },
                },
                () => this.isFromSubmissionBlock()
            );
        }
    };
    success = (e) => {
        toast.success("Congrats ! Expert created!");
        let oldToken = localStorage.getItem("instituteExpertToken")
        sessionStorage.setItem("token", oldToken);
        setTimeout(() => {
            this.props.history.push("/dashboard");
            sessionStorage.removeItem("expertId");
            sessionStorage.removeItem("expertData");
            sessionStorage.removeItem("description");

            localStorage.setItem('flowName', 'institute')
            localStorage.setItem('flowType', 'institute')
            localStorage.removeItem("instituteExpert");
            localStorage.removeItem("instituteExpertToken");

            // window.location.reload()
        }, 2000)
        // swal({
        //   title: "Congrats ! Expert created!",
        //   icon: "Success",
        //   buttons: [
        //     'Keep Logged in!',
        //     'Go to dashboard'
        //   ],
        //   dangerMode: true,
        // }).then(function (isConfirm) {
        //   if (isConfirm) {

        //     // sessionStorage.removeItem("expertId");
        //     // sessionStorage.removeItem("expertData");
        //     // localStorage.setItem('flowName', 'institute')
        //     // localStorage.setItem('flowType', 'institute')
        //     // localStorage.removeItem("instituteExpert");

        //     setTimeout(() => {

        //       window.location.reload()
        //     }, 2000)

        //   } else {
        //     //   swal("Cancelled", "Your imaginary file is safe :)", "error");
        //   }
        // })

    }

    onTouchHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value === "") {
            this.setState({
                formError: {
                    ...this.state.formError,
                    [name]: "req",
                },
            });
        } else {
            if (!this.inputRegexValidation(name, value)) {
                this.setState({
                    formError: {
                        ...this.state.formError,
                        [name]: "regx",
                    },
                });
            }
        }
    };

    inputRegexValidation = (name, value) => {
        let passwordRegex =
            /[^\n]+/;
        if (name === "currentpassword") {
            if (!passwordRegex.test(value)) {
                return false;
            } else {
                return true;
            }
        } else {
            if (!passwordRegex.test(value)) {
                return false;
            } else {
                return true;
            }
        }
    };

    isFromSubmissionBlock = () => {
        let { newpassword } = this.state.formData;
        if ((newpassword)) {
            if (
                !this.inputRegexValidation("newpassword", newpassword)
            ) {
                this.setState({
                    formSubmitButtonDisable: true,
                });
            }
        } else {
            this.setState({
                formSubmitButtonDisable: true,
            });
        }
    };
    formSubmitHandler = (e) => {


        e.preventDefault();
        let flowType = localStorage.getItem("flowType")
        let caseName = localStorage.getItem("requestType")

        const { newpassword, verifypassword } = this.state.formData;
        const email = JSON.parse(sessionStorage.getItem('email'));
        const token = JSON.parse(sessionStorage.getItem('otpValidateToken'));
        const resendOtpData = JSON.parse(sessionStorage.getItem('resendOtpData'));

        let data = {
            "otp": newpassword,
            "tokenValidate": token,
        }

        this.setState({
            isLoading: true
        })
        let expertId = sessionStorage.getItem("expertId") && JSON.parse(sessionStorage.getItem("expertId"))
        if (expertId || flowType === "expert") {
            delete data.category
        }

        if (flowType === "customer") {
            data.email = resendOtpData?.email
        }

        let fullUrl = flowType === "customer" ? `${baseURL}/customer/loginOtpValidation` : `${baseURL}/${flowType === "expert" ? "expert" : flowType === "customer" ? "customer" : "institute"}/otpValidation`;

        axios.post(fullUrl, data, {
            headers: {
                token: token
            }
        }).then(res => {
            if (res.data.success) {
                sessionStorage.removeItem("otpValidateToken")
                this.setState({
                    isLoading: false
                })
                if (flowType === "customer") {
                    sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
                    sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.customerId));
                    sessionStorage.setItem("customerId", JSON.stringify(res.data?.data?.customerId));
                    sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
                    localStorage.setItem('flowName', 'customer')
                    localStorage.setItem('flowType', 'customer')
                    sessionStorage.setItem("loginAs", 'customer')
                    sessionStorage.removeItem("resendOtpData")

                    toast.success("You have logged-in successfully");
                    setTimeout(() => {
                        this.props.history.push("/customerDashBoard");
                        window.location.reload()
                    }, 2000)
                } else if (flowType === "expert") {
                    sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
                    sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.expertId));
                    sessionStorage.setItem("expertId", JSON.stringify(res.data?.data?.expertId));
                    sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
                    sessionStorage.setItem("loginAs", 'expert')
                    localStorage.setItem('flowName', 'expert')
                    localStorage.setItem('flowType', 'expert')
                    sessionStorage.removeItem("resendOtpData")

                    toast.success("You have logged-in successfully");
                    if (!res.data?.data?.isPermanentPasswordSet) {
                        setTimeout(() => {
                            this.props.history.push("/saveNewPassword");
                            // window.location.reload()
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            this.props.history.push("/expertDashboardHome");
                            // window.location.reload()
                        }, 2000)

                    }
                } else {
                    sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
                    sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
                    sessionStorage.setItem("isLoggedIn", true);
                    sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.instituteId));
                    sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
                    sessionStorage.setItem("loginAs", 'institute')
                    localStorage.setItem('flowName', 'institute')
                    localStorage.setItem('flowType', 'institute')
                    sessionStorage.removeItem("resendOtpData")

                    toast.success("You have logged-in successfully");


                    if (!res.data?.data?.isPermanentPasswordSet) {
                        setTimeout(() => {
                            this.props.history.push("/saveNewPassword");
                            window.location.reload()
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            this.props.history.push("/dashboard");
                            window.location.reload()
                        }, 2000)

                    }
                }

                // window.location.reload()
            } else {

                toast.error(res?.data?.error?.message ?? "something went wrong");

                this.setState({
                    isLoading: false
                })
            }
        }).catch(err => {

            toast.error(err?.response?.data?.error?.message ?? "something went wrong");

            this.setState({
                isLoading: false
            })
        })
    };



    render() {

        return (
            <>
                <NewPasswordPage
                    formData={this.state.formData}
                    onInputChangeHandler={this.onInputChangeHandler}
                    onTouchHandler={this.onTouchHandler}
                    formSubmitButtonDisable={this.state.formSubmitButtonDisable}
                    onResendOTPClick={this.onResendOTPClick}
                    onTimerComplete={this.onTimerComplete}

                    formError={this.state.formError}
                    formSubmitHandler={this.formSubmitHandler}
                    timerStart={this.state.timerStart}

                />
                {this.state.isLoading && <Loader />}

            </>
        );
    }
}

export default OtpLogin;


