import React, { useState } from 'react'
import { Footer } from '../../common/footer/footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import "../realEstate/realEstate.css";
import "../realEstate/realEstateresponsive.css";

import realEaskicon from "../../../assets/sharedImages/iconaskbtn.svg"
import { Trans, useTranslation } from 'react-i18next';
import toprealEttextline from "../../../assets/sharedImages/toprealEttextline.png";
import RealEtext from "../../../assets/realEstateimages/RealEtext.png";
import expertuiserborder from "../../../assets/realEstateimages/expertuiserborder.png";
import dropbox from "../../../assets/images/dropbox.svg";
import Realestatebanner from "../../../assets/realEstateimages/RealEstateimage.svg";
import CommonOffer from "./instituteOffer";
import DetailedProductsJsx from '../../publicProfileView/ppvCommon/detailedProduct/detailedProduct.jsx';



const RealEstateJsx = (props) => {
  const { t } = useTranslation();
  const {
    offerData,
    productData,
    expertData,
    instituteData,
    blogData,
    onInstituteClick
  } = props;
  const [visibleBlogs, setVisibleBlogs] = useState(4);

  const handleViewAll = () => {
    setVisibleBlogs(blogData?.body?.length);
  };

  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () =>{
    setProductDetails({})
  }

  var institutelogo = {
    infinite: false,
    rows: 2,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    adaptiveHeight: true,
    arrows: true,

    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                rows: 2,
                infinite: false,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                dots: true,

            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                dots: true,

            }
        },
        {
            breakpoint: 320,
            settings: {
                dots: true,

            }
        }
    ]


};

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '20px',
    arrows: true,
    //  initialSlide:2    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState(null);

  // {t("PRINT.TITLE")}


 
  var Experts = {
    dots: false,
    arrows:false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <>
<section className="container-rimib newrealestateui">
      <section className="categorybanner">
        {/* <img src={Realestatebanner}/> */}
        <div className="container makethecontainer">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="bn-image">
                  <img src={Realestatebanner}  alt=''/>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
            <div className="categorybannertext">
              <div className="bannerfont borderbottom ">
                <h2 >{t("REALESTATE.TEXT1")}</h2>
                <h6>
                <Trans i18nKey="REALESTATE.HEADING_TEXT_BANNER" >
                  
                </Trans>
                </h6>
                <p>{t("REALESTATE.TEXT4")}<span>{t("REALESTATE.TEXTTWO4")}</span>{t("REALESTATE.TEXTTHREE4")}
                  <span>{t("REALESTATE.TEXTFOUR4")}</span> {t("REALESTATE.TEXTFIVE4")}</p>
                <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                  <div className="askbutton">
                    <p className="askres">{t("REALESTATE.TEXT25")}</p>
                    <img src={realEaskicon} alt="" />
                  </div>
                </Link>
                <p className="mb-0 mt-3 realestateherotext">{t("REALESTATE.TEXTSIX5")}</p>
              </div>
            </div>
            </div>
          </div>
           
        
        </div>
      </section>

      <section className="categorysectionone">
        <div className="container">

          <div className="letest-inner">
            <h2>{t("REALESTATE.TEXT5")}</h2>
            <h6>{t("REALESTATE.TEXT6")} <span>{t("REALESTATE.TEXTONE6")}</span>               {t("REALESTATE.TEXT7")} <span>{t("REALESTATE.TEXTONE7")}</span> {t("REALESTATE.TEXTTWO7")}
              <br />{t("REALESTATE.TEXTTHREE7")}<span>{t("REALESTATE.TEXTFOUR7")}</span>{t("REALESTATE.TEXTFIVE7")}</h6>
          </div>
          <div className="row offersss">
            {offerData?.length > 0 ? (
              <Slider {...settings}>
                {offerData.map((offer) => {
                  return (
                    <div key={offer.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <CommonOffer offer={offer} classnameattr={`category-${offer?.institutionCategoryId}`} />
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
              <Link to={'/offers'}  className="showallourbesttoo " style={{ textDecoration: "none" }} >{t("COMMON.SHOWALL")}</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="categorysectiontwo">
        <div className="container">
          <div className="TopRealEstateProducts-inner">
            <h2>{t("REALESTATE.TEXT8")}</h2>
            <h6>{t("REALESTATE.TEXT9")}<span> {t("REALESTATE.TEXTONE9")}</span>{t("REALESTATE.TEXTTOO9")}
      
              <br /> {t("REALESTATE.TEXTTWO9")}</h6>
          
         </div>
         
          <div className="row  offersss">
            {productData?.length > 0 ? (
              <Slider {...settings}>
                {productData.map((item,index) => {
                  return (
                    <div key={item.id} className="col-lg-12 col-md-6 col-sm-6 col-xs-12 ">
                      <div className="list-inner">
                        <div className="list-image">
                        <img src={`${NEW_URL}/${item?.imageUrl}`} alt="" />
                        </div>
                        <div className="coliin">
                        <div className="colinstititeinnername text-center">{item?.productName}</div>
                        <div className="posbuttontf d-flex">
                         
                          <button
                                    className="applynowbuttoncomm "
                                    onClick={() => {setProductDetails({ data: item, category: item?.parentInstitutionCategory, subCategory: item?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' })}}>
                            {t("REALESTATE.TEXT30")}
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt="" />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !!</h5>
                </div>
              </div>
            )}
            <div className="latestbutton">
            <Link to={'/products'} className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</Link>
            </div>

          
          </div>
        </div>
      </section>

      

      <section className="categorysectionthree">
        <div className="container">
          <div className="row" >
          <div className="col-lg-7 col-12 p-0 ">
              <div className="institutelogosection">
                
                  {instituteData?.length > 0 &&
                  <Slider {...institutelogo}>
                  {
                    instituteData.map(institute => {
                      return<div className="psi">
                        <div
                          className="colinstititeinner"
                          onClick={() => onInstituteClick(institute)}
                        >
                          <div className="colinstititeinnerimage text-center">
                            <img
                              src={!institute?.imageUrl ? `https://ui-avatars.com/api/?name=${institute?.name}&rounded=false` : `${NEW_URL}/${institute?.imageUrl}`}
                              alt={institute?.name || "Institute Image"}
                            />
                          </div>
                          <div className="colinstititeinnername text-center institute-text">
                            {institute?.name}
                          </div>
                        </div>
                      </div>
                     })
                    }
                </Slider>
            }


  

              </div>
              <div className="showallbtn text-center ">
                <a
                  href="/globalMainSearchInstitutions"
                  className="showallourbesttoo "
                  style={{ textDecoration: "none" }}
                >
                  Show All
  </a>
              </div>

            </div>
            <div className="col-lg-5 col-12 p-0">
              <div className="toprealErighttext">
                <h2>{t("REALESTATE.TOP")} <span><img className="toprealEtext" src={RealEtext} alt="" /></span> {t("REALESTATE.INSTITUTE")} <span className="canada">{t("REALESTATE.CANADA")}</span></h2>
                <img className="toprealEborder" src={toprealEttextline} alt="" />
                <p>{t("REALESTATE.ARE_YOU_LOOKIN")} <br/> <br/> {t("REALESTATE.CHECK_YOUR")} </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      


      <section className="categorysectionfour bestdeals Experts">
        <div className="container-fluid">
          <h2 className="text-center expertheading">{t("REALESTATE.TEXT17")}</h2>
          <h5 className="text-center  h5_style expertsub1">{t("REALESTATE.TEXTONE18")}<br />
          </h5>
          <p className="expertsub2 text-center">{t("REALESTATE.TEXTTWO18")}</p>
         <div className=" p bestdealsbox bestexperts">
            {expertData?.length > 0 ?
              <Slider {...Experts}>
                {expertData?.map(data => {
                  return <div>
                     <Link to={`/expertPpv/${data?.slugUrl}`} target="_blank">
                    <div className="mt-5">                                       
                      <div className="Expertsitmes" >
                        <div className="Expertstop"><img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data.firstName}+${data.lastName}&rounded=false` : `${NEW_URL}/${data?.imageUrl}`} alt={' '} />
                        </div>
                        <div className="Expertsbottom Expertstext text-center">
                          <div className="Expertstext"><h5>{`${data.firstName} ${data.lastName}`}</h5> <img src={expertuiserborder} alt="" /></div>
                          <p>{data?.description}</p>
                          <div className="Expertsbutton">
                            <div className="Expertsbuttoni">
                              <a href={`/expertPpv/${data?.slugUrl}#contact`} className="messagemebuttoncomm">{t("REALESTATE.TEXT27")}</a>                              
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      </Link>
                    </div>
                  
                })}
              </Slider>
              :
              <div className="dropdefault text-center">
                <div className="dropdefaultimage">
                  <img src={dropbox} alt={' '} />
                </div>
                <div className="dropdefaulttext">
                  <h5 className="opacity0">No Record Found !! </h5>
                </div>
              </div>

            }
            <div className="latestbutton  marginbottom">
              <a href="/globalMainSearchExpert" className="showallourbesttoo " style={{ textDecoration: "none" }}>{t("COMMON.SHOWALL")}</a>
            </div>
          </div>

        </div>
      </section>
      <section className="categorysectionfive">
        <div className="container-fluid">
          <div className="realEquestion">
            <div className="realEquestiontext">
              <h4>{t("REALESTATE.TEXT19")}</h4>
              <h2><span>{t("REALESTATE.TEXTTWO19")}</span> {t("REALESTATE.TEXTTHREE19")}<span>{t("REALESTATE.TEXTFOUR19")}</span>{t("REALESTATE.TEXTFIVE19")}</h2>
              <h3 className="">{t("REALESTATE.TEXT20")}</h3>
              <Link to={'/AskTheExpert'} style={{ textDecoration: "none" }}>
                <div className="realEbuttonmain realEmargin askbuttoncomm">
                  <p className="realEbutton">{t("REALESTATE.TEXT25")}</p>
                  <img src={realEaskicon} alt="" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="categorysectionsix blogdetailsexpertppv">
        <div className="container-fluid">
          <div className="realEbloginner">
            <h2 className="text-center">{t("REALESTATE.TEXT21")}</h2>
            <p className="text-center realEblogtext">{t("REALESTATE.TEXT22")}
            </p>
          
            <p> {t("REALESTATE.TEXT23")} {t("REALESTATE.TEXTONE23")}</p>
          </div>
          <div className="row realEbloglog">
            {
              blogData?.body?.length > 0 ?
                blogData?.body?.slice(0, visibleBlogs).map(blog => {
                  return <div className="col-lg-6 col-md-6 col-sm-6 minheightrealestate">
                    <Link to={`/articleDetails/${blog.slug}`}>
                      <div className="bloginner  blogsmain text-center">
                        <div className="bloginnerimage "><img className="blogsimgimg" src={blog?.image} alt={' '} /></div>
                        <div className="bloginnertag  displaynone">{blog?.cats.map((cat) => cat.name).join(',')}</div>
                        <div className="bloginnertext ">{blog?.title}</div>
                        <div className="bloginnerdate ">{blog?.short_content} </div>
                      </div>

                    </Link>

                  </div>
                }) :

                <div className="dropdefault text-center">
                  <div className="dropdefaultimage">
                    <img src={dropbox} alt={' '} />
                  </div>
                  <div className="dropdefaulttext">
                    <h5>You don't have any Blogs yet. </h5>
                  </div>
                </div>
            }

    
            
              <div className="latestbutton">
              <Link to={{pathname: `/articles`, search:'?category=Real Estate'}} className="showallourbesttoo">
              {t("COMMON.SHOWALL")}
                </Link>
               </div>
             
          </div>
        </div>
      </section>
      <section>

      </section></section>
      <Footer />

      {
        openModal &&
        <div className="modalspecial blog-modal">
          <div>
            <div className="mybtn">
              <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                  fill="#ffffff" stroke="none">
                  <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                </g>
              </svg></button>
            </div>
            <div className="modal-header text-center">
              <h3>{blog?.title}</h3>
            </div>
            <div className="modal-body text-center">
              <div className="blog-image-dv"><img src={blog.image} alt={' '} />
              </div>
              <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
            </div>
          </div>
        </div>

      }

      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory ={productDetails.subCategory} closFunction={closeFunction}/>
      }


    </>

  );
};

export default RealEstateJsx;
