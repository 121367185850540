import React from 'react'
import { useEffect, useState } from 'react';
import { Footer } from '../../../common/footer/footer';

import { useTranslation } from "react-i18next";

import Sidebar from "../sidebar/sidebar.js";
import AOS from 'aos';
import 'aos/dist/aos.css';
const AskTheExpertQuestionListCustomerJsx = (props) => {
  
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };
 
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
            

        
          <div className="dashboardrightpanel martop40">
            <h2 className="h2_style">Submit Question</h2>
          <div className="myexpertformarea searcharea searchareaquestion martop40">
           
            <div className="input-container form-group">
              <select className="input form-control form-select" placeholder=" " name="institutionCategory">
                <option value="">All</option>
                <option value="1">Insurance</option>
                <option value="2">Investment</option>
                <option value="3">Mortgage</option>
                <option value="4">Banking</option>
                <option value="5">Real Estate</option>
              </select>
              <div className="placeholder">Category</div>
              </div>
              
              <div className="input-container form-group">
                <select className="input form-control form-select" placeholder=" " name="status">
                  <option value="">All</option>
                  <option value="partially-registered-s">Partially Registered - S</option>
                  <option value="partially-registered-a">Partially Registered - A</option>
                  <option value="active">Active</option>
                  <option value="verified">Verified</option>
                  <option value="pending-invoice">Pending Invoice</option>
                </select><div className="placeholder">status</div>
              </div>
              <div className="input-container form-group"><button type="submit" className="ctaonebutton">Search</button></div></div>
              <div className="searchdetails">
                <table className="searchdetailsdb">
                  <tr>
                    <th>Category</th>
                    <th>Sub-category</th>
                    <th>Answer</th>
                  </tr>
                  <tr>
                    <td>Category1</td>
                    <td>Sub-Category1</td>
                    <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</td>
                  </tr>
                  <tr>
                    <td>Category1</td>
                    <td>Sub-Category1</td>
                    <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</td>
                  </tr>
                  </table>
                  </div>
          </div>
        </div>
            </div>

          </div>
          
      </section>

      <Footer />
    </>

  );
};

export default AskTheExpertQuestionListCustomerJsx;