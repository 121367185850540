import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Footer } from '../../../common/footer/footer';

export const FinalAsktheExpertScreenJSX = (props) => {
    const { t } = useTranslation();
    const { onHomebuttonClick } = props;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
           

            <div className="thankyoupopup popupouter ">
            
  <div className="popup">
                <div className="row">
                    <div className="col-lg-12 col-sm-7">
                        <div className="thankyou">
                            <div className="thakyoutext">
                                <h2>Thank you for your question!</h2>
                                <p className="thankyousubtext">Conscious finance boots overall well-being.</p>
                                <p className="thankyousubtext2">Your questions has been assigned to the experts. You will receive tailored answers in your inbox shortly!</p>
                            </div>
                            <div className="thankyoubtn">
                                <a href="/home" onClick={onHomebuttonClick} className="thankyoubutton">HOME</a>
                                <a href="/askTheExpert" onClick={onHomebuttonClick} className="thankyoubutton2">Ask Another Question</a>
                            </div>
                            <div className="thakyoutext">
                            <p className="thankyousubtext">Would you like to keep a record of your question history?</p>
                                <p className="thankyousubtext2">Register today and get access to your personalized dashboard. Also, get the latest offers & product recommendations based on your interest.
                </p>
                                
                            </div>
                            <div className="thankyou2btn">
                                <a href="/customerRegistration" className="thankyou2button">Register Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</div>
            <Footer />

        </>
    )
}