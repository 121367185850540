import React from 'react'

import facebook from "../../../../assets/images/facebook.svg"
import twitter from "../../../../assets/images/twitter.svg"
import instagram from "../../../../assets/images/insta.svg"
import linkedin from "../../../../assets/images/linkedin.svg"

import { Link } from "react-router-dom";

const SocialMediaJsx = (props) => {
  const {
    onInputChangeHandler, onSubmitButtonClick, editSocial, changeSocialState, formError
  } = props;
  const information = props?.wholeData
  const totalInformation = props.responseData?.socialMedia

  // const [edit, setEdit] = useState(false);

  return (
    <>
      {!editSocial ? <div className="myaccountsection institutedetails martop60 mb-5">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Social Media</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeSocialState(true)}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4597 5.8743L13.8341 4.49994L13.8341 4.49992C14.0686 4.26543 14.1858 4.14818 14.2703 4.03599C14.8063 3.32377 14.8063 2.34278 14.2703 1.63057C14.1858 1.51837 14.0686 1.40112 13.8341 1.16663L13.8341 1.16661L13.8341 1.16658C13.5996 0.932098 13.4823 0.814852 13.3701 0.730405C12.6579 0.194357 11.6769 0.194357 10.9647 0.730405C10.8525 0.814852 10.7353 0.932097 10.5008 1.16658L10.5007 1.16661L9.10713 2.56023C9.90404 3.94047 11.0608 5.08825 12.4597 5.8743ZM7.65253 4.01482L2.19046 9.4769C1.7654 9.90196 1.55287 10.1145 1.41314 10.3756C1.2734 10.6367 1.21446 10.9314 1.09657 11.5209L0.647836 13.7645C0.581314 14.0971 0.548053 14.2634 0.642661 14.358C0.737269 14.4526 0.903573 14.4194 1.23618 14.3529H1.23618L3.47983 13.9041C4.06928 13.7862 4.36401 13.7273 4.62511 13.5876C4.8862 13.4478 5.09873 13.2353 5.52379 12.8102L11.0014 7.33267C9.65352 6.48191 8.51135 5.34748 7.65253 4.01482Z" fill="white"/>
</svg></button>
          </div>
        </div>

        <div className="disgrid grid-2">
          <div className="form-fieldinner sociallink">
            <label><img src={facebook}  alt={''}/></label>
            <div className="fieldans"><Link to={{ pathname: totalInformation?.facebookUrl }} target="_blank"  rel="noreferrer" >{totalInformation?.facebookUrl}</Link></div>

          </div>
          <div className="form-fieldinner sociallink">
            <label><img src={twitter} alt={''} /></label>
            <div className="fieldans"><Link to={{ pathname: totalInformation?.twitterUrl }} target="_blank"  rel="noreferrer" >{totalInformation?.twitterUrl}</Link></div>
          </div>
          <div className="form-fieldinner sociallink">
            <label><img src={instagram}  alt={''}/></label>
            <div className="fieldans"><Link to={{ pathname: totalInformation?.instagramUrl }} target="_blank"  rel="noreferrer" >{totalInformation?.instagramUrl}</Link></div>
          </div>
          <div className="form-fieldinner sociallink">
            <label><img src={linkedin}  alt={''}/></label>
            <div className="fieldans"><Link to={{ pathname: totalInformation?.linkedinUrl }} target="_blank"  rel="noreferrer" >{totalInformation?.linkedinUrl}</Link></div>
          </div>
        </div>
      </div> : null}
      {editSocial ? <div className="myaccountsection institutedetails martop60 mb-5">
        <div className="myaccountsectionheading">
          <h3 className="h3_style">Social Media</h3>
          <div className="mybtn">
            <button type="button" className="myadbtn" onClick={() => changeSocialState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
          </div>
        </div>

        <div className="disgrid grid-2">

          <div className="ss">
            <div className="input-container form-group social-control">
              <input type="text" className="input form-control" placeholder=" " name="facebook" value={information.facebook} onChange={onInputChangeHandler} />
              <label className="placeholder">Facebook</label>
              <img src={facebook} className="formscicon"  alt={''} />
              <span className="text-danger">
                {formError['facebook'] &&
                  (formError['facebook'] === "req"
                    ? ''
                    : 'Link is not valid')}
              </span>
            </div>


          </div>
          <div className="ss">
            <div className="input-container form-group social-control">
              <input type="text" className="input form-control" placeholder=" " name="twitter" value={information.twitter} onChange={onInputChangeHandler} />
              <label className="placeholder">Twitter</label>
              <img src={twitter} className="formscicon" alt={''} />
              <span className="text-danger">
                {formError['twitter'] &&
                  (formError['twitter'] === "req"
                    ? ''
                    : 'Link is not valid')}
              </span>
            </div>
          </div>
          <div className="ss">
            <div className="input-container form-group social-control">
              <input type="text" className="input form-control" placeholder=" " name="insta" value={information.insta} onChange={onInputChangeHandler} />
              <label className="placeholder">Instagram</label>
              <img src={instagram} className="formscicon" alt={''} />
              <span className="text-danger">
                {formError['insta'] &&
                  (formError['insta'] === "req"
                    ? ''
                    : 'Link is not valid')}
              </span>
            </div>

          </div>
          <div className="ss">
            <div className="input-container form-group social-control">
              <input type="text" className="input form-control" placeholder=" " name="linkedin" value={information.linkedin} onChange={onInputChangeHandler} />
              <label className="placeholder">Linkedin</label>
              <img src={linkedin} className="formscicon"  alt={''}/>
              <span className="text-danger">
                {formError['linkedin'] &&
                  (formError['linkedin'] === "req"
                    ? ''
                    : 'Link is not valid')}
              </span>
            </div>

          </div>

        </div>
        <button type="button" className="ctaonebutton widthhalf mt-4" onClick={() => onSubmitButtonClick()}>Save</button>
      </div> : null}
    </>
  );
};

export default SocialMediaJsx;
