import React, { useEffect, useState } from 'react';
import './pagination.css';

export const Pagination = (props) => {
    const { limit, totalCount, onPaginationButtonClick, resetPagi = false, allOffersData, page } = props;
    const [activePage, setActive] = useState(1);
    const totalPages = Math.ceil(allOffersData?.length / 10);
    const reset = sessionStorage.getItem("changing")
    const onNextButtonClick = () => {
        sessionStorage.removeItem("changing")

        setActive(page + 1)
        onPaginationButtonClick(page + 1)
    }
    const resetState = () => {
        setActive(1)

    }
    useEffect(() => {
        resetState()
    }, [reset])



    const onPreviousButtonClick = () => {
        sessionStorage.removeItem("changing")

        setActive(page - 1)
        onPaginationButtonClick(page - 1)
    }

    useEffect(() => {
        setActive(1);
    }, [totalCount])


    useEffect(() => {
        resetPagi && setActive(1);
    }, [resetPagi])

    return (
        <div className='pagination-section'>
            <div style={{ display: 'flex', alignContent:'center' }}>
                <button className="prev" disabled={page === 1} onClick={() => onPreviousButtonClick()}>{'prev'}</button>
                <div className='active-page'>{page}</div>
                <button className="next" disabled={page === totalPages} onClick={() => onNextButtonClick()}>{'next'}</button>
            </div>
        </div>
    )
}