import React, { useState } from 'react'
import { useEffect, } from 'react';

import { useTranslation } from "react-i18next";

import { Footer } from '../../../common/footer/footer';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../globalMainSearch/globalMainSearch.css";
import "../globalMainSearch/globalMainSearchresponsive.css";
import { NEW_URL, baseURL } from '../../../../constants/applicationConstants';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader } from '../../../common/loader/loader';
import { onOfferClick } from '../../../../shared/helper';

const GlobalMainSearchJsx = (props) => {
    const location = useLocation(); // Hook to access the location object
    const history = useHistory();

    const { t } = useTranslation();
    useEffect(() => {
       
        AOS.init();
    }, [])

   

    const [searchedData, setSearchedData] = useState('');
    const [allOffersData, setAllOffersData] = useState([]);
    const [overallOffers, setOverallOffers] = useState([]);
    const [isProcessing, setIsProcessing] = useState(true);
    const [blank, setBlank] = useState(false);

    const searchParams = new URLSearchParams(location.search);

    const search = searchParams.get('search') || '';
    const activeInstitutes = allOffersData?.institutes?.filter(institute => institute.status === 'active');
    const activeExperts = allOffersData?.experts?.filter(experts => experts.status === 'active');


    useEffect(() => {
        if (search) {
            setSearchedData(search);
            axios.get(`${baseURL}/search-content?keyword=${search}`)
                .then(res => {
                    setAllOffersData(res.data.data);
                    setOverallOffers(res.data.data);
                    setIsProcessing(false);
                });
        } else {
            setIsProcessing(false); // Handle the case where there's no search value
            history.push("/");

        }
    }, [location.search]);
    useEffect(() => {
        if (allOffersData?.offers?.length === 0 && allOffersData?.products?.length === 0 && allOffersData?.institutes?.length === 0 && allOffersData?.experts?.length === 0 && allOffersData?.articles?.body?.length === 0) {
            setBlank(true)
        }
    }, [allOffersData])

    // Dependency array to re-run when search changes
    var productsection = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        adaptiveHeight: true,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,

                }
            }
        ]


    };

    var offersection = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        adaptiveHeight: true,
        arrows: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,

                }
            }
        ]


    };
    const sliderSettings = {
        infinite: false,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: true,
                    dots: false

                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    arrows: true,
                    dots: false,

                }
            }
        ]
    }
    // {t("PRINT.TITLE")}
    return (
        <>
            {!blank ?
        <section className="homecustom">
        {allOffersData?.offers?.length &&
            <section className="exclusive searchtopproduct">
                <div className="container">
                    <div className="exclusive-text">
                        <h2 className="text-center">“{searchedData}” in Offers</h2>
                    </div>
                    <div className="exclusive-slider">

                        <Slider {...offersection}>
                            {
                                allOffersData?.offers?.map(data => {
                                    return <>
                                        <div className="p_sliderouter main_offers exclusivepadding" onClick={() => onOfferClick(data)}>
                                            <div className="p_slider offer_inner exclusived">

                                                <div className="sldeimage offer_image"><img
                                                    src={`${NEW_URL}/${data.imageUrl}`} alt={''} /></div>
                                            </div>
                                        </div>
                                    </>
                                })
                            }


                        </Slider>

                    </div>
                    <div className="showall  text-center">
                        <Link to={'/offers'} className="showallbtn mt-3 mb-3">{t("COMMON.SHOWALL")}</Link>
                    </div>

                </div>
            </section>
        }
        {allOffersData?.experts?.length &&
            <section className="ourbest ourbestexpertsearch mt-5 mb-5">
                <div className="container">
                    <div className="ourbexttext ourbestmargin" >
                        <h2>“{searchedData}” in Experts</h2>

                    </div>


                    <Slider {...sliderSettings}>
                        {
                            activeExperts?.map(data => {
                                return <>
                                    <div className="catpop categorynameReal Estate">
                                        <Link to={`/expertPpv/${data?.slugUrl}`} target="_blank">
                                            <div className="ourexpertuser">
                                                <div className="ourexpertinner">
                                                    <div className="ourexpertinnerimg"> <img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data.firstName}+${data.lastName}&rounded=true` : `${NEW_URL}/${data?.imageUrl}`} /></div>
                                                    <h3>{data.firstName + " " + data.lastName}</h3>
                                                    <p>{data.description}</p>
                                                </div>
                                                <div className="Expertsbutton">
                                                    <div className="Expertsbuttoni">
                                                        <a href={`/expertPpv/${data?.slugUrl}#contact`} target="_blank" className="messagemebuttoncomm">Message me</a>

                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </>
                            })
                        }





                    </Slider>
                    <div className="showallourbesttwo  text-center mb-3 mb-5">
                        <Link to={`/globalMainSearchexpert?search=${search}`} className="showallourbesttoo mt-5 mb-4">{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>
            </section>

        }
        {allOffersData?.products?.length &&

            <section className="topproduct searchtopproduct pb-3 pt-3">
                <div className="container">
                    <div className="topproduct-text">
                        <h2 className="text-center">“{searchedData}” in Products</h2>
                    </div>
                    <div className="exclusive-slider prodectsslider">

                        <Slider {...productsection}>
                            {
                                allOffersData?.products?.map(data => {
                                    return <>
                                        <div className="p_sliderouter product_main exclusivepadding" onClick={() => onOfferClick(data)}>
                                            <div className="p_slider product_inner">
                                                <div className="sldeimage product_image">
                                                    <img
                                                        src={`${NEW_URL}/${data.imageUrl}`} onclick="zoomImage(event)" alt={''} /></div>
                                            </div>
                                        </div>
                                    </>
                                })
                            }

                        </Slider>
                    </div>
                    <div className="showallourbesttwo  text-center mb-3 mb-5">
                        <Link to={'/products'} className="topproductshowallbtn mt-3">{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>
            </section>
        }
        {allOffersData?.institutes?.length &&
            <section className="ourbest ourbestexpertsearch mt-5 mb-5">
                <div className="container">
                    <div className="ourbexttext ourbestmargin" >
                        <h2 className="text-center">“{searchedData}” in Institutions</h2>

                    </div>


                    <Slider {...sliderSettings}>

                        {
                            activeInstitutes?.map(data => {
                                return <>
                                    <div className="catpop categorynameReal Estate">
                                        <Link to={`institute/${data?.formattedLegalName}`} target="_blank">
                                            <div className="ourexpertuser">
                                                <div className="ourexpertinner">
                                                    <div className="ourinstituteinnerimg"> <img src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data.name}+${data.name}&rounded=false` : `${NEW_URL}/${data?.imageUrl}`} alt=''/></div>
                                                    <h3>{data.name}</h3>
                                                    <p>{data.email}</p>
                                                </div>
                                                <div className="Expertsbutton">
                                                    <div className="Expertsbuttoni">
                                                        <a href={`/institute/${data?.legalName}#contact`} target="_blank" className="messagemebuttoncomm">Message me</a>
                                                        <a href={`/institute/${data?.legalName}#ppvOfferSection`} target="_blank" className="myadsbuttoncomm">Offer</a>
                                                        <a href={`/institute/${data?.legalName}#ppvProductSection`} target="_blank" className="myadsbuttoncomm">Products</a>


                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                </>
                            })
                        }


                    </Slider>
                    <div className="showallourbesttwo  text-center mb-3 mb-5">
                        <Link to={`/globalMainSearchInstitutions?search=${search}`}
                            className="showallourbesttoo mt-5 mb-4">{t("COMMON.SHOWALL")}</Link>
                    </div>
                </div>
            </section>
        }

        {allOffersData?.articles?.body?.length &&
            <section className="searcharticle">
                <h2>“{searchedData}” in Articles</h2>
                <div className="container">

                    {
                        allOffersData?.articles?.body?.map(data => {
                            return <>
                                <div className="searcharticleinner">
                                    <div className="searcharticleimage"><img src={data.image} alt=''/></div>
                                    <div className="searcharticlecontent">
                                        <h3>{data?.title}</h3>
                                        {data?.short_content}
                                    </div>
                                </div>
                            </>
                        })
                    }
                    <div className="showall  text-center">
                        <Link to={`/searchArticles?search=${search}`} className="showallbtn mt-3 mb-3">{t("COMMON.SHOWALL")}</Link>
                    </div>

                </div>
            </section>
        }

    </section> : 
    <h1>No Record Available !</h1>
    }
            
            {isProcessing && <Loader />}
            <Footer />

        </>

    );
};

export default GlobalMainSearchJsx;