import React from 'react'
import { useEffect, useState } from 'react';

import { Pagination } from '../../../common/pagination/pagination';
import { Footer } from '../../../common/footer/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import moment from 'moment';
import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    subCategoryData,
    formData,
    onInputChange,
    
    onTouchHandler,
    onDeleteButtonClick,
    onSubmitButtonClick,
    onEditButtonClick,
    expertData,
    onPaginationButtonClick
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  
  const [meta, setMeta] = useState({});
  
  const [searchLoading, setSearchLoading] = useState(false);
  
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">

        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">

              <div className="myexpertformarea searcharea searchareaquestion searchareaquestionadmin martop40">
                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="category"
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {categoryData?.map((item) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Category</div>
                </div>
                <div className="input-container form-group">
                  <select
                    className="input form-control form-select"
                    placeholder=" "
                    name="subCategory"
                    value={formData.subCategory}
                    onChange={onInputChange}
                    onBlur={onTouchHandler}
                  >
                    <option value="">All</option>
                    {subCategoryData?.map((item, i) => {
                      return (
                        <>
                          <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.subCategory}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                  <div className="placeholder">Sub Category</div>
                </div>

                <div className="input-container form-group"><button type="submit" className="ctaonebutton"   onClick={() => onSubmitButtonClick()}>Submit</button></div>
              </div>
</div>
              <div className="expertsection martop30 mb-5">
               

                  {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  <div className="searchdetails searchdetalsen">
                    <table className="searchdetailsdb">
                      <tr>
                        <th>Question</th>
                        <th>Created At </th>
                        <th>Action</th>
                      </tr>
                      {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  {
                    expertData.length > 0 ?
                      expertData?.map(data => { return  <tr>
                        
                        
                          <td>{data?.question}</td>
                          <td>{moment(data?.createdAt).format('MM-DD-YYYY')}</td>
                          <td>  <button className="btn btn-success" onClick={() => onEditButtonClick(data?.askExpertQuestionId)}>Edit</button>
                          <button className="btn btn-danger"  onClick={() => onDeleteButtonClick(data?.askExpertQuestionId)} >delete</button></td>
                        </tr>
                      }) :
                       <div className="opacity0">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  }
                      </table>
                  </div>
                  {/* {searchLoading === true && <div className="loadingResult"><h4>Loading</h4></div>}
                  {
                    expertData.length > 0 ?
                      expertData?.map(data => {
                        return <div className="expertinner">
                          <div className="expertuser">

                            <p>Question : {data?.question}</p>
                           Created At : {moment(data?.createdAt).format('DD-MM-YYYY')}
                          </div>
                          <button onClick={() => onEditButtonClick(data?.askExpertQuestionId)}>Edit</button>
                          <button onClick={() => onDeleteButtonClick(data?.askExpertQuestionId)} >delete</button>


                        </div>
                      }) :
                      <div className="opacity1">{meta?.totalPages ? t("SEARCHEXPERT.SEARCHRESULTNOTFOUND") : 'Search for expert'}</div>
                  } */}
               

                {expertData?.length > 0 && <Pagination
            limit={10}
            totalCount={expertData?.meta?.totalCount}
            onPaginationButtonClick={onPaginationButtonClick}
          />}
              </div>
            </div>
          </div>

        </div>


        
      </section><Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;