import React from 'react';
import { useEffect,useState } from 'react';
import { Footer } from '../../common/footer/footer';

import ascending from "../../../assets/productimage/ascending-bars.svg";
import descending from "../../../assets/productimage/descending-bars.svg";

import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../products/products.css"
import "../products/productresponsive.css"
import { NEW_URL } from '../../../constants/applicationConstants';

import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Filter from '../../../shared/filter.jsx';
import DetailedProductsJsx from '../../publicProfileView/ppvCommon/detailedProduct/detailedProduct.jsx';
import { Pagination } from '../../common/pagination/uiPagination.js';

const ProductJsx = (props) => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState({});
  const closeFunction = () =>{
    setProductDetails({})
  }
  const {
    categoryData, 
    hitSearch,
    selectAll,
    selectAllHit,
    allProductData,
    oldest,
    hitOldest,
    investmentProductData,
    mortgageProductData,
    insuranceProductData,
    bankingProductData,
    realStateProductData,
    topProducts,
    selectCategory, 
    selectSubCategory, 
    subCategories,
    categories, 
    searchProductByKeyword,page,onPaginationButtonClick
  } = props;


  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);
  const { hash } = useLocation()
  const currentItems = allProductData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  

  useEffect(() => {
    AOS.init();

    if (hash) {
      document.getElementById(hash.replace('#', '')) && document.getElementById(hash.replace('#', '')).scrollIntoView()
    } else {
      window.scrollTo(0, 0);
    }

  }, [realStateProductData, investmentProductData, mortgageProductData, insuranceProductData, bankingProductData])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px',

    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
    ]
  };
  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="container-rimib newproductdesign">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-5">
                <div className="offerherobannertext">

                  <h2>{t("PRODUCTS.TEXT1")}</h2>
                  <h5>{t("PRODUCTS.TEXT2")}</h5>
                </div>
              </div>
              <div className="col-lg-7 col-sm-7">
                <div className="offerbannerslider productbannerslider">
                  <Slider {...settingstwo}>
                    {
                      topProducts?.map(product => {

                        return (
                          <a href={product.externalLink ?? '#'} target="_blank">
                            <div className="sliderContent">
                              <img
                                className="d-block w-100"
                                src={`${NEW_URL}/${product.imageUrl}`}
                                alt=" "
                              />
                              {/* <h5>{product.productName}</h5> */}
                            </div>                            </a>

                        )

                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search"  value={props.keyword} placeholder="" onChange={searchProductByKeyword}
              />
              <button className="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("OFFERE.TEXT5")}</h2>
            <h4>{t("OFFERE.TEXT6")}</h4>
          </div>
        </section>


        <section className="mainofferdetails">
          <div className="container">
            <h2>{t("PRODUCTS.TEXT6")}</h2>
            <h5>{t("PRODUCTS.TEXT7")} {t("PRODUCTS.TEXT8")}</h5>
            <h5><b>{t("PRODUCTS.TEXT9")}</b></h5>
            <Filter
              categoryData={categoryData}
              selectAll={selectAll}
              selectAllHit={selectAllHit}
              selectCategory={selectCategory}
              selectSubCategory={selectSubCategory}
              subCategories={subCategories}
              categories={categories}

            />
          </div>
        </section>
        <section className="sortingdiv">
          <div className="container">
            <span className="shortby">{t("PRODUCTS.TEXT17")}</span>
            <ul>
              <li className= {!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}><img src={ascending} className="descending" /> {t("PRODUCTS.TEXT18")}</a></li>
              <li className= {oldest ? "active" : ""}><a onClick={() => hitOldest(true)}><img src={descending} className="descending" /> {t("PRODUCTS.TEXT19")}</a></li>
            </ul>
          </div>
        </section>
        <section className="entireofferfiltercustomised">
          <div className="container">
            {allProductData &&
              <section className="topexpertproduct">

                <div className="productslider mt-4">
                  <div className="row">
                    {
                      currentItems?.map(product => {
                        return <div className={`col-lg-4 col-md-4 col-sm-4 sliouter ${product?.parentInstitutionCategory || product?.institutionCategoryName}`}>
                          <div className={`p_sliderouter ${product?.parentInstitutionCategory || product?.institutionCategoryName}`}>
                            <div className="p_slider">
                              <div className="parentcatname">{product?.parentInstitutionCategory}</div>
                              <div className="productcatname">{product?.subInstitutionCategory?.name}</div>
                              <div className="sldeimage">
                                <LazyLoad>
                                  <img src={`${NEW_URL}/${product.imageUrl}`} onclick="zoomImage(event)" alt={' '} />
                                </LazyLoad>
                              </div>
                              <h5>{product.productName}</h5>
                              <div className="slbtn">
                                <div className="d-flex">

                                  <button
                                    className="ctaonebutton ctanewtwobutton"
                                    onClick={() => {setProductDetails({ data: product, category: product?.parentInstitutionCategory, subCategory: product?.subInstitutionCategory?.name, frompage: 'realestateProduct', pagePath: '/products' })}}>
                                    {t("PRODUCTS.TEXT20")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      })
                    }



                  </div>
                </div>

              </section>
            }


          </div>
        </section>
      </section>      
      {productDetails?.data &&
        <DetailedProductsJsx data={productDetails?.data} category={productDetails?.category} subCategory ={productDetails.subCategory} closFunction={closeFunction}/>
      }
       <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allProductData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

              />
      <Footer />
    </>

  );
};

export default ProductJsx;