import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { onOfferClick } from '../../../../shared/helper';
import { NEW_URL } from '../../../../constants/applicationConstants';
import "../suggestedOffers/suggestedOffer.css";
import CustomerQnaAnswer from '../customerQnaAnswer/customerQnaAnswer';
import CustomerQna from '../customerQna/customerQna';
import SuggestedOffer from './suggestedOffer';
import seacrchicon from "../../../../assets/images/seacrchicon.png";
import { Pagination } from '../../../common/pagination/pagination';

const SuggestedOfferJsx = (props) => {
  const { t } = useTranslation();
  const {
    investmentOfferData,
    mortgageOfferData,
    insuranceOfferData,
    bankingOfferData,
    realestateOfferData,
    activeTab,
    redirectSearchPage,
    seTypeSearchValue,
     onPaginationButtonClick, meta,allOffersData,page
  } = props;

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5
  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (
    <>
      <section className="alldashboard latest-offers">
        <div className="dashboardrightpanel martop40 mb-5">
          <div className="suggestedoffercustomer">
            <section className="limitedoffer">
              <div className="limitedofferinner">
                <div className="heading">

                  <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE0")}</h5>
                </div>
                <div className="offerslider ">
                  <AwesomeSlider>
                    {
                      realestateOfferData?.map(offer => {


                        return <div>
                          <div className="ppvawesomeslider-button">
                            <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`}>{offer?.parentInstitutionCategory}</button></div>
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                          <h3>{offer.description}</h3>
                          <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory?.replace(/\s+/g, '')}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="ppvawesomesliderproducttype-inner" >
                              <div className="ppvawesomesliderproducttyp-name">
                                <h4>{t("CUSTOMERHOME.PRODUCTNAMEANDDES")}</h4>
                                {/* <p>{offer?.offerName}</p> */}
                                <p>{offer?.description}</p>
                              </div>
                              <div className="ppvawesomesliderproducttype-product">
                                <div className="ppvawesomesliderproducttype-product-text">
                                  <h4>{t("CUSTOMERHOME.RELATEDPRODUCTS")}</h4>
                                  <p >{offer?.offerName}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-button">
                                  <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf">{t("CUSTOMERHOME.KNOWMOREBUTTON")}</button>

                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      })
                    }
                  </AwesomeSlider>
                </div>
              </div>
              <section className="limitedoffer ">
                <div className="limitedofferinner">
                  <div className="heading">

                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE1")} </h5>
                  </div>
                  <div className="offerslider">

                    <AwesomeSlider>
                      {
                        investmentOfferData?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                              <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`} >{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("CUSTOMERHOME.PRODUCTNAMEANDDES")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("CUSTOMERHOME.RELATEDPRODUCTS")}</h4>
                                    <p>{offer?.offerName}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf">{t("CUSTOMERHOME.KNOWMOREBUTTON")}</button>

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer ">
                <div className="limitedofferinner">
                  <div className="heading">

                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE2")} </h5>
                  </div>
                  <div className="offerslider">

                    <AwesomeSlider>
                      {
                        mortgageOfferData?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                              <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`} >{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner">
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("CUSTOMERHOME.PRODUCTNAMEANDDES")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("CUSTOMERHOME.RELATEDPRODUCTS")}</h4>
                                    <p>{offer?.offerName}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf" >{t("CUSTOMERHOME.KNOWMOREBUTTON")}</button>

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer ">
                <div className="limitedofferinner">
                  <div className="heading">

                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE3")}</h5>
                  </div>
                  <div className="offerslider ">

                    <AwesomeSlider>
                      {
                        insuranceOfferData?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                              <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`} >{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            /><div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner" >
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("CUSTOMERHOME.PRODUCTNAMEANDDES")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("CUSTOMERHOME.RELATEDPRODUCTS")}</h4>
                                    <p>{offer?.offerName}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf" >{t("CUSTOMERHOME.KNOWMOREBUTTON")}</button>

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              <section className="limitedoffer ">
                <div className="limitedofferinner">
                  <div className="heading">

                    <h5 className="mt-2" style={{ display: 'none' }}>{t("TOPOFFERS.OFFERSUBTITLE4")} </h5>
                  </div>
                  <div className="offerslider">

                    <AwesomeSlider>
                      {
                        bankingOfferData?.map(offer => {
                          return <div>
                            <div className="ppvawesomeslider-button">
                              <button className={`ctaonebutton widthhalf ${offer?.parentInstitutionCategory}`} >{offer?.parentInstitutionCategory}</button></div>
                            <img
                              className="d-block w-100"
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                            <div className={`ppvawesomesliderdescription-main ${offer?.parentInstitutionCategory}`} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <div className="ppvawesomesliderproducttype-inner" >
                                <div className="ppvawesomesliderproducttyp-name">
                                  <h4>{t("CUSTOMERHOME.PRODUCTNAMEANDDES")}</h4>
                                  {/* <p>{offer?.offerName}</p> */}
                                  <p>{offer?.description}</p>
                                </div>
                                <div className="ppvawesomesliderproducttype-product">
                                  <div className="ppvawesomesliderproducttype-product-text">
                                    <h4>{t("CUSTOMERHOME.RELATEDPRODUCTS")}</h4>
                                    <p>{offer?.offerName}</p>
                                  </div>
                                  <div className="ppvawesomesliderproducttype-button">
                                    <button onClick={() => onOfferClick(offer)} className="knowmore_button ctaonebutton widthhalf" >{t("CUSTOMERHOME.KNOWMOREBUTTON")}</button>

                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        })
                      }
                    </AwesomeSlider>
                  </div>
                </div>
              </section>
              {/* {data.length > 5 &&
                <section className="newpagination"> <Pagination
                  currentPage={currentPage}
                  page={page}
                  allOffersData={allOffersData}
                  currentItems={currentItems}
                  onPaginationButtonClick={onPaginationButtonClick}

                /></section>
              } */}
              {/* <div>
                  {activeTab === 'answered' ? <CustomerQnaAnswer /> : activeTab === 'pending' ? <CustomerQna /> : <SuggestedOffer />}
                  
                </div> */}
              {activeTab !== 'suggestedOffer' && <div className="searchincustomer">
                <section className="searchpageherosection backwhite">

                  <div class="searchmaininnerhero heroleftpanel">
                    <h2 class="text-center"> {t("customerhome.SEARCHTITLE")}</h2>
                    <h4 className="h4_style text-center">{t("customerhome.SEARCHPARAGRAPH")}</h4>
                    <h4 className="h4_style text-center">{t("customerhome.SEARCHSUBPARAGRAPH")}</h4>
                    <h4 className="h4_style text-center">{t("customerhome.SEARCHSPANPARAGRAPH")}</h4>
                    <h2 className="h2_style text-center">{t("customerhome.SEARCHQUESTIONPARAGRAPH")} </h2>
                    <div className="myexpertformarea searcharea searchmainara ">
                      <div className="input-container form-group">
                        <select className="input form-control form-select" onChange={(e) => seTypeSearchValue(e.target.value)} placeholder=" " name="category">
                          <option value="/searchExpert">{t("customerhome.EXPERTOPTION")}</option>
                          <option value="/searchInstitute">{t("customerhome.EXPERTINSTITUTION")}</option>
                        </select>

                      </div>
                      <div className="input-container form-group">
                        <button type="submit" onClick={redirectSearchPage} className="ctaonebutton searchiconbutton"><img src={seacrchicon} alt="" /></button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>}
            </section>
          </div>
        </div>
      </section>

    </>

  );
};

export default SuggestedOfferJsx;