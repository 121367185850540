import React from 'react'
import leftarrow from "../../../../assets/images/left-arrow.svg";
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddCardView = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    formData,
    onInputChangeHandler,
    onTouchHandler,
    formSubmitButtonDisable,
    formError,
    formSubmitHandler,
    isLoading,
  } = props;
  const isRequired = true
  return (
    <>
      <section className="loginscreeninstitute registrationinstitute">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-5">
              <div className="leftsightbackdetails martop60">
                <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={'icon'} /></div>
                <div className="detailsleft plaindetailsleft">
                  <h1>Card Addition</h1>

                </div>
              </div>
              <div className="passtext martop60">
                <h2 className="h2_style martop60">Add Your <span>Credit Card</span> to Pay</h2>
                <h5 className="h5_style martop30">For Secured, Easy & Fast Payment.</h5>
                <h5 className="h5_style">You can always replace or delete added credit card from your dashboard.</h5>
              </div>
            </div>
            <div className="col-lg-7 col-sm-7 right-institute">
              <div className="institutelogintext martop60 paddingbot60">
                <div className="planbuttonouter">
                  <h2 className="h2_style">Please add your credit card </h2>
                  <h5 className="h5_style fst-italic">Be assured! We don’t store data. </h5>
                  <h5 className="h5_style">All your card information gets saved with STRIPE.</h5>
                  <h5 className="h5_style">By adding card, you are saved from hassles of repeatedly entering your credit card details</h5>
                </div>
                <div className="signout">
                  <img src={Sign_out_squre} alt={'icon'} />
                  <span className="title">Logout</span>
                </div>
                <div className="addcard martop60">
                  <h3 className="h3_style">Credit Card</h3>
                  <div className="mt-4 mb-4 input-container form-group">
                    <input
                      placeholder=" " 
                      onChange={onInputChangeHandler}
                      onBlur={onTouchHandler}
                      required
                      type="text"
                      value={(formData) ? formData.name : null}
                      maxLength="50"
                      className={
                        isRequired && formError["name"]
                          ? "input form-control  is-invalid"
                          : "input form-control"
                      }
                      id="name"
                      name="name"
                    />
                    <div className="placeholder">Name of the Cardholder</div>
                    <span className="text-danger">
                      
                      {isRequired &&
                        formError["name"] &&
                        (formError["name"] === "req"
                          ? "Card member name is required!"
                          : "Please enter a proper name!")}
                    </span>
                  </div>
                  <div className="mb-4 input-container form-group">
                    <input 
                     type="text" 
                     placeholder=" "
                     onChange={onInputChangeHandler}
                     onBlur={onTouchHandler}
                     required
                     maxLength="16"
                     value={(formData) ? formData.card : null}
                     className={
                       isRequired && formError["card"]
                         ? "input form-control  is-invalid"
                         : "input form-control"
                     }
                     id="card"
                     name="card"
                    />
                    <div className="placeholder">Card Number</div>
                    <span className="text-danger">
                      {isRequired &&
                        formError["card"] &&
                        (formError["card"] === "req"
                          ? "Card Number is required!"
                          : "Please enter a proper Card Number!")}
                    </span>
                  </div>
                  <div className="disgrid grid-2 cardvalid">

                    <div className="inlinecard">
                      <div className="input-container form-group">
                        <span className="validth">Valid Thru</span>
                        <input 
                         onChange={onInputChangeHandler}
                         onBlur={onTouchHandler}
                         value={(formData) ? formData.month : null}
                         required
                         type="text"
                         placeholder=" "
                         className={
                           isRequired && formError["month"]
                             ? "input form-control  is-invalid"
                             : "input form-control"
                         }
                         id="month"
                         name="month"
                         maxLength="2"
                        />
                        <div className="placeholder">Month</div>
                        <span className="text-danger">
                      {isRequired &&
                        formError["month"] &&
                        (formError["month"] === "req"
                          ? "Month is required!"
                          : "Please enter a proper Month!")}
                    </span>
                      </div>
                      <div className="input-container form-group">
                        <input 
                         onChange={onInputChangeHandler}
                         onBlur={onTouchHandler}
                         required
                         type="text"
                         placeholder=" "
                         value={(formData) ? formData.year : null}
                         maxLength="4"
                         className={
                           isRequired && formError["year"]
                             ? "input form-control  is-invalid"
                             : "input form-control"
                         }
                         id="year"
                         name="year" />
                        <div className="placeholder">Year</div>
                        <span className="text-danger">
                      {isRequired &&
                        formError["year"] &&
                        (formError["year"] === "req"
                          ? "year  is required!"
                          : "Please enter a proper year !")}
                    </span>
                      </div>
                    </div>
                    <div className="input-container form-group">
                      <input 
                       onChange={onInputChangeHandler}
                       onBlur={onTouchHandler}
                       required
                       type="text"
                       placeholder=" "
                       maxLength="3"
                       value={(formData) ? formData.cvc : null}
                       className={
                         isRequired && formError["cvc"]
                           ? "input form-control  is-invalid"
                           : "input form-control"
                       }
                       id="cvc"
                       name="cvc" />
                      <div className="placeholder">CVC</div>
                      <span className="text-danger">
                      {isRequired &&
                        formError["cvc"] &&
                        (formError["cvc"] === "req"
                          ? "cvc  is required!"
                          : "Please enter a proper cvc !")}
                    </span>
                    </div>
                  </div>
                  <button  disabled={formSubmitButtonDisable} onClick={formSubmitHandler} type="button" className={
                      formSubmitButtonDisable
                        ? "btn-secondary btn mt-4 ctatwobutton"
                        : "mt-4 ctaonebutton"
                    }> {isLoading ? t("COMMON.PROCESSING") : t("COMMON.ADDCARD")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default AddCardView;