import React, { Component } from "react";

import axios from "axios";
import {
  emailRegex,
  encrypt,
  allRegex
} from '../../../shared/helper.js'
import { Loader } from "../../common/loader/loader.jsx";
import {
  baseURL,
} from "../../../constants/applicationConstants.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import cookies from "js-cookie"

import LoginPage from './login.jsx'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      isProcessing: false,
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false,
      isFirstTime: true
    };
  }

  componentDidMount = () => {
    let isLogin = sessionStorage.getItem('isLoggedIn') && sessionStorage.getItem('isLoggedIn');
    if (isLogin && isLogin) {
      this.props.history.push("/dashboard");
    } else {
      this.props.history.push("/login");

    }
    sessionStorage.removeItem("instituteId")
    sessionStorage.removeItem("email")

    sessionStorage.removeItem("token")
    sessionStorage.removeItem("comingFrom")

    sessionStorage.removeItem("instLoginType")
    localStorage.removeItem("flowName")
    localStorage.removeItem("AdminFlow")

    localStorage.removeItem("flowType")

  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  // encrypt = () => {
  //   let ciphertext = CryptoJS.AES.encrypt('$C$pYuM#QM0f', 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3').toString();

  // }
  // decrypt = () => {
  //   let bytes = CryptoJS.AES.decrypt("U2FsdGVkX1+axK93BX1ZQ+AR8bXDqjtELPE7D6aJXwI=", 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325');
  //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
  // }


  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "email") {
      return emailRegex.test(value);
    }
    else {
      return allRegex.test(value)
    }
  };

  isFromSubmissionBlock = () => {
    let { email, password } = this.state.formData;
    if (email && password) {
      if (
        !this.inputRegexValidation("email", email)
        ||
        !this.inputRegexValidation("password", password)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {
    this.setState({
      isLoading: true
    })
    e.preventDefault();
    let data = {
      email: this.state.formData.email,
      password: encrypt(this.state.formData.password)
      // password: this.state.formData.password

    };
    axios.post(`${baseURL}/institute/login`, data).then((res) => {
      sessionStorage.setItem("resendOtpData", JSON.stringify(data))

      sessionStorage.setItem("otpValidateToken", JSON.stringify(res.data?.data?.tokenValidate));

      // sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
      // sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
      // sessionStorage.setItem("isLoggedIn", true);
      // sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.instituteId));
      // sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
      // sessionStorage.setItem("loginAs",'institute')
      localStorage.setItem('flowName', 'institute')
      localStorage.setItem('flowType', 'institute')
      this.setState({
        isLoading: false
      })
      // swal({
      //   title: "success",
      //   text: "You have logged-in successfully",
      //   icon: "success",
      //   successMode: true,
      // }).then(res => {
      //     if (res) {
      //       this.props.history.push("/dashboard");
      //     }
      // });
      toast.success("Login OTP sent to you Email successfully");
      setTimeout(() => {
        this.props.history.push("/otpLogin");
      }, 2000)

    }).catch((res) => {
      toast.error(res?.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })

  };
  onResendOTPClick = () => {

    let dataCustomer = {
      category: "institute",
      userType: "institute"
    }
    let email = JSON.parse(sessionStorage.getItem("email"))
    this.setState({ isLoading: true });
    axios.post(`${baseURL}/forgotPassword/${email}/sendOTP`, dataCustomer).then((res) => {
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.tokenValidate));
    }).catch((res) => {
      toast.error(res?.response.data.error.message);

      this.setState({ isLoading: false });
    })
  }

  onFirstLoginClick = () => {
    this.setState({
      isLoading: true
    })

    sessionStorage.removeItem("instLoginType");
    sessionStorage.removeItem("instUserId");
    axios.post(`${baseURL}/institute/${this.state.formData?.email}/existence`).then(res => {
      this.setState({
        isLoading: false
      })
      sessionStorage.setItem("instLoginType", res?.data?.data?.userType);
      if (res?.data?.data?.userType == 'institute_user') {
        sessionStorage.setItem("instUserId", res?.data?.data?.instituteUserId);
      }
      // checking start
      // if (res.data.data.onboardingStep === 'subscription-paid')
      //   this.setState({ isFirstTime: false });
      // else if (res.data.data.onboardingStep === 'basic-info') {
      //   sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));

      //   this.props.history.push('./plans')
      // }
      // else if (res.data.data.onboardingStep === 'verify-otp')
      //   this.props.history.push('./passwordAuth')
      // else
      //   this.props.history.push('./newPassword')

      if (res.data.data.onboardingStep === 'subscription-paid')
        this.setState({ isFirstTime: false });
      else {
        sessionStorage.setItem("email", JSON.stringify(this.state.formData?.email));
        localStorage.setItem('flowName', 'institutional')
        localStorage.setItem('flowType', 'institute')
        sessionStorage.setItem("comingFrom", "login")
        localStorage.setItem('AdminFlow', 'institute')

        sessionStorage.setItem("instituteId", JSON.stringify(res.data?.data?.instituteId));

        this.onResendOTPClick()
        this.props.history.push('./passwordAuth')
      }
      // checking end
    }).catch(err => {
      toast.error(err.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })
  }

  render() {
    return (
      <div>
        <LoginPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}
          isFirstTime={this.state.isFirstTime}
          onFirstLoginClick={this.onFirstLoginClick}
        // encrypt={this.encrypt}
        // decrypt={this.decrypt}
        // isProcessing={this.state.isProcessing}
        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default Login;
