import React, { useState, useEffect } from 'react'
import dropbox from "../../../../assets/images/dropbox.svg";
import minus from "../../../../assets/images/minus.svg";
import plus from "../../../../assets/Instituteppvimages/plus-circle.svg"
import ItemDetails from './itemDetails.jsx';
import { NEW_URL } from '../../../../constants/applicationConstants';
import "../myProducts/products.model.css"
import { useTranslation } from "react-i18next"; 

const ProductsJsx = (props) => {
    const {
        produtsList, getProducts, getParticularProducts, openEditBox
    } = props;
    const [showData, setShowData] = useState(produtsList);
    const [itemData, setItemData] = useState("");
    const [totalData, setTotalData] = useState("");
    const { t } = useTranslation();
    const onPlusButtonClick = (value, subValue, action) => {
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], isOpen: action }
                })
        })
        setShowData(tempArray)
        if (action === false) {
            // window.location.reload()
            window.location.href = `${window.location.origin}${window.location.pathname}?page=myProduct`;
            // setTimeout(() => {
            //     onTabClick('myProduct')

            // }, 1000);
        }
    }

    const onProductClick = (value, subValue, productId, action, totalData) => {
        setItemData(productId)
        setTotalData(totalData)
        let tempArray = [...showData]
        tempArray.map((tempDT) => {
            if (tempDT.parentInstitutionCategory === value)
                tempDT.subInstitutionCategories.map((subDT, index) => {
                    if (subDT.name === subValue)
                        tempDT.subInstitutionCategories[index] = { ...tempDT.subInstitutionCategories[index], activeID: !!productId }
                })
        })
        setShowData(tempArray);
    }

    useEffect(() => {
        setShowData(produtsList)
    }, [produtsList])

    const limitData = JSON.parse(sessionStorage.getItem('loginData'));

    return (
        <>

            <div>
                <p className="product-title">{t("instituteproduct.TITLE")}</p>
                <p className="product-subtitle">{t("instituteproduct.SUBTITILE")} {limitData?.totalProductsLimit} {t("instituteproduct.SUBTITILES")}</p>
                {
                    showData?.length > 0 ?
                        showData?.map(product => {
                            return (
                                <div className="myproduct-div">
                                   <p className={`product-category ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}`}>{product.parentInstitutionCategory} {t("instituteproduct.NAME")}</p>

                                    <div className="subcategory-heading">
                                        <ul>
                                            {
                                                product.subInstitutionCategories?.map((mainItem ,index) => {
                                                    return (
                                                        <li >
                                                            <>
                                                            {!mainItem.isOpen?<p className="product-subcategory"><p className={`count-number ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}-bg`} >{index+1}</p>  {mainItem.name}</p> : <p className="product-open">{mainItem.name}</p>}
                                                                {!mainItem.isOpen ? <img src={plus} className={`plus ${product.parentInstitutionCategory.toLowerCase().replace(" ", "_")}-filter`} alt={''} onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, true)} /> :
                                                                    <img src={minus} alt={''} className="minus" onClick={() => onPlusButtonClick(product.parentInstitutionCategory, mainItem.name, false)} />}

                                                                {mainItem.isOpen &&
                                                                    <>

                                                                        <div className="row prodcut-div">

                                                                            <>
                                                                                {mainItem?.activeID ? <ItemDetails
                                                                                    openEditBox={openEditBox}
                                                                                    onProductClick={() => onProductClick(product.parentInstitutionCategory, mainItem.name, "", false, "")}
                                                                                    itemData={itemData}
                                                                                    getProducts={getProducts}
                                                                                    getParticularProducts={getParticularProducts}
                                                                                    totalData={totalData}
                                                                                /> :

                                                                                    mainItem.products?.map(productData => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className="col-lg-4 col-sm-4" onClick={() => onProductClick(product.parentInstitutionCategory, mainItem.name, productData.institutionProductId, true, productData)}>
                                                                                                    <div className="single-blog">
                                                                                                        <div className="single-blog-img"><img src={`${NEW_URL}/${productData.imageUrl}`} alt={''} /></div>
                                                                                                        <div className="blog-name">{productData.productName}</div>
                                                                                                        <div className="blog-description">{product.parentInstitutionCategory} . {productData.description}</div>
                                                                                                    </div>
                                                                                                </div>                                                                                        </>
                                                                                        )

                                                                                    })
                                                                                }
                                                                            </>
                                                                        </div>

                                                                    </>

                                                                }
                                                            </>


                                                        </li>
                                                    )

                                                })
                                            }
                                        </ul>
                                    </div>


                                </div>
                            )

                        }) :
                        <div className="dropdefault text-center mb-5">
                            <div className="dropdefaultimage">
                                <img src={dropbox} alt={''} />
                            </div>
                            <div className="dropdefaulttext">
                                <h5>You dont have any product as of yet. </h5>
                                <h5>Create a new product now !</h5>
                            </div>

                        </div>
                }
            </div>

        </>

    );
};

export default ProductsJsx;
