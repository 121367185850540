import React from 'react'
import mywork from "../../../../assets/images/mywork.svg";
import manageexpert from "../../../../assets/images/manageexpert.svg";

import myworkspace from "../../../../assets/images/myworkspace.svg";
import myblog from "../../../../assets/images/myblog.svg";
import myaccount from "../../../../assets/images/myaccount.svg";

import manageinstitute from "../../../../assets/images/manageinstitute.svg";
import assignquestions from "../../../../assets/images/assignquestions.svg";
import manageunverifieduser from "../../../../assets/images/manageunverifieduser.svg";
import manageoffer from "../../../../assets/images/manageoffer.svg";
import manageproduct from "../../../../assets/images/manageproduct.svg";
import logoutIcon from "../../../../assets/images/logout.svg";
import { Link } from "react-router-dom";
import { NEW_URL } from '../../../../constants/applicationConstants'; import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const SideBarsJsx = (props) => {
  const history = useHistory();

  const {
    response
  } = props;

  const logout = () => {
    swal({
      title: "Are you sure you want to Logout ?",
      icon: "warning",
      buttons: [
        'Continue',
        'Logout'
      ],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("auth");
        sessionStorage.removeItem("masterID");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("loginData");
        sessionStorage.removeItem("legalName");
        sessionStorage.removeItem("isLoggedIn");

        history.push("/");
        window.location.reload()
      } else {
        //   swal("Cancelled", "Your imaginary file is safe :)", "error");
      }
    })

  }
  // {t("PRINT.TITLE")}
  return (
    <>
      <div className="dashboardleftpanel martop40">
        <div className="userdetails">
          <div className="dashboardlogo"><img src={`${NEW_URL}/${response?.imageUrl}`} alt={''}></img></div>
          <div className="responsename">{response?.name} <Link to={`/expertPpv/${response?.slugUrl}`} target="_blank" rel="noreferrer" className="viewprofilebtn">View Profile</Link></div>
        </div>
        <div className="dashboardlink martop40">
          <ul>
            <li className={`${window.location.href?.includes('opsDashboardHome') ? 'active' : ""}`}><Link to={'/admin/opsDashboardHome'}><img src={mywork} alt={''} />My Work</Link></li>
            <li className={`${window.location.href?.includes('experts') ? 'active' : ""}`}><Link to={'/admin/experts'}><img src={manageexpert} alt={''} />Manage Experts</Link></li>
            <li className={`${window.location.href?.includes('institutes') ? 'active' : ""}`}><Link to={'/admin/institutes'}><img src={manageinstitute} alt={''} />Manage Institutions</Link></li>
            <li className={`${window.location.href?.includes('pendingQuestion') ? 'active' : ""}`}><Link to={'/pendingQuestion'}><img src={assignquestions} alt={''} />Assign Questions</Link></li>
            {/* <li className={`${window.location.href?.includes('myads') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={managepayments}  alt={''}/>Manage Payments</Link></li> */}
            <li className={`${window.location.href?.includes('manageUnverifiedInstitute') ? 'active' : ""}`}><Link to={'/admin/manageUnverifiedInstitute'}><img src={manageunverifieduser} alt={''} />Manage Unverified Institute</Link></li>
            <li className={`${window.location.href?.includes('manageUnverifiedExpert') ? 'active' : ""}`}><Link to={'/admin/manageUnverifiedExpert'}><img src={manageunverifieduser} alt={''} />Manage Unverified Expert</Link></li>
            <li className={`${window.location.href?.includes('offers') ? 'active' : ""}`}><Link to={'/admin/offers'}><img src={manageoffer} alt={''} />Manage Offers</Link></li>
            <li className={`${window.location.href?.includes('offers') ? 'active' : ""}`}><Link to={'/admin/trackPayment'}><img src={manageoffer} alt={''} />Track Payment</Link></li>

            <li className={`${window.location.href?.includes('products') ? 'active' : ""}`}><Link to={'/admin/products'}><img src={manageproduct} alt={''} />Manage Products</Link></li>
            <li className={`${window.location.href?.includes('advertisements') ? 'active' : ""}`}><Link to={'/admin/advertisements'}><img src={manageproduct} alt={''} />Manage Advertisements</Link></li>
            {/* <li className={`${window.location.href?.includes('expertPayment') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={helpmanagement} />Help Management</Link></li> */}
            {/* <li className={`${window.location.href?.includes('expertPayment') ? 'active' : ""}`}><Link to={'/admin/#'}><img src={manageunverifieduser} />Bulk Upload</Link></li> */}



            <div className="accordion" id="accordionSidebar">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Question

      </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionSidebar">
                  <div className="accordion-body">
                    <li className={`${window.location.href?.includes('adminAskTheExpert') ? 'active' : ""}`}><Link to={'/admin/adminAskTheExpert'}><img src={myworkspace} alt={''} />Create Question</Link></li>
                    <li className={`${window.location.href?.includes('searchQuestion') ? 'active' : ""}`}><Link to={'/admin/searchQuestion'}><img src={myblog} alt={''} />Question List</Link></li>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Plans
      </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionSidebar">
                  <div className="accordion-body">
                    <li className={`${window.location.href?.includes('AdminAddPlans') ? 'active' : ""}`}><Link to={'/admin/AdminAddPlans'}><img src={myaccount} alt={''} />Add Plans</Link></li>
                    <li className={`${window.location.href?.includes('adminAllPlans') ? 'active' : ""}`}><Link to={'/admin/adminAllPlans'}><img src={myaccount} alt={''} />Search Plans</Link></li>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Query

                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionSidebar">
                  <div className="accordion-body">
                    <li className={`${window.location.href?.includes('expertQuery') ? 'active' : ""}`}><Link to={'/admin/expertQuery'}><img src={myaccount} alt={''} />Expert Query</Link></li>
                    <li className={`${window.location.href?.includes('customerQuery') ? 'active' : ""}`}><Link to={'/admin/customerQuery'}><img src={myaccount} alt={''} />Customer Query</Link></li>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Sponsor Adds

                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionSidebar">
                  <div className="accordion-body">
                    {/* <li className={`${window.location.href?.includes('sponsorAdds') ? 'active' : ""}`}><Link to={'/admin/sponsorAdds'}><img src={myaccount} alt={''} />Add Sponsor Adds</Link></li> */}
                    <li className={`${window.location.href?.includes('sponsorAddsList') ? 'active' : ""}`}><Link to={'/admin/sponsorAddsList'}><img src={myaccount} alt={''} />Sponsor Adds</Link></li>
                  </div>
                </div>
              </div>

            </div>
            <li className={`${window.location.href?.includes('adminCategory') ? 'active' : ""}`}><Link to={'/admin/adminCategory'}><img src={myaccount} alt={''} />Admin Category</Link></li>

            <li className={`${window.location.href?.includes('role') ? 'active' : ""}`}><Link to={'/admin/role'}><img src={myaccount} alt={''} />Role Managements</Link></li>

            <li onClick={logout}><a  ><img src={logoutIcon} alt={''} />Logout</a></li>

          </ul>
        </div>
      </div>
    </>

  );
};

export default SideBarsJsx;