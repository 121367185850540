
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'aos/dist/aos.css';
import { Footer } from '../../common/footer/footer';
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import './articleDeatils.model.css'; 

const ArticleDeatilsJsx  = (props) => {
  const {
    detialsData 
  } = props;

 
  // const params = new Proxy(new URLSearchParams(window.location.search), {
  //   get: (searchParams, prop) => searchParams.get(prop),
  // });

  const { t } = useTranslation();
 

  
  useEffect(() => { 
    console.log("===>>>> 00000000")
  }, []);




  return (
    <>
      <section className="homecustom">
        <section  >
          <div className="container"> 
          <div className="row col-12">
           
          <p className="articleflow">{t("HEADER.HOME")} / {detialsData?.body[0].cats[0].name } / <span className="articleflowsub">{detialsData?.body[0].title}</span></p>
         
          <h1 className="articlesubheading">{detialsData?.body[0].cats[0].name}</h1>
          <div className="subunderlines"></div>
          </div>
           
            <div className="modal-header articlepart">
              <h1 className="articleTitle">{detialsData?.body[0].title}</h1>
            </div>
            <div className="articlepart" >
              <p className="articlepublishdate">{detialsData?.body[0].date_publish}</p>
            </div>
            <div className="modal-body  articlepart">
              <div className="blog-image-dv articleimage"><img src={detialsData?.body[0].image} alt={' '} />
              </div>
              <div className="blog-content-dv articledetails" dangerouslySetInnerHTML={{ __html: detialsData?.body[0].content }}></div>
            </div>
          </div>
        </section>
        <Footer />
      </section>

    </>

  );
};

export default ArticleDeatilsJsx;