import React from 'react'
import { useEffect } from 'react';
import { Footer } from '../../../common/footer/footer';
import leftarrow from "../../../../assets/images/left-arrow.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";


const ProductListingJsx = (props) => {
  const history = useHistory();
  const {    
    categoryData,
    subCategoryData,
    onSubmitButtonClick,
    formData,
    onInputChange,
    category,
    subCategory, 
    productData
  } = props;
  const { categoryId, subcategoryId } = useParams()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <section className="listofoffermainsection">
        <div className="container">
          <div className="leftsightbackdetails listofofferleft padtop60">
            <div className="back_btn" onClick={() => history.goBack()}><img src={leftarrow} alt={' '} /></div>
            <div className="plaindetailsleft">
              <h1 className="h2_style font-weight-bold">List of Products</h1>
              <h5 className="h5_style">We collect the best products for you! Explore our top offerings & bolster your finances. </h5>
              <h5 className="h5_style font-weight-bold">Click on “View Details” for a detailed view. </h5>
              <h5 className="h5_style font-weight-bold">You can also refine your search by entering the name of the product you're looking for.  </h5>
              {/* <h5 className="h5_style">Have you tried it yet? Just tap on any icon below!</h5> */}
            </div>
          </div>
          <div className="newserachfield">
            <div className="myexpertformarea searcharea martop40">
              <div className="input-container form-group">
                <select
                  className="input form-control form-select"
                  placeholder=" "
                  name="category"
                  onChange={onInputChange}
                // onBlur={onTouchHandler}
                >
                  <option value="">All</option>
                  {categoryData?.map((item) => {
                    return (
                      <>
                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId ==
                          categoryId}>{item.name}</option>
                      </>
                    )
                  })}
                </select>
                <div className="placeholder">Category</div>
              </div>
              <div className="input-container form-group">
                <select
                  className="input form-control form-select"
                  placeholder=" "
                  name="subCategory"
                  onChange={onInputChange}
                  value={formData.subCategory}

                // onBlur={onTouchHandler}
                >
                  <option value="">All</option>
                  {subCategoryData?.map((item, i) => {
                    return (
                      <>
                        <option value={item.institutionCategoryId} selected={item.institutionCategoryId == subcategoryId}>{item.name}</option>
                      </>
                    )
                  })}
                </select>
                <div className="placeholder">Sub Category</div>
              </div>
              <div className="input-container form-group">
                <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search Products</button>
              </div>
            </div>
          </div>
          <div className="listofproduct mt-5 mb-5">
            <div className="row">
            {
                productData.length > 0 ? 
                productData?.map(data => {
                  return <div className="col-lg-6 col-sm-6">
                  <div className="listofadinner mb-4">
                    <div className="listofimage">
                      {/* <img src="https://testprod.rimib.com/images/institute-product/e3518bd9-de5a-44b5-90d8-1c200ca2d0df.jpg" alt={' '} /> */}
                      <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />
                    </div>
                    <div className="listofadinnerpop">
                      <div className="listofadtext">
                        <h3>{data?.productName}</h3>
                      </div>
                      <div className="listofadbutton">
                        {data?.externalLink &&
                        <Link
                          to={{
                            pathname: data?.externalLink,
                            state: { data: data, category: category, subCategory: subCategory }
                          }}
                          target="_blank"
                          className="ctaonebutton"
                          onClick={() => window.scrollTo(0, 0)}>
                          Apply Now
                              </Link> }
                        <Link
                          to={{
                            pathname: "/detailedProduct",
                            state: { data: data, category: category, subCategory: subCategory }
                          }}
                          className="ctatwobutton ctanewtwobutton"
                          onClick={() => window.scrollTo(0, 0)}>
                          View Details
                              </Link>
  
                      </div>
                    </div>
                  </div>
                </div>
                }) :
                "No products found"
              }
              
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>

  );
};

export default ProductListingJsx;
