import React, { useState } from 'react'
import { useEffect, lazy } from 'react';
import InputMask from 'react-input-mask';
import { Trans, useTranslation } from "react-i18next";
import { NEW_URL } from '../../../constants/applicationConstants';
import { Footer } from '../../common/footer/footer';
import rimib11 from "../../../assets/homeimages/RealEstateblack.svg";
import rimib22 from "../../../assets/homeimages/Investmentblack.svg";
import rimib33 from "../../../assets/homeimages/Mortgageblack.svg";
import rimib44 from "../../../assets/homeimages/Insuranceblack.svg";
import rimib55 from "../../../assets/homeimages/Bankingblack.svg";
import askimg from "../../../assets/homeimages/askbtn.svg";
import mobilesearchinstitute from "../../../assets/homeimages/searchin.png";
import cat1 from "../../../assets/homeimages/blackblackicon.png";
import topcatimgicon1 from "../../../assets/homeimages/whiteiconall.png";
import mobilesearchexpert from "../../../assets/homeimages/mobilesearchexpert.svg";
import searchbg from "../../../assets//homeimages/searchexprtbg.png";

import Topinstitution from "../../../assets/homeimages/Top institution.png";
import insuranceicon11 from "../../../assets/homeimages/insuranceiconooff.png";
import insuranceicon1 from "../../../assets/homeimages/insuranceicononn.png";
import insuranceicon22 from "../../../assets/homeimages/insuranceiconofff2.png";
import insuranceicon2 from "../../../assets/homeimages/insuranceicon2.png";
import dropbox from "../../../assets/images/dropbox.svg";
import { onOfferClick } from '../../../shared/helper';


// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import "../home/home.css";
import "../home/homeresponsive.css";

import { useHistory } from "react-router-dom";
import { useRef } from 'react';
// import MagicSliderDots from 'react-magic-slider-dots';
// import 'react-magic-slider-dots/dist/magic-dots.css';

const BlogSection = lazy(() => import('./blogSection'));
const QuoteSection = lazy(() => import('./quotesSection'));
const ExpertSection = lazy(() => import('./expertSection'));
const ProductSection = lazy(() => import('./productSection'));
const OfferSection = lazy(() => import('./offerSection'));


const HomeJsx = (props) => {


    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const {
        blogData,
        onSelectBlogType,
        quoteData,
        onTouchHandler,
        onInputChangeHandler,
        formError,
        onButtonClick, 
        productData,
        featureProduct, featureOffer, getOfferById, getProductById, getOfferData, 
        getProductData, expertDataFull, sponsorAddsData,
        offerData, instituteData, selectedOfferCat, selecteddProductCat
    } = props;

    const location = useLocation()
    const history = useHistory();



    useEffect(() => {
        AOS.init();
        const hash = window.location.hash;
        if (hash) {
            // Remove the '#' character to get the section ID
            const sectionId = hash.substring(1);

            // Find the element with the corresponding ID
            const sectionElement = document.getElementById(sectionId);

            if (sectionElement) {
                // Scroll to the section
                sectionElement.scrollIntoView({ behavior: 'smooth' });

            }
        } else {
            window.scrollTo(0, 0);
        }
        // alert(hash) 
    }, [location])
 

    var settingstwo = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        autoplay: true,

        // initialSlide:2    
    };
    var settingsthree = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        // initialSlide:2  
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px',
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '0px',
                    slidesToScroll: 1
                }
            }
        ]
    };
    var exclusiveslider = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        adaptiveHeight: true,
        slidesToScroll: 1
    };
    var topproductslider = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var rimibslidermobile = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        adaptiveHeight: true,
        arrows: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false, arrows: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false, arrows: true,

                }
            }
        ]

    };



    var rimibsliderone = {
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        adaptiveHeight: true,
        arrows: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    arrows: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false, arrows: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false, arrows: true,

                }
            }
        ]

    };

    var rimibslidertwo = {
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true,
        arrows: false,
        dots: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,

                }
            }
        ]
    };

    var rimibsliderthree = {
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        adaptiveHeight: true,
        arrows: false,
        dots: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,

                }
            }
        ]
    };

    var rimibsliderfour = {
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 9000,
        adaptiveHeight: true,
        arrows: false,
        dots: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,

                }
            }
        ]
    };

    var rimibsliderfive = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        adaptiveHeight: true,
        arrows: false,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,

                }
            }
        ]
    };
    const sliderSettings = {
        infinite: false,
        speed: 700,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        adaptiveHeight: true,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        arrows: false,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: false,

                }
            }
        ]
    }

    var offersection = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        adaptiveHeight: true,
        arrows: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            }
        ]


    };

    var institutelogo = {
        infinite: false,
        rows: 2,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: true,
        arrows: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rows: 2,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    dots: true,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    dots: true,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,

                }
            }
        ]


    };


    var productsection = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        adaptiveHeight: true,
        slidesToScroll: 1,
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true, 
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 320,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                }
            }
        ]


    };


    const [activeButton, setActiveButton] = useState('insurance');
    const [showMoreOffer, setShowMoreOffer] = useState(false);
    const [showMoreProduct, setShowMoreProduct] = useState(false);

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };
    const [name, setName] = useState('');
    const handleChange = (event) => {
        const { value } = event.target;
        const isValid = /^[a-zA-Z\s]*$/.test(value);
        if (isValid) {
            setName(value);
        }
    };




    const [openModal, setOpenModal] = useState(false);
    const [blog, setBlog] = useState({});

    const onSelectClick = (title) => {
        onSelectBlogType(title);
    }

    const [showAll, setShowAll] = useState(false);
    const handleShowAll = () => {

    };


    const initialArticlesCount = 5;

    

      const [activeSlider, setActiveSlider] = useState(0);
      const sliderRefs = useRef([]);
    
      useEffect(() => {
        // Start autoplay on the first slider initially
        if (sliderRefs.current[0]) {
          sliderRefs.current[0].slickPlay();
        }
      }, [location]);

        // Function to handle end of a slider and trigger the next one
  const handleSliderEnd = (currentSliderIndex) => {
    sliderRefs.current[currentSliderIndex].slickPause(); // Stop the current slider

    const nextSliderIndex = currentSliderIndex + 1;
    if (nextSliderIndex < 5) {
      setActiveSlider(nextSliderIndex);
      sliderRefs.current[nextSliderIndex].slickPlay(); // Start the next slider
    } else {
      setActiveSlider(0);
      sliderRefs.current[0].slickPlay();
    }
  };

    // Slider settings
    const sliderSettingsn = {
        autoplay: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        afterChange: (current, slick,abc,xyz) => {
           
          // If at the last slide of the active slider, trigger handleSliderEnd
          if (current === 2) {
            handleSliderEnd(activeSlider);
          }
        },
      };

    return (
        <>
            <section className="homecustom">



                {/* start */}
                <section className="herosection p-0 overflow-hidden herosectionhid d-none d-sm-block">
                    <div className="container-fluid p-0">
                        <div className="heroinner row">
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[0] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block" id="rbg">
                                            <Link to={'/realEstate'}>
                                                <div className="rimib-block-letter">R</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.REALESTATE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="42" height="63" viewBox="0 0 42 63" fill="none">
                                                    <path d="M41.934 29.4V63L34.6016 63L32.0096 63L25.8864 63L23.5582 63L17.862 63V5.95069L15.0356 5.17808V0L23.5582 3.67111V63L25.8864 63V4.44073L32.0096 7.90399V63L34.6016 63L34.6921 25.4589L41.934 29.4Z" fill="#00831D" />
                                                    <path d="M14.8 7.34955L15.3097 62.9995L8.59474 62.9995L6.07995 62.9995L0 62.9994V21.9215L6.32239 18.3288L6.07995 62.9995L8.59474 62.9995V9.99249L14.8 7.34955Z" fill="#00831D" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>


                                    {
                                        sponsorAddsData?.['Real Estate'].map(offer => {
                                            return <div className="hover-content p-0">
                                                <div className="hover-content-inner">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.title}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn mt-4 mb-4" href={(offer?.externalUrl)} target="_blank">{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                </div>
                                                <div className="text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <a href="/realEstate" className="realbtn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={rimib11} className="me-3" alt="" /> {offer?.name}</a>
                                                </div>
                                            </div>
                                        })
                                    }
 <div>
                                        <div className="rimib-block" id="rbg">
                                            <Link to={'/realEstate'}>
                                                <div className="rimib-block-letter">R</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.REALESTATE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="42" height="63" viewBox="0 0 42 63" fill="none">
                                                    <path d="M41.934 29.4V63L34.6016 63L32.0096 63L25.8864 63L23.5582 63L17.862 63V5.95069L15.0356 5.17808V0L23.5582 3.67111V63L25.8864 63V4.44073L32.0096 7.90399V63L34.6016 63L34.6921 25.4589L41.934 29.4Z" fill="#00831D" />
                                                    <path d="M14.8 7.34955L15.3097 62.9995L8.59474 62.9995L6.07995 62.9995L0 62.9994V21.9215L6.32239 18.3288L6.07995 62.9995L8.59474 62.9995V9.99249L14.8 7.34955Z" fill="#00831D" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 2 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[1] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-two" id="rbg2">
                                            <Link to={'/investment'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INVESTMENT")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="64" viewBox="0 0 32 64" fill="none">
                                                    <path d="M20.2375 63.4336V17.395L11.5642 24.6643V63.4336H20.2375Z" fill="#86357A" />
                                                    <path d="M31.802 63.4339V8.91455L23.1287 14.9723V63.3121L31.802 63.4339Z" fill="#86357A" />
                                                    <path d="M0 63.4337V33.4919L8.67331 27.0875V63.4337H0Z" fill="#86357A" />
                                                    <path d="M23.8516 4.70914L0 21.8133V24.6639L25.5855 6.13448L28.0607 9.85755L31.8021 0.433105L20.5779 2.14664L23.8516 4.70914Z" fill="#86357A" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Investment?.map(offer => {
                                            return <div className="hover-content-too p-0 mb-5">
                                                <div className="hover-content-inner-too ">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.title}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-too mt-4 mb-4" href={(offer?.externalUrl)} target="_blank">{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <a href="/investment" className="realbtn-too " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib22} alt="" /> {offer?.name}</a>
                                                </div>
                                            </div>
                                        })
                                    }
<div>
                                        <div className="rimib-block rimib-block-two" id="rbg2">
                                            <Link to={'/investment'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INVESTMENT")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="64" viewBox="0 0 32 64" fill="none">
                                                    <path d="M20.2375 63.4336V17.395L11.5642 24.6643V63.4336H20.2375Z" fill="#86357A" />
                                                    <path d="M31.802 63.4339V8.91455L23.1287 14.9723V63.3121L31.802 63.4339Z" fill="#86357A" />
                                                    <path d="M0 63.4337V33.4919L8.67331 27.0875V63.4337H0Z" fill="#86357A" />
                                                    <path d="M23.8516 4.70914L0 21.8133V24.6639L25.5855 6.13448L28.0607 9.85755L31.8021 0.433105L20.5779 2.14664L23.8516 4.70914Z" fill="#86357A" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 2 */}
                            {/* 3 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[2] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-three" id="rbg3">
                                            <Link to={'/mortgage'}>
                                                <div className="rimib-block-letter">M</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.MORTGAGE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="88" height="63" viewBox="0 0 88 63" fill="none">
                                                    <path d="M14.5385 26.6539V63.0001H32.7116H58.154H72.6925V26.6539H87.231L43.6155 0L0 26.6539H14.5385Z" fill="#2F596F" />
                                                    <path d="M32 37L43.5 37.0003L55 37V63H32V37Z" fill="#F6F8FB" />
                                                    <path d="M21.6838 20.5966V63.0005" stroke="#F6F8FB" stroke-width="2.5" />
                                                    <path d="M65.2988 21.8076V63" stroke="#F6F8FB" stroke-width="2.5" />
                                                    <path d="M10 28.4997L43.4914 7.26923L77 28.4997" stroke="#F6F8FB" stroke-width="2.5" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>


                                    {
                                        sponsorAddsData?.Mortgage?.map(offer => {
                                            return <div className="hover-content-three p-0">
                                                <div className="hover-content-inner-three">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.title}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-three mt-4 mb-4" href={(offer?.externalUrl)} target="_blank">{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <a href="/mortgage" className="realbtn-three" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib33} alt="" />Mortgage</a>
                                                </div>
                                            </div>
                                        })
                                    }
<div>
                                        <div className="rimib-block rimib-block-three" id="rbg3">
                                            <Link to={'/mortgage'}>
                                                <div className="rimib-block-letter">M</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.MORTGAGE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="88" height="63" viewBox="0 0 88 63" fill="none">
                                                    <path d="M14.5385 26.6539V63.0001H32.7116H58.154H72.6925V26.6539H87.231L43.6155 0L0 26.6539H14.5385Z" fill="#2F596F" />
                                                    <path d="M32 37L43.5 37.0003L55 37V63H32V37Z" fill="#F6F8FB" />
                                                    <path d="M21.6838 20.5966V63.0005" stroke="#F6F8FB" stroke-width="2.5" />
                                                    <path d="M65.2988 21.8076V63" stroke="#F6F8FB" stroke-width="2.5" />
                                                    <path d="M10 28.4997L43.4914 7.26923L77 28.4997" stroke="#F6F8FB" stroke-width="2.5" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 3/ */}
                            {/* 4 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[3] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-four" id="rbg4">
                                            <Link to={'/insurance'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INSURANCE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
                                                    <path d="M0 31.5868C0.459758 10.2047 17.7944 4.19384 27.3727 3.38391C14.4995 9.2154 13.1542 25.4255 13.5373 32.7148C8.93976 28.3412 2.87349 29.7645 0 31.5868Z" fill="#FF4C00" />
                                                    <path d="M29.7088 32.4999L29.7089 4.49994C18.215 10.3314 15.6118 25.5715 15.8033 32.4559C20.4009 29.0542 27.0269 31.0826 29.7088 32.4999Z" fill="#FF4C00" />
                                                    <path d="M31.7089 32.4999L31.7089 4.49994C43.2028 10.3314 45.572 25.5715 45.3804 32.4559C40.7829 29.0542 34.3908 31.0826 31.7089 32.4999Z" fill="#FF4C00" />
                                                    <path d="M63.1741 32.7754C62.7143 11.3933 43.4215 4.25445 33.8432 3.44452C46.7164 9.276 47.7636 24.2985 47.3805 31.5878C51.9781 27.2142 60.3006 30.9531 63.1741 32.7754Z" fill="#FF4C00" />
                                                    <path d="M31.812 0H29.5558V3.32429H31.812V0Z" fill="#FF4C00" />
                                                    <path d="M30.7089 35.5V56.2726C30.2089 62.2735 26.7089 63.1968 24.7089 58.5806" stroke="#FF4C00" stroke-width="2" stroke-linecap="round" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Insurance?.map(offer => {
                                            return <div className="hover-content-four p-0">
                                                <div className="hover-content-inner-four">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.title}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center">
                                                        <a className=" findbtn-four mt-4 mb-4" href={(offer?.externalUrl)} target="_blank">{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <a href="/insurance" className=" realbtnfour" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib44} alt="" />Insurance</a>
                                                </div>
                                            </div>
                                        })
                                    }
 <div>
                                        <div className="rimib-block rimib-block-four" id="rbg4">
                                            <Link to={'/insurance'}>
                                                <div className="rimib-block-letter">I</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.INSURANCE")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="63" viewBox="0 0 64 63" fill="none">
                                                    <path d="M0 31.5868C0.459758 10.2047 17.7944 4.19384 27.3727 3.38391C14.4995 9.2154 13.1542 25.4255 13.5373 32.7148C8.93976 28.3412 2.87349 29.7645 0 31.5868Z" fill="#FF4C00" />
                                                    <path d="M29.7088 32.4999L29.7089 4.49994C18.215 10.3314 15.6118 25.5715 15.8033 32.4559C20.4009 29.0542 27.0269 31.0826 29.7088 32.4999Z" fill="#FF4C00" />
                                                    <path d="M31.7089 32.4999L31.7089 4.49994C43.2028 10.3314 45.572 25.5715 45.3804 32.4559C40.7829 29.0542 34.3908 31.0826 31.7089 32.4999Z" fill="#FF4C00" />
                                                    <path d="M63.1741 32.7754C62.7143 11.3933 43.4215 4.25445 33.8432 3.44452C46.7164 9.276 47.7636 24.2985 47.3805 31.5878C51.9781 27.2142 60.3006 30.9531 63.1741 32.7754Z" fill="#FF4C00" />
                                                    <path d="M31.812 0H29.5558V3.32429H31.812V0Z" fill="#FF4C00" />
                                                    <path d="M30.7089 35.5V56.2726C30.2089 62.2735 26.7089 63.1968 24.7089 58.5806" stroke="#FF4C00" stroke-width="2" stroke-linecap="round" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 4/ */}
                            {/* 5 */}
                            <div className="col-2 nopad p-0 impspace">
                                <Slider ref={(el) => (sliderRefs.current[4] = el)} {...sliderSettingsn}>
                                    <div>
                                        <div className="rimib-block rimib-block-five" id="rbg5">
                                            <Link to={'/banking'}>
                                                <div className="rimib-block-letter">B</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.BANKING")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="65" viewBox="0 0 51 65" fill="none">
                                                    <path d="M1 60.3861V57.3629H22.227V60.3861C20.8951 62.4325 18.0648 63.5643 16.8162 63.8744C6.49404 65.9208 1.97117 62.4015 1 60.3861Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 57.1306C21.8976 57.587 21.6644 58.0565 21.1683 58.5191C20.6717 58.9821 19.9352 59.4143 18.9975 59.7841C17.1242 60.523 14.5136 60.9872 11.6135 60.9872C8.71334 60.9872 6.10282 60.523 4.22944 59.7841C3.29178 59.4143 2.55525 58.9821 2.05865 58.5191C1.56255 58.0565 1.32933 57.587 1.32933 57.1306C1.32933 56.6741 1.56255 56.2047 2.05865 55.7421C2.55525 55.279 3.29178 54.8468 4.22944 54.477C6.10282 53.7382 8.71334 53.274 11.6135 53.274C14.5136 53.274 17.1242 53.7382 18.9975 54.477C19.9352 54.8468 20.6717 55.279 21.1683 55.7421C21.6644 56.2047 21.8976 56.6741 21.8976 57.1306Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 57.1301C19.8165 57.4538 19.6458 57.8 19.2548 58.1528C18.8637 58.5055 18.2794 58.8383 17.5294 59.1245C16.0315 59.6961 13.94 60.0565 11.6135 60.0565C9.2869 60.0565 7.1954 59.6961 5.69754 59.1245C4.94748 58.8383 4.36318 58.5055 3.97217 58.1528C3.58111 57.8 3.41038 57.4538 3.41038 57.1301C3.41038 56.8063 3.58111 56.4601 3.97217 56.1073C4.36318 55.7546 4.94748 55.4218 5.69754 55.1356C7.1954 54.5641 9.2869 54.2037 11.6135 54.2037C13.94 54.2037 16.0315 54.5641 17.5294 55.1356C18.2794 55.4218 18.8637 55.7546 19.2548 56.1073C19.6458 56.4601 19.8165 56.8063 19.8165 57.1301Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 57.8286C11.78 53.7356 5.43965 56.1232 3.28921 57.8286C2.66489 56.6658 3.28921 55.0379 8.4919 54.1077C12.654 53.3635 16.6081 54.4178 18.0648 55.0379C20.3956 55.9681 20.2847 57.2859 19.9378 57.8286Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 55.1413V52.1182H22.227V55.1413C20.8951 57.1878 18.0648 58.3195 16.8162 58.6296C6.49404 60.6761 1.97117 57.1568 1 55.1413Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 51.8852C21.8976 52.3416 21.6644 52.811 21.1683 53.2736C20.6717 53.7367 19.9352 54.1689 18.9975 54.5387C17.1242 55.2775 14.5136 55.7418 11.6135 55.7418C8.71334 55.7418 6.10282 55.2775 4.22944 54.5387C3.29178 54.1689 2.55525 53.7367 2.05865 53.2736C1.56255 52.811 1.32933 52.3416 1.32933 51.8852C1.32933 51.4287 1.56255 50.9593 2.05865 50.4967C2.55525 50.0336 3.29178 49.6014 4.22944 49.2316C6.10282 48.4928 8.71334 48.0285 11.6135 48.0285C14.5136 48.0285 17.1242 48.4928 18.9975 49.2316C19.9352 49.6014 20.6717 50.0336 21.1683 50.4967C21.6644 50.9593 21.8976 51.4287 21.8976 51.8852Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 51.8853C19.8165 52.2091 19.6458 52.5553 19.2548 52.9081C18.8637 53.2608 18.2794 53.5936 17.5294 53.8798C16.0315 54.4513 13.94 54.8117 11.6135 54.8117C9.2869 54.8117 7.1954 54.4513 5.69754 53.8798C4.94748 53.5936 4.36318 53.2608 3.97217 52.9081C3.58111 52.5553 3.41038 52.2091 3.41038 51.8853C3.41038 51.5615 3.58111 51.2153 3.97217 50.8626C4.36318 50.5098 4.94748 50.177 5.69754 49.8908C7.1954 49.3193 9.2869 48.9589 11.6135 48.9589C13.94 48.9589 16.0315 49.3193 17.5294 49.8908C18.2794 50.177 18.8637 50.5098 19.2548 50.8626C19.6458 51.2153 19.8165 51.5615 19.8165 51.8853Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 52.5831C11.78 48.4902 5.43965 50.8777 3.28921 52.5831C2.66489 51.4203 3.28921 49.7924 8.4919 48.8622C12.654 48.118 16.6081 49.1723 18.0648 49.7924C20.3956 50.7226 20.2847 52.0404 19.9378 52.5831Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 49.8955V46.8723H22.227V49.8955C20.8951 51.9419 18.0648 53.0737 16.8162 53.3838C6.49404 55.4302 1.97117 51.9109 1 49.8955Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 46.64C21.8976 47.0965 21.6644 47.5659 21.1683 48.0285C20.6717 48.4916 19.9352 48.9238 18.9975 49.2936C17.1242 50.0324 14.5136 50.4966 11.6135 50.4966C8.71334 50.4966 6.10282 50.0324 4.22944 49.2936C3.29178 48.9238 2.55525 48.4916 2.05865 48.0285C1.56255 47.5659 1.32933 47.0965 1.32933 46.64C1.32933 46.1836 1.56255 45.7141 2.05865 45.2515C2.55525 44.7885 3.29178 44.3563 4.22944 43.9865C6.10282 43.2476 8.71334 42.7834 11.6135 42.7834C14.5136 42.7834 17.1242 43.2476 18.9975 43.9865C19.9352 44.3563 20.6717 44.7885 21.1683 45.2515C21.6644 45.7141 21.8976 46.1836 21.8976 46.64Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 46.6399C19.8165 46.9637 19.6458 47.3098 19.2548 47.6626C18.8637 48.0154 18.2794 48.3481 17.5294 48.6343C16.0315 49.2059 13.94 49.5663 11.6135 49.5663C9.2869 49.5663 7.1954 49.2059 5.69754 48.6343C4.94748 48.3481 4.36318 48.0154 3.97217 47.6626C3.58111 47.3098 3.41038 46.9637 3.41038 46.6399C3.41038 46.3161 3.58111 45.9699 3.97217 45.6171C4.36318 45.2644 4.94748 44.9316 5.69754 44.6454C7.1954 44.0739 9.2869 43.7135 11.6135 43.7135C13.94 43.7135 16.0315 44.0739 17.5294 44.6454C18.2794 44.9316 18.8637 45.2644 19.2548 45.6171C19.6458 45.9699 19.8165 46.3161 19.8165 46.6399Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 47.3379C11.78 43.245 5.43965 45.6325 3.28921 47.3379C2.66489 46.1752 3.28921 44.5472 8.4919 43.617C12.654 42.8729 16.6081 43.9271 18.0648 44.5472C20.3956 45.4775 20.2847 46.7953 19.9378 47.3379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 44.6501V41.6269H22.227V44.6501C20.8951 46.6965 18.0648 47.8283 16.8162 48.1383C6.49404 50.1848 1.97117 46.6655 1 44.6501Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 41.3944C21.8976 41.8509 21.6644 42.3203 21.1683 42.7829C20.6717 43.246 19.9352 43.6782 18.9975 44.048C17.1242 44.7868 14.5136 45.251 11.6135 45.251C8.71334 45.251 6.10282 44.7868 4.22944 44.048C3.29178 43.6782 2.55525 43.246 2.05865 42.7829C1.56255 42.3203 1.32933 41.8509 1.32933 41.3944C1.32933 40.938 1.56255 40.4685 2.05865 40.0059C2.55525 39.5429 3.29178 39.1107 4.22944 38.7409C6.10282 38.002 8.71334 37.5378 11.6135 37.5378C14.5136 37.5378 17.1242 38.002 18.9975 38.7409C19.9352 39.1107 20.6717 39.5429 21.1683 40.0059C21.6644 40.4685 21.8976 40.938 21.8976 41.3944Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 41.3937C19.8165 41.7175 19.6458 42.0637 19.2548 42.4165C18.8637 42.7692 18.2794 43.102 17.5294 43.3882C16.0315 43.9597 13.94 44.3201 11.6135 44.3201C9.2869 44.3201 7.1954 43.9597 5.69754 43.3882C4.94748 43.102 4.36318 42.7692 3.97217 42.4165C3.58111 42.0637 3.41038 41.7175 3.41038 41.3937C3.41038 41.0699 3.58111 40.7238 3.97217 40.371C4.36318 40.0182 4.94748 39.6855 5.69754 39.3993C7.1954 38.8277 9.2869 38.4673 11.6135 38.4673C13.94 38.4673 16.0315 38.8277 17.5294 39.3993C18.2794 39.6855 18.8637 40.0182 19.2548 40.371C19.6458 40.7238 19.8165 41.0699 19.8165 41.3937Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 42.0922C11.78 37.9993 5.43965 40.3868 3.28921 42.0922C2.66489 40.9295 3.28921 39.3016 8.4919 38.3714C12.654 37.6272 16.6081 38.6814 18.0648 39.3016C20.3956 40.2318 20.2847 41.5496 19.9378 42.0922Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 39.4051V36.382H22.227V39.4051C20.8951 41.4516 18.0648 42.5833 16.8162 42.8934C6.49404 44.9399 1.97117 41.4206 1 39.4051Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 36.1491C21.8976 36.6055 21.6644 37.075 21.1683 37.5376C20.6717 38.0006 19.9352 38.4328 18.9975 38.8026C17.1242 39.5415 14.5136 40.0057 11.6135 40.0057C8.71334 40.0057 6.10282 39.5415 4.22944 38.8026C3.29178 38.4328 2.55525 38.0006 2.05865 37.5376C1.56255 37.075 1.32933 36.6055 1.32933 36.1491C1.32933 35.6926 1.56255 35.2232 2.05865 34.7606C2.55525 34.2975 3.29178 33.8653 4.22944 33.4955C6.10282 32.7567 8.71334 32.2925 11.6135 32.2925C14.5136 32.2925 17.1242 32.7567 18.9975 33.4955C19.9352 33.8653 20.6717 34.2975 21.1683 34.7606C21.6644 35.2232 21.8976 35.6926 21.8976 36.1491Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 36.149C19.8165 36.4728 19.6458 36.819 19.2548 37.1718C18.8637 37.5245 18.2794 37.8573 17.5294 38.1435C16.0315 38.715 13.94 39.0754 11.6135 39.0754C9.2869 39.0754 7.1954 38.715 5.69754 38.1435C4.94748 37.8573 4.36318 37.5245 3.97217 37.1718C3.58111 36.819 3.41038 36.4728 3.41038 36.149C3.41038 35.8252 3.58111 35.4791 3.97217 35.1263C4.36318 34.7735 4.94748 34.4408 5.69754 34.1546C7.1954 33.583 9.2869 33.2226 11.6135 33.2226C13.94 33.2226 16.0315 33.583 17.5294 34.1546C18.2794 34.4408 18.8637 34.7735 19.2548 35.1263C19.6458 35.4791 19.8165 35.8252 19.8165 36.149Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 36.8468C11.78 32.7539 5.43965 35.1414 3.28921 36.8468C2.66489 35.6841 3.28921 34.0562 8.4919 33.1259C12.654 32.3818 16.6081 33.436 18.0648 34.0562C20.3956 34.9864 20.2847 36.3042 19.9378 36.8468Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 34.1587V31.1356H22.227V34.1587C20.8951 36.2052 18.0648 37.3369 16.8162 37.647C6.49404 39.6935 1.97117 36.1742 1 34.1587Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 30.9035C21.8976 31.3599 21.6644 31.8294 21.1683 32.292C20.6717 32.755 19.9352 33.1872 18.9975 33.557C17.1242 34.2959 14.5136 34.7601 11.6135 34.7601C8.71334 34.7601 6.10282 34.2959 4.22944 33.557C3.29178 33.1872 2.55525 32.755 2.05865 32.292C1.56255 31.8294 1.32933 31.3599 1.32933 30.9035C1.32933 30.447 1.56255 29.9776 2.05865 29.515C2.55525 29.0519 3.29178 28.6197 4.22944 28.2499C6.10282 27.5111 8.71334 27.0469 11.6135 27.0469C14.5136 27.0469 17.1242 27.5111 18.9975 28.2499C19.9352 28.6197 20.6717 29.0519 21.1683 29.515C21.6644 29.9776 21.8976 30.447 21.8976 30.9035Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 30.9034C19.8165 31.2272 19.6458 31.5734 19.2548 31.9262C18.8637 32.2789 18.2794 32.6117 17.5294 32.8979C16.0315 33.4694 13.94 33.8298 11.6135 33.8298C9.2869 33.8298 7.1954 33.4694 5.69754 32.8979C4.94748 32.6117 4.36318 32.2789 3.97217 31.9262C3.58111 31.5734 3.41038 31.2272 3.41038 30.9034C3.41038 30.5796 3.58111 30.2335 3.97217 29.8807C4.36318 29.5279 4.94748 29.1952 5.69754 28.909C7.1954 28.3374 9.2869 27.977 11.6135 27.977C13.94 27.977 16.0315 28.3374 17.5294 28.909C18.2794 29.1952 18.8637 29.5279 19.2548 29.8807C19.6458 30.2335 19.8165 30.5796 19.8165 30.9034Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 31.6014C11.78 27.5085 5.43965 29.8961 3.28921 31.6014C2.66489 30.4387 3.28921 28.8108 8.4919 27.8806C12.654 27.1364 16.6081 28.1907 18.0648 28.8108C20.3956 29.741 20.2847 31.0588 19.9378 31.6014Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 28.9138V25.8906H22.227V28.9138C20.8951 30.9603 18.0648 32.092 16.8162 32.4021C6.49404 34.4485 1.97117 30.9292 1 28.9138Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 25.6579C21.8976 26.1143 21.6644 26.5838 21.1683 27.0464C20.6717 27.5094 19.9352 27.9416 18.9975 28.3114C17.1242 29.0503 14.5136 29.5145 11.6135 29.5145C8.71334 29.5145 6.10282 29.0503 4.22944 28.3114C3.29178 27.9416 2.55525 27.5094 2.05865 27.0464C1.56255 26.5838 1.32933 26.1143 1.32933 25.6579C1.32933 25.2014 1.56255 24.732 2.05865 24.2694C2.55525 23.8063 3.29178 23.3741 4.22944 23.0043C6.10282 22.2655 8.71334 21.8012 11.6135 21.8012C14.5136 21.8012 17.1242 22.2655 18.9975 23.0043C19.9352 23.3741 20.6717 23.8063 21.1683 24.2694C21.6644 24.732 21.8976 25.2014 21.8976 25.6579Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 25.6575C19.8165 25.9813 19.6458 26.3275 19.2548 26.6803C18.8637 27.033 18.2794 27.3658 17.5294 27.652C16.0315 28.2235 13.94 28.5839 11.6135 28.5839C9.2869 28.5839 7.1954 28.2235 5.69754 27.652C4.94748 27.3658 4.36318 27.033 3.97217 26.6803C3.58111 26.3275 3.41038 25.9813 3.41038 25.6575C3.41038 25.3337 3.58111 24.9876 3.97217 24.6348C4.36318 24.282 4.94748 23.9493 5.69754 23.6631C7.1954 23.0915 9.2869 22.7311 11.6135 22.7311C13.94 22.7311 16.0315 23.0915 17.5294 23.6631C18.2794 23.9493 18.8637 24.282 19.2548 24.6348C19.6458 24.9876 19.8165 25.3337 19.8165 25.6575Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 26.356C11.78 22.2631 5.43965 24.6506 3.28921 26.356C2.66489 25.1933 3.28921 23.5654 8.4919 22.6352C12.654 21.891 16.6081 22.9452 18.0648 23.5654C20.3956 24.4956 20.2847 25.8134 19.9378 26.356Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 23.6684V20.6453H22.227V23.6684C20.8951 25.7149 18.0648 26.8466 16.8162 27.1567C6.49404 29.2032 1.97117 25.6839 1 23.6684Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 20.413C21.8976 20.8694 21.6644 21.3389 21.1683 21.8015C20.6717 22.2645 19.9352 22.6967 18.9975 23.0665C17.1242 23.8054 14.5136 24.2696 11.6135 24.2696C8.71334 24.2696 6.10282 23.8054 4.22944 23.0665C3.29178 22.6967 2.55525 22.2645 2.05865 21.8015C1.56255 21.3389 1.32933 20.8694 1.32933 20.413C1.32933 19.9565 1.56255 19.4871 2.05865 19.0245C2.55525 18.5614 3.29178 18.1293 4.22944 17.7594C6.10282 17.0206 8.71334 16.5564 11.6135 16.5564C14.5136 16.5564 17.1242 17.0206 18.9975 17.7594C19.9352 18.1293 20.6717 18.5614 21.1683 19.0245C21.6644 19.4871 21.8976 19.9565 21.8976 20.413Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 20.4128C19.8165 20.7366 19.6458 21.0828 19.2548 21.4356C18.8637 21.7883 18.2794 22.1211 17.5294 22.4073C16.0315 22.9788 13.94 23.3392 11.6135 23.3392C9.2869 23.3392 7.1954 22.9788 5.69754 22.4073C4.94748 22.1211 4.36318 21.7883 3.97217 21.4356C3.58111 21.0828 3.41038 20.7366 3.41038 20.4128C3.41038 20.089 3.58111 19.7429 3.97217 19.3901C4.36318 19.0373 4.94748 18.7046 5.69754 18.4184C7.1954 17.8468 9.2869 17.4864 11.6135 17.4864C13.94 17.4864 16.0315 17.8468 17.5294 18.4184C18.2794 18.7046 18.8637 19.0373 19.2548 19.3901C19.6458 19.7429 19.8165 20.089 19.8165 20.4128Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 21.1108C11.78 17.0179 5.43965 19.4055 3.28921 21.1108C2.66489 19.9481 3.28921 18.3202 8.4919 17.39C12.654 16.6458 16.6081 17.7001 18.0648 18.3202C20.3956 19.2504 20.2847 20.5682 19.9378 21.1108Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 18.1776V15.1544H22.227V18.1776C20.8951 20.2241 18.0648 21.3558 16.8162 21.6659C6.49404 23.7123 1.97117 20.193 1 18.1776Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 14.9218C21.8976 15.3782 21.6644 15.8477 21.1683 16.3103C20.6717 16.7733 19.9352 17.2055 18.9975 17.5753C17.1242 18.3142 14.5136 18.7784 11.6135 18.7784C8.71334 18.7784 6.10282 18.3142 4.22944 17.5753C3.29178 17.2055 2.55525 16.7733 2.05865 16.3103C1.56255 15.8477 1.32933 15.3782 1.32933 14.9218C1.32933 14.4653 1.56255 13.9959 2.05865 13.5333C2.55525 13.0702 3.29178 12.638 4.22944 12.2682C6.10282 11.5294 8.71334 11.0652 11.6135 11.0652C14.5136 11.0652 17.1242 11.5294 18.9975 12.2682C19.9352 12.638 20.6717 13.0702 21.1683 13.5333C21.6644 13.9959 21.8976 14.4653 21.8976 14.9218Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 14.9216C19.8165 15.2453 19.6458 15.5915 19.2548 15.9443C18.8637 16.2971 18.2794 16.6298 17.5294 16.916C16.0315 17.4876 13.94 17.848 11.6135 17.848C9.2869 17.848 7.1954 17.4876 5.69754 16.916C4.94748 16.6298 4.36318 16.2971 3.97217 15.9443C3.58111 15.5915 3.41038 15.2453 3.41038 14.9216C3.41038 14.5978 3.58111 14.2516 3.97217 13.8988C4.36318 13.5461 4.94748 13.2133 5.69754 12.9271C7.1954 12.3556 9.2869 11.9952 11.6135 11.9952C13.94 11.9952 16.0315 12.3556 17.5294 12.9271C18.2794 13.2133 18.8637 13.5461 19.2548 13.8988C19.6458 14.2516 19.8165 14.5978 19.8165 14.9216Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 15.6193C11.78 11.5264 5.43965 13.9139 3.28921 15.6193C2.66489 14.4566 3.28921 12.8287 8.4919 11.8985C12.654 11.1543 16.6081 12.2085 18.0648 12.8287C20.3956 13.7589 20.2847 15.0767 19.9378 15.6193Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 12.9327V9.90948H22.227V12.9327C20.8951 14.9791 18.0648 16.1109 16.8162 16.4209C6.49404 18.4674 1.97117 14.9481 1 12.9327Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 9.67666C21.8976 10.1331 21.6644 10.6026 21.1683 11.0652C20.6717 11.5282 19.9352 11.9604 18.9975 12.3302C17.1242 13.0691 14.5136 13.5333 11.6135 13.5333C8.71334 13.5333 6.10282 13.0691 4.22944 12.3302C3.29178 11.9604 2.55525 11.5282 2.05865 11.0652C1.56255 10.6026 1.32933 10.1331 1.32933 9.67666C1.32933 9.2202 1.56255 8.75076 2.05865 8.28816C2.55525 7.8251 3.29178 7.39293 4.22944 7.02311C6.10282 6.28426 8.71334 5.82005 11.6135 5.82005C14.5136 5.82005 17.1242 6.28426 18.9975 7.02311C19.9352 7.39293 20.6717 7.8251 21.1683 8.28816C21.6644 8.75076 21.8976 9.2202 21.8976 9.67666Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 9.67662C19.8165 10.0004 19.6458 10.3466 19.2548 10.6994C18.8637 11.0521 18.2794 11.3849 17.5294 11.6711C16.0315 12.2426 13.94 12.603 11.6135 12.603C9.2869 12.603 7.1954 12.2426 5.69754 11.6711C4.94748 11.3849 4.36318 11.0521 3.97217 10.6994C3.58111 10.3466 3.41038 10.0004 3.41038 9.67662C3.41038 9.35284 3.58111 9.00666 3.97217 8.65387C4.36318 8.30113 4.94748 7.96837 5.69754 7.68217C7.1954 7.11063 9.2869 6.75022 11.6135 6.75022C13.94 6.75022 16.0315 7.11063 17.5294 7.68217C18.2794 7.96837 18.8637 8.30113 19.2548 8.65387C19.6458 9.00666 19.8165 9.35284 19.8165 9.67662Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 10.3746C11.78 6.28172 5.43965 8.66925 3.28921 10.3746C2.66489 9.2119 3.28921 7.58399 8.4919 6.65378C12.654 5.90961 16.6081 6.96385 18.0648 7.58399C20.3956 8.51419 20.2847 9.832 19.9378 10.3746Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 7.44212V4.41895H22.227V7.44212C20.8951 9.48858 18.0648 10.6203 16.8162 10.9304C6.49404 12.9769 1.97117 9.45757 1 7.44212Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 4.18593C21.8976 4.64239 21.6644 5.11183 21.1683 5.57443C20.6717 6.03749 19.9352 6.46966 18.9975 6.83948C17.1242 7.57833 14.5136 8.04254 11.6135 8.04254C8.71334 8.04254 6.10282 7.57833 4.22944 6.83948C3.29178 6.46966 2.55525 6.03749 2.05865 5.57443C1.56255 5.11183 1.32933 4.64239 1.32933 4.18593C1.32933 3.72948 1.56255 3.26004 2.05865 2.79744C2.55525 2.33438 3.29178 1.9022 4.22944 1.53239C6.10282 0.793537 8.71334 0.329325 11.6135 0.329325C14.5136 0.329325 17.1242 0.793537 18.9975 1.53239C19.9352 1.9022 20.6717 2.33438 21.1683 2.79744C21.6644 3.26004 21.8976 3.72948 21.8976 4.18593Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 4.18578C19.8165 4.50957 19.6458 4.85574 19.2548 5.20853C18.8637 5.56127 18.2794 5.89404 17.5294 6.18024C16.0315 6.75178 13.94 7.11218 11.6135 7.11218C9.2869 7.11218 7.1954 6.75178 5.69754 6.18024C4.94748 5.89404 4.36318 5.56127 3.97217 5.20853C3.58111 4.85574 3.41038 4.50957 3.41038 4.18578C3.41038 3.86199 3.58111 3.51582 3.97217 3.16303C4.36318 2.81029 4.94748 2.47752 5.69754 2.19132C7.1954 1.61978 9.2869 1.25938 11.6135 1.25938C13.94 1.25938 16.0315 1.61978 17.5294 2.19132C18.2794 2.47752 18.8637 2.81029 19.2548 3.16303C19.6458 3.51582 19.8165 3.86199 19.8165 4.18578Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9381 4.88379C11.7802 0.790874 5.4399 3.17841 3.28945 4.88379C2.66513 3.72106 3.28945 2.09314 8.49214 1.16293C12.6543 0.418768 16.6083 1.473 18.0651 2.09314C20.3959 3.02335 20.2849 4.34116 19.9381 4.88379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 60.3861V57.3629H50.4386V60.3861C49.1067 62.4325 46.2765 63.5643 45.0278 63.8744C34.7057 65.9208 30.1828 62.4015 29.2117 60.3861Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 57.1306C50.1093 57.587 49.8761 58.0565 49.38 58.5191C48.8834 58.9821 48.1469 59.4143 47.2092 59.7841C45.3358 60.523 42.7253 60.9872 39.8252 60.9872C36.925 60.9872 34.3145 60.523 32.4411 59.7841C31.5034 59.4143 30.7669 58.9821 30.2703 58.5191C29.7742 58.0565 29.541 57.587 29.541 57.1306C29.541 56.6741 29.7742 56.2047 30.2703 55.7421C30.7669 55.279 31.5034 54.8468 32.4411 54.477C34.3145 53.7382 36.925 53.274 39.8252 53.274C42.7253 53.274 45.3358 53.7382 47.2092 54.477C48.1469 54.8468 48.8834 55.279 49.38 55.7421C49.8761 56.2047 50.1093 56.6741 50.1093 57.1306Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 57.1301C48.0282 57.4538 47.8575 57.8 47.4664 58.1528C47.0754 58.5055 46.4911 58.8383 45.7411 59.1245C44.2432 59.6961 42.1517 60.0565 39.8251 60.0565C37.4986 60.0565 35.4071 59.6961 33.9092 59.1245C33.1592 58.8383 32.5748 58.5055 32.1838 58.1528C31.7928 57.8 31.622 57.4538 31.622 57.1301C31.622 56.8063 31.7928 56.4601 32.1838 56.1073C32.5748 55.7546 33.1592 55.4218 33.9092 55.1356C35.4071 54.5641 37.4986 54.2037 39.8251 54.2037C42.1517 54.2037 44.2432 54.5641 45.7411 55.1356C46.4911 55.4218 47.0754 55.7546 47.4664 56.1073C47.8575 56.4601 48.0282 56.8063 48.0282 57.1301Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 57.8286C39.9917 53.7356 33.6513 56.1232 31.5009 57.8286C30.8766 56.6658 31.5009 55.0379 36.7036 54.1077C40.8657 53.3635 44.8198 54.4178 46.2765 55.0379C48.6073 55.9681 48.4963 57.2859 48.1495 57.8286Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 55.1413V52.1182H50.4386V55.1413C49.1067 57.1878 46.2765 58.3195 45.0278 58.6296C34.7057 60.6761 30.1828 57.1568 29.2117 55.1413Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 51.8852C50.1093 52.3416 49.8761 52.811 49.38 53.2736C48.8834 53.7367 48.1469 54.1689 47.2092 54.5387C45.3358 55.2775 42.7253 55.7418 39.8252 55.7418C36.925 55.7418 34.3145 55.2775 32.4411 54.5387C31.5034 54.1689 30.7669 53.7367 30.2703 53.2736C29.7742 52.811 29.541 52.3416 29.541 51.8852C29.541 51.4287 29.7742 50.9593 30.2703 50.4967C30.7669 50.0336 31.5034 49.6014 32.4411 49.2316C34.3145 48.4928 36.925 48.0285 39.8252 48.0285C42.7253 48.0285 45.3358 48.4928 47.2092 49.2316C48.1469 49.6014 48.8834 50.0336 49.38 50.4967C49.8761 50.9593 50.1093 51.4287 50.1093 51.8852Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 51.8853C48.0282 52.2091 47.8575 52.5553 47.4664 52.9081C47.0754 53.2608 46.4911 53.5936 45.7411 53.8798C44.2432 54.4513 42.1517 54.8117 39.8251 54.8117C37.4986 54.8117 35.4071 54.4513 33.9092 53.8798C33.1592 53.5936 32.5748 53.2608 32.1838 52.9081C31.7928 52.5553 31.622 52.2091 31.622 51.8853C31.622 51.5615 31.7928 51.2153 32.1838 50.8626C32.5748 50.5098 33.1592 50.177 33.9092 49.8908C35.4071 49.3193 37.4986 48.9589 39.8251 48.9589C42.1517 48.9589 44.2432 49.3193 45.7411 49.8908C46.4911 50.177 47.0754 50.5098 47.4664 50.8626C47.8575 51.2153 48.0282 51.5615 48.0282 51.8853Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 52.5831C39.9917 48.4902 33.6513 50.8777 31.5009 52.5831C30.8766 51.4203 31.5009 49.7924 36.7036 48.8622C40.8657 48.118 44.8198 49.1723 46.2765 49.7924C48.6073 50.7226 48.4963 52.0404 48.1495 52.5831Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 49.8955V46.8723H50.4386V49.8955C49.1067 51.9419 46.2765 53.0737 45.0278 53.3838C34.7057 55.4302 30.1828 51.9109 29.2117 49.8955Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 46.64C50.1093 47.0965 49.8761 47.5659 49.38 48.0285C48.8834 48.4916 48.1469 48.9238 47.2092 49.2936C45.3358 50.0324 42.7253 50.4966 39.8252 50.4966C36.925 50.4966 34.3145 50.0324 32.4411 49.2936C31.5034 48.9238 30.7669 48.4916 30.2703 48.0285C29.7742 47.5659 29.541 47.0965 29.541 46.64C29.541 46.1836 29.7742 45.7141 30.2703 45.2515C30.7669 44.7885 31.5034 44.3563 32.4411 43.9865C34.3145 43.2476 36.925 42.7834 39.8252 42.7834C42.7253 42.7834 45.3358 43.2476 47.2092 43.9865C48.1469 44.3563 48.8834 44.7885 49.38 45.2515C49.8761 45.7141 50.1093 46.1836 50.1093 46.64Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 46.6399C48.0282 46.9637 47.8575 47.3098 47.4664 47.6626C47.0754 48.0154 46.4911 48.3481 45.7411 48.6343C44.2432 49.2059 42.1517 49.5663 39.8251 49.5663C37.4986 49.5663 35.4071 49.2059 33.9092 48.6343C33.1592 48.3481 32.5748 48.0154 32.1838 47.6626C31.7928 47.3098 31.622 46.9637 31.622 46.6399C31.622 46.3161 31.7928 45.9699 32.1838 45.6171C32.5748 45.2644 33.1592 44.9316 33.9092 44.6454C35.4071 44.0739 37.4986 43.7135 39.8251 43.7135C42.1517 43.7135 44.2432 44.0739 45.7411 44.6454C46.4911 44.9316 47.0754 45.2644 47.4664 45.6171C47.8575 45.9699 48.0282 46.3161 48.0282 46.6399Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 47.3379C39.9917 43.245 33.6513 45.6325 31.5009 47.3379C30.8766 46.1752 31.5009 44.5472 36.7036 43.617C40.8657 42.8729 44.8198 43.9271 46.2765 44.5472C48.6073 45.4775 48.4963 46.7953 48.1495 47.3379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 44.6501V41.6269H50.4386V44.6501C49.1067 46.6965 46.2765 47.8283 45.0278 48.1383C34.7057 50.1848 30.1828 46.6655 29.2117 44.6501Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 41.3944C50.1093 41.8509 49.8761 42.3203 49.38 42.7829C48.8834 43.246 48.1469 43.6782 47.2092 44.048C45.3358 44.7868 42.7253 45.251 39.8252 45.251C36.925 45.251 34.3145 44.7868 32.4411 44.048C31.5034 43.6782 30.7669 43.246 30.2703 42.7829C29.7742 42.3203 29.541 41.8509 29.541 41.3944C29.541 40.938 29.7742 40.4685 30.2703 40.0059C30.7669 39.5429 31.5034 39.1107 32.4411 38.7409C34.3145 38.002 36.925 37.5378 39.8252 37.5378C42.7253 37.5378 45.3358 38.002 47.2092 38.7409C48.1469 39.1107 48.8834 39.5429 49.38 40.0059C49.8761 40.4685 50.1093 40.938 50.1093 41.3944Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 41.3937C48.0282 41.7175 47.8575 42.0637 47.4664 42.4165C47.0754 42.7692 46.4911 43.102 45.7411 43.3882C44.2432 43.9597 42.1517 44.3201 39.8251 44.3201C37.4986 44.3201 35.4071 43.9597 33.9092 43.3882C33.1592 43.102 32.5748 42.7692 32.1838 42.4165C31.7928 42.0637 31.622 41.7175 31.622 41.3937C31.622 41.0699 31.7928 40.7238 32.1838 40.371C32.5748 40.0182 33.1592 39.6855 33.9092 39.3993C35.4071 38.8277 37.4986 38.4673 39.8251 38.4673C42.1517 38.4673 44.2432 38.8277 45.7411 39.3993C46.4911 39.6855 47.0754 40.0182 47.4664 40.371C47.8575 40.7238 48.0282 41.0699 48.0282 41.3937Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 42.0922C39.9917 37.9993 33.6513 40.3868 31.5009 42.0922C30.8766 40.9295 31.5009 39.3016 36.7036 38.3714C40.8657 37.6272 44.8198 38.6814 46.2765 39.3016C48.6073 40.2318 48.4963 41.5496 48.1495 42.0922Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 39.1587V36.1356H50.4386V39.1587C49.1067 41.2052 46.2765 42.3369 45.0278 42.647C34.7057 44.6935 30.1828 41.1742 29.2117 39.1587Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 35.9035C50.1093 36.3599 49.8761 36.8294 49.38 37.292C48.8834 37.755 48.1469 38.1872 47.2092 38.557C45.3358 39.2959 42.7253 39.7601 39.8252 39.7601C36.925 39.7601 34.3145 39.2959 32.4411 38.557C31.5034 38.1872 30.7669 37.755 30.2703 37.292C29.7742 36.8294 29.541 36.3599 29.541 35.9035C29.541 35.447 29.7742 34.9776 30.2703 34.515C30.7669 34.0519 31.5034 33.6197 32.4411 33.2499C34.3145 32.5111 36.925 32.0469 39.8252 32.0469C42.7253 32.0469 45.3358 32.5111 47.2092 33.2499C48.1469 33.6197 48.8834 34.0519 49.38 34.515C49.8761 34.9776 50.1093 35.447 50.1093 35.9035Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 35.9034C48.0282 36.2272 47.8575 36.5734 47.4664 36.9262C47.0754 37.2789 46.4911 37.6117 45.7411 37.8979C44.2432 38.4694 42.1517 38.8298 39.8251 38.8298C37.4986 38.8298 35.4071 38.4694 33.9092 37.8979C33.1592 37.6117 32.5748 37.2789 32.1838 36.9262C31.7928 36.5734 31.622 36.2272 31.622 35.9034C31.622 35.5796 31.7928 35.2335 32.1838 34.8807C32.5748 34.5279 33.1592 34.1952 33.9092 33.909C35.4071 33.3374 37.4986 32.977 39.8251 32.977C42.1517 32.977 44.2432 33.3374 45.7411 33.909C46.4911 34.1952 47.0754 34.5279 47.4664 34.8807C47.8575 35.2335 48.0282 35.5796 48.0282 35.9034Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 36.6014C39.9917 32.5085 33.6513 34.8961 31.5009 36.6014C30.8766 35.4387 31.5009 33.8108 36.7036 32.8806C40.8657 32.1364 44.8198 33.1907 46.2765 33.8108C48.6073 34.741 48.4963 36.0588 48.1495 36.6014Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 33.9138V30.8906H50.4386V33.9138C49.1067 35.9603 46.2765 37.092 45.0278 37.4021C34.7057 39.4485 30.1828 35.9292 29.2117 33.9138Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 30.6579C50.1093 31.1143 49.8761 31.5838 49.38 32.0464C48.8834 32.5094 48.1469 32.9416 47.2092 33.3114C45.3358 34.0503 42.7253 34.5145 39.8252 34.5145C36.925 34.5145 34.3145 34.0503 32.4411 33.3114C31.5034 32.9416 30.7669 32.5094 30.2703 32.0464C29.7742 31.5838 29.541 31.1143 29.541 30.6579C29.541 30.2014 29.7742 29.732 30.2703 29.2694C30.7669 28.8063 31.5034 28.3741 32.4411 28.0043C34.3145 27.2655 36.925 26.8012 39.8252 26.8012C42.7253 26.8012 45.3358 27.2655 47.2092 28.0043C48.1469 28.3741 48.8834 28.8063 49.38 29.2694C49.8761 29.732 50.1093 30.2014 50.1093 30.6579Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 30.6575C48.0282 30.9813 47.8575 31.3275 47.4664 31.6803C47.0754 32.033 46.4911 32.3658 45.7411 32.652C44.2432 33.2235 42.1517 33.5839 39.8251 33.5839C37.4986 33.5839 35.4071 33.2235 33.9092 32.652C33.1592 32.3658 32.5748 32.033 32.1838 31.6803C31.7928 31.3275 31.622 30.9813 31.622 30.6575C31.622 30.3337 31.7928 29.9876 32.1838 29.6348C32.5748 29.282 33.1592 28.9493 33.9092 28.6631C35.4071 28.0915 37.4986 27.7311 39.8251 27.7311C42.1517 27.7311 44.2432 28.0915 45.7411 28.6631C46.4911 28.9493 47.0754 29.282 47.4664 29.6348C47.8575 29.9876 48.0282 30.3337 48.0282 30.6575Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 31.356C39.9917 27.2631 33.6513 29.6506 31.5009 31.356C30.8766 30.1933 31.5009 28.5654 36.7036 27.6352C40.8657 26.891 44.8198 27.9452 46.2765 28.5654C48.6073 29.4956 48.4963 30.8134 48.1495 31.356Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 28.6684V25.6453H50.4386V28.6684C49.1067 30.7149 46.2765 31.8466 45.0278 32.1567C34.7057 34.2032 30.1828 30.6839 29.2117 28.6684Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 25.413C50.1093 25.8694 49.8761 26.3389 49.38 26.8015C48.8834 27.2645 48.1469 27.6967 47.2092 28.0665C45.3358 28.8054 42.7253 29.2696 39.8252 29.2696C36.925 29.2696 34.3145 28.8054 32.4411 28.0665C31.5034 27.6967 30.7669 27.2645 30.2703 26.8015C29.7742 26.3389 29.541 25.8694 29.541 25.413C29.541 24.9565 29.7742 24.4871 30.2703 24.0245C30.7669 23.5614 31.5034 23.1293 32.4411 22.7594C34.3145 22.0206 36.925 21.5564 39.8252 21.5564C42.7253 21.5564 45.3358 22.0206 47.2092 22.7594C48.1469 23.1293 48.8834 23.5614 49.38 24.0245C49.8761 24.4871 50.1093 24.9565 50.1093 25.413Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 25.4128C48.0282 25.7366 47.8575 26.0828 47.4664 26.4356C47.0754 26.7883 46.4911 27.1211 45.7411 27.4073C44.2432 27.9788 42.1517 28.3392 39.8251 28.3392C37.4986 28.3392 35.4071 27.9788 33.9092 27.4073C33.1592 27.1211 32.5748 26.7883 32.1838 26.4356C31.7928 26.0828 31.622 25.7366 31.622 25.4128C31.622 25.089 31.7928 24.7429 32.1838 24.3901C32.5748 24.0373 33.1592 23.7046 33.9092 23.4184C35.4071 22.8468 37.4986 22.4864 39.8251 22.4864C42.1517 22.4864 44.2432 22.8468 45.7411 23.4184C46.4911 23.7046 47.0754 24.0373 47.4664 24.3901C47.8575 24.7429 48.0282 25.089 48.0282 25.4128Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 26.1108C39.9917 22.0179 33.6513 24.4055 31.5009 26.1108C30.8766 24.9481 31.5009 23.3202 36.7036 22.39C40.8657 21.6458 44.8198 22.7001 46.2765 23.3202C48.6073 24.2504 48.4963 25.5682 48.1495 26.1108Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                    {
                                        sponsorAddsData?.Banking?.map(offer => {
                                            return <div className="hover-content-five p-0">
                                                <div className="hover-content-inner-five">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" className="mainadiamge" />
                                                    <h3 className="mar20">{offer?.title}</h3>
                                                    <p className="mb-2 mainsliderdescription">{offer?.description}</p>
                                                    <div className="  text-center  ">
                                                        <a className="findbtn-five mt-4 mb-4" href={(offer?.externalUrl)} target="_blank">{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                </div>
                                                <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                    <a href="/banking" className="realbtn-five " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib55} alt="" /> Banking</a>
                                                </div>
                                            </div>
                                        })
                                    }
<div>
                                        <div className="rimib-block rimib-block-five" id="rbg5">
                                            <Link to={'/banking'}>
                                                <div className="rimib-block-letter">B</div>
                                                <div className="rimib-block-letter-text">{t("HOMEPAGE.BANKING")}</div>
                                                <div className="rimib-block-letter-image"><svg xmlns="http://www.w3.org/2000/svg" width="51" height="65" viewBox="0 0 51 65" fill="none">
                                                    <path d="M1 60.3861V57.3629H22.227V60.3861C20.8951 62.4325 18.0648 63.5643 16.8162 63.8744C6.49404 65.9208 1.97117 62.4015 1 60.3861Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 57.1306C21.8976 57.587 21.6644 58.0565 21.1683 58.5191C20.6717 58.9821 19.9352 59.4143 18.9975 59.7841C17.1242 60.523 14.5136 60.9872 11.6135 60.9872C8.71334 60.9872 6.10282 60.523 4.22944 59.7841C3.29178 59.4143 2.55525 58.9821 2.05865 58.5191C1.56255 58.0565 1.32933 57.587 1.32933 57.1306C1.32933 56.6741 1.56255 56.2047 2.05865 55.7421C2.55525 55.279 3.29178 54.8468 4.22944 54.477C6.10282 53.7382 8.71334 53.274 11.6135 53.274C14.5136 53.274 17.1242 53.7382 18.9975 54.477C19.9352 54.8468 20.6717 55.279 21.1683 55.7421C21.6644 56.2047 21.8976 56.6741 21.8976 57.1306Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 57.1301C19.8165 57.4538 19.6458 57.8 19.2548 58.1528C18.8637 58.5055 18.2794 58.8383 17.5294 59.1245C16.0315 59.6961 13.94 60.0565 11.6135 60.0565C9.2869 60.0565 7.1954 59.6961 5.69754 59.1245C4.94748 58.8383 4.36318 58.5055 3.97217 58.1528C3.58111 57.8 3.41038 57.4538 3.41038 57.1301C3.41038 56.8063 3.58111 56.4601 3.97217 56.1073C4.36318 55.7546 4.94748 55.4218 5.69754 55.1356C7.1954 54.5641 9.2869 54.2037 11.6135 54.2037C13.94 54.2037 16.0315 54.5641 17.5294 55.1356C18.2794 55.4218 18.8637 55.7546 19.2548 56.1073C19.6458 56.4601 19.8165 56.8063 19.8165 57.1301Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 57.8286C11.78 53.7356 5.43965 56.1232 3.28921 57.8286C2.66489 56.6658 3.28921 55.0379 8.4919 54.1077C12.654 53.3635 16.6081 54.4178 18.0648 55.0379C20.3956 55.9681 20.2847 57.2859 19.9378 57.8286Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 55.1413V52.1182H22.227V55.1413C20.8951 57.1878 18.0648 58.3195 16.8162 58.6296C6.49404 60.6761 1.97117 57.1568 1 55.1413Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 51.8852C21.8976 52.3416 21.6644 52.811 21.1683 53.2736C20.6717 53.7367 19.9352 54.1689 18.9975 54.5387C17.1242 55.2775 14.5136 55.7418 11.6135 55.7418C8.71334 55.7418 6.10282 55.2775 4.22944 54.5387C3.29178 54.1689 2.55525 53.7367 2.05865 53.2736C1.56255 52.811 1.32933 52.3416 1.32933 51.8852C1.32933 51.4287 1.56255 50.9593 2.05865 50.4967C2.55525 50.0336 3.29178 49.6014 4.22944 49.2316C6.10282 48.4928 8.71334 48.0285 11.6135 48.0285C14.5136 48.0285 17.1242 48.4928 18.9975 49.2316C19.9352 49.6014 20.6717 50.0336 21.1683 50.4967C21.6644 50.9593 21.8976 51.4287 21.8976 51.8852Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 51.8853C19.8165 52.2091 19.6458 52.5553 19.2548 52.9081C18.8637 53.2608 18.2794 53.5936 17.5294 53.8798C16.0315 54.4513 13.94 54.8117 11.6135 54.8117C9.2869 54.8117 7.1954 54.4513 5.69754 53.8798C4.94748 53.5936 4.36318 53.2608 3.97217 52.9081C3.58111 52.5553 3.41038 52.2091 3.41038 51.8853C3.41038 51.5615 3.58111 51.2153 3.97217 50.8626C4.36318 50.5098 4.94748 50.177 5.69754 49.8908C7.1954 49.3193 9.2869 48.9589 11.6135 48.9589C13.94 48.9589 16.0315 49.3193 17.5294 49.8908C18.2794 50.177 18.8637 50.5098 19.2548 50.8626C19.6458 51.2153 19.8165 51.5615 19.8165 51.8853Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 52.5831C11.78 48.4902 5.43965 50.8777 3.28921 52.5831C2.66489 51.4203 3.28921 49.7924 8.4919 48.8622C12.654 48.118 16.6081 49.1723 18.0648 49.7924C20.3956 50.7226 20.2847 52.0404 19.9378 52.5831Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 49.8955V46.8723H22.227V49.8955C20.8951 51.9419 18.0648 53.0737 16.8162 53.3838C6.49404 55.4302 1.97117 51.9109 1 49.8955Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 46.64C21.8976 47.0965 21.6644 47.5659 21.1683 48.0285C20.6717 48.4916 19.9352 48.9238 18.9975 49.2936C17.1242 50.0324 14.5136 50.4966 11.6135 50.4966C8.71334 50.4966 6.10282 50.0324 4.22944 49.2936C3.29178 48.9238 2.55525 48.4916 2.05865 48.0285C1.56255 47.5659 1.32933 47.0965 1.32933 46.64C1.32933 46.1836 1.56255 45.7141 2.05865 45.2515C2.55525 44.7885 3.29178 44.3563 4.22944 43.9865C6.10282 43.2476 8.71334 42.7834 11.6135 42.7834C14.5136 42.7834 17.1242 43.2476 18.9975 43.9865C19.9352 44.3563 20.6717 44.7885 21.1683 45.2515C21.6644 45.7141 21.8976 46.1836 21.8976 46.64Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 46.6399C19.8165 46.9637 19.6458 47.3098 19.2548 47.6626C18.8637 48.0154 18.2794 48.3481 17.5294 48.6343C16.0315 49.2059 13.94 49.5663 11.6135 49.5663C9.2869 49.5663 7.1954 49.2059 5.69754 48.6343C4.94748 48.3481 4.36318 48.0154 3.97217 47.6626C3.58111 47.3098 3.41038 46.9637 3.41038 46.6399C3.41038 46.3161 3.58111 45.9699 3.97217 45.6171C4.36318 45.2644 4.94748 44.9316 5.69754 44.6454C7.1954 44.0739 9.2869 43.7135 11.6135 43.7135C13.94 43.7135 16.0315 44.0739 17.5294 44.6454C18.2794 44.9316 18.8637 45.2644 19.2548 45.6171C19.6458 45.9699 19.8165 46.3161 19.8165 46.6399Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 47.3379C11.78 43.245 5.43965 45.6325 3.28921 47.3379C2.66489 46.1752 3.28921 44.5472 8.4919 43.617C12.654 42.8729 16.6081 43.9271 18.0648 44.5472C20.3956 45.4775 20.2847 46.7953 19.9378 47.3379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 44.6501V41.6269H22.227V44.6501C20.8951 46.6965 18.0648 47.8283 16.8162 48.1383C6.49404 50.1848 1.97117 46.6655 1 44.6501Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 41.3944C21.8976 41.8509 21.6644 42.3203 21.1683 42.7829C20.6717 43.246 19.9352 43.6782 18.9975 44.048C17.1242 44.7868 14.5136 45.251 11.6135 45.251C8.71334 45.251 6.10282 44.7868 4.22944 44.048C3.29178 43.6782 2.55525 43.246 2.05865 42.7829C1.56255 42.3203 1.32933 41.8509 1.32933 41.3944C1.32933 40.938 1.56255 40.4685 2.05865 40.0059C2.55525 39.5429 3.29178 39.1107 4.22944 38.7409C6.10282 38.002 8.71334 37.5378 11.6135 37.5378C14.5136 37.5378 17.1242 38.002 18.9975 38.7409C19.9352 39.1107 20.6717 39.5429 21.1683 40.0059C21.6644 40.4685 21.8976 40.938 21.8976 41.3944Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 41.3937C19.8165 41.7175 19.6458 42.0637 19.2548 42.4165C18.8637 42.7692 18.2794 43.102 17.5294 43.3882C16.0315 43.9597 13.94 44.3201 11.6135 44.3201C9.2869 44.3201 7.1954 43.9597 5.69754 43.3882C4.94748 43.102 4.36318 42.7692 3.97217 42.4165C3.58111 42.0637 3.41038 41.7175 3.41038 41.3937C3.41038 41.0699 3.58111 40.7238 3.97217 40.371C4.36318 40.0182 4.94748 39.6855 5.69754 39.3993C7.1954 38.8277 9.2869 38.4673 11.6135 38.4673C13.94 38.4673 16.0315 38.8277 17.5294 39.3993C18.2794 39.6855 18.8637 40.0182 19.2548 40.371C19.6458 40.7238 19.8165 41.0699 19.8165 41.3937Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 42.0922C11.78 37.9993 5.43965 40.3868 3.28921 42.0922C2.66489 40.9295 3.28921 39.3016 8.4919 38.3714C12.654 37.6272 16.6081 38.6814 18.0648 39.3016C20.3956 40.2318 20.2847 41.5496 19.9378 42.0922Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 39.4051V36.382H22.227V39.4051C20.8951 41.4516 18.0648 42.5833 16.8162 42.8934C6.49404 44.9399 1.97117 41.4206 1 39.4051Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 36.1491C21.8976 36.6055 21.6644 37.075 21.1683 37.5376C20.6717 38.0006 19.9352 38.4328 18.9975 38.8026C17.1242 39.5415 14.5136 40.0057 11.6135 40.0057C8.71334 40.0057 6.10282 39.5415 4.22944 38.8026C3.29178 38.4328 2.55525 38.0006 2.05865 37.5376C1.56255 37.075 1.32933 36.6055 1.32933 36.1491C1.32933 35.6926 1.56255 35.2232 2.05865 34.7606C2.55525 34.2975 3.29178 33.8653 4.22944 33.4955C6.10282 32.7567 8.71334 32.2925 11.6135 32.2925C14.5136 32.2925 17.1242 32.7567 18.9975 33.4955C19.9352 33.8653 20.6717 34.2975 21.1683 34.7606C21.6644 35.2232 21.8976 35.6926 21.8976 36.1491Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 36.149C19.8165 36.4728 19.6458 36.819 19.2548 37.1718C18.8637 37.5245 18.2794 37.8573 17.5294 38.1435C16.0315 38.715 13.94 39.0754 11.6135 39.0754C9.2869 39.0754 7.1954 38.715 5.69754 38.1435C4.94748 37.8573 4.36318 37.5245 3.97217 37.1718C3.58111 36.819 3.41038 36.4728 3.41038 36.149C3.41038 35.8252 3.58111 35.4791 3.97217 35.1263C4.36318 34.7735 4.94748 34.4408 5.69754 34.1546C7.1954 33.583 9.2869 33.2226 11.6135 33.2226C13.94 33.2226 16.0315 33.583 17.5294 34.1546C18.2794 34.4408 18.8637 34.7735 19.2548 35.1263C19.6458 35.4791 19.8165 35.8252 19.8165 36.149Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 36.8468C11.78 32.7539 5.43965 35.1414 3.28921 36.8468C2.66489 35.6841 3.28921 34.0562 8.4919 33.1259C12.654 32.3818 16.6081 33.436 18.0648 34.0562C20.3956 34.9864 20.2847 36.3042 19.9378 36.8468Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 34.1587V31.1356H22.227V34.1587C20.8951 36.2052 18.0648 37.3369 16.8162 37.647C6.49404 39.6935 1.97117 36.1742 1 34.1587Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 30.9035C21.8976 31.3599 21.6644 31.8294 21.1683 32.292C20.6717 32.755 19.9352 33.1872 18.9975 33.557C17.1242 34.2959 14.5136 34.7601 11.6135 34.7601C8.71334 34.7601 6.10282 34.2959 4.22944 33.557C3.29178 33.1872 2.55525 32.755 2.05865 32.292C1.56255 31.8294 1.32933 31.3599 1.32933 30.9035C1.32933 30.447 1.56255 29.9776 2.05865 29.515C2.55525 29.0519 3.29178 28.6197 4.22944 28.2499C6.10282 27.5111 8.71334 27.0469 11.6135 27.0469C14.5136 27.0469 17.1242 27.5111 18.9975 28.2499C19.9352 28.6197 20.6717 29.0519 21.1683 29.515C21.6644 29.9776 21.8976 30.447 21.8976 30.9035Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 30.9034C19.8165 31.2272 19.6458 31.5734 19.2548 31.9262C18.8637 32.2789 18.2794 32.6117 17.5294 32.8979C16.0315 33.4694 13.94 33.8298 11.6135 33.8298C9.2869 33.8298 7.1954 33.4694 5.69754 32.8979C4.94748 32.6117 4.36318 32.2789 3.97217 31.9262C3.58111 31.5734 3.41038 31.2272 3.41038 30.9034C3.41038 30.5796 3.58111 30.2335 3.97217 29.8807C4.36318 29.5279 4.94748 29.1952 5.69754 28.909C7.1954 28.3374 9.2869 27.977 11.6135 27.977C13.94 27.977 16.0315 28.3374 17.5294 28.909C18.2794 29.1952 18.8637 29.5279 19.2548 29.8807C19.6458 30.2335 19.8165 30.5796 19.8165 30.9034Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 31.6014C11.78 27.5085 5.43965 29.8961 3.28921 31.6014C2.66489 30.4387 3.28921 28.8108 8.4919 27.8806C12.654 27.1364 16.6081 28.1907 18.0648 28.8108C20.3956 29.741 20.2847 31.0588 19.9378 31.6014Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 28.9138V25.8906H22.227V28.9138C20.8951 30.9603 18.0648 32.092 16.8162 32.4021C6.49404 34.4485 1.97117 30.9292 1 28.9138Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 25.6579C21.8976 26.1143 21.6644 26.5838 21.1683 27.0464C20.6717 27.5094 19.9352 27.9416 18.9975 28.3114C17.1242 29.0503 14.5136 29.5145 11.6135 29.5145C8.71334 29.5145 6.10282 29.0503 4.22944 28.3114C3.29178 27.9416 2.55525 27.5094 2.05865 27.0464C1.56255 26.5838 1.32933 26.1143 1.32933 25.6579C1.32933 25.2014 1.56255 24.732 2.05865 24.2694C2.55525 23.8063 3.29178 23.3741 4.22944 23.0043C6.10282 22.2655 8.71334 21.8012 11.6135 21.8012C14.5136 21.8012 17.1242 22.2655 18.9975 23.0043C19.9352 23.3741 20.6717 23.8063 21.1683 24.2694C21.6644 24.732 21.8976 25.2014 21.8976 25.6579Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 25.6575C19.8165 25.9813 19.6458 26.3275 19.2548 26.6803C18.8637 27.033 18.2794 27.3658 17.5294 27.652C16.0315 28.2235 13.94 28.5839 11.6135 28.5839C9.2869 28.5839 7.1954 28.2235 5.69754 27.652C4.94748 27.3658 4.36318 27.033 3.97217 26.6803C3.58111 26.3275 3.41038 25.9813 3.41038 25.6575C3.41038 25.3337 3.58111 24.9876 3.97217 24.6348C4.36318 24.282 4.94748 23.9493 5.69754 23.6631C7.1954 23.0915 9.2869 22.7311 11.6135 22.7311C13.94 22.7311 16.0315 23.0915 17.5294 23.6631C18.2794 23.9493 18.8637 24.282 19.2548 24.6348C19.6458 24.9876 19.8165 25.3337 19.8165 25.6575Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 26.356C11.78 22.2631 5.43965 24.6506 3.28921 26.356C2.66489 25.1933 3.28921 23.5654 8.4919 22.6352C12.654 21.891 16.6081 22.9452 18.0648 23.5654C20.3956 24.4956 20.2847 25.8134 19.9378 26.356Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 23.6684V20.6453H22.227V23.6684C20.8951 25.7149 18.0648 26.8466 16.8162 27.1567C6.49404 29.2032 1.97117 25.6839 1 23.6684Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 20.413C21.8976 20.8694 21.6644 21.3389 21.1683 21.8015C20.6717 22.2645 19.9352 22.6967 18.9975 23.0665C17.1242 23.8054 14.5136 24.2696 11.6135 24.2696C8.71334 24.2696 6.10282 23.8054 4.22944 23.0665C3.29178 22.6967 2.55525 22.2645 2.05865 21.8015C1.56255 21.3389 1.32933 20.8694 1.32933 20.413C1.32933 19.9565 1.56255 19.4871 2.05865 19.0245C2.55525 18.5614 3.29178 18.1293 4.22944 17.7594C6.10282 17.0206 8.71334 16.5564 11.6135 16.5564C14.5136 16.5564 17.1242 17.0206 18.9975 17.7594C19.9352 18.1293 20.6717 18.5614 21.1683 19.0245C21.6644 19.4871 21.8976 19.9565 21.8976 20.413Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 20.4128C19.8165 20.7366 19.6458 21.0828 19.2548 21.4356C18.8637 21.7883 18.2794 22.1211 17.5294 22.4073C16.0315 22.9788 13.94 23.3392 11.6135 23.3392C9.2869 23.3392 7.1954 22.9788 5.69754 22.4073C4.94748 22.1211 4.36318 21.7883 3.97217 21.4356C3.58111 21.0828 3.41038 20.7366 3.41038 20.4128C3.41038 20.089 3.58111 19.7429 3.97217 19.3901C4.36318 19.0373 4.94748 18.7046 5.69754 18.4184C7.1954 17.8468 9.2869 17.4864 11.6135 17.4864C13.94 17.4864 16.0315 17.8468 17.5294 18.4184C18.2794 18.7046 18.8637 19.0373 19.2548 19.3901C19.6458 19.7429 19.8165 20.089 19.8165 20.4128Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 21.1108C11.78 17.0179 5.43965 19.4055 3.28921 21.1108C2.66489 19.9481 3.28921 18.3202 8.4919 17.39C12.654 16.6458 16.6081 17.7001 18.0648 18.3202C20.3956 19.2504 20.2847 20.5682 19.9378 21.1108Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 18.1776V15.1544H22.227V18.1776C20.8951 20.2241 18.0648 21.3558 16.8162 21.6659C6.49404 23.7123 1.97117 20.193 1 18.1776Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 14.9218C21.8976 15.3782 21.6644 15.8477 21.1683 16.3103C20.6717 16.7733 19.9352 17.2055 18.9975 17.5753C17.1242 18.3142 14.5136 18.7784 11.6135 18.7784C8.71334 18.7784 6.10282 18.3142 4.22944 17.5753C3.29178 17.2055 2.55525 16.7733 2.05865 16.3103C1.56255 15.8477 1.32933 15.3782 1.32933 14.9218C1.32933 14.4653 1.56255 13.9959 2.05865 13.5333C2.55525 13.0702 3.29178 12.638 4.22944 12.2682C6.10282 11.5294 8.71334 11.0652 11.6135 11.0652C14.5136 11.0652 17.1242 11.5294 18.9975 12.2682C19.9352 12.638 20.6717 13.0702 21.1683 13.5333C21.6644 13.9959 21.8976 14.4653 21.8976 14.9218Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 14.9216C19.8165 15.2453 19.6458 15.5915 19.2548 15.9443C18.8637 16.2971 18.2794 16.6298 17.5294 16.916C16.0315 17.4876 13.94 17.848 11.6135 17.848C9.2869 17.848 7.1954 17.4876 5.69754 16.916C4.94748 16.6298 4.36318 16.2971 3.97217 15.9443C3.58111 15.5915 3.41038 15.2453 3.41038 14.9216C3.41038 14.5978 3.58111 14.2516 3.97217 13.8988C4.36318 13.5461 4.94748 13.2133 5.69754 12.9271C7.1954 12.3556 9.2869 11.9952 11.6135 11.9952C13.94 11.9952 16.0315 12.3556 17.5294 12.9271C18.2794 13.2133 18.8637 13.5461 19.2548 13.8988C19.6458 14.2516 19.8165 14.5978 19.8165 14.9216Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 15.6193C11.78 11.5264 5.43965 13.9139 3.28921 15.6193C2.66489 14.4566 3.28921 12.8287 8.4919 11.8985C12.654 11.1543 16.6081 12.2085 18.0648 12.8287C20.3956 13.7589 20.2847 15.0767 19.9378 15.6193Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 12.9327V9.90948H22.227V12.9327C20.8951 14.9791 18.0648 16.1109 16.8162 16.4209C6.49404 18.4674 1.97117 14.9481 1 12.9327Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 9.67666C21.8976 10.1331 21.6644 10.6026 21.1683 11.0652C20.6717 11.5282 19.9352 11.9604 18.9975 12.3302C17.1242 13.0691 14.5136 13.5333 11.6135 13.5333C8.71334 13.5333 6.10282 13.0691 4.22944 12.3302C3.29178 11.9604 2.55525 11.5282 2.05865 11.0652C1.56255 10.6026 1.32933 10.1331 1.32933 9.67666C1.32933 9.2202 1.56255 8.75076 2.05865 8.28816C2.55525 7.8251 3.29178 7.39293 4.22944 7.02311C6.10282 6.28426 8.71334 5.82005 11.6135 5.82005C14.5136 5.82005 17.1242 6.28426 18.9975 7.02311C19.9352 7.39293 20.6717 7.8251 21.1683 8.28816C21.6644 8.75076 21.8976 9.2202 21.8976 9.67666Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 9.67662C19.8165 10.0004 19.6458 10.3466 19.2548 10.6994C18.8637 11.0521 18.2794 11.3849 17.5294 11.6711C16.0315 12.2426 13.94 12.603 11.6135 12.603C9.2869 12.603 7.1954 12.2426 5.69754 11.6711C4.94748 11.3849 4.36318 11.0521 3.97217 10.6994C3.58111 10.3466 3.41038 10.0004 3.41038 9.67662C3.41038 9.35284 3.58111 9.00666 3.97217 8.65387C4.36318 8.30113 4.94748 7.96837 5.69754 7.68217C7.1954 7.11063 9.2869 6.75022 11.6135 6.75022C13.94 6.75022 16.0315 7.11063 17.5294 7.68217C18.2794 7.96837 18.8637 8.30113 19.2548 8.65387C19.6458 9.00666 19.8165 9.35284 19.8165 9.67662Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9378 10.3746C11.78 6.28172 5.43965 8.66925 3.28921 10.3746C2.66489 9.2119 3.28921 7.58399 8.4919 6.65378C12.654 5.90961 16.6081 6.96385 18.0648 7.58399C20.3956 8.51419 20.2847 9.832 19.9378 10.3746Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M1 7.44212V4.41895H22.227V7.44212C20.8951 9.48858 18.0648 10.6203 16.8162 10.9304C6.49404 12.9769 1.97117 9.45757 1 7.44212Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M21.8976 4.18593C21.8976 4.64239 21.6644 5.11183 21.1683 5.57443C20.6717 6.03749 19.9352 6.46966 18.9975 6.83948C17.1242 7.57833 14.5136 8.04254 11.6135 8.04254C8.71334 8.04254 6.10282 7.57833 4.22944 6.83948C3.29178 6.46966 2.55525 6.03749 2.05865 5.57443C1.56255 5.11183 1.32933 4.64239 1.32933 4.18593C1.32933 3.72948 1.56255 3.26004 2.05865 2.79744C2.55525 2.33438 3.29178 1.9022 4.22944 1.53239C6.10282 0.793537 8.71334 0.329325 11.6135 0.329325C14.5136 0.329325 17.1242 0.793537 18.9975 1.53239C19.9352 1.9022 20.6717 2.33438 21.1683 2.79744C21.6644 3.26004 21.8976 3.72948 21.8976 4.18593Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.8165 4.18578C19.8165 4.50957 19.6458 4.85574 19.2548 5.20853C18.8637 5.56127 18.2794 5.89404 17.5294 6.18024C16.0315 6.75178 13.94 7.11218 11.6135 7.11218C9.2869 7.11218 7.1954 6.75178 5.69754 6.18024C4.94748 5.89404 4.36318 5.56127 3.97217 5.20853C3.58111 4.85574 3.41038 4.50957 3.41038 4.18578C3.41038 3.86199 3.58111 3.51582 3.97217 3.16303C4.36318 2.81029 4.94748 2.47752 5.69754 2.19132C7.1954 1.61978 9.2869 1.25938 11.6135 1.25938C13.94 1.25938 16.0315 1.61978 17.5294 2.19132C18.2794 2.47752 18.8637 2.81029 19.2548 3.16303C19.6458 3.51582 19.8165 3.86199 19.8165 4.18578Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M19.9381 4.88379C11.7802 0.790874 5.4399 3.17841 3.28945 4.88379C2.66513 3.72106 3.28945 2.09314 8.49214 1.16293C12.6543 0.418768 16.6083 1.473 18.0651 2.09314C20.3959 3.02335 20.2849 4.34116 19.9381 4.88379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 60.3861V57.3629H50.4386V60.3861C49.1067 62.4325 46.2765 63.5643 45.0278 63.8744C34.7057 65.9208 30.1828 62.4015 29.2117 60.3861Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 57.1306C50.1093 57.587 49.8761 58.0565 49.38 58.5191C48.8834 58.9821 48.1469 59.4143 47.2092 59.7841C45.3358 60.523 42.7253 60.9872 39.8252 60.9872C36.925 60.9872 34.3145 60.523 32.4411 59.7841C31.5034 59.4143 30.7669 58.9821 30.2703 58.5191C29.7742 58.0565 29.541 57.587 29.541 57.1306C29.541 56.6741 29.7742 56.2047 30.2703 55.7421C30.7669 55.279 31.5034 54.8468 32.4411 54.477C34.3145 53.7382 36.925 53.274 39.8252 53.274C42.7253 53.274 45.3358 53.7382 47.2092 54.477C48.1469 54.8468 48.8834 55.279 49.38 55.7421C49.8761 56.2047 50.1093 56.6741 50.1093 57.1306Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 57.1301C48.0282 57.4538 47.8575 57.8 47.4664 58.1528C47.0754 58.5055 46.4911 58.8383 45.7411 59.1245C44.2432 59.6961 42.1517 60.0565 39.8251 60.0565C37.4986 60.0565 35.4071 59.6961 33.9092 59.1245C33.1592 58.8383 32.5748 58.5055 32.1838 58.1528C31.7928 57.8 31.622 57.4538 31.622 57.1301C31.622 56.8063 31.7928 56.4601 32.1838 56.1073C32.5748 55.7546 33.1592 55.4218 33.9092 55.1356C35.4071 54.5641 37.4986 54.2037 39.8251 54.2037C42.1517 54.2037 44.2432 54.5641 45.7411 55.1356C46.4911 55.4218 47.0754 55.7546 47.4664 56.1073C47.8575 56.4601 48.0282 56.8063 48.0282 57.1301Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 57.8286C39.9917 53.7356 33.6513 56.1232 31.5009 57.8286C30.8766 56.6658 31.5009 55.0379 36.7036 54.1077C40.8657 53.3635 44.8198 54.4178 46.2765 55.0379C48.6073 55.9681 48.4963 57.2859 48.1495 57.8286Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 55.1413V52.1182H50.4386V55.1413C49.1067 57.1878 46.2765 58.3195 45.0278 58.6296C34.7057 60.6761 30.1828 57.1568 29.2117 55.1413Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 51.8852C50.1093 52.3416 49.8761 52.811 49.38 53.2736C48.8834 53.7367 48.1469 54.1689 47.2092 54.5387C45.3358 55.2775 42.7253 55.7418 39.8252 55.7418C36.925 55.7418 34.3145 55.2775 32.4411 54.5387C31.5034 54.1689 30.7669 53.7367 30.2703 53.2736C29.7742 52.811 29.541 52.3416 29.541 51.8852C29.541 51.4287 29.7742 50.9593 30.2703 50.4967C30.7669 50.0336 31.5034 49.6014 32.4411 49.2316C34.3145 48.4928 36.925 48.0285 39.8252 48.0285C42.7253 48.0285 45.3358 48.4928 47.2092 49.2316C48.1469 49.6014 48.8834 50.0336 49.38 50.4967C49.8761 50.9593 50.1093 51.4287 50.1093 51.8852Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 51.8853C48.0282 52.2091 47.8575 52.5553 47.4664 52.9081C47.0754 53.2608 46.4911 53.5936 45.7411 53.8798C44.2432 54.4513 42.1517 54.8117 39.8251 54.8117C37.4986 54.8117 35.4071 54.4513 33.9092 53.8798C33.1592 53.5936 32.5748 53.2608 32.1838 52.9081C31.7928 52.5553 31.622 52.2091 31.622 51.8853C31.622 51.5615 31.7928 51.2153 32.1838 50.8626C32.5748 50.5098 33.1592 50.177 33.9092 49.8908C35.4071 49.3193 37.4986 48.9589 39.8251 48.9589C42.1517 48.9589 44.2432 49.3193 45.7411 49.8908C46.4911 50.177 47.0754 50.5098 47.4664 50.8626C47.8575 51.2153 48.0282 51.5615 48.0282 51.8853Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 52.5831C39.9917 48.4902 33.6513 50.8777 31.5009 52.5831C30.8766 51.4203 31.5009 49.7924 36.7036 48.8622C40.8657 48.118 44.8198 49.1723 46.2765 49.7924C48.6073 50.7226 48.4963 52.0404 48.1495 52.5831Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 49.8955V46.8723H50.4386V49.8955C49.1067 51.9419 46.2765 53.0737 45.0278 53.3838C34.7057 55.4302 30.1828 51.9109 29.2117 49.8955Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 46.64C50.1093 47.0965 49.8761 47.5659 49.38 48.0285C48.8834 48.4916 48.1469 48.9238 47.2092 49.2936C45.3358 50.0324 42.7253 50.4966 39.8252 50.4966C36.925 50.4966 34.3145 50.0324 32.4411 49.2936C31.5034 48.9238 30.7669 48.4916 30.2703 48.0285C29.7742 47.5659 29.541 47.0965 29.541 46.64C29.541 46.1836 29.7742 45.7141 30.2703 45.2515C30.7669 44.7885 31.5034 44.3563 32.4411 43.9865C34.3145 43.2476 36.925 42.7834 39.8252 42.7834C42.7253 42.7834 45.3358 43.2476 47.2092 43.9865C48.1469 44.3563 48.8834 44.7885 49.38 45.2515C49.8761 45.7141 50.1093 46.1836 50.1093 46.64Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 46.6399C48.0282 46.9637 47.8575 47.3098 47.4664 47.6626C47.0754 48.0154 46.4911 48.3481 45.7411 48.6343C44.2432 49.2059 42.1517 49.5663 39.8251 49.5663C37.4986 49.5663 35.4071 49.2059 33.9092 48.6343C33.1592 48.3481 32.5748 48.0154 32.1838 47.6626C31.7928 47.3098 31.622 46.9637 31.622 46.6399C31.622 46.3161 31.7928 45.9699 32.1838 45.6171C32.5748 45.2644 33.1592 44.9316 33.9092 44.6454C35.4071 44.0739 37.4986 43.7135 39.8251 43.7135C42.1517 43.7135 44.2432 44.0739 45.7411 44.6454C46.4911 44.9316 47.0754 45.2644 47.4664 45.6171C47.8575 45.9699 48.0282 46.3161 48.0282 46.6399Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 47.3379C39.9917 43.245 33.6513 45.6325 31.5009 47.3379C30.8766 46.1752 31.5009 44.5472 36.7036 43.617C40.8657 42.8729 44.8198 43.9271 46.2765 44.5472C48.6073 45.4775 48.4963 46.7953 48.1495 47.3379Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 44.6501V41.6269H50.4386V44.6501C49.1067 46.6965 46.2765 47.8283 45.0278 48.1383C34.7057 50.1848 30.1828 46.6655 29.2117 44.6501Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 41.3944C50.1093 41.8509 49.8761 42.3203 49.38 42.7829C48.8834 43.246 48.1469 43.6782 47.2092 44.048C45.3358 44.7868 42.7253 45.251 39.8252 45.251C36.925 45.251 34.3145 44.7868 32.4411 44.048C31.5034 43.6782 30.7669 43.246 30.2703 42.7829C29.7742 42.3203 29.541 41.8509 29.541 41.3944C29.541 40.938 29.7742 40.4685 30.2703 40.0059C30.7669 39.5429 31.5034 39.1107 32.4411 38.7409C34.3145 38.002 36.925 37.5378 39.8252 37.5378C42.7253 37.5378 45.3358 38.002 47.2092 38.7409C48.1469 39.1107 48.8834 39.5429 49.38 40.0059C49.8761 40.4685 50.1093 40.938 50.1093 41.3944Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 41.3937C48.0282 41.7175 47.8575 42.0637 47.4664 42.4165C47.0754 42.7692 46.4911 43.102 45.7411 43.3882C44.2432 43.9597 42.1517 44.3201 39.8251 44.3201C37.4986 44.3201 35.4071 43.9597 33.9092 43.3882C33.1592 43.102 32.5748 42.7692 32.1838 42.4165C31.7928 42.0637 31.622 41.7175 31.622 41.3937C31.622 41.0699 31.7928 40.7238 32.1838 40.371C32.5748 40.0182 33.1592 39.6855 33.9092 39.3993C35.4071 38.8277 37.4986 38.4673 39.8251 38.4673C42.1517 38.4673 44.2432 38.8277 45.7411 39.3993C46.4911 39.6855 47.0754 40.0182 47.4664 40.371C47.8575 40.7238 48.0282 41.0699 48.0282 41.3937Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 42.0922C39.9917 37.9993 33.6513 40.3868 31.5009 42.0922C30.8766 40.9295 31.5009 39.3016 36.7036 38.3714C40.8657 37.6272 44.8198 38.6814 46.2765 39.3016C48.6073 40.2318 48.4963 41.5496 48.1495 42.0922Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 39.1587V36.1356H50.4386V39.1587C49.1067 41.2052 46.2765 42.3369 45.0278 42.647C34.7057 44.6935 30.1828 41.1742 29.2117 39.1587Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 35.9035C50.1093 36.3599 49.8761 36.8294 49.38 37.292C48.8834 37.755 48.1469 38.1872 47.2092 38.557C45.3358 39.2959 42.7253 39.7601 39.8252 39.7601C36.925 39.7601 34.3145 39.2959 32.4411 38.557C31.5034 38.1872 30.7669 37.755 30.2703 37.292C29.7742 36.8294 29.541 36.3599 29.541 35.9035C29.541 35.447 29.7742 34.9776 30.2703 34.515C30.7669 34.0519 31.5034 33.6197 32.4411 33.2499C34.3145 32.5111 36.925 32.0469 39.8252 32.0469C42.7253 32.0469 45.3358 32.5111 47.2092 33.2499C48.1469 33.6197 48.8834 34.0519 49.38 34.515C49.8761 34.9776 50.1093 35.447 50.1093 35.9035Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 35.9034C48.0282 36.2272 47.8575 36.5734 47.4664 36.9262C47.0754 37.2789 46.4911 37.6117 45.7411 37.8979C44.2432 38.4694 42.1517 38.8298 39.8251 38.8298C37.4986 38.8298 35.4071 38.4694 33.9092 37.8979C33.1592 37.6117 32.5748 37.2789 32.1838 36.9262C31.7928 36.5734 31.622 36.2272 31.622 35.9034C31.622 35.5796 31.7928 35.2335 32.1838 34.8807C32.5748 34.5279 33.1592 34.1952 33.9092 33.909C35.4071 33.3374 37.4986 32.977 39.8251 32.977C42.1517 32.977 44.2432 33.3374 45.7411 33.909C46.4911 34.1952 47.0754 34.5279 47.4664 34.8807C47.8575 35.2335 48.0282 35.5796 48.0282 35.9034Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 36.6014C39.9917 32.5085 33.6513 34.8961 31.5009 36.6014C30.8766 35.4387 31.5009 33.8108 36.7036 32.8806C40.8657 32.1364 44.8198 33.1907 46.2765 33.8108C48.6073 34.741 48.4963 36.0588 48.1495 36.6014Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 33.9138V30.8906H50.4386V33.9138C49.1067 35.9603 46.2765 37.092 45.0278 37.4021C34.7057 39.4485 30.1828 35.9292 29.2117 33.9138Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 30.6579C50.1093 31.1143 49.8761 31.5838 49.38 32.0464C48.8834 32.5094 48.1469 32.9416 47.2092 33.3114C45.3358 34.0503 42.7253 34.5145 39.8252 34.5145C36.925 34.5145 34.3145 34.0503 32.4411 33.3114C31.5034 32.9416 30.7669 32.5094 30.2703 32.0464C29.7742 31.5838 29.541 31.1143 29.541 30.6579C29.541 30.2014 29.7742 29.732 30.2703 29.2694C30.7669 28.8063 31.5034 28.3741 32.4411 28.0043C34.3145 27.2655 36.925 26.8012 39.8252 26.8012C42.7253 26.8012 45.3358 27.2655 47.2092 28.0043C48.1469 28.3741 48.8834 28.8063 49.38 29.2694C49.8761 29.732 50.1093 30.2014 50.1093 30.6579Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 30.6575C48.0282 30.9813 47.8575 31.3275 47.4664 31.6803C47.0754 32.033 46.4911 32.3658 45.7411 32.652C44.2432 33.2235 42.1517 33.5839 39.8251 33.5839C37.4986 33.5839 35.4071 33.2235 33.9092 32.652C33.1592 32.3658 32.5748 32.033 32.1838 31.6803C31.7928 31.3275 31.622 30.9813 31.622 30.6575C31.622 30.3337 31.7928 29.9876 32.1838 29.6348C32.5748 29.282 33.1592 28.9493 33.9092 28.6631C35.4071 28.0915 37.4986 27.7311 39.8251 27.7311C42.1517 27.7311 44.2432 28.0915 45.7411 28.6631C46.4911 28.9493 47.0754 29.282 47.4664 29.6348C47.8575 29.9876 48.0282 30.3337 48.0282 30.6575Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 31.356C39.9917 27.2631 33.6513 29.6506 31.5009 31.356C30.8766 30.1933 31.5009 28.5654 36.7036 27.6352C40.8657 26.891 44.8198 27.9452 46.2765 28.5654C48.6073 29.4956 48.4963 30.8134 48.1495 31.356Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M29.2117 28.6684V25.6453H50.4386V28.6684C49.1067 30.7149 46.2765 31.8466 45.0278 32.1567C34.7057 34.2032 30.1828 30.6839 29.2117 28.6684Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M50.1093 25.413C50.1093 25.8694 49.8761 26.3389 49.38 26.8015C48.8834 27.2645 48.1469 27.6967 47.2092 28.0665C45.3358 28.8054 42.7253 29.2696 39.8252 29.2696C36.925 29.2696 34.3145 28.8054 32.4411 28.0665C31.5034 27.6967 30.7669 27.2645 30.2703 26.8015C29.7742 26.3389 29.541 25.8694 29.541 25.413C29.541 24.9565 29.7742 24.4871 30.2703 24.0245C30.7669 23.5614 31.5034 23.1293 32.4411 22.7594C34.3145 22.0206 36.925 21.5564 39.8252 21.5564C42.7253 21.5564 45.3358 22.0206 47.2092 22.7594C48.1469 23.1293 48.8834 23.5614 49.38 24.0245C49.8761 24.4871 50.1093 24.9565 50.1093 25.413Z" fill="white" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.0282 25.4128C48.0282 25.7366 47.8575 26.0828 47.4664 26.4356C47.0754 26.7883 46.4911 27.1211 45.7411 27.4073C44.2432 27.9788 42.1517 28.3392 39.8251 28.3392C37.4986 28.3392 35.4071 27.9788 33.9092 27.4073C33.1592 27.1211 32.5748 26.7883 32.1838 26.4356C31.7928 26.0828 31.622 25.7366 31.622 25.4128C31.622 25.089 31.7928 24.7429 32.1838 24.3901C32.5748 24.0373 33.1592 23.7046 33.9092 23.4184C35.4071 22.8468 37.4986 22.4864 39.8251 22.4864C42.1517 22.4864 44.2432 22.8468 45.7411 23.4184C46.4911 23.7046 47.0754 24.0373 47.4664 24.3901C47.8575 24.7429 48.0282 25.089 48.0282 25.4128Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                    <path d="M48.1495 26.1108C39.9917 22.0179 33.6513 24.4055 31.5009 26.1108C30.8766 24.9481 31.5009 23.3202 36.7036 22.39C40.8657 21.6458 44.8198 22.7001 46.2765 23.3202C48.6073 24.2504 48.4963 25.5682 48.1495 26.1108Z" fill="#2280B8" stroke="white" stroke-width="0.65865" />
                                                </svg></div>
                                            </Link>
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                            {/* 5/ */}
                        </div>
                    </div>
                </section>
                {/* end */}
                <section className="herosection p-0 overflow-hidden herosectionhid d-block d-sm-none">
                    <div className="container-fluid p-0">
                        <div className="heroinner row">
                            <div className="col-2 nopad p-0 impspace">
                                <Slider {...rimibslidermobile}>

                                    {
                                        sponsorAddsData?.['Real Estate'].map(offer => {
                                            return <div className="hover-content hover-con-pos p-0">
                                                <div className="hover-content-inner">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                                                    <h3 className="mt-4 mb-2">{offer?.title}</h3>
                                                    <p className="mb-4">{offer?.description}</p>
                                                    <div className="text-center ">
                                                        <a className="findbtn mt-4 mb-4" href={(offer?.externalUrl)}>{t("HOMEPAGE.FIND")}</a>
                                                    </div><div className="text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                        <a href="/realEstate" className="realbtn" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img src={rimib11} className="me-3" alt="" /> Real Estate</a>
                                                    </div>
                                                </div>

                                            </div>
                                        })
                                    }



                                    {
                                        sponsorAddsData?.Investment?.map(offer => {
                                            return <div className="hover-content-too hover-con-pos p-0">
                                                <div className="hover-content-inner-too ">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                                                    <h3 className="mt-4 mb-2">{offer?.title}</h3>
                                                    <p className="mb-4">{offer?.description}</p>
                                                    <div className="  text-center  ">
                                                        <a className="findbtn-too mt-4 mb-4" href={(offer?.externalUrl)}>{t("HOMEPAGE.FIND")}</a>
                                                    </div><div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                        <a href="/investment" className="realbtn realbtn-too " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib22} alt="" /> Investment</a>
                                                    </div>
                                                </div>

                                            </div>
                                        })
                                    }




                                    {
                                        sponsorAddsData?.Mortgage?.map(offer => {
                                            return <div className="hover-content-three hover-con-pos p-0">
                                                <div className="hover-content-inner-three">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                                                    <h3 className="mt-4 mb-2">{offer?.title}</h3>
                                                    <p className="mb-4">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-three mt-4 mb-4" href={(offer?.externalUrl)}>{t("HOMEPAGE.FIND")}</a>
                                                    </div><div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                        <a href="/mortgage" className="realbtn realbtn-three" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib33} alt="" />Mortgage</a>
                                                    </div>
                                                </div>

                                            </div>
                                        })
                                    }


                                    {
                                        sponsorAddsData?.Insurance?.map(offer => {
                                            return <div className="hover-content-four hover-con-pos p-0">
                                                <div className="hover-content-inner-four">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                                                    <h3 className="mt-4 mb-2">{offer?.title}</h3>
                                                    <p className="mb-4">{offer?.description}</p>
                                                    <div className="  text-center ">
                                                        <a className="findbtn-four mt-4 mb-4" href={(offer?.externalUrl)}>{t("HOMEPAGE.FIND")}</a>
                                                    </div> <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                        <a href="/insurance" className="realbtn realbtnfour" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib44} alt="" />Insurance</a>
                                                    </div>
                                                </div>

                                            </div>
                                        })
                                    }


                                    {
                                        sponsorAddsData?.['Banking'].map(offer => {
                                            return <div className="hover-content-five hover-con-pos p-0">
                                                <div className="hover-content-inner-five">
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt="" />
                                                    <h3 className="mt-4 mb-2">{offer?.title}</h3>
                                                    <p className="mb-4">{offer?.description}</p>
                                                    <div className="  text-center  ">
                                                        <a className="findbtn-five mt-4 mb-4" href={(offer?.externalUrl)}>{t("HOMEPAGE.FIND")}</a>
                                                    </div>
                                                    <div className=" text-center mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                                        <a href="/banking" className="realbtn realbtn-five " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><img className="me-3" src={rimib55} alt="" /> Banking</a>
                                                    </div>
                                                </div>
                                            </div>

                                        })
                                    }

                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="question">
                    <div className="container">
                        <div className="row" style={{ alignItems: "center" }} >
                            <div className="col-md-6 d-flex align-items-center ">
                                <div className="textquestion">
                                    <h2>
                                        {t("HOMEPAGE.HOME")} <b>  {t("HOMEPAGE.MENU1")}</b>
                                    </h2>
                                    <p>{t("HOMEPAGE.MENU2")} <b className="textquestion-b">{t("HOMEPAGE.MENU3")}</b></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <a href="/AskTheExpert" style={{ textDecoration: "none" }}>
                                    <div className="askbutton">
                                        <p className="askres mb-0">{t("HOMEPAGE.MENU4")}</p>
                                        <img src={askimg} alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="whatareyou ">
                    <div className="container">
                        <h2>{t("HOME.INTERESTEDHEADING")} <span>{t("HOME.INTERESTEDSUBHEADING")}</span></h2>
                        <div className={`whatareyoucat  ${showMoreOffer ? 'showEnable' : ''}`}>
                            <div className={`whatarecatbox ${selectedOfferCat === 0 ? 'active' : ''}`} onClick={() => getOfferData()}>
                                <img src={cat1} alt="" />
                                <p className="hover-none">All</p>
                            </div>
                            {
                                featureOffer?.map(offer => {
                                    return <div className={`whatarecatbox what-res ${selectedOfferCat === offer?.institutionCategoryId ? 'active' : ''}`} onClick={() => getOfferById(offer)}>

                                        <img src={`${NEW_URL}/${offer?.icon}`} className="mb-1" alt="" />
                                        <p >{offer.name}</p>
                                    </div>
                                })
                            }


                        </div><div className="d-block d-sm-none smmore" onClick={(e) => { setShowMoreOffer(!showMoreOffer) }}>{showMoreOffer ? 'Show Less...' : 'Show More...'} </div>
                    </div>
                </section>

                <section className="exclusive">
                    <div className="container">
                        <div className="exclusive-text">
                            <h2>{t("HOMEPAGE.MENU5")}</h2>
                            <p>{t("HOMEPAGE.MENU6")}</p>
                        </div>
                        <div className="exclusive-slider ">
                            {offerData.length > 0 &&
                                <Slider {...offersection}>
                                    {
                                        offerData?.map(offer => {
                                            return <div className="p_sliderouter main_offers exclusivepadding" onClick={() => onOfferClick(offer)}>
                                                <div className="p_slider offer_inner exclusived">   
                                                <div className="sldeimage offer_image">                                                
                                                    <img src={`${NEW_URL}/${offer?.imageUrl}`} alt={''} />
                                                    {/* <Link to={'#'} className="applybutton">Apply Now</Link> */}
                                                </div>
                                                </div> 
                                            </div>
                                        })
                                    }
                                </Slider>
                            }
                        </div>
                        <div className="showall  text-center">
                            <Link to={'/offers'} className="showallbtn mt-3">{t("COMMON.SHOWALL")}</Link>
                        </div>

                    </div>
                </section>
                <section className="topproduct">
                    <div className="container">
                        <div className="topproduct-text">
                            <h2>{t("HOMEPAGE.MENU7")}</h2>
                            <p>{t("HOMEPAGE.MENU8")}</p>
                        </div>
                        <div className="exclusive-slider prodectsslider">
                            {productData.length > 0 &&
                                <Slider {...productsection}>
                                    {
                                        productData?.map(data => {
                                            return <div className="p_sliderouter product_main exclusivepadding" onClick={() => onOfferClick(data)}>
                                               
                                               <div className="p_slider product_inner">    
                                                <div className="sldeimage product_image">                                                                                                       
                                                    <img src={`${NEW_URL}/${data?.imageUrl}`} alt={''} />                                                   
                                                    {/* <Link to={'#'} className="applybutton">Apply Now</Link> */}
                                                </div>
                                                </div>
                                                
                                            </div>
                                        })
                                    }
                                </Slider>
                            }
                        </div>



                        <div className="  text-center">
                            <Link to={'/products'} className="topproductshowallbtn mt-3">{t("COMMON.SHOWALL")}</Link>
                        </div>
                        <div className={`topproductcat mb-3 ${showMoreProduct ? 'showEnable' : ''}`}>
                            <div className={`topproductcatbox ${selecteddProductCat === 0 ? 'active' : ''}`} onClick={() => getProductData()}>
                                <img src={topcatimgicon1} className="mb-1" alt="" alt="" />
                                <p>all</p>
                            </div>

                            {
                                featureProduct?.map(product => {
                                    return <div className={`topproductcatbox ${selecteddProductCat === product?.institutionCategoryId ? 'active' : ''}`} onClick={() => getProductById(product)}>
                                        <img src={`${NEW_URL}/${product?.icon}`} className="mb-1" alt="" />
                                        <p>{product.name}</p>
                                    </div>
                                })
                            }


                        </div>
                        <div className="d-block d-sm-none smmore" onClick={(e) => { setShowMoreProduct(!showMoreProduct) }}>{showMoreProduct ? 'Show Less...' : 'View All...'} </div>
                    </div>
                </section>
                <section className="ourbest">
                    <div className="container">
                        <div className="ourbexttext ourbestmargin" >
                            <h2>{t("HOME.EXPERTHEADING")}</h2>
                            <p className="mb-0">{t("HOME.EXPERTHEADING1")} {t("HOME.EXPERTHEADING2")}</p>
                        
                        </div>
                        <div className="row" style={{ justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>

                            {
                                expertDataFull?.map(product => {
                                    return <div className={`col-lg-2 col-12 category-id${product?.institutionCategoryId}`}>
                                        <Slider {...sliderSettings}>
                                            {
                                                product?.experts?.map(expert => {
                                                    return <div>
                                                        <Link to={`/expertPpv/${expert?.slugUrl}`} target="_blank">
                                                            <div className="ourexpertuser">
                                                                <div className="ourexpertinner">
                                                                    <div className="ourexpertinnerimg"> <img src={!expert?.imageUrl ? `https://ui-avatars.com/api/?name=${expert.firstName}+${expert.lastName}&rounded=false` : `${NEW_URL}/${expert?.imageUrl}`} alt=''/></div>
                                                                    <h3>{expert?.firstName} {expert?.lastName}</h3>
                                                                    <p>{expert?.description}</p>
                                                                </div>
                                                                <div className="ourexperticon" >
                                                                    <div className="ourexperticoninner" >
                                                                        <p className=" firstlatter mb-0 ms-3">
                                                                            {product.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                })
                                            }


                                        </Slider>
                                    </div>
                                })
                            }


                        </div>
                        <div className="showallourbesttwo  text-center mb-3 mb-5">
                            <Link to={'/globalMainSearchExpert'} className="showallourbesttoo">{t("COMMON.SHOWALL")}</Link>
                        </div>
                    </div>
                </section>
                <section className="search mt-5">
                    <div className="container" style={{ backgroundColor: "#E8FDAC" }}>
                        <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col-lg-6  col-9">
                                <div className="main-search">
                                    <h2>{t("HOMEPAGE.MENU9")}</h2>
                                    <p>
                                    <Trans i18nKey="HOMEPAGE.MENU10" >                  
                  </Trans></p>
                                    <div className="buttonsearch mt-4" >
                                        <Link to={'/globalMainSearchExpert'} className="searchnow  mb-4">{t("HOMEPAGE.SUBMENU1")}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none d-sm-block nopadright">
                                <div className="searchimg">
                                    <img src={searchbg} alt="" className="w-100" alt=''/>
                                </div>
                            </div>
                            <div className="searchbackcs d-block d-sm-none"><img src={mobilesearchexpert} alt=''/></div>
                        </div>
                    </div>
                </section>
                <section className="quotes"  >
                    <div className="container ">
                        <div className="quotes-inner">
                            <div className="quotes-main">
                                <h2>Quotes</h2>
                            </div>
                            <div className="row" >
                                <div className="col-lg-6 col-12">
                                    <div className="quotes-main-main" style={{ position: "relative" }}>
                                        <div className="quotes-text mortgagequot">
                                            <h2> {activeButton === 'mortgage'
                                                ? 'Find Your Perfect Quote!'
                                                : 'Your Perfect Quote is Here!'}</h2>

                                            <p> {activeButton === 'mortgage'
                                                ? 'Explore the best mortgage or insurance quotes designed specifically for you.'
                                                : 'Get the best mortgage or insurance quotes specifically designed for you.'}</p>
                                            <p className="weoffer"> {activeButton === 'mortgage'
                                                ? 'We offer various options to provide you with quotes that fit your specific needs.'
                                                : ''}</p>
                                            <div className="mainexplorenowbutton" >
                                                <a className="explorebtn mt-4 mb-4">{activeButton === 'mortgage' ? 'Explore Now!'
                                                    : 'Start the process now!'}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="quotes-main-right">
                                        <div className="quotes-bg-color">
                                            <div className="quotes-text-right ">
                                                <div className="quotes-on-btn">
                                                    <div className="text-center ">
                                                        <a
                                                            className={`quotesinsurancebtn ${activeButton === 'insurance' ? 'active' : ''}`}
                                                            onClick={() => handleButtonClick('insurance')}
                                                            style={activeButton === 'insurance' ? { backgroundColor: '#E34400' } : {}}
                                                        >
                                                            <img src={activeButton === 'insurance' ? insuranceicon1 : insuranceicon11} style={{ marginRight: '10px' }} alt="Insurance Icon" /> Insurance
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="quotes-on-btn">
                                                    <div className="text-center">
                                                        <a
                                                            className={`quotesmortgagebtn ${activeButton === 'mortgage' ? 'active' : ''}`}
                                                            onClick={() => handleButtonClick('mortgage')}
                                                            style={activeButton === 'mortgage' ? { backgroundColor: '#2F596F' } : {}}
                                                        >
                                                            <img
                                                                src={activeButton === 'mortgage' ? insuranceicon2 : insuranceicon22}
                                                                style={{ marginRight: '10px' }}
                                                                alt="Mortgage Icon"
                                                            /> Mortgage </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {activeButton === 'insurance' && (
                                            <div className="insuranceonclickform">

                                                <div className="row quotes-form">
                                                    <div className="col-12 mb-4 inputextsize">
                                                        <input type="text" className="form-control" placeholder=" " maxlength="30" name='name' placeholder="Your full Name" value={quoteData.name} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                                        <span className="text-danger">
                                                            {formError['name'] &&
                                                                (formError['name'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid name')}
                                                        </span>
                                                    </div>
                                                    <div className="col-12 mb-4 inputextsize">
                                                        <input type="text" className="form-control" placeholder="Your Email" maxlength="30" name='email' value={quoteData.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                                        <span className="text-danger">
                                                            {formError['email'] &&
                                                                (formError['email'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Email')}
                                                        </span>
                                                    </div>

                                                    <div className="col-6 mb-4 inputextsize">
                                                        <InputMask mask={'(999) 999 9999'} value={quoteData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                            {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Your phone number" disableUnderline />}
                                                        </InputMask>

                                                        <span className="text-danger">
                                                            {formError['phone'] &&
                                                                (formError['phone'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Phone Number')}
                                                        </span>
                                                    </div>
                                                    <div className="col-6 mb-4 inputextsize">
                                                        <InputMask mask={'a9a 9a9'} value={quoteData.postalCode} name="postalCode" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                            {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Postal Code" disableUnderline />}
                                                        </InputMask>

                                                        <span className="text-danger">
                                                            {formError['postalCode'] &&
                                                                (formError['postalCode'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Postal Code')}
                                                        </span>
                                                    </div>

                                                    <div className="col-12"><button type="button" className="showmebtn" onClick={() => onButtonClick()}>Show me Quotes!</button></div>

                                                </div>

                                            </div>
                                        )}
                                        {activeButton === 'mortgage' && (
                                            <div className="morgageonclickform">
                                                <div className="row quotes-form">
                                                    <div className="mb-4 col-12 inputextsize mortgageshadow">
                                                        <input type="text" className="form-control" placeholder=" " maxlength="30" name='name' placeholder="Your full Name" value={quoteData.name} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                                        <span className="text-danger">
                                                            {formError['name'] &&
                                                                (formError['name'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid name')}
                                                        </span>
                                                    </div>
                                                    <div className="mb-4 col-12 inputextsize mortgageshadow">
                                                        <input type="text" className="form-control" placeholder="Your Email" maxlength="30" name='email' value={quoteData.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />

                                                        <span className="text-danger">
                                                            {formError['email'] &&
                                                                (formError['email'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Email')}
                                                        </span>
                                                    </div>

                                                    <div className="mb-4 col-6 inputextsize mortgageshadow">
                                                        <InputMask mask={'(999) 999 9999'} value={quoteData.phone} name="phone" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                            {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Your phone number" disableUnderline />}
                                                        </InputMask>

                                                        <span className="text-danger">
                                                            {formError['phone'] &&
                                                                (formError['phone'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Phone Number')}
                                                        </span>
                                                    </div>
                                                    <div className="mb-4 col-6 inputextsize mortgageshadow">
                                                        <InputMask mask={'a9a 9a9'} value={quoteData.postalCode} name="postalCode" onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                                            {(inputProps) => <input {...inputProps} type="tel" className="form-control" placeholder="Postal Code" disableUnderline />}
                                                        </InputMask>

                                                        <span className="text-danger">
                                                            {formError['postalCode'] &&
                                                                (formError['postalCode'] === "req"
                                                                    ? ''
                                                                    : 'Enter a valid Postal Code')}
                                                        </span>
                                                    </div>

                                                    <div className="col-12"> <button type="button" className="showmebtn mortgagebuttoncolor" onClick={() => onButtonClick()}>Show me Quotes!</button></div>

                                                </div>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="articles">
                    <div className="container">
                        <div className="articles-text">
                            <h2>{t("HOMEPAGE.SUBMENU2")}</h2>
                            <p>{t("HOMEPAGE.SUBMENU3")} {t("HOMEPAGE.SUBMENU4")}</p>
                            
                        </div>


                        <div className="articles-box ">
                            <div className="main-articles-box">

                                <div className="item1">
                                    {
                                        blogData?.body?.length > 0 ? (
                                            blogData.body.slice(0, showAll ? blogData.body.length : initialArticlesCount).map(blog => (
                                                <div className="itemtts" key={blog.id}>
                                                      <Link to={`/articleDetails/${blog.slug}`}>

                                                        <div className="item-img">
                                                            <img src={blog.image} alt={blog.title} />
                                                        </div>
                                                        {/* <div className="bloginnertag mt-3">
                                                                {blog.cats.map(cat => cat.name).join(', ')}
                                                            </div> */}
                                                        <div className="item-text"><h5>{blog.title}</h5>

                                                            <p>{blog.short_content}</p></div>


                                                    </Link>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="dropdefault text-center">
                                                <div className="dropdefaultimage">
                                                    <img src={dropbox} alt="No blogs" />
                                                </div>
                                                <div className="dropdefaulttext">
                                                    <h5>You don't have any Blogs yet.</h5>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                                {
                                    openModal &&
                                    <div className="modalspecial blog-modal">
                                        <div>
                                            <div className="mybtn">
                                                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><div id="cross"></div></button>
                                            </div>
                                            <div className="modal-header">
                                                <h3>{blog?.title}</h3>
                                            </div>
                                            <div className="modal-body">
                                                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                                                </div>
                                                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                                            </div>
                                        </div>
                                    </div>

                                }


                            </div>
                        </div>
                        <div className="showallourbesttwo text-center mt-3">
                            {!showAll ? (
                                <div className="showallourbesttwo  text-center mt-0 mb-0">

                                    <button className="showallourbesttoo mt-0 mb-0" onClick={() => {
                                      
                                        history.push(`/searchArticles`);
                             
                                    }} >{t("COMMON.SHOWALL")}</button>

                                </div>
                            ) : (
                                <div className="showallourbesttwo  text-center mt-0 mb-5">
                                    <button className="showallourbesttoo mt-0 mb-4" onClick={handleShowAll}>{t("COMMON.SHOWALL")}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                <section className="Ourintitutions">
                    <div className="container">
                        <div className="row flexflow" >
                            <div className="col-lg-7 padleft30">
                                <div className="ourbestimgslider ">
                                
                                  <Slider {...institutelogo}>
                                  {
                                
                                        instituteData?.map(offer => {
                                            return <div className="ourbestimg"><Link to={`institute/${offer?.formattedLegalName}`} target="_blank">
                                                <div className="ourbestimgbox">
                                                    <img src={!offer?.imageUrl ? `https://ui-avatars.com/api/?name=${offer?.name}&rounded=true` : `${NEW_URL}/${offer?.imageUrl}`} alt={''} />                                                </div>
                                            </Link>
                                            </div>
                                        })
                                    }
                           </Slider>
                            
                                </div>
                                <div className="showallourbesttwo mt-5 text-center">
                                    <Link to='/globalMainSearchInstitutions' className="showallourbesttoo ">{t("COMMON.SHOWALL")}</Link>
                                </div>

                            </div>
                            <div className="col-lg-5">
                                <div className="ourbestrighttext">
                                    <h2>{t("HOMEPAGE.SUBMENU8")}</h2>
                                 
                                    <p>{t("HOMEPAGE.SUBMENU9")}</p>
                                    <p>{t("HOMEPAGE.SUBMENU10")}</p>
                                    <p>{t("HOMEPAGE.SUBMENU11")}</p>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
                <section className="searchtop">
                    <div className="container">
                        <div className="row paddingzero" style={{ backgroundColor: '#EEF7FF', alignItems: 'center' }}>
                            <div className="col-lg-6 col-xs-12 d-block d-sm-none p-0">
                                <img className="searchtop-images" src={mobilesearchinstitute} alt="" />
                            </div>
                            <div className="col-lg-6 col-xs-12">
                                <div className="searchtop-text" >
                                    <h2>{t("HOMEPAGE.SUBMENU6")}</h2>
                                    <p>{t("HOMEPAGE.SUBMENU7")}</p>
                                </div>
                                <div className=" buttonsearchmaim  mt-4">
                                    <Link to={'/globalMainSearchInstitutions'} className="searchnow mb-4">Search Now</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-6 d-none d-sm-block nopadright">
                                <img src={Topinstitution} alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                {/* home page end */}
                {/* <section className="herosection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="heroleftpanel"  data-aostest="fade-right">
                <h2>{t("HOME.HEADING1")}</h2>
                <h5>{t("HOME.HEADING2")}</h5>
                <h6 className="mt-5">{t("HOME.HEADING3")}</h6>
                <h6>{t("HOME.HEADING4")}</h6>
                <div className="heroleftbutton d-flex mt-4">
                  <Link to={'/AskTheExpert'} className="ctaonebutton border-radius-50"> I want to ask now!</Link>
                  <a href="/AskTheExpert#faq" className="ctatwobutton border-radius-50">FAQs</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="herorightpanel"  data-aostest="fade-left">
                <img src={Consultingrafiki} alt={' '}/>
              </div>
            </div>
          </div>
        </div>
      </section> */}
                {/* <section className="homepageproductoffer">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="pname">

                </div>
              </div>
            </div>
          </div>
        </section> */}








                {/* <section className="latestrates">
        <div className="container">
          <h2 className="text-center">Latest Rates</h2>
          <h4 className="text-center mt-3">Don’t wander places! Get all the latest rates here. All top institutions covered.</h4>
          <h4 className="text-center">Browse more and secure the best deals. </h4>
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="latestratesinner mt-5">
                <div className="latestratesinnerheading text-center">Mortgages</div>
                <ul className="logheading">
                  <li><span>Lender</span> <span>Rate</span></li>
                </ul>
                <ul className="logohd">
                  <li>
                    <span className="ratesicon"><img src={Butler_logo} /> Butler Mortgage</span>
                    <span className="ratesicontext">4.49%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={pine_logo} /> Pine Mortgage</span>
                    <span className="ratesicontext">4.59%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={neo_logo} /> Neo Financial</span>
                    <span className="ratesicontext">4.64%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <button type="button" className="viewall">View All<img src={chevrons_right} /></button>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="latestratesinner mt-5">
                <div className="latestratesinnerheading text-center">Mortgages</div>
                <ul className="logheading">
                  <li><span>Lender</span> <span>Rate</span></li>
                </ul>
                <ul className="logohd">
                  <li>
                    <span className="ratesicon"><img src={saven_logo} /> Saven Financial</span>
                    <span className="ratesicontext">5.30%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={alterna_logo} /> Altrena Bank</span>
                    <span className="ratesicontext">5.00%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <li>
                    <span className="ratesicon"><img src={Scotia_logo} /> Scotiabank</span>
                    <span className="ratesicontext">5.00%
            <span className="smalltext">5 years</span>
                    </span>
                  </li>
                  <button type="button" className="viewall">View All<img src={chevrons_right} /></button>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}


                {/* <section className="homepageproductoffer mt-5 mb-5">
        <div className="container">
          <h2 className="text-center" data-aostest="flip-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Free Tools</h2>
          <h4 className="text-center mt-3" data-aostest="fade-left" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Crunch real numbers with our modern financial calculators. </h4>
          <h4 className="text-center" data-aostest="fade-right" data-aostest-easing="ease-out-cubic"
     data-aostest-duration="2000">Craft the personalized financial plan you always wanted!</h4>
           <ul className="calculatehomepage d-flex mt-5 mb-4">
            <li>
              <h4>Received your free amortization schedule yet?</h4>
              <button type="button" className="ctaonebutton border-radius-50 mt-3">Calculate it now!</button>
            </li>
            <li>
              <h4>Compare Top-Ranked Financial Products</h4>
              <button type="button" className="ctaonebutton border-radius-50 mt-3">Compare Now</button>
            </li>
          </ul> 
          <div className="homeiconimage text-center mt-3">
            <img src={homeimage} data-aostest="flip-left"/>
            <h4 className="mt-3" data-aostest="fade-left">Improve your financial health by tapping our mortgage calculators!</h4>
          </div>
        </div>
      </section> */}
                {/* <section className="latestrates">
                    <div className="container">
                        <h2 className="text-center" data-aostest="fade-left" data-aostest-anchor-placement="bottom-bottom">{t("HOME.KNOWSEARCH1")}</h2>
                        <div className="d-flex latestratesflex">
                            <div data-aostest="fade-left">
                                <h4 className="mt-3">{t("HOME.KNOWSEARCH2")}</h4>
                                <h4 className="">{t("HOME.KNOWSEARCH3")}</h4>
                            </div>
                            <div className="" data-aostest="fade-right" data-aostest-anchor-placement="bottom-bottom">
                                <Link to={'/searchInstitute'} className="ctaonebutton border-radius-50 mt-5 widthhalf">{t("HOME.KNOWSEARCH4")}</Link>
                            </div>
                        </div>
                        <div className="advertisehome d-none d-sm-block">
                            <Slider {...settingstwo}>
                                <div className="adscreenone mt-5 adscreenheight">
                                    <a href="https://www.meridiancu.ca/personal/loans-and-lines/driveon" target="_blank" rel="noreferrer"><img src={hads1} alt={' '} /></a></div>
                                <div className="adscreenone mt-5 adscreenheight">
                                    <a href="https://appt-booking.meridiancu.ca/AppointmentType?default=Personal%20borrowing&_gl=1*1io06y9*_ga*MTg1NDE5NTU3NS4xNzA1NzM1NTAx*_ga_6D78MN23LS*MTcwNTkxNjczNC42LjEuMTcwNTkxNjk4NS4zMy4wLjA." target="_blank" rel="noreferrer"><img src={hads2} alt={' '} /></a></div>
                            </Slider>
                        </div>
                    </div>
                </section> */}
                <Footer />
            </section>
        </>

    );
};

export default HomeJsx;
