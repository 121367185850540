import React, { Component } from "react";
import axios from "axios";
import {
  emailRegex,
  encrypt,
  allRegex
} from '../../../shared/helper.js'
import { Loader } from "../../common/loader/loader.jsx";
import {
  baseURL, SERVER_IP
} from "../../../constants/applicationConstants.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './login.jsx'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        email: "",
        password: "",
      },
      isProcessing: false,
      formError: {},
      formSubmitButtonDisable: true,
      isLoading: false
    };
  }
  componentDidMount = () => {
    let isLogin = sessionStorage.getItem('isLoggedIn') && sessionStorage.getItem('isLoggedIn');
    if (isLogin && isLogin) {
      this.props.history.push("/dashboard");
    } else {
      this.props.history.push("/admin");

    }
    this.getIPData()
  }
  getIPData = () => {
    axios.get(`https://api64.ipify.org?format=json`).then(response => {
      const arrIP = SERVER_IP.split(","); //convert string to array to check
      if (arrIP.includes(response?.data?.ip)) {
        this.props.history.push("/admin")
      } else {
        this.props.history.push("/")
      }

    })
  }

  onInputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value) {
      if (!this.inputRegexValidation(name, value)) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "regx",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      } else {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              [name]: value,
            },
            formError: {
              ...this.state.formError,
              [name]: "",
            },
          },
          () => this.isFromSubmissionBlock()
        );
      }
    } else {
      this.setState(
        {
          formData: {
            ...this.state.formData,
            [name]: value,
          },
          formError: {
            ...this.state.formError,
            [name]: "",
          },
        },
        () => this.isFromSubmissionBlock()
      );
    }
  };
  // encrypt = () => {
  //   let ciphertext = CryptoJS.AES.encrypt('$C$pYuM#QM0f', 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3').toString();
  // }
  // decrypt = () => {
  //   let bytes = CryptoJS.AES.decrypt("U2FsdGVkX1+axK93BX1ZQ+AR8bXDqjtELPE7D6aJXwI=", 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3325');
  //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
  // }


  onTouchHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (value === "") {
      this.setState({
        formError: {
          ...this.state.formError,
          [name]: "req",
        },
      });
    } else {
      if (!this.inputRegexValidation(name, value)) {
        this.setState({
          formError: {
            ...this.state.formError,
            [name]: "regx",
          },
        });
      }
    }
  };

  inputRegexValidation = (name, value) => {

    if (name === "email") {
      return emailRegex.test(value);
    }
    else {
      return allRegex.test(value)
    }
  };

  isFromSubmissionBlock = () => {
    let { email, password } = this.state.formData;
    if (email && password) {
      if (
        !this.inputRegexValidation("email", email)
        ||
        !this.inputRegexValidation("password", password)
      ) {
        this.setState({
          formSubmitButtonDisable: true,
        });
      } else {
        this.setState({
          formSubmitButtonDisable: false,
        });
      }
    } else {
      this.setState({
        formSubmitButtonDisable: true,
      });
    }
  };
  formSubmitHandler = (e) => {
    this.setState({
      isLoading: true
    })
    e.preventDefault();
    let data = {
      email: this.state.formData.email,
      password: encrypt(this.state.formData.password),
      userType: "admin",
      // password: this.state.formData.password

    };
    axios.post(`${baseURL}/user/login`, data).then((res) => {
      sessionStorage.setItem("auth", JSON.stringify(res?.data?.data));
      sessionStorage.setItem("token", JSON.stringify(res.data?.data?.accessToken));
      // sessionStorage.setItem("isLoggedIn", true);
      sessionStorage.setItem("masterID", JSON.stringify(res.data?.data?.userId));
      sessionStorage.setItem("loginData", JSON.stringify(res.data?.data));
      localStorage.setItem('flowName', 'admin')
      localStorage.setItem('flowType', 'admin')
      this.setState({
        isLoading: false
      })
      // swal({
      //   title: "success",
      //   text: "You have logged-in successfully",
      //   icon: "success",
      //   successMode: true,
      // }).then(res => {
      //     if (res) {
      //       this.props.history.push("/dashboard");
      //     }
      // });
      toast.success("You have logged-in successfully");
      setTimeout(() => {
        this.props.history.push("/admin/opsDashboardHome");
        // window.location.reload()
      }, 2000)

    }).catch((res) => {
      toast.error(res?.response.data.error.message);
      this.setState({
        isLoading: false
      })
    })

  };



  render() {
    return (
      <div>
        <LoginPage
          formData={this.state.formData}
          onInputChangeHandler={this.onInputChangeHandler}
          onTouchHandler={this.onTouchHandler}
          formSubmitButtonDisable={this.state.formSubmitButtonDisable}
          formError={this.state.formError}
          formSubmitHandler={this.formSubmitHandler}
          isLoading={this.state.isLoading}
        // encrypt={this.encrypt}
        // decrypt={this.decrypt}
        // isProcessing={this.state.isProcessing}
        />
        {this.state.isLoading && <Loader />}
      </div>
    );
  }
}

export default Login;
