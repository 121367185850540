import React from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";
import "../customerInterest/interest.css";
import CustOffer from "../custOffers/custOffers";
import CustProduct from "../custProducts/custProducts";
import CustAdd from "../custAdds/custAdds";
import suggestedoffers from "../../../../assets/images/suggestedoffers.png";
import suggestedads from "../../../../assets/images/suggestedads.png";
import suggestedproducts from "../../../../assets/images/suggestedproducts.png";
import { useTranslation } from 'react-i18next';


const CustomerDashboardJsx = (props) => {
  const { t } = useTranslation();

  const {
    activeTab, onTabClick
  } = props;

  return (
    <>
      <section className="entiredashboard maincustomerdash main-interest">
        <div className="container">
          <div className="row bg-color-customer">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel">

                <div className="dashboardlinkpop">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9 p-0">
              <div className="dashboardrightpanel ">
                <div className="dashboardrightpannerinner">
                  <div className="dashboardrightpannerinnerleft">
                    <h2 className="h2_style font-weight-600">{t("customerinterest.HEADING")}</h2>
                    <h5 className="h5_style">{t("customerinterest.PARAGRAPH")}</h5>
                   
                  </div>
                  
                  <div className="dashboardrightpannerinnerright">
                    <div className="signout">
                      <a href="#logout">
                        <img src={Sign_out_squre} alt={''} /><span className="title">{t("customerinterest.LOGOUTBUTTON")}</span>
                      </a>
                    </div>
                    <div id="logout" className="modal-window">
                      <div>
                        <a  title="Close" class="modal-close">{t("customerinterest.CLOSEBUTTON")}</a>
                        <div className="modal-header">

                        </div><div className="modal-body mb-4">
                          <div className="modal-body-inner">
                            <h2 className="h2_style text-center">{t("customerinterest.SEARCHPARAGRAPH")}</h2>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="ctaonebutton"> {t("customerinterest.SAVEBUTTON")}</button>
                          <button type="button" className="ctatwobutton"> {t("customerinterest.SAVEBUTTON")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myexpertnav myaccountnav qnanav martop40">
                  <ul>
                  <li className={`${activeTab === 'custOffer' ? 'active' : ''}`} onClick={() => onTabClick('custOffer')}><img src={suggestedoffers} alt={''}/> {t("customerinterest.SUGGESTEDOFFERSBUTTON")}</li>
                  <li className={`${activeTab === 'custProduct' ? 'active' : ''}`} onClick={() => onTabClick('custProduct')}><img src={suggestedproducts} alt={''}/>{t("customerinterest.SUGGESTEDPRODUCTSBUTTON")}</li>
                  <li className={`${activeTab === 'custAdd' ? 'active' : ''}`} onClick={() => onTabClick('custAdd')}><img src={suggestedads} alt={''}/>{t("customerinterest.SUGGESTEDADSBUTTON")}</li>
                  </ul>
                </div>

                <div>
                  {activeTab === 'custProduct' ? <CustProduct /> : activeTab === 'custAdd' ? <CustAdd /> : <CustOffer />}
                </div>

                {/* {activeTab !== 'custOffer' && <div className="searchincustomer">
                  <section className="searchpageherosection backwhite">

                    <div className="searchmaininnerhero heroleftpanel">
                      <h2 className="text-center"> Search the Experts/Institutions</h2>
                      <h4 className="h4_style text-center">Are you searching for highly qualified experts or institutions in your vicinity?</h4>
                      <h4 className="h4_style text-center">Our comprehensive list is just a click away! </h4>
                      <h4 className="h4_style text-center">Please select your desired field. from below and click “Go”.</h4>
                      <h2 className="h2_style text-center mt-4">What do you like to search? </h2>
                      <div className="myexpertformarea searcharea searchmainara martop40">
                        <div className="input-container form-group">
                          <select className="input form-control form-select" placeholder=" " name="category">
                            <option value="searchExpert">Expert</option>
                            <option value="searchInstitute">Institution</option>
                          </select>
                          <div className="placeholder">Select</div>
                        </div>
                        <div className="input-container form-group">
                          <button type="submit" className="ctaonebutton">Go</button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </>

  );
};

export default CustomerDashboardJsx;