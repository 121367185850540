import { useEffect } from 'react';

import { getIconOne } from '../askTheExpert.jsx';
import clipboard from "../../../../assets/askTheExpertImages/clipboard.svg";
import { NEW_URL } from '../../../../constants/applicationConstants';




const ConfirmAskTheExpertJsx = (props) => {
  const {
    category,    
    subCategory,    
    question,    
    onConfirmAndSubmitButtonClick,
    closeModalEdit,icon
  } = props;
  useEffect(() => {
    window.scrollTo(0, 3200);
  }, [])



  return (
    <>
      <div className="modalspecial asktheexpertconfirmpopup popupouter">
        <div>
        <div className="popupnew">
          <div className="row"><div className="col-lg-12 col-sm-12"> <h2>{question}</h2></div>
            <div className="col-lg-7 col-sm-7">
              <div className="cliparttext">
               
                <div className="clipartmain">
                  <div className="cliparttextbox">
                    <p className="text-categ">Category</p>
                    <div className="cliparttextboxinner">
                      <img src={getIconOne(category)} alt="" /></div>
                  </div>


                  {subCategory &&
                    <div className="cliparttextboxouter"> <p>Sub-Category</p>
                      <div className="cliparttextbox cliparttextboxtwo">
                        <div className="iconbase">
                          <img 
                          // src={`/${(subCategory).replace(/\s/g, '')}.png`}
                          src={`${NEW_URL}/${icon}`}

                           alt="" /></div>
                        <p>{subCategory}</p>
                      </div>
                    </div>
                  }

                </div>
                <div className="clipartbtn mt-4">
                  <a onClick={closeModalEdit} className="clipartbutton">Edit</a>
                  <a onClick={onConfirmAndSubmitButtonClick} className="clipartbutton2">Confirm & Submit</a>
                </div>
              </div>

            </div>
            <div className="col-lg-5 col-sm-5 clipart-box">
              <div className="clipart"><img src={clipboard} /></div>
            </div>
          </div>
        </div>
      </div>
</div>

    </>

  );
};

export default ConfirmAskTheExpertJsx;