import React, { useEffect, useState } from 'react'
import dropbox from "../../../../assets/images/dropbox.svg";
import Sidebar from "../sidebar/sidebar.js";

import { useTranslation } from "react-i18next";
import Str from "../dashBoard/dashBoard.json";
import '../dashBoard/dashBoard.model.css';
import logout from "../../../../assets/images/logout.svg";
import AwesomeSlider from 'react-awesome-slider';
import { NEW_URL } from '../../../../constants/applicationConstants';
import { format, parse } from 'date-fns';
import { PreNexPagination } from '../../../common/pre_next_pagination/pre_next_pagination';
import Slider from "react-slick";
import productblue from "../../../../assets/homeimages/productblue.svg"


const DashBoardJsx = (props) => {
  const {
    blogData,
    offerData,
    expertData,
    onPaginationButtonClick,
    onPaginationExButtonClick
  } = props;
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [blog, setBlog] = useState({});


  useEffect(() => {

  }, []);




  const formattedDate = (value) => {
    const date = parse(value, 'dd MMM yyyy', new Date());
    return format(date, 'MMMM d, yyyy'); // Output: December 22, 2023
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }; 

  return (
    <>
      <section class="entiredashboard maincustomerdash main">
        
        <div className="container">
          <div className="row dashboard-decoration">
            <div className="col-lg-3 col-sm-3" style={{margin:"0px"}}>
              <Sidebar />
            </div>
            <div className="col-lg-9 col-sm-9 dashboard-data">
              <div >
                <div >
                  <div className="row" >
                  
                    <div className="col-lg-7  col-sm-7">
                 
                      <p className="dahsboardTitle">{t("instituteDashboard.HEADING")}</p>
                      <h5 className="dahsboardSubTitle">{t("instituteDashboard.SUBHEADING")}</h5>
                    </div>
                    <div class="col-lg-5  col-sm-5"  >
                      <div class="logoutbutton">  <img class="logoutImg" src={logout} alt={''} />
                        <p className="dashBoardLogout ">{t("instituteDashboard.LOGOUT")} </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboardsliderdata">
                  <p className="dashboars-liveoffer">{t("instituteDashboard.LIVEOFFERS")}
                  </p>

                  {/* {offerData?.length > 0 ? <AwesomeSlider>
                    {
                      offerData?.map(offer => {

                        const date = new Date(offer.validTo); // Create Date object from ISO string
                        const formattedDate = format(date, 'd, MMM yyyy');
                        return <div style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                          <div className="sliderimage">
                            <img
                              key={offer.imageUrl}
                              className=""
                              src={`${NEW_URL}/${offer.imageUrl}`}
                              alt=" "
                            />
                          </ div>
                          <div  >
                            <p className="offer-name">{offer.offerName}</p>

                          </div>
                          <div className="row description-card">
                            <div className="col-6">
                              <p className="descriptionTitle">(offer description)</p>
                              <p className="descriptionoffer">{offer.description}</p>
                            </div>
                            <div className="col-6">
                              <div className="related-products"> <p>Related Products</p></div>
                              <div className="valid-products"> <p>Valid till {formattedDate}</p></div>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </AwesomeSlider> :
                    <div className=" ">

                    </div>
                  } */}
                  
          <Slider {...settings}>{
            offerData?.map(offer => {

              const date = new Date(offer.validTo); // Create Date object from ISO string
              const formattedDate = format(date, 'd, MMM yyyy');
              return <div style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>
                <div className="sliderimage">
                  <img
                    key={offer.imageUrl}
                    className=""
                    src={`${NEW_URL}/${offer.imageUrl}`}
                    alt=" "
                  />
                </ div>
                <div  >
                  <p className="offer-name">{offer.offerName}</p>

                </div>
                <div className="row description-card">
                  <div className="col-6">
                    <p className="descriptionTitle">(offer description)</p>
                    <p className="descriptionoffer">{offer.description}</p>
                  </div>
                  <div className="col-6">
                    <div className="related-products"> <p>Related Products</p></div>
                    <div className="valid-products"> <p>Valid till {formattedDate}</p></div>
                  </div>
                </div>
              </div>
            })
          }
          </Slider>
        </div>
                


                <div >
                  <div className="row">
                    <div className="col-lg-8  col-sm-8">

                      <p className="dahsboardTitle">{t("instituteDashboard.BLOGSTITLE")} </p>
                      <h5 className="dahsboardSubTitle">{t("instituteDashboard.DESC")}</h5>
                    </div>

                  </div>
                </div>
                <div className="dashboardblog martop30 marbot60">
                  <p className="blogsubmaintitle text-center">{t("instituteDashboard.EXPERTAUTHOR")}</p>
                  <p className="blogsubsubtitle text-center">{t("instituteDashboard.REGISTERED")} </p>

                  <div className="expert-all-blog">
                    {

                      expertData?.length > 0 ?
                        expertData?.map(blog => {
                          return <div className="expert-blog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                            <div className="expert-blog-img"> <img src={blog.image} alt={''} /></div>
                            <div className="">
                              <div className="">
                                <div className="expert-blog-title">{blog.title}</div>
                                <div className="expert-blog-description">{blog.short_content}</div>
                              </div>
                              {
                                blog?.cats?.map(catgeory => {
                                  return <p className="expert-blog-catgeory">{catgeory.cat_name}</p>
                                })
                              }

                            </div>
                          </div>
                        }) :
                        <div className="expert-blog">
                          <div className="expert-blog-img">
                            <img src={dropbox} alt={''} />
                          </div>
                          <div className="">
                            <p>{t("BLOG.NOBLOGS")}</p>

                          </div>
                        </div>
                    }
                    {expertData?.length > 8 && <div className="pagination-center"><PreNexPagination
                      limit={8}
                      totalCount={expertData?.length}
                      onPaginationButtonClick={onPaginationExButtonClick}
                    /></div>}
                  </div>
                </div>



                <div className="dashboardblog martop30 marbot60">
                  <p className="blogsubmaintitle text-center">{t("instituteDashboard.PRODUCTS")}</p>
                  <p className="blogsubsubtitle text-center">{t("instituteDashboard.PRODUCTSDESC")} </p>

                  <div className="expert-all-blog">
                    {
                      blogData?.length > 0 ?
                        blogData?.map(blog => {
                          return <div className="expert-blog" onClick={() => { setBlog(blog); setOpenModal(true) }}>
                            <div className="expert-blog-img"> <img src={blog.image} alt={''} /></div>
                            <div className="">
                              <div className="">
                                <div className="expert-blog-title">{blog.title}</div>
                                <div className="expert-blog-description">{blog.short_content}</div>
                              </div>
                              {
                                blog?.cats?.map(catgeory => {
                                  return <p className="expert-blog-catgeory">{catgeory.cat_name}</p>
                                })
                              }
                            </div>
                          </div>
                        }) :
                        <div className="expert-blog">
                          <div className="expert-blog-img">
                            <img src={dropbox} alt={''} />
                          </div>
                          <div className="">
                            <p>{t("BLOG.NOBLOGS")}</p>
                          </div>
                        </div>
                    }
                  </div>
                  {blogData?.length > 8 && <div className="pagination-center"><PreNexPagination
                    limit={8}
                    totalCount={blogData?.length}
                    onPaginationButtonClick={onPaginationButtonClick}
                  /></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          openModal &&
          <div className="modalspecial blog-modal">
            <div>
              <div className="mybtn">
                <button type="button" className="myadbtn" onClick={() => { setOpenModal(false) }}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                  width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
                  preserveAspectRatio="xMidYMid meet">

                  <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                    fill="#ffffff" stroke="none">
                    <path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
                  </g>
                </svg></button>
              </div>
              <div className="modal-header text-center">
                <h3>{blog?.title}</h3>
              </div>
              <div className="modal-body text-center">
                <div className="blog-image-dv"><img src={blog.image} alt={' '} />
                </div>
                <div className="blog-content-dv" dangerouslySetInnerHTML={{ __html: blog.content }}></div>
              </div>
            </div>
          </div>

        }

      </section>
    </>

  );
};

export default DashBoardJsx;
