import React from 'react'
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Footer } from '../../../common/footer/footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    expertData
    // meta
  } = props;
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  
  const [page, setPage] = useState(1);



  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Customer Query</h2>


                
              </div>

              <div className="expertsection martop30 mb-5">
                <div className="searchdetails searchdetalsen">
                  <table className="searchdetailsdb">
                    <tr>
                      <th>Customer ID</th>
                      <th>Name</th>
                      <th>Email</th>

                      <th>Phone</th>
                      <th>Address</th>
                     



                      <th>Query</th>
                      <th>Created ad</th>
                      {/* <th>Action</th> */}
                    </tr>

                    {expertData.map((item, i) => (
                      <>
                        <tr>
                          <td>{item.customerId}</td>
                          <td>{item.customerDetails.firstName} {item.customerDetails.lastName}</td>
                          
                          <td>{item.customerDetails.email}</td>
                          <td>{item.customerDetails.phoneNumber}</td>
                          <td>{item.customerDetails.address}, {item.customerDetails.city}, {item.customerDetails.postalCode}</td>
                        


                          <td>{item.query}</td>
                          <td>{moment(item.updatedAt).format('MM-DD-YYYY')}</td>
                          {/* <td>
                          <button className="btn btn-success" onClick={() => editCategory(item?.institutionCategoryId)}>Edit</button>
                                  <button className="btn btn-danger"  onClick={() => deleteOffer(item?.institutionCategoryId)} >delete</button></td> */}
                          
                        </tr>
                      </>
                    ))}

                  </table>
                </div>
              </div>


            </div>

          </div>

        </div>
      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;