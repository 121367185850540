import React, {useState} from 'react'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect } from 'react';
import { Footer } from '../../common/footer/footer';

import Vector2 from "../../../assets/offersimage/Vector2.svg";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NEW_URL } from '../../../constants/applicationConstants';
import { onOfferClick } from '../../../shared/helper';
import Filter from '../../../shared/filter.jsx';

import "../offers/offers.css";
import "../offers/offerresponsive.css";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Pagination } from '../../common/pagination/uiPagination.js';

const OffersJsx = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation()

  const {
    categoryData, hitSearch,
    allOffersData,
    hitOldest,
    oldest,
    selectAll,
    selectAllHit,
    topOffers, 
    getAllFeauturedOffersData, 
    selectCategory, 
    selectSubCategory, 
    subCategories, 
    categories, 
    searchOfferByKeyword,onPaginationButtonClick,page
  } = props;
  const itemsPerPage = 10

  const [currentPage, setCurrentPage] = useState(1);


//  const onPaginationButtonClick = (page) => {
//     // this.setState({ page: page }, () => this.getBlogData())
//     setCurrentPage(page);

// }
  // const handleNext = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePrev = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  const currentItems = allOffersData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  useEffect(() => {
    getAllFeauturedOffersData()
  }, [pathname])
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };
  var settingstwo = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide:2  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: '0px',
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])

  const getProperData = (date) => {
    if (date) {
      const date2 = date?.split('T');
      const date1 = date2[0]?.split('-');
      return `${date1[2]}/${date1[1]}/${date1[0]}`
    }
  }

  return (
    <>
      <section className="container-rimib newofferdesign new-offer-design">
        <section className="herosectionoffer">

          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-sm-5">
                <div className="offerherobannertext">
                  <h2><span>{t("OFFERE.TEXT1")}</span></h2>
                  <h2>{t("OFFERE.TEXT2")}</h2>
                  <h5>{t("OFFERE.TEXT3")}</h5>
                </div>
              </div>
              <div className="col-lg-7 col-sm-7">
                <div className="vectorone vector-img"><img src={Vector2}  alt=''/></div> 
              <div className="offerbannerslider">
                
                {/* <div className="vectortwo vector-img-too"><img src={Vector2} /></div> */}

                <Slider {...settingstwo}>
                  {
                    topOffers?.map(offer => {
                      return <div className="sliderContent slider-image">

                        <a href={offer.externalUrl ?? '#'} target="_blank">
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />
                        </a>
                        {/* <h5>{offer.offerName}</h5> */}
                      </div>
                    })
                  }
                </Slider>
              </div></div>
            </div>

          </div>
        </section>

        <section className="offersearch">
          <div className="container">
            <div className="customsearchblock">
              <input type="search" value={props.keyword} placeholder="" onChange={searchOfferByKeyword}
              />
              <button className="headersearch" onClick={() => hitSearch()}>{t("COMMON.SEARCH")}</button>
            </div>
            <h2>{t("OFFERE.TEXT5")}</h2>
            <h4>{t("OFFERE.TEXT6")}</h4>
          </div>
        </section>
        


        {/* Real estate offers */}

        <section className="limitedoffer">
          <div className="container">
            <section className="mainofferdetails">
              <h2>{t("OFFERE.TEXT7")}</h2>
              <h5>{t("OFFERE.TEXT8")}</h5>
              <h5><b>{t("OFFERE.TEXT9")}</b></h5>

              <Filter
                categoryData={categoryData}
                selectAll={selectAll}
                selectAllHit={selectAllHit}
                getAllFeauturedOffersData={getAllFeauturedOffersData}
                selectCategory={selectCategory}
                selectSubCategory={selectSubCategory}
                subCategories={subCategories}
                categories={categories}
              />
            </section>
            <section className="sortingdiv">
              <div className="container">
                <span className="shortby">Sort By</span>
                <ul>
                  <li className={!oldest ? "active" : ""}><a onClick={() => hitOldest(false)}> Newest</a></li>
                  <li className={oldest ? "active" : ""}><a onClick={() => hitOldest(true)}> Expiring Shortly</a></li>
                </ul>
              </div>
            </section>
            <div className="limitedofferinner limited-offers">
            
             
              <div className="offerslider martop40 mb-5">
                <div className="row">

                  {
                    currentItems?.map(offer => {
                      return <div className="col-lg-6 col-sm-6">
                        <div className={`sliderContent ${offer?.parentInstitutionCategory || offer?.institutionCategoryName}`}>
                          <img
                            className="d-block w-100"
                            src={`${NEW_URL}/${offer.imageUrl}`}
                            alt=" "
                          />

                          <div className="descriptionppvpop">
                            <div className="d-flex ppvcatds">


                              <div className="ctnam">{offer?.parentInstitutionCategory}</div><div className="pscat"><span><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path d="M7.41854 11.0209V7.65145L9.10327 6.64061M13.4836 7.65145C13.4836 11.0011 10.7682 13.7165 7.41854 13.7165C4.06892 13.7165 1.35352 11.0011 1.35352 7.65145C1.35352 4.30183 4.06892 1.58643 7.41854 1.58643C10.7682 1.58643 13.4836 4.30183 13.4836 7.65145Z" stroke="black" stroke-width="1.2021" stroke-linecap="round" stroke-linejoin="round" />
                              </svg></span>{t("COMMON.EXPIRING")} : {moment(offer?.validTo).format('DD MMM YY')}</div>

                            </div>
                            <div className="offerdspop">
                              <div className="ppvcat">
                                {/* <div className="ctnam">{offer?.subInstitutionCategory}</div> */}
                                <h5 className={`primary-color-category${offer?.institutionCategoryId} text-type`}>{offer?.offerName}</h5>
                                <h4 className="foffer-description"> {offer?.description}</h4>
                              </div>


                              {/* <div className="ppvdate">
                            <h3>Expires On</h3>
                            {getProperData(offer?.validTo)}
                          </div> */}
                            </div>



                          </div>

                          <button onClick={() => onOfferClick(offer)} className={`transparent-color-category${offer?.institutionCategoryId} button-type ctaonebutton widthhalf`}>{t("OFFERE.TEXT13")}</button>
                        </div>
                      </div>
                    })
                  }

                </div>
              </div>
            </div>
          </div>
        </section>
       <section className="newpagination"> <Pagination
              currentPage={currentPage}
              page={page}
              allOffersData={allOffersData}
              currentItems={currentItems}
              onPaginationButtonClick={onPaginationButtonClick}

              /></section>
      </section>
      <Footer />
    </>

  );
};

export default OffersJsx;
