import React from 'react'
import Select from 'react-select';
import DatePicker3 from "react-modern-calendar-datepicker";
import { formatInputValue } from '../../../../shared/helper';
import datecalender from "../../../../assets/images/datecalender.svg"
import { getProperDateMonth, formatCanadianPhoneNumber } from "../../../../shared/helper.js";
import InputMask from 'react-input-mask';
import editicon from "../../../../assets/images/edit-icon.png";
import { useTranslation } from 'react-i18next';
const InstituteDetailsJsx = (props) => {
    const { t } = useTranslation();

    const genderList = [
        {
            "label": "Select",
            "value": null
        },
        {
            "label": "Male",
            "value": "Male"
        },
        {
            "label": "Female",
            "value": "Female"
        },
        {
            "label": "Others",
            "value": "Others"
        }
    ]
    const { onInputChangeHandler, handleChange, onDateChange,
        onInstituteButtonClick, editInstitite, changeHideState, formError, onTouchHandler
    } = props;
    const isRequired = true
    const information = props?.wholeData
    const getMuskPattern = (name) =>
        name === 'phone' || name === "secondaryPhone" ? '(999) 999 9999' : 'a9a 9a9'
    return (
        <>
            {!editInstitite ? <div className="myaccountsection institutedetails personal-details">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">{t("customerdetails.HEADING")}</h3>
                    <div className="mybtn ">
                        <button type="button" className="edit-button" onClick={() => changeHideState(true)}>{t("customerdetails.EDITBUTTON")} <img src={editicon} alt=""/></button>

                    </div>
                </div>
                <div className="form-field">
                    {/* <div className="form-fieldinner">
                        <label>About Me*</label>
                        <div className="fieldans">{information?.description}</div>
                    </div> */}
                    <div className="disgrid grid-2">
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.FIRSTNAME")}</label>
                        <div className="fieldans">{information?.firstName}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.LASTNAME")}</label>
                        <div className="fieldans">{information?.lastName}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.EMAIL")} </label>
                        <div className="fieldans">{information?.email}</div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <label>Alternative Email  </label>
                        <div className="fieldans">{information?.alternativeEmail}</div>
                    </div> */}
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.PHONENO")}  </label>
                        <div className="fieldans">{information?.phone && formatCanadianPhoneNumber(information?.phone)}</div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <label>Secondary Phone no. </label>
                        <div className="fieldans">{information?.secondaryPhone}</div>
                    </div> */}
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.DOB")} </label>
                        <div className="fieldans">{getProperDateMonth(information?.dateOfBirth?.day)}-{getProperDateMonth(information?.dateOfBirth?.month)}-{information?.dateOfBirth?.year}</div>
                    </div>
                    <div className="form-fieldinner">
                        <label>{t("customerdetails.GENDER")} </label>
                        <div className="fieldans">{information?.gender}</div>
                    </div>
                    </div>

                </div>
            </div> : null}
            {editInstitite ? <div className="myaccountsection institutedetails martop60">
                <div className="myaccountsectionheading">
                    <h3 className="h3_style">{t("customerdetails.HEADING")}</h3>
                    <div className="mybtn">
                        <button type="button" className="myadbtn" onClick={() => changeHideState(false)}><svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M156 484 c-9 -8 -16 -20 -16 -26 0 -5 28 -39 62 -74 l61 -64 -61 -64
c-34 -35 -62 -69 -62 -74 0 -14 28 -42 42 -42 5 0 39 28 74 62 l64 61 64 -61
c35 -34 69 -62 74 -62 14 0 42 28 42 42 0 5 -28 39 -62 74 l-61 64 61 64 c34
35 62 69 62 74 0 14 -28 42 -42 42 -5 0 -39 -28 -74 -62 l-64 -61 -64 61 c-35
34 -69 62 -74 62 -6 0 -18 -7 -26 -16z"/>
</g>
</svg></button>
                    </div>
                </div>
                <div className="form-field">
                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                // className="input form-control"

                                className={
                                    isRequired && formError["description"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                maxLength={200}
                                name="description" placeholder=" " value={information.description} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">About me</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["description"] &&
                                    (formError["description"] === "req"
                                        ? "description is required!"
                                        : "Please enter a proper description!")}
                            </span>
                        </div>
                    </div> */}
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                // className="input form-control"

                                className={
                                    isRequired && formError["firstName"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                maxLength={30}
                                name="firstName" placeholder=" " value={information.firstName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">{t("customerdetails.FIRSTNAME")}</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["firstName"] &&
                                    (formError["firstName"] === "req"
                                        ? "firstName is required!"
                                        : "Please enter a proper firstName!")}
                            </span>
                        </div>
                    </div>
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text"
                                className={
                                    isRequired && formError["lastName"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                }
                                name="lastName" placeholder=" " value={information.lastName} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">{t("customerdetails.LASTNAME")}</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["lastName"] &&
                                    (formError["lastName"] === "req"
                                        ? "lastName is required!"
                                        : "Please enter a proper lastName!")}
                            </span>
                        </div>
                    </div>
                    <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text" className={
                                isRequired && formError["email"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                            } name="email" disabled placeholder=" " value={information.email} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">{t("customerdetails.EMAIL")}</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["email"] &&
                                    (formError["email"] === "req"
                                        ? "email is required!"
                                        : "Please enter a proper email!")}
                            </span>
                        </div>
                    </div>

                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">
                            <input type="text" className={
                                isRequired && formError["alternativeEmail"]
                                    ? "input form-control  is-invalid"
                                    : "input form-control"
                            } name="alternativeEmail" placeholder=" " value={information.alternativeEmail} onChange={onInputChangeHandler} onBlur={onTouchHandler} />
                            <label className="placeholder">Alternative Email</label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["alternativeEmail"] &&
                                    (formError["alternativeEmail"] === "req"
                                        ? "alternativeEmail is required!"
                                        : "Please enter a proper alternativeEmail!")}
                            </span>
                        </div>
                    </div> */}

                    <div className="form-fieldinner">
                        <div className="input-container form-group">

                            <InputMask mask={getMuskPattern('phone')} name="phone" value={information.phone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                {(inputProps) => <input {...inputProps} type="tel" className={
                                    isRequired && formError["phone"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } placeholder=" " disableUnderline />}
                            </InputMask>
                            <div className="cut"></div>
                            <label className="placeholder">{t("customerdetails.PHONENO")}  </label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["phone"] &&
                                    (formError["phone"] === "req"
                                        ? "phone is required!"
                                        : "Please enter a proper phone!")}
                            </span>
                        </div>
                    </div>
                    {/* <div className="form-fieldinner">
                        <div className="input-container form-group">

                            <InputMask mask={getMuskPattern('secondaryPhone')} name="secondaryPhone" value={information.secondaryPhone} onChange={onInputChangeHandler} onBlur={onTouchHandler}>
                                {(inputProps) => <input {...inputProps} type="tel" className={
                                    isRequired && formError["secondaryPhone"]
                                        ? "input form-control  is-invalid"
                                        : "input form-control"
                                } placeholder=" " disableUnderline />}
                            </InputMask>
                            <div className="cut"></div>
                            <label className="placeholder">Secondary Phone Number </label>
                            <span className="text-danger">
                                {isRequired &&
                                    formError["secondaryPhone"] &&
                                    (formError["secondaryPhone"] === "req"
                                        ? "secondaryPhone is required!"
                                        : "Please enter a proper secondaryPhone!")}
                            </span>
                        </div>
                    </div> */}




                    <div className="disgrid grid-2">
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                {/* <input type="text" className="input form-control" name="sizeOfInstitution" placeholder=" " value={totalInformation?.instituteSize?.size} onChange={onInputChangeHandler} />
                                <label className="placeholder">Size of Institution * </label> */}
                                <Select className={`selectbox`}
                                    closeMenuOnSelect={true}
                                    placeholder={" "}
                                    name="gender"
                                    options={genderList}
                                    onChange={(e) => handleChange(e, 'gender')}
                                    value={genderList.filter(ite => ite.value === information.gender)}

                                />
                                <div className="placeholder">{t("customerdetails.GENDER")}</div>
                            </div>
                        </div>
                        <div className="form-fieldinner">
                            <div className="input-container form-group">
                                {/* <input type="text" className="input form-control" name="dateOfIncorporation" placeholder=" " value={moment(information.dateOfIncorporation).format("DD-MM-YYYY")} onChange={onInputChangeHandler} />
                                <label className="placeholder">Date of Incorporation * </label> */}

                                <DatePicker3 className="form-control" name="dateOfBirth"
                                    onChange={(date) => { onDateChange(date) }}
                                    inputPlaceholder="dd/mm/yyyy"
                                    shouldHighlightWeekends
                                    maximumDate={{
                                        year: new Date().getFullYear(),
                                        month: new Date().getMonth() + 1,
                                        day: new Date().getDate()
                                    }}
                                    wrapperClassName="input-container form-group"
                                    renderInput={({ ref }) => (
                                        <>
                                            <input
                                                ref={ref}
                                                placeholder=" "
                                                type="text"
                                                required
                                                className="input form-control"
                                                value={formatInputValue(information.dateOfBirth)}
                                            />
                                            <div className="cut"></div>
                                            <label className="placeholder">{t("customerdetails.DOB")}</label>
                                            <span className="dateim"><img src={datecalender}  alt={''}/></span>
                                        </>
                                    )}
                                    formatInputText={() => formatInputValue(information.dateOfBirth)}
                                />
                            </div>
                        </div>

                    </div>
                    <button type="button" className="ctaonebutton widthhalf" onClick={() => onInstituteButtonClick()}>{t("customerdetails.SAVEBUTTON")}</button>
                </div>
            </div> : null}
        </>
    );
};

export default InstituteDetailsJsx;
