import React from 'react'
import { Footer } from '../../../common/footer/footer';
import { Pagination } from '../../../common/pagination/pagination';
import Sidebar from "../sidebar/sidebar.js";

const AdvertisementsJsx = (props) => {
    const {
        offerData,
        categoryData,
        formData,
        onInputChange,
        onTouchHandler,
        onPaginationButtonClick,
        onSubmitButtonClick,
        makeFeature,
        handleChange, handleChange1
    } = props;

    return (
        <>
            <section className="entiredashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlink martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9">
                            <div className="dashboardrightpanel martop40">
                                <section classNames="addexpertformsec">
                                    <div className="myadssection">
                                        <h2 className="h2_style martop30">Manage Advertisements</h2>
                                        <p>Morem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    </div>
                                    <div className="myexpertformarea searcharea martop40">
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="type"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="">All</option>
                                                <option value="Elite">Elite</option>
                                                <option value="Premium">Premium</option>
                                            </select>
                                            <div className="placeholder">Type</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="instituteName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Institution Name</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="expertName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Expert Name</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="institutionCategory"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="">All</option>
                                                {categoryData?.map((item) => {
                                                    return (
                                                        <>

                                                            <option value={item.institutionCategoryId}>{item.name}</option>
                                                        </>
                                                    )
                                                })}
                                            </select>
                                            <div className="placeholder">Category</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="productName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Description</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="homeFeatured"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="">All</option>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            <div className="placeholder">Home Featured</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search</button>
                                        </div>
                                    </div>
                                    <div className="searchdetails">
                                        <table className="searchdetailsdb">
                                            <tr>
                                                <th>Institution Name</th>
                                                <th>Expert Name</th>
                                                <th>Category</th>
                                                <th>Sub category</th>

                                                {/* <th>Product Name</th> */}
                                                <th>Description</th>
                                                <th>Home Featured</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                            </tr>

                                            {

                                                offerData?.map(data => {
                                                    return <tr>
                                                        <td>{data?.institutes && data?.institutes[0]?.name || "NA"}</td>
                                                        <td>{data?.experts && data?.experts[0]?.name || "NA"}</td>
                                                        <td>{data?.parentInstitutionCategory ?? "NA"}</td>
                                                        <td>{data?.subInstitutionCategory?.name ?? "NA"}</td>

                                                        {/* <td>name test</td> */}
                                                        <td>{data.description}</td>
                                                        <td><input type="checkbox" checked={data?.isFeatured} onChange={e => handleChange(e, data.advertisementId)} /></td>
                                                        {/* <td><input type="checkbox" checked={data.isActive} onChange={e => handleChange1(e, data.expertId)} /></td> */}
                                                        {/* <td><button type="submit" onClick={() => makeFeature(data.expertId)} className="ctaonebutton">Save</button></td> */}
                                                        <td>{data.isActive ? "Active" : "In-Active"}</td>

                                                    </tr>
                                                })
                                            }
                                            {/* <tr>
                                            <td>ICICI Bank</td>
                                            <td>Credit card</td>
                                            <td>First line of disc...</td>
                                            <td><button type="submit" className="ctaonebutton">Product Details</button></td>
                                            <td><input type="text" className="tabletextbox" /></td>
                                            <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                        </tr>
                                        <tr>
                                            <td>ICICI Bank</td>
                                            <td>Credit card</td>
                                            <td>First line of disc...</td>
                                            <td>Product Details</td>
                                            <td><input type="text" className="tabletextbox" /></td>
                                            <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                        </tr> */}
                                        </table>
                                    </div>
                                </section>
                            </div>
                            {offerData?.length > 0 && <Pagination
                        limit={10}
                        totalCount={offerData?.meta?.totalCount}
                        onPaginationButtonClick={onPaginationButtonClick}
                    />}
                        </div>
                    </div>
                    
                </div>
            </section>
            <Footer />

        </>

    );
};

export default AdvertisementsJsx;