import React from 'react'
import { useEffect } from 'react';
import { InstituteDetail } from './instituteDetails';
import { OfferSection } from './offerSection';
import { AdsSection } from './adsSection';
import { ServiceSection } from './serviceSection';
import { ProductSection } from './productSection';
import { GetInTouchSection } from './getInTouchSection';

import MyExpertJsx from './myExpert.jsx';
import "../institute/instituteppv.css";
import "../institute/instituteppvresponsive.css";

const PublicProfileViewsJsx = (props) => {
  const {
    profileData,categoryData,expertData,
    userData,
    formError,
    onInputChangeHandler,
    onInputChange,
    onTouchHandler,
    onSendMessageClick,
    legalName,
    formSubmitButtonDisableForBlog,
    formData,onSubmitButtonClick,onViewProfieClick
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <section className="institutenewdesignppv">


        <section className="institutenewdesignppvinner">

          <InstituteDetail profileData={profileData} />

          {profileData?.instituteOffers?.length > 0 && <OfferSection profileData={profileData} legalName={legalName} />}

          {profileData?.advertisements?.length > 0 &&<AdsSection profileData={profileData} legalName={legalName} />}

        </section>

        {profileData?.instituteServices.length > 0 && <ServiceSection profileData={profileData} />}

        {profileData?.instituteServices.length > 0 &&<GetInTouchSection
          profileData={profileData}
          userData={userData}
          formError={formError}
          onInputChangeHandler={onInputChangeHandler}
          onTouchHandler={onTouchHandler}
          onSendMessageClick={onSendMessageClick}
          formSubmitButtonDisable={formSubmitButtonDisableForBlog}
        />}
        <MyExpertJsx
        categoryData={categoryData}
        formError={formError}
        expertData={expertData}
        formData={formData}
        onSubmitButtonClick={onSubmitButtonClick}
        onInputChange={onInputChange}
        onViewProfieClick={onViewProfieClick}

        />

        {/* <ExpertSection /> */}

        {profileData?.instituteProducts?.categories.length > 0 && <ProductSection profileData={profileData} legalName={legalName} />}
      </section>
    </>

  );
};

export default PublicProfileViewsJsx;
