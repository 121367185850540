import axios from 'axios';
import React from 'react'
import { Component } from 'react';
import { Loader } from '../../../common/loader/loader.jsx';
import CustomerBlogJsx from './customerBlogs.jsx'
import { BLOG_URL, BLOG_URL_TOKEN } from "../../../../constants/applicationConstants"

export default class CustomerBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryData: null,
            blogData: null,
            isProcessing: false,
            page: 1,
            blogType: 'real-estate'
        }
    }

    getBlogData = () => {
        var data = new FormData();
        data.append('limit', '10');
        data.append('device_token', '20.204.66.244');
        data.append('paged', this.state.page)
        data.append('cat_slug', this.state.blogType.toLowerCase())
        this.setState({ isProcessing: true })

        axios.post(`${BLOG_URL}`, data,
            {
                headers: { 'Eba-access-api-base-token': BLOG_URL_TOKEN }
            }).then(res => {
                this.setState({ blogData: res.data, isProcessing: false });
            }).catch(err => {
                this.setState({ isProcessing: false })
            })
    }

    componentDidMount = () => {
        // let token = JSON.parse(sessionStorage.getItem('token'));
        // if (!token){
        //   this.props.history.push("/");   
        //  }
        this.getBlogData();
        this.fetchData();
    }

    onPaginationButtonClick = (page) => {
        window.scrollTo(0, 0);
        this.setState({ page: page }, () => this.getBlogData())
    }

    onBlogTypeClick = (title) => {
        this.setState({ blogType: title }, () => this.getBlogData());
    }


    fetchData = () => {
        try {

            this.setState({ isProcessing: true })
            const response = axios.get('https://devprod.rimib.com/api/institutionCategoriesAndSubCategories');
            // setCategories(response.data.data || []);
            let token = JSON.parse(sessionStorage.getItem('token'));
            axios.get("https://devprod.rimib.com/api/institutionCategoriesAndSubCategories", {
                headers: { 'x-access-token': token }
            }).then(res => {
                this.setState({ categoryData: res.data, isLoading: false })
            })

        } catch (err) {
            // setError(err.message || "Something went wrong");
        } finally {
            // setLoading(false);
            this.setState({ isProcessing: false })
        }
    }


    render() {
        return (
            <div>
                <CustomerBlogJsx
                    blogData={this.state.blogData}
                    onPaginationButtonClick={this.onPaginationButtonClick}
                    blogType={this.state.blogType}
                    blogIcon={this.state.blogIcon}
                    onBlogTypeClick={this.onBlogTypeClick}
                    categoryData={this.state.categoryData}
                />
                {this.state.isProcessing && <Loader />}
            </div>
        )
    }
}