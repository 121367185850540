import React from 'react';
import { Component } from 'react';
import { FinalAsktheExpertScreenJSX } from './finalAskTheExpertScreen.jsx';

export default class FinalAskTheExpertScreen extends Component{
    onHomebuttonClick = () =>{
        sessionStorage.clear('askTheExpert')
        // this.props.history.push('/askTheExpert');
    }

    render(){
        return(
            <FinalAsktheExpertScreenJSX
            onHomebuttonClick = {this.onHomebuttonClick}
            />
        )
    }
}