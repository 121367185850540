import React, { useEffect } from 'react'
import Sign_out_squre from "../../../../assets/images/Sign_out_squre.svg";
import call from "../../../../assets/Instituteppvimages/call.svg";
import map from "../../../../assets/Instituteppvimages/map.svg";
import email from "../../../../assets/Instituteppvimages/email.svg";
import Partial from "../myExpertUn/myExpert.js"
import Sidebar from "../../../../components/dashboard/institute/sidebar/sidebar.js";
import InputMask from 'react-input-mask';
import { NEW_URL } from '../../../../constants/applicationConstants';  
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { formatCanadianPhoneNumber } from '../../../../shared/helper'
import "../myExpert/myExpert.model.css"

import logout from "../../../../assets/images/logout.svg";
import myexpertbg from "../../../../assets/Instituteppvimages/myexpertbg.svg";
import { PreNexPagination } from '../../../../components/common/pre_next_pagination/pre_next_pagination';
import swal from "sweetalert";

const MyExpertJsx = (props) => {
  const history = useHistory();
  const { t } = useTranslation();


  const {
    categoryData, formData,
    onInputChange,
    formError,
    onTouchHandler,
    onSubmitButtonClick,
    onViewProfieClick,
    expertData,
    activeTab,
    onTabClick,
    onPaginationButtonClick,
    onDeactivateButtonClick 
  } = props;
  const loginData = JSON.parse(sessionStorage.getItem('loginData'));

  const getCategory = (abhi) => {
    const data = abhi?.expertSpecializations?.map(spec => '<span>' + spec.parentInstitutionCategory + '</span>');
    return data && data.join("")

  }

  const getInstitutionName = () => {
    let tempArr = [];

    expertData?.map(cat => {

      tempArr.push(cat?.slugUrl)
    })
    localStorage.setItem("slugSet", tempArr.join())


    console.log("props.expertData ==>",props.expertData);

  }
  useEffect(() => {
    getInstitutionName()
  });

  const planType = sessionStorage.getItem("planName")
  const addAnotherExpert = () => {
    let token = sessionStorage.getItem("token")
    localStorage.setItem('AdminFlow', 'expert')
    localStorage.setItem("instituteExpertToken", token)
    localStorage.setItem("instituteExpert", true)
    localStorage.setItem("adInsExpert", true)
    sessionStorage.setItem("comingFromInstitute", true)
    history.push("/expertRegistration");
  }


  const deactivate = (expert) => {
    swal({
      title: expert.isActiveByInstitute?t("instituteExpertrs.DEACTIVATEMSG") :t("instituteExpertrs.ACTIVATEMSG") ,
      icon: "warning",
      buttons: [
        t('instituteExpertrs.CANCEL'),
        expert.isActiveByInstitute ? t("instituteExpertrs.DEACTIVATE") : t("instituteExpertrs.ACTIVATE")
      ],
      dangerMode: true,
    }).then(function (isConfirm) { 
      if (isConfirm) {
        onDeactivateButtonClick(expert);
      }
      
    })

  }

  return (
    <>
      <>
        {  activeTab === 'acntStng' ?
          <section className="entiredashboard maincustomerdash main">
            <div className="container">
              <div className="row dashboard-decoration">
                <div className="col-lg-3 col-sm-3">
                  <Sidebar />
                </div>
                <div className="col-lg-9 col-sm-9 dashboard-data">
                  <div  >
                    <div  >
                      <div className="row" >

                        <div className="col-lg-7  col-sm-7">

                          <p className="dahsboardTitle">{t("instituteExpertrs.HEADING")}</p>
                          <p className="dahsboardSubTitle"> <Trans i18nKey="instituteExpertrs.SUBHEADING" ></Trans> </p>
                        </div>
                        <div class="col-lg-5  col-sm-5"  >
                          <div class="logoutbutton">  <img class="logoutImg" src={logout} alt={''} />
                            <p className="dashBoardLogout ">{t("DASHBOARD.LOGOUT")} </p>
                          </div>
                        </div>
                      </div>
                      <div className="dashboardrightpannerinnerright">
                        <div id="logout" className="modal-window">
                          <div>
                            <a title="Close" className="modal-close">{t("instituteExpertrs.CLOSE")}</a>
                            <div className="modal-header">

                            </div><div className="modal-body mb-4">
                              <div className="modal-body-inner">
                                <h2 className="h2_style text-center">{t("instituteExpertrs.LOGOUTTITLE")}</h2>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="ctaonebutton"> Save</button>
                              <button type="button" className="ctatwobutton"> Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row add-expert">
                      <div className="col-6 add-expert-data">
                        <h3 className="add-expert-title">{t("instituteExpertrs.ADDEXPERT")}</h3>
                        <p className="add-expert-subtitle">{t("instituteExpertrs.ADDEXPERTTITLE")}</p>
                        <p className="add-expert-limit">{t("instituteExpertrs.ADDEXPERTLIMIT")} {loginData?.totalExpertsLimit} </p>
                        <div className="addexpertright">
                          {planType && planType === "Premium" ? <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>
                            :
                            <button type="button" className="add-expert-button" onClick={addAnotherExpert} >{t("instituteExpertrs.ADDEXPERTBUTTON")}</button>

                          }
                        </div>
                      </div>
                      <div className="col-6 add-expert-img">
                        <img src={myexpertbg} alt={''} />
                      </div>
                    </div>

                    <div className="myexpert-nav row">
                      <p className="registered-experts col-6">{t("instituteExpertrs.REGEXPERTS")}</p>
                      <p className="partially-registered col-6" onClick={() => onTabClick('partial')}>{t("instituteExpertrs.PARREGEXPERTS")}</p>
                    </div>

                    <div className="expert-section grid">
                      {props?.expertData?.length > 0 ? (
                        props.expertData.map(data => (
                          <div className="expert-div" key={data.slugUrl}>
                            <div className="expert-profile">
                              <img resizeMode={"contain"} src={!data?.imageUrl ? `https://ui-avatars.com/api/?name=${data?.firstName}+${data?.lastName}&rounded=true` : `${NEW_URL}/${data.imageUrl}`} alt={' '} />
                            </div>
                            <div className="plan-name">{data.planName || t("instituteExpertrs.NOTAVAILABLE")}</div>
                            <div className="free-button">Free</div>
                            <div>
                              <h3 className="expert-name">{`${data?.firstName} ${data?.lastName}`}</h3>
                              <div className="expert-category" dangerouslySetInnerHTML={{ __html: getCategory(data) }}></div>
                              <ul>
                                <li className="expert-location"><span><img src={map} alt={''} /></span> {data?.expertSpecializations[0]?.city || "NA"}</li>
                                <li className="expert-call"><span><img src={call} alt={''} /></span> {data.phoneNumber && formatCanadianPhoneNumber(data.phoneNumber)}</li>
                                <li className="expert-email"><span><img src={email} alt={''} /></span> {data.email}</li>
                              </ul>
                            </div>
                            <button className="view-profile-button" onClick={() => onViewProfieClick(data?.slugUrl)}>View Profile</button>
                             <div 
  className={`deactivate-button ${data?.isActiveByInstitute ? 'active' : 'inactive'}`} 
  onClick={() => deactivate(data)}
>
  {t(data?.isActiveByInstitute ? "instituteExpertrs.DEACTIVATE" : "instituteExpertrs.ACTIVATE")}
</div>
                          </div>
                        ))
                      ) : (
                        <h3>{t("instituteExpertrs.NOEXPERTMSG")}</h3>
                      )}
                    </div>


                    {/* {props?.expertData?.length > 6 && <div className="pagination-center"><PreNexPagination
                    limit={6}
                    totalCount={props?.expertData?.length}
                    onPaginationButtonClick={onPaginationButtonClick}/> </div>
                      } */}


                  </div>
                </div>
              </div>
            </div>
          </section>
          : <Partial />}
      </>
    </>

  );
};

export default MyExpertJsx;
