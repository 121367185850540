import React from 'react'
import { useEffect, useState } from 'react';

import { Footer } from '../../../common/footer/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

import Sidebar from "../sidebar/sidebar.js";

export const CustomerAskTheExpertJsx = (props) => {
  const { t } = useTranslation();
  const {
    categoryData,
    formData,
    onInputChange,    
    onTouchHandler,    
    onSubmitButtonClick,    
    onFileChange, 
    profileImagePreviewUrl, 
    onNextButtonClick
    // meta
  } = props;

  const typeData = [
    {
      "label": "Yes",
      "value": "true"
    },
    {
      "label": "No",
      "value": "false"
    }
  ]

  const planDataSet = [
    {
      "label": "Yes",
      "value": "true"
    },
    {
      "label": "No",
      "value": "false"
    }
  ]
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, [])
  
  const [page, setPage] = useState(1);




  const handleOnLoadMore = () => {
    setPage(page + 1);

    // fetchPosts();

  };



  // {t("PRINT.TITLE")}
  return (
    <>
      <section className="entiredashboard admindashboard">
        <div className="container">

          <div className="row">
            <div className="col-lg-3 col-sm-3">


              <div className="dashboardleftpanel martop40">

                <div className="dashboardlink martop40">
                  <Sidebar />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-sm-9">
              <div className="dashboardrightpanel martop40">
                <h2 className="h2_style">Submit Sponsor Adds</h2>
                {
                  profileImagePreviewUrl &&
                  <img
                    src={profileImagePreviewUrl}
                    height="170px"
                    width="250px"
                    alt="edit profile"
                  />

                }
                <div className="uploaddetails">
                  {/* <input type="file" onChange={onFileChange}></input> */}
                  <div className="button_outer">
                    <div className="btn_upload">
                      <input type="file" id="upload_file" name="" onChange={onFileChange} accept="image/png, image/gif, image/jpeg" />
                      <label for="upload_file">Choose Picture</label>
                    </div>
                  </div>
                  <button type="button" className="ctaonebutton" disabled={!profileImagePreviewUrl} onClick={() => onNextButtonClick()}>Upload Picture</button>
                  {/* <button type="button" className="ctaonebutton" onClick={() => changeLogoState(true)}>Change Logo</button> */}
                </div>
              </div>
              <div className="dashboardrightpanel martop40">
                <div className="row">
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="name"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.name}
                      />
                      <div className="placeholder">Title</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="active"
                        value={formData.planFor}
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                                            <option value="">All</option>

                        {typeData?.map((item, i) => {
                          return (
                            <>
                              <option value={item.value} selected={item.value === formData.active}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Active</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="category"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                        <option value="">All</option>
                        {categoryData?.map((item) => {
                          return (
                            <>
                              <option value={item.institutionCategoryId} selected={item.institutionCategoryId === formData.category}>{item.name}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Category</div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <select
                        className="input form-control form-select"
                        placeholder=" "
                        name="isFeatured"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                      >
                                            <option value="">Select</option>

                        {planDataSet?.map((item) => {
                          <option></option>
                          return (
                            <>
                              <option  value={item.value} selected={item.value === formData.subCategory}>{item.label}</option>
                            </>
                          )
                        })}
                      </select>
                      <div className="placeholder">Is Featured</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="description"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.description}
                      />
                      <div className="placeholder">Description</div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    <div className="input-container form-group">
                      <input
                        type="text"
                        className="form-control input"
                        placeholder=" "
                        name="externalUrl"
                        onChange={onInputChange}
                        onBlur={onTouchHandler}
                        value={formData.externalUrl}
                      />
                      <div className="placeholder">External Url</div>
                    </div>
                  </div>
                  


                  <div className="input-container form-group"><button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Submit</button></div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>

      <Footer />
    </>

  );
};

export default CustomerAskTheExpertJsx;