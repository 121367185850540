import React from 'react'
import { Footer } from '../../../common/footer/footer';
import Sidebar from "../sidebar/sidebar.js";

const ManageExpertJsx = (props) => {
    const {
        offerData,
        formData,
        onInputChange,
        onTouchHandler,
        onSubmitButtonClick,
    } = props;


    return (
        <>
            <section className="entiredashboard admindashboard">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-3">


                            <div className="dashboardleftpanel martop40">

                                <div className="dashboardlink martop40">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-9">
                            <div className="dashboardrightpanel martop40">
                                <section classNames="addexpertformsec">
                                    <div className="myadssection">
                                        <h2 className="h2_style martop30">Manage Offers</h2>
                                        <p>Morem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    </div>
                                    <div className="myexpertformarea searcharea martop40">
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="type"
                                                onClick={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="0">Elite</option>
                                                <option value="1">Premium</option>
                                            </select>
                                            <div className="placeholder">Type</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="instituteName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Institution Name</div></div>
                                        <div className="input-container form-group">
                                            <input type="text" className="form-control input" placeholder=" " name="productName"
                                                onChange={onInputChange}
                                                onBlur={onTouchHandler}
                                                value={formData.name} />
                                            <div className="placeholder">Product name of offer</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <select className="input form-control form-select" placeholder=" " name="homeFeatured"
                                                onClick={onInputChange}
                                                onBlur={onTouchHandler}>
                                                <option value="All">All</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <div className="placeholder">Home Featured</div>
                                        </div>
                                        <div className="input-container form-group">
                                            <button type="submit" className="ctaonebutton" onClick={() => onSubmitButtonClick()}>Search</button>
                                        </div>
                                    </div>
                                    <div className="searchdetails">
                                        <table className="searchdetailsdb">
                                            <tr>
                                                <th>Institution Name</th>
                                                <th>Product Name</th>
                                                <th>Description</th>
                                                <th>Product Details</th>
                                                <th>Commission</th>
                                                <th>Home Featured</th>
                                            </tr>
                                            {
                                                offerData?.map(data => {
                                                    return <tr>
                                                        <td>ICICI Bank</td>
                                                        <td>{data.offerName}</td>
                                                        <td>{data.description}</td>
                                                        <td><button type="submit" className="ctaonebutton">Product Details</button></td>
                                                        <td><input type="text" className="tabletextbox" /></td>
                                                        <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                                    </tr>
                                                })
                                            }
                                            {/* <tr>
                                                <td>ICICI Bank</td>
                                                <td>Credit card</td>
                                                <td>First line of disc...</td>
                                                <td><button type="submit" className="ctaonebutton">Product Details</button></td>
                                                <td><input type="text" className="tabletextbox" /></td>
                                                <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                            </tr>
                                            <tr>
                                                <td>ICICI Bank</td>
                                                <td>Credit card</td>
                                                <td>First line of disc...</td>
                                                <td>Product Details</td>
                                                <td><input type="text" className="tabletextbox" /></td>
                                                <td> <label className="container-checkbox"> <input type="checkbox" className="form-check-input" checked=" " /><span className="checkmark"></span></label><button type="submit" className="ctaonebutton">Save</button></td>
                                            </tr> */}
                                        </table>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>

    );
};

export default ManageExpertJsx;